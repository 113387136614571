import React, { useState } from 'react';
import {
  Dispensary,
  FincenCtrReport,
  FincenSarReport,
  Bank,
  getReportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects,
} from '@gcv/shared';
import ReactTooltip from 'react-tooltip';
import { useForm } from 'react-hook-form';

import { GcvDataTable, GcvZeroState, GcvCheckbox, GcvButton, GcvModal, GcvLoading, GcvInputForm } from '../../../lib';
import {
  account,
  dueDate,
  sarType,
  amount,
  dateOfTransaction,
  status,
  exportedOn,
  bsaIdUpdate,
  trackingId,
} from './columns';
import { useApi, getEndpoint } from '../../../hooks/';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';

import {
  ReportSelector,
  Options,
  CurrentReport,
  VerticalCenter,
  Title,
  ModalTextSmall,
  ModalTextLarge,
  SubOptions,
} from './styles';
import { GcvDropButton } from '../../../lib/GcvDropButton/GcvDropButton';
import { DispensaryMap, getTableDataSar, getTableDataCtr } from './reports-util';
import { api } from 'libs/react-ui/src/api';

type ModalType = 'delete' | 'update-bsa' | 'update-tracking' | 'export-xml';

interface Props {
  emitData: (payload) => void;
  reports: { ctrs: FincenCtrReport[]; sars: FincenSarReport[] };
  dispensaryMap: DispensaryMap;
  reportsStatus: 'filed' | 'open';
  reportsType: 'CTR' | 'SAR';
  bank: Bank;
  reportsLoading: Boolean;
}

export const ReportsTable = ({
  emitData,
  reports,
  reportsStatus,
  dispensaryMap,
  reportsType,
  bank,
  reportsLoading,
}: Props) => {
  const apiClient = api();

  const { register, handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });

  const [viewSar, setViewSar] = useState<boolean>(reportsType === 'SAR');
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [selectedRowsData, setSelectedRowsData] = useState<FincenCtrReport | FincenSarReport[]>([]);
  const [toggledClearRows, setToggledClearRows] = useState<boolean>(false);

  const tableDataSar = getTableDataSar(reports.sars, dispensaryMap);

  const tableDataCtr = getTableDataCtr(reports.ctrs, dispensaryMap);

  const onSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRowsData(selectedRows);
  };

  const handleSwitchTable = () => {
    setViewSar(!viewSar);
  };

  const handleRowClick = row => {
    emitData({ type: 'viewReport', reportId: row.id });
  };

  const resetState = () => {
    toggleModal();
    setLoading(false);
    setToggledClearRows(!toggledClearRows);
    setSelectedRowsData([]);
    emitData({ type: 'getReports' });
  };

  const toggleModal = (type?: ModalType, rowInfo?: any) => {
    if (type) {
      setModalType(type);
      if (rowInfo) setSelectedRowsData([rowInfo]);
    }
    setModalOpen(!modalOpen);
  };

  const handleModalConfirm = formData => {
    setLoading(true);
    if (modalType === 'update-bsa') {
      handleUpdateReport('bsaId', formData);
    } else if (modalType === 'update-tracking') {
      handleUpdateReport('trackingId', formData);
    } else if (modalType === 'delete') {
      handleDeleteReports();
    } else if (modalType === 'export-xml') {
      handleExportReport();
    }
  };

  const handleUpdateReport = (type: 'trackingId' | 'bsaId', formData) => {
    Promise.all(
      selectedRowsData.map(({ report }) => {
        report[type] = formData[type];
        let cleanedReport = report;
        if (report.green_check_transactions) {
          cleanedReport = getReportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects(report);
        }
        return apiClient.fincen.fincenReportByIdPutHandler(bank.id, cleanedReport, () => {});
      })
    )
      .catch(err => {
        alert('error updating the fincen reports' + err);
      })
      .finally(() => {
        resetState();
      });
  };

  const handleExportReport = async () => {
    const body = {
      selectedReportIds: selectedRowsData.map(row => row.id),
    };

    await apiClient.fincen
      .fincenReportXmlPostHandler(body, () => {})
      .then(({ fileName, fileData }) => {
        downloadXML(fileName, fileData);
      })
      .catch(err => {
        alert('error posting the fincen reports' + err);
      })
      .finally(() => {
        resetState();
      });
  };

  const handleDeleteReports = () => {
    Promise.all(
      selectedRowsData.map(row => {
        return apiClient.fincen.fincenReportDeleteById(row.id, () => {});
      })
    )
      .catch(err => {
        alert('error deleting the fincen reports' + err);
      })
      .finally(() => {
        resetState();
      });
  };

  const downloadXML = (fileName, fileData) => {
    const linkSource = `data:application/xml;base64,${fileData}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const columnsCtr =
    reportsStatus === 'open'
      ? [
          account('Account'),
          dueDate('Due Date'),
          amount('Amount'),
          dateOfTransaction('Transaction Date'),
          status('Status', handleRowClick),
        ]
      : [
          account('Account'),
          exportedOn('Exported On'),
          amount('Amount'),
          dateOfTransaction('Transaction Date'),
          trackingId('Tracking ID', toggleModal),
          bsaIdUpdate('BSA ID', toggleModal),
        ];
  const columnsSar =
    reportsStatus === 'open'
      ? [account('Account'), sarType('Type'), dueDate('Due Date'), amount('Amount'), status('Status', handleRowClick)]
      : [
          account('Account'),
          sarType('Type'),
          exportedOn('Exported On'),
          amount('Amount'),
          trackingId('Tracking ID', toggleModal),
          bsaIdUpdate('BSA ID', toggleModal),
        ];

  const headerText = `You currently have no open ${viewSar ? 'SAR' : 'CTR'} FinCEN reports.`;
  const subText = 'Sit tight, Green Check will notify you when a new report is created.';

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'Fincen Report must be complete before exporting';
    return props.disabled ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  const batchActionsDisabled = selectedRowsData.length < 1;
  const exportDisabled = batchActionsDisabled || selectedRowsData.filter(row => row.status !== 'complete').length > 0;

  const modalTitles = {
    'update-bsa': `Update BSA ID`,
    'update-tracking': `Update Tracking ID`,
    'export-xml': `All Set?`,
    delete: `Delete ${selectedRowsData.length} reports?`,
  };

  const modalButtonText = {
    'update-bsa': `Update`,
    'update-tracking': `Update`,
    'export-xml': `Export`,
    delete: `Delete`,
  };

  const exportXMLButton = {
    title: 'Export XML',
    onClick: () => toggleModal('export-xml'),
    toolTip: reportsStatus === 'open' ? 'Only completed reports can be exported in XML format' : null,
    disabled: exportDisabled,
  };
  const updateTrackingIdButton = {
    title: 'Update Tracking ID',
    onClick: () => toggleModal('update-tracking'),
    disabled: batchActionsDisabled,
  };
  const deleteButton = { title: 'Delete', onClick: () => toggleModal('delete'), disabled: batchActionsDisabled };
  const updateBsaIdButton = {
    title: 'Update BSA ID',
    onClick: () => toggleModal('update-bsa'),
    disabled: batchActionsDisabled,
  };

  return (
    <>
      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        backButton={<GcvButton onClick={() => toggleModal()}>Cancel</GcvButton>}
        continueButton={
          <GcvButton
            primary={modalType != 'delete'}
            danger={modalType === 'delete'}
            onClick={handleSubmit(handleModalConfirm)}
          >
            {modalButtonText[modalType]}
          </GcvButton>
        }
        title={modalTitles[modalType]}
        loading={loading}
      >
        {
          <>
            {modalType === 'delete' ? (
              <>
                <ModalTextSmall>
                  Deleted reports will be removed from this view and permanently deleted 30 days after. Permanently
                  deleted reports can not be recovered.
                </ModalTextSmall>
                <ModalTextLarge>Are you sure you want to delete the selected reports?</ModalTextLarge>
              </>
            ) : null}
            {modalType === 'export-xml'
              ? `You won't be able to edit a report once it's been exported, so please confirm that you're ready to file this report by clicking the button below.`
              : null}
            {modalType === 'update-tracking' ? (
              <GcvInputForm {...form} name="trackingId" label={`Enter the Tracking ID`} />
            ) : null}
            {modalType === 'update-bsa' ? <GcvInputForm {...form} name="bsaId" label={`Enter the BSA ID`} /> : null}
          </>
        }
      </GcvModal>

      <Options>
        <SubOptions>
          <Title>{viewSar ? 'Suspicious Activity Reports' : 'Currency Transaction Reports'}</Title>
          <VerticalCenter>
            <ReportSelector onClick={handleSwitchTable}>
              Switch to:
              <CurrentReport>{viewSar ? 'Currency Transaction Reports' : 'Suspicious Activity Reports'}</CurrentReport>
            </ReportSelector>
          </VerticalCenter>
        </SubOptions>
        <GcvDropButton
          title={'Actions'}
          secondary={true}
          style={{ margin: '0', display: 'flex', justifyContent: 'flex-end', width: '12rem', boxSizing: 'border-box' }}
          buttons={
            reportsStatus === 'open'
              ? [exportXMLButton, deleteButton]
              : [updateTrackingIdButton, updateBsaIdButton, exportXMLButton, deleteButton]
          }
        ></GcvDropButton>
      </Options>
      {reportsLoading ? (
        <GcvLoading></GcvLoading>
      ) : (
        <GcvDataTable
          data={viewSar ? tableDataSar : tableDataCtr}
          columns={viewSar ? columnsSar : columnsCtr}
          onRowClicked={handleRowClick}
          defaultSortField={reportsStatus === 'open' ? 'due_date' : 'exported_on'}
          defaultSortAsc={true}
          keyField="id"
          noDataComponent={<GcvZeroState basic={true} headerText={headerText} subText={subText} />}
          subHeaderAlign="left"
          selectableRows={true}
          onSelectedRowsChange={onSelectedRowsChange}
          paginationPerPage={10}
          selectableRowsComponent={renderSelectComponent}
          clearSelectedRows={toggledClearRows}
        />
      )}
    </>
  );
};
