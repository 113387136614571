<div class="amplify-container" data-cy="forgot-password.component">
  <div class="amplify-form-container">
    <img src="../../assets/gcv_logo_alt.png" class="logo" width="150px" />
    <div class="amplify-form-body">
      <div class="amplify-form-header">Reset Your Password</div>
      <div class="amplify-form-text" *ngIf="!code_sent">You will receive a verification code</div>
      <div class="amplify-form-text" *ngIf="code_sent">
        Check your email for the code we sent. Enter that code and set your new password.
      </div>
      <div class="amplify-form-row" *ngIf="!code_sent">
        <mat-form-field class="example-full-width" [formGroup]="userData">
          <input
            autocomplete="off"
            matInput
            type="Email"
            placeholder="Your email"
            formControlName="email"
            data-cy="forgot-password.component.email"
          />
          <mat-error *ngIf="userData.controls.email.errors?.email">
            Please enter a valid email
          </mat-error>
        </mat-form-field>
      </div>
      <div class="amplify-form-row" *ngIf="code_sent">
        <mat-form-field class="example-full-width" [formGroup]="formData">
          <input matInput placeholder="Code" formControlName="code" data-cy="forgot-password.component.code" />
          <mat-error *ngIf="formData.controls.code.errors?.required">
            Please enter the code you received at your email address.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="amplify-form-row" *ngIf="code_sent">
        <mat-form-field class="example-full-width" [formGroup]="formData">
          <input
            matInput
            type="password"
            placeholder="New Password"
            formControlName="newPass"
            data-cy="forgot-password.component.newPassword"
          />
          <mat-error *ngIf="formData.controls.newPass.errors?.required">
            A new password is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="formData.controls.newPass.errors?.minlength">
            Your password must be atleast 8 characters
          </mat-error>
        </mat-form-field>
      </div>
      <div class="amplify-form-row" *ngIf="code_sent">
        <mat-form-field class="example-full-width" [formGroup]="formData">
          <input
            matInput
            type="password"
            placeholder="Confirm New Password"
            formControlName="newPassCheck"
            data-cy="forgot-password.component.confirmPassword"
          />
          <mat-error *ngIf="formData.controls.newPassCheck.errors?.equalTo">
            Your new password does not match
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="code_sent">
        <password-strength-meter
          *ngIf="formData.controls.newPass.value.length > 0"
          [password]="formData.controls.newPass.value"
        ></password-strength-meter>
        <div class="passwordRequirements">
          <span>
            Requirements:
          </span>
          <ul>
            <li [ngClass]="requirements.length && 'fulfilled'">10 or more characters</li>
            <li [ngClass]="requirements.lower && 'fulfilled'">A lowercase letter</li>
            <li [ngClass]="requirements.number && 'fulfilled'">A number</li>
            <li [ngClass]="requirements.upper && 'fulfilled'">An uppercase letter</li>
            <li [ngClass]="requirements.special && 'fulfilled'">A special character</li>
          </ul>
        </div>
      </div>

      <div class="amplify-form-actions">
        <div class="amplify-form-cell-right">
          <gcv-button
            class="fullWidth"
            (clickEvent)="onSend()"
            [disabled]="!isEmailValid()"
            buttonText="Submit"
            type="primary full-width large"
            *ngIf="!code_sent"
            data-cy="forgot-password.component.submit"
          ></gcv-button>
          <gcv-button
            *ngIf="code_sent"
            class="fullWidth"
            (clickEvent)="onSubmit()"
            [disabled]="!isValid()"
            buttonText="Verify"
            type="primary full-width large"
            data-cy="forgot-password.component.verify"
          ></gcv-button>
        </div>

        <div class="amplify-form-cell-left">
          <div class="amplify-form-actions-left">
            <a
              *ngIf="code_sent"
              class="amplify-form-link"
              (click)="onSend()"
              data-cy="forgot-password.component.resendCode"
              >Resend Code</a
            >
            <a
              *ngIf="!code_sent"
              class="amplify-form-link"
              (click)="onSignIn()"
              data-cy="forgot-password.component.backToSignIn"
              >Back to Sign in</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="amplify-alert" *ngIf="errorMessage">
    <div class="amplify-alert-body">
      <span class="amplify-alert-icon">&#9888;</span>
      <div class="amplify-alert-message" data-cy="forgot-password.component.errorMessage">{{ errorMessage }}</div>
      <a class="amplify-alert-close" (click)="onAlertClose()" data-cy="forgot-password.component.alertClose">&times;</a>
    </div>
  </div>
</div>
