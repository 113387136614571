import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { GlobalErrorService } from './globalError.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '@user-interface/gcv-ui';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LaunchDarklyService } from './launch-darkley/launchdarkley.service';
import { RollbarService } from './rollbar/rollbar.service';
import { v1 as uuidv1 } from 'uuid';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private errorService: GlobalErrorService,
    private injector: Injector,
    private location: LocationStrategy
  ) {}
  handleError(error: Error | HttpErrorResponse) {
    const errorHasMessageProp = error.message;
    try {
      if (errorHasMessageProp) {
        // see: https://github.com/rollbar/rollbar.js/blob/master/dist/rollbar.js#L4410-L4412
        const rollbar = this.injector.get(RollbarService);
        rollbar.error(error);
      }
    } catch (e) {
      console.log(e);
    }

    const id = uuidv1();
    const source = this.location instanceof PathLocationStrategy ? this.location.path() : location.pathname;
    const type = error instanceof HttpErrorResponse ? 'SERVER' : 'CLIENT';
    const data = errorHasMessageProp ? error.message : error.toString();
    this.errorService.setErrorEvent({ id: id, source: source, type: type, data: data });
  }
}
