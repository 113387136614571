import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  GcvButton,
  GcvDataTable,
  GcvCheckbox,
  GcvInputSelect,
  GcvZeroState,
  GcvLoading,
  GcvTimePeriodDropdown,
} from '../../../lib';
import { DepositDetailDrawer } from '../../../shared/Deposits';
import { finalDeposit, status, crbAccount, arrivalDate } from './columns';
import { useForm } from 'react-hook-form';
import calculateDateRange, { timePeriodOptions } from '../../../util/calculateDateRange';
import { DateTime, Interval } from 'luxon';
import _ from 'lodash';

import styled, { createGlobalStyle } from 'styled-components';
import { IANATimezones } from '@gcv/shared';

interface Props {
  current: boolean;
  timezone: IANATimezones;
}

const TabContainer = styled.div`
  height: 30rem;
`;

const Tab = styled.div`
  background: #ffffff;
  border: 1px solid #ebeef7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 45px;
  width: 60%;
  margin: 0 auto;

  h1 {
    margin-bottom: 30px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const Label = styled.label`
  margin: 5px 0px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  input {
    margin 0px 10px;
  }
`;

const FormContent = styled.div`
  margin: 5px 0px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  next: (data) => void;
  back: () => void;
  data: any;
  onTimePeriodFilter: any;
}

export const CreateReportTab3 = ({ next, back, data, timezone }: Props) => {
  const filterDepositsByAccount = ({ deposits, account }) =>
    account ? deposits.filter(deposit => deposit.dispensary_id === account.value) : [];
  const [deposits, setDeposits] = useState(filterDepositsByAccount(data));

  useEffect(() => {
    setDeposits(filterDepositsByAccount(data));
  }, [data.deposits]);

  const headerText = 'There are no Green Check transactions available for this report';
  const subText = `You either did not choose an account to associate with this report, or the account you chose does not have any transactions for this time period.`;

  const [selectedDepositId, setSelectedDepositId] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const form = useForm();

  useEffect(() => {
    setDeposit(deposits.find(el => el.deposit_id === selectedDepositId));
  }, [selectedDepositId, deposits]);

  const handleRowClick = rowData => {
    setSelectedDepositId(rowData.deposit_id);
  };

  const clearSelectedDepositId = () => {
    setSelectedDepositId(null);
  };

  const columns = [
    crbAccount('Account', data.dispensaries),
    finalDeposit('Amount'),
    arrivalDate('Date Arrived'),
    status('Status', handleRowClick),
  ];

  const onSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRowsData(selectedRows);
  };

  const handleSubmit = () => {
    setLoading(true);
    next({ selectedDeposits: selectedRowsData });
  };

  const onBack = () => {
    back();
  };

  const isRowDisabled = row => {
    return row.status !== 'accepted';
  };

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'Reconcile this deposit to associate it with this report';
    return props.disabled ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  const onTimePeriodFilter = val => {
    if (val) {
      const { start, end } = calculateDateRange(val.value, timezone);

      const startDate = DateTime.fromISO(start);
      const endDate = DateTime.fromISO(end);
      const interval = Interval.fromDateTimes(startDate, endDate);
      const result = filterDepositsByAccount(data).filter(accountDeposit =>
        interval.contains(DateTime.fromISO(accountDeposit.deposit_date))
      );

      setDeposits(result);
    }
  };

  const sort = (rows, field: any = '', direction) => {
    if (field === 'date') {
      return _.orderBy(
        rows,
        ({ arrived_date, deposit_date }) => (arrived_date ? arrived_date : deposit_date),
        direction
      );
    }
    return _.orderBy(rows, field, direction);
  };

  if (loading) {
    return <GcvLoading />;
  }

  return (
    <>
      <TabContainer>
        <Header>
          <h1>For record keeping purposes, select any deposits associated with this report (optional):</h1>
          <div style={{ width: '300px' }} data-cy="time-period-select">
            <GcvTimePeriodDropdown emitData={onTimePeriodFilter} />
          </div>
        </Header>
        <GcvDataTable
          data={deposits}
          columns={columns}
          onRowClicked={handleRowClick}
          keyField="react_key"
          defaultSortField="date"
          defaultSortAsc={false}
          selectableRows={true}
          onSelectedRowsChange={onSelectedRowsChange}
          paginationPerPage={6}
          selectableRowsComponent={renderSelectComponent}
          selectableRowDisabled={isRowDisabled}
          sortFunction={sort}
          noDataComponent={<GcvZeroState basic={true} headerText={headerText} subText={subText} />}
        />

        <DepositDetailDrawer
          open={selectedDepositId && deposit}
          onClose={clearSelectedDepositId}
          deposit={deposit}
          emitData={data.reconcileDeposit}
          bankId={data.bankId}
          userMap={data.staff}
          type={'bank'}
          dispensaryMap={data.dispensaries}
        />
      </TabContainer>
      <Buttons>
        <GcvButton onClick={onBack} tertiary={true} data-cy="back-button">
          Back
        </GcvButton>
        <GcvButton onClick={handleSubmit} primary={true} data-cy="next-button">
          Complete
        </GcvButton>
      </Buttons>
    </>
  );
};
