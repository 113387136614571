import React from 'react';

import { DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { AreaConfig } from './chart-options';
import { DateTime } from 'luxon';
import { tooltip } from './Tooltip';
import { formatMoney } from 'libs/react-ui/src/util';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TotalSalesAndDepositsAreaChart: React.FC<Props> = props => {
  return <GcvChart type="area" config={chartDataAdapter(props.review)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData) => {
  return dataAdapter(review.data.daily_distribution);
};

export const dataAdapter = (data: {
  [date: string]: {
    sales: number;
    deposits: number;
  };
}): AreaConfig => {
  return {
    categories: [
      {
        category: Object.keys(data)
          .sort((a, b) =>
            DateTime.fromFormat(a, 'yyyy-MM-dd') > DateTime.fromFormat(b, 'yyyy-MM-dd')
              ? 1
              : DateTime.fromFormat(b, 'yyyy-MM-dd') > DateTime.fromFormat(a, 'yyyy-MM-dd')
              ? -1
              : 0
          )
          .map(d => {
            return { label: DateTime.fromFormat(d, 'yyyy-MM-dd').day.toString() };
          }),
      },
    ],
    dataset: [
      {
        seriesName: 'Deposits',
        color: '#1270FC',
        renderAs: 'bar2d',
        data: Object.keys(data)
          .sort((a, b) =>
            DateTime.fromFormat(a, 'yyyy-MM-dd') > DateTime.fromFormat(b, 'yyyy-MM-dd')
              ? 1
              : DateTime.fromFormat(b, 'yyyy-MM-dd') > DateTime.fromFormat(a, 'yyyy-MM-dd')
              ? -1
              : 0
          )
          .map(d => {
            return {
              value: data[d].deposits / 100,
              toolText: tooltip('Deposits', [
                { label: 'Total', value: formatMoney(data[d].deposits) },
                { label: 'Date', value: DateTime.fromFormat(d, 'yyyy-MM-dd').toLocaleString() },
              ]),
            };
          }),
      },
      {
        seriesName: 'Sales',
        color: '#00BC66',
        renderAs: 'area',
        alpha: 20,
        data: Object.keys(data)
          .sort((a, b) =>
            DateTime.fromFormat(a, 'yyyy-MM-dd') > DateTime.fromFormat(b, 'yyyy-MM-dd')
              ? 1
              : DateTime.fromFormat(b, 'yyyy-MM-dd') > DateTime.fromFormat(a, 'yyyy-MM-dd')
              ? -1
              : 0
          )
          .map(d => {
            return {
              value: data[d].sales / 100,
              toolText: tooltip('Sales', [
                { label: 'Total', value: formatMoney(data[d].sales) },
                { label: 'Date', value: DateTime.fromFormat(d, 'yyyy-MM-dd').toLocaleString() },
              ]),
            };
          }),
      },
    ],
  };
};
