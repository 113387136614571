import React, { useEffect, useState } from 'react';
import {
  FincenCtrReport,
  FincenSarReport,
  Bank,
  getReportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects,
} from '@gcv/shared';
import ReactTooltip from 'react-tooltip';
import { useForm } from 'react-hook-form';

import {
  GcvDataTable,
  GcvZeroState,
  GcvCheckbox,
  GcvButton,
  GcvModal,
  GcvLoading,
  GcvInputForm,
  GcvInputSelectNew,
  GcvSearchDropDown,
} from '../../../lib';
import {
  account,
  dueDate,
  sarType,
  amount,
  dateOfTransaction,
  reportStatus,
  exportedOn,
  bsaIdUpdate,
  trackingId,
} from './columns';
import { GcvDropButton } from '../../../lib/GcvDropButton/GcvDropButton';
import { DispensaryMap, getTableDataSar, getTableDataCtr } from '../reports-util';

import styled from 'styled-components';

import { $blue, $black, $danger, $primary } from '../../../util/styleVariables';
import { api } from 'libs/react-ui/src/api';

const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: center;
  margin: 0 0 2rem 0;
`;

const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 1rem 0 0;
`;

const SubOptions = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  margin: 0.5rem 0 0 0;
`;

const ModalTextSmall = styled.div`
  color: ${$black};
  text-align: center;
  padding: 20px;
`;

const ModalTextLarge = styled.div`
  color: ${$black};
  font-weight: 600;
  padding: 20px;
  text-align: center;
`;

type ModalType = 'delete' | 'update-bsa' | 'update-tracking' | 'export-xml';

interface Props {
  emitData: (payload) => void;
  reports: { ctrs: FincenCtrReport[]; sars: FincenSarReport[] };
  dispensaryMap: DispensaryMap;
  reportsStatus: 'filed' | 'open';
  reportsType: 'CTR' | 'SAR';
  bank: Bank;
  reportsLoading: Boolean;
  setReportsType: (reportType: string) => any;
}

export const FincenTabReports = ({
  emitData,
  reports,
  reportsStatus,
  dispensaryMap,
  reportsType,
  setReportsType,
  bank,
  reportsLoading,
}: Props) => {
  const apiClient = api();
  const { register, handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [selectedRowsData, setSelectedRowsData] = useState<FincenCtrReport | FincenSarReport[]>([]);
  const [toggledClearRows, setToggledClearRows] = useState<boolean>(false);
  const [filteredDispensaryMap, setFilteredDispensaryMap] = useState(dispensaryMap);
  const [searchDropDownOptions, setSearchDropDownOptions] = useState(
    Object.values(dispensaryMap).map(disp => {
      return { label: disp.name, value: disp.id };
    })
  );

  useEffect(() => {
    setFilteredDispensaryMap(dispensaryMap);
    setSearchDropDownOptions(
      Object.values(dispensaryMap).map(disp => {
        return { label: disp.name, value: disp.id };
      })
    );
  }, [dispensaryMap]);

  const filteredSars = reports.sars.filter(sar => filteredDispensaryMap[sar.dispensary_id]);
  const filteredCtrs = reports.ctrs.filter(ctr => filteredDispensaryMap[ctr.dispensary_id]);

  const tableDataCtr = getTableDataCtr(filteredCtrs, dispensaryMap, bank.iana_timezone);
  const tableDataSar = getTableDataSar(filteredSars, dispensaryMap, bank.iana_timezone);

  const onSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRowsData(selectedRows);
  };

  const handleSwitchTable = reportType => {
    setReportsType(reportType);
  };

  const handleDispensaryFilter = (ids: string[]) => {
    const newMap = ids.reduce((acc, id) => {
      return { ...acc, [id]: dispensaryMap[id] };
    }, {});
    setFilteredDispensaryMap(newMap);
  };

  const handleRowClick = row => {
    emitData({ type: 'viewReport', reportId: row.id });
  };

  const resetState = () => {
    toggleModal();
    setLoading(false);
    setToggledClearRows(!toggledClearRows);
    setSelectedRowsData([]);
  };

  const toggleModal = (type?: ModalType, rowInfo?: any) => {
    if (type) {
      setModalType(type);
      if (rowInfo) setSelectedRowsData([rowInfo]);
    }
    setModalOpen(!modalOpen);
  };

  const handleModalConfirm = formData => {
    setLoading(true);
    if (modalType === 'update-bsa' || modalType === 'update-tracking') {
      handleUpdateReport(formData);
    } else if (modalType === 'delete') {
      handleDeleteReports();
    } else if (modalType === 'export-xml') {
      handleExportReport();
    }
  };

  const handleUpdateReport = formData => {
    Promise.all(
      selectedRowsData.map(({ report }) => {
        const request = {
          trackingId: formData.trackingId,
          bsaId: formData.bsaId,
        };
        return apiClient.fincen
          .fincenReportByIdTrackingPutHandler(bank.id, report.id, request, () => {})
          .then(updatedReport => {
            emitData({ type: 'updateReport', reports: [updatedReport] });
          });
      })
    )
      .catch(err => {
        alert('error updating the fincen reports' + err);
      })
      .finally(() => {
        resetState();
      });
  };

  const handleExportReport = async () => {
    const body = {
      selectedReportIds: selectedRowsData.map(row => row.id),
    };

    await apiClient.fincen
      .fincenReportXmlPostHandler(body, () => {})
      .then(({ fileName, fileData }) => {
        downloadXML(fileName, fileData);
        emitData({ type: 'getReports', bankId: bank.id });
      })
      .catch(err => {
        alert('error posting the fincen reports' + err);
      })
      .finally(() => {
        resetState();
      });
  };

  const handleDeleteReports = async () => {
    Promise.all(
      selectedRowsData.map(row => {
        return apiClient.fincen
          .fincenReportDeleteById(row.id, () => {})
          .then(() => {
            emitData({ type: 'deleteReport', id: row.id });
          });
      })
    )
      .catch(err => {
        alert('error deleting the fincen reports' + err);
      })
      .finally(() => {
        resetState();
      });
  };

  const downloadXML = (fileName, fileData) => {
    const linkSource = `data:application/xml;base64,${fileData}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const columnsCtr =
    reportsStatus === 'open'
      ? [
          account('Account'),
          dueDate('Due Date'),
          amount('Amount'),
          dateOfTransaction('Transaction Date'),
          reportStatus('Status', handleRowClick),
        ]
      : [
          account('Account'),
          exportedOn('Exported On'),
          amount('Amount'),
          dateOfTransaction('Transaction Date'),
          trackingId('Tracking ID', toggleModal),
          bsaIdUpdate('BSA ID', toggleModal),
        ];
  const columnsSar =
    reportsStatus === 'open'
      ? [
          account('Account'),
          sarType('Type'),
          dueDate('Due Date'),
          amount('Amount'),
          reportStatus('Status', handleRowClick),
        ]
      : [
          account('Account'),
          sarType('Type'),
          exportedOn('Exported On'),
          amount('Amount'),
          trackingId('Tracking ID', toggleModal),
          bsaIdUpdate('BSA ID', toggleModal),
        ];

  const headerText = `You currently have no ${reportsStatus} ${reportsType} FinCEN reports.`;
  const subText = 'Sit tight, Green Check will notify you when a new report is created.';

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'Fincen Report must be complete before exporting';
    return props.disabled ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  const batchActionsDisabled = selectedRowsData.length < 1;
  const exportDisabled = batchActionsDisabled || selectedRowsData.filter(row => row.status !== 'complete').length > 0;

  const modalTitles = {
    'update-bsa': `Update BSA ID`,
    'update-tracking': `Update Tracking ID`,
    'export-xml': `All Set?`,
    delete: `Delete ${selectedRowsData.length} reports?`,
  };

  const modalButtonText = {
    'update-bsa': `Update`,
    'update-tracking': `Update`,
    'export-xml': `Export`,
    delete: `Delete`,
  };

  const exportXMLButton = {
    title: 'Export XML',
    onClick: () => toggleModal('export-xml'),
    toolTip: reportsStatus === 'open' ? 'Only completed reports can be exported in XML format' : null,
    disabled: exportDisabled,
  };
  const updateTrackingIdButton = {
    title: 'Update Tracking ID',
    onClick: () => toggleModal('update-tracking'),
    disabled: batchActionsDisabled,
  };
  const deleteButton = { title: 'Delete', onClick: () => toggleModal('delete'), disabled: batchActionsDisabled };
  const updateBsaIdButton = {
    title: 'Update BSA ID',
    onClick: () => toggleModal('update-bsa'),
    disabled: batchActionsDisabled,
  };

  return (
    <>
      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        backButton={<GcvButton onClick={() => toggleModal()}>Cancel</GcvButton>}
        continueButton={
          <GcvButton
            primary={modalType != 'delete'}
            danger={modalType === 'delete'}
            onClick={handleSubmit(handleModalConfirm)}
          >
            {modalButtonText[modalType]}
          </GcvButton>
        }
        title={modalTitles[modalType]}
        loading={loading}
      >
        {
          <>
            {modalType === 'delete' ? (
              <>
                <ModalTextSmall>
                  Deleted reports will be removed from this view and permanently deleted 30 days after. Permanently
                  deleted reports can not be recovered.
                </ModalTextSmall>
                <ModalTextLarge>Are you sure you want to delete the selected reports?</ModalTextLarge>
              </>
            ) : null}
            {modalType === 'export-xml'
              ? `You won't be able to edit a report once it's been exported, so please confirm that you're ready to file this report by clicking the button below.`
              : null}
            {modalType === 'update-tracking' ? (
              <GcvInputForm {...form} name="trackingId" label={`Enter the Tracking ID`} />
            ) : null}
            {modalType === 'update-bsa' ? <GcvInputForm {...form} name="bsaId" label={`Enter the BSA ID`} /> : null}
          </>
        }
      </GcvModal>

      <Options>
        <SubOptions>
          <SelectContainer>
            <GcvInputSelectNew
              label="Type"
              options={[
                { label: 'Currency Transaction Reports', value: 'CTR' },
                { label: 'Suspicious Activity Reports', value: 'SAR' },
              ]}
              onChange={e => handleSwitchTable(e.value)}
              dropWidth={'260px'}
              labelWidth={'60px'}
              dropCharLength={35}
              defaultValueDrop={reportsType}
            />
          </SelectContainer>
          <SelectContainer>
            <GcvSearchDropDown distinctAccounts={searchDropDownOptions} filterReport={handleDispensaryFilter} />
          </SelectContainer>
        </SubOptions>
        <GcvDropButton
          title={'Actions'}
          secondary={true}
          style={{ margin: '0', display: 'flex', justifyContent: 'flex-end', width: '12rem', boxSizing: 'border-box' }}
          buttons={
            reportsStatus === 'open'
              ? [exportXMLButton, deleteButton]
              : [updateTrackingIdButton, updateBsaIdButton, exportXMLButton, deleteButton]
          }
        ></GcvDropButton>
      </Options>
      {reportsLoading ? (
        <GcvLoading></GcvLoading>
      ) : (
        <GcvDataTable
          data={reportsType === 'SAR' ? tableDataSar : tableDataCtr}
          columns={reportsType === 'SAR' ? columnsSar : columnsCtr}
          onRowClicked={handleRowClick}
          defaultSortField={reportsStatus === 'open' ? 'due_date' : 'exported_on'}
          defaultSortAsc={true}
          keyField="id"
          noDataComponent={<GcvZeroState basic={true} headerText={headerText} subText={subText} />}
          subHeaderAlign="left"
          selectableRows={true}
          onSelectedRowsChange={onSelectedRowsChange}
          paginationPerPage={10}
          selectableRowsComponent={renderSelectComponent}
          clearSelectedRows={toggledClearRows}
        />
      )}
    </>
  );
};
