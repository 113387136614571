import React, { useState } from 'react';
import * as base64arraybuffer from 'base64-arraybuffer';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import {
  GcvModal,
  GcvButton,
  GcvInputFile,
  GcvLoading,
  GcvInputSelect,
  GcvInputForm,
  dateFormatterToISO,
} from '../../../lib';
import { SubTitle, UploadContainer, VerticalSpacer } from './styles';
import { User, LicenseManager } from '@gcv/shared';

import { useApi, getEndpoint, getV2Endpoint } from '../../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { environment } from '../../../../../../apps/user-interface/src/environments/environment';
import { api } from 'libs/react-ui/src/api';

interface UploadDocumentProps {
  updateApplication: (dispensary?) => void;
  toggleModal: () => void;
  modalOpen: boolean;
  requirementName: string;
  dispensaryId: string;
  dispensaryState: string;
  requirementId: string;
  documentType: 'document' | 'license';
  userMap: { [id: string]: User };
  subTitle?: string;
  userType: 'bank' | 'dispensary';
}

export const UploadDocumentModal = ({
  updateApplication,
  requirementName,
  dispensaryId,
  dispensaryState,
  requirementId,
  documentType,
  toggleModal,
  modalOpen,
  userType,
  subTitle,
}: UploadDocumentProps) => {
  const apiClient = api();
  const { register, handleSubmit, ...form } = useForm();

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const onSubmit = data => {
    uploadDocuments(files, data);
    setLoading(true);
  };

  const uploadDocuments = async (blobs, data?) => {
    Promise.all(blobs.map(async blob => handleAddDocumentToS3({ blob: blob, userType })))
      .then(files => {
        updateFileData(files, data);
      })
      .catch(err => {
        alert('Error uploading document to S3, please contact support@greencheckverified.com' + err);
      });
  };

  const readFileAsDataURL = async file => {
    const result_base64 = await new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onloadend = e => {
        const arrayBuffer = e.target['result'];
        if (typeof arrayBuffer !== 'string') {
          resolve(base64arraybuffer.encode(arrayBuffer));
        }
      };
      fileReader.readAsArrayBuffer(file);
    }).catch(e => console.log(e));

    return result_base64;
  };

  const handleAddDocumentToS3 = async ({ blob, userType }) => {
    const result_base64 = await readFileAsDataURL(blob);
    const initial_s3_key = `${dispensaryId}/${blob.name}`;
    const config = {
      document: { file_name: blob.name, data: result_base64 },
      userType: userType,
      orgId: dispensaryId,
      s3_key: initial_s3_key,
    };

    try {
      if (!config.document.data) {
        console.log(`Document data was null or missing, ${config.document}`);
        throw new Error();
      }
      const { s3LinkPath, s3_key } = await getPresignedUrl(config);
      await uploadfileToS3(s3LinkPath, config.document.data);

      return {
        filename: blob.name,
        s3_key: s3_key,
      };
    } catch (e) {
      alert('Error uploading document, please contact support@greencheckverified.com');
    }
  };

  const getPresignedUrl = async config => {
    const { s3_key, userType } = config;

    const body = {
      bucket: `${environment.env}-org-documents-file-bucket`,
      key: s3_key,
      action: 'put',
      userType,
    };

    return await apiClient.permissions.getS3Permission(body, () => {});
  };

  const uploadfileToS3 = async (fileuploadurl, file) => {
    try {
      return fetch(fileuploadurl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/plain',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: file, // body data type must match "Content-Type" header
      });
    } catch (e) {
      console.log(e);
    }
  };

  const updateFileData = (files, data) => {
    const documents = files.map(file => {
      return { ...file, req_name: requirementName };
    });

    const license_data =
      documentType === 'license'
        ? {
            license_number: data.license_number,
            license_name: requirementId === 'business_licenses' ? data.license_name.value : data.license_name,
            expiration_date: dateFormatterToISO(data.expiration_date),
          }
        : undefined;

    const body = {
      documents,
      license_data,
    };

    apiClient.documentManagement
      .createDocAndUpdateStatus(dispensaryId, requirementId, body, () => {})
      .then(updatedDispensary => {
        updateApplication(updatedDispensary);
        toggleModal();
        setLoading(false);
        setFiles([]);
      })
      .catch(e => {
        alert('Error uploading document, please contact support@greencheckverified.com');
        setLoading(false);
        setFiles([]);
      });
  };

  const title = `Upload ${requirementName}`;

  const licenses = LicenseManager.getLicenseData(dispensaryState);

  const licenseNameOptionsBusiness = licenses.business_license_types.map(business_license_type => {
    return { label: business_license_type.viewValue, value: business_license_type.value };
  });

  const formDateRules = {
    required: true,
    validate: {
      future: value => dateFormatterToISO(value) > DateTime.local().toISO() || 'must input a date in the future',
    },
    minLength: 10,
  };

  return (
    <>
      <GcvModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        backButton={
          <GcvButton onClick={toggleModal} tertiary={true} data-cy="cancel-button">
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton
            onClick={handleSubmit(onSubmit)}
            primary={true}
            disabled={files.length < 1}
            data-cy="upload-button"
          >
            Upload
          </GcvButton>
        }
        title={title}
        loading={loading}
      >
        {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}

        {
          <UploadContainer data-cy="upload-container">
            {documentType === 'license' ? (
              <>
                <VerticalSpacer>
                  {requirementId === 'business_licenses' ? (
                    <GcvInputSelect
                      {...form}
                      options={licenseNameOptionsBusiness}
                      label={'License Name'}
                      name={'license_name'}
                      rules={{ required: true }}
                    />
                  ) : (
                    <GcvInputForm {...form} name={'license_name'} rules={{ required: true }} label={'Employee Name'} />
                  )}
                </VerticalSpacer>

                <VerticalSpacer>
                  <GcvInputForm {...form} name={'license_number'} rules={{ required: true }} label={'License Number'} />
                </VerticalSpacer>

                <VerticalSpacer>
                  <GcvInputForm
                    {...form}
                    name={'expiration_date'}
                    rules={formDateRules}
                    label={'Expiration Date'}
                    type="date"
                  />
                </VerticalSpacer>
              </>
            ) : null}
            <VerticalSpacer>
              <GcvInputFile
                updateFileState={fileState => setFiles(fileState.allFiles)}
                acceptedTypes={['all']}
                files={files}
                multiple={documentType === 'document'}
              ></GcvInputFile>
            </VerticalSpacer>
          </UploadContainer>
        }
      </GcvModal>
    </>
  );
};
