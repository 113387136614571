import { SelectValues } from './constants';
import {
  ValidateOrganizationCodeType,
  SARValidateFederalRegulatorCodeType,
  ValidatePartyIdentificationCodeType,
} from '@gcv/shared';

export const ctrFinancialInstitutions: SelectValues[] = [
  {
    value: ValidateOrganizationCodeType['Casino/Card club'],
    viewValue: 'Casino/Card Club',
  },
  {
    value: ValidateOrganizationCodeType['Depository institution'],
    viewValue: 'Depository Institution',
  },
  {
    value: ValidateOrganizationCodeType['Money Services Business (MSB)'],
    viewValue: 'Money Services Business (MSB)',
  },
  {
    value: ValidateOrganizationCodeType['Securities/Futures'],
    viewValue: 'Securities/Futures',
  },
  {
    value: ValidateOrganizationCodeType['Housing GSE'],
    viewValue: 'Housing GSE',
  },
  { value: ValidateOrganizationCodeType.Other, viewValue: 'Other' },
];

export const sarFinancialInstitutions: SelectValues[] = [
  {
    value: ValidateOrganizationCodeType['Casino/Card club'],
    viewValue: 'Casino/Card Club',
  },
  {
    value: ValidateOrganizationCodeType['Depository institution'],
    viewValue: 'Depository Institution',
  },
  {
    value: ValidateOrganizationCodeType['Insurance company'],
    viewValue: 'Insurance Company',
  },
  {
    value: ValidateOrganizationCodeType['Money Services Business (MSB)'],
    viewValue: 'MSB',
  },
  {
    value: ValidateOrganizationCodeType['Securities/Futures'],
    viewValue: 'Securities/Futures',
  },
  {
    value: ValidateOrganizationCodeType['Loan or Finance Company'],
    viewValue: 'Loan or Finance Company',
  },
  {
    value: ValidateOrganizationCodeType['Housing GSE'],
    viewValue: 'Housing GSE',
  },
  { value: ValidateOrganizationCodeType.Other, viewValue: 'Other' },
];

export const primaryFederalRegulators: SelectValues[] = [
  { value: 'FRB', viewValue: 'FRB - Federal Reserve Board' },
  {
    value: 'FDIC',
    viewValue: 'FDIC - Federal Deposit Insurance Corporation',
  },
  { value: 'IRS', viewValue: 'IRS - Internal Revenue Service' },
  { value: 'NCUA', viewValue: 'NCUA - National Credit Union Administration' },
  {
    value: 'OCC',
    viewValue: 'OCC - Office of the Comptroller of the Currency',
  },
];

export const sarPrimaryRegulatorTypeCode: SelectValues[] = [
  {
    value: SARValidateFederalRegulatorCodeType.CFTC,
    viewValue: 'CFTC (Commodities Futures Trading Commission)',
  },
  {
    value: SARValidateFederalRegulatorCodeType['Federal Reserve'],
    viewValue: 'Federal Reserve',
  },
  {
    value: SARValidateFederalRegulatorCodeType.FDIC,
    viewValue: 'FDIC (Federal Deposit Insurance Corporation)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.FHFA,
    viewValue: 'FHFA (Federal Housing Finance Agency)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.IRS,
    viewValue: 'IRS (Internal Revenue Service)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.NCUA,
    viewValue: 'NCUA (National Credit Union Administration)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.OCC,
    viewValue: 'OCC (Office of the Comptroller of the Currency)',
  },
  {
    value: SARValidateFederalRegulatorCodeType.SEC,
    viewValue: 'SEC (Securities and Exchange Commission)',
  },
  {
    value: SARValidateFederalRegulatorCodeType['Not applicable'],
    viewValue: 'Not Applicable',
  },
];

export const sarTinTypes: SelectValues[] = [
  { value: ValidatePartyIdentificationCodeType.EIN, viewValue: 'EIN' },
  {
    value: ValidatePartyIdentificationCodeType['SSN/ITIN'],
    viewValue: 'SSN-ITIN',
  },
  { value: ValidatePartyIdentificationCodeType.Foreign, viewValue: 'Foreign' },
];

export const tinTypes: SelectValues[] = sarTinTypes;

export const financialInstitutionIdentificationTypes: SelectValues[] = [
  { value: 'CRD', viewValue: 'Central Registration Depository (CRD) number' },
  {
    value: 'IARD',
    viewValue: 'Investment Adviser Registration Depository (IARD) number',
  },
  { value: 'NFA', viewValue: 'National Futures Association (NFA) number' },
  {
    value: 'RSSD',
    viewValue: 'Research, Statistics, Supervision and Discount (RSSD) number',
  },
  {
    value: 'SEC',
    viewValue: 'Securities and Exchange Commission (SEC) number',
  },
  {
    value: 'NAIC',
    viewValue: 'National Association of Insurance Commissioners (NAIC Code)',
  },
  { value: 'NMLS', viewValue: 'Mortgage (NMLS ID)' },
];

export const sarFinancialInstitutionIdentificationTypes: SelectValues[] = [
  {
    value: ValidatePartyIdentificationCodeType['CRD number'],
    viewValue: 'Central Registration Depository (CRD) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['IARD number'],
    viewValue: 'Investment Adviser Registration Depository (IARD) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['NFA ID number'],
    viewValue: 'National Futures Association (NFA) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['RSSD number'],
    viewValue: 'Research, Statistics, Supervision and Discount (RSSD) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['SEC number'],
    viewValue: 'Securities and Exchange Commission (SEC) number',
  },
  {
    value: ValidatePartyIdentificationCodeType['NAIC number'],
    viewValue: 'National Association of Insurance Commissioners (NAIC Code)',
  },
  {
    value: ValidatePartyIdentificationCodeType['NMLS Id number'],
    viewValue: 'Mortgage (NMLS ID)',
  },
];

export const cyberEventIndicators: SelectValues[] = [
  {
    value: 'commandAndControlIpAddress',
    viewValue: 'Command and control IP address',
  },
  {
    value: 'commandAndControlIpAddress',
    viewValue: 'Command and control URL/domain',
  },
  {
    value: 'malware',
    viewValue: 'Malware MD5, Malware SHA-1, or Malware SHA-256',
  },
  { value: 'MAC', viewValue: 'Media Access Control (MAC) Address' },
  { value: 'port', viewValue: 'Port' },
  { value: 'suspiciousEmailAddress', viewValue: 'Suspicious email address' },
  { value: 'suspiciousFileName', viewValue: 'Suspicious file name' },
  { value: 'suspiciousIpAddress', viewValue: 'Suspicious IP address' },
  { value: 'suspiciousUrlDomain', viewValue: 'Suspicious URL/domain' },
  { value: 'targetedSystem', viewValue: 'Targeted system' },
  { value: 'other', viewValue: 'Other' },
];
