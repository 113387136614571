import { DispensaryRiskRating, ReviewFrequency } from '@gcv/shared';

export const dispensary_rating_options: { label: string; value: DispensaryRiskRating }[] = [
  { label: 'No Rating', value: 'none' },
  { label: 'Low', value: 'low' },
  { label: 'Low-Moderate', value: 'low-moderate' },
  { label: 'Moderate-High', value: 'high-moderate' },
  { label: 'High', value: 'high' },
];

export const dispensary_frequency_options: { label: string; value: ReviewFrequency }[] = [
  { label: 'No Review', value: 'none' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' },
];
