import styled from 'styled-components';

import { $blue, $primary, $primaryDark, $white } from '../../../util/styleVariables';

export const Header = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-left: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const SubHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0px;
  vertical-align: middle;
`;

export const Description = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 14px 0px 24px 0px;
`;

export const ContentContainerLeft = styled.div`
  width: 50%;
  padding-right: 40px;
`;

export const ContentContainerRight = styled.div`
  width: 50%;
  padding-left: 40px;
`;

export const TableContainer = styled.div`
  margin-right: 13px;
  width: 50%;
  box-sizing: border-box;
`;

export const ColumnContainer = styled.div`
  display: flex;
`;
export const ButtonContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const ArchiveContainer = styled.div`
  margin: 24px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

export const Archive = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ArchiveText = styled.span`
  color: ${$blue};
  margin-left: 5px;
`;

export const ActivityContainer = styled.div`
  border-top: 1px solid #dbe1ed;
  padding: 15px 0px;
  margin: 5px;
  color: #33353c;
`;

export const ActivityFeedContainer = styled.div`
  box-sizing: border-box;
  width: 50%;
  background: #ffffff;
  border: 1px solid #e0e5f0;
  box-sizing: border-box;
  border-radius: 7px;
  margin-left: 13px;
`;

export const Activities = styled.div`
  overflow-y: auto;
  padding: 0px 20px 20px 20px;
  max-height: 442px;
  box-sizing: border-box;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  padding: 20px 20px 0px 20px;
  height: 39px;
  box-sizing: border-box;
`;

export const Date = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #828599;
`;

export const Comment = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;

export const Document = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #6896fb;
  cursor: pointer;
`;

export const ContentContainerBottom = styled.div`
  margin-top: 25px;
  display: flex;
`;

export const UpdateButtonContainer = styled.div`
  width: 12rem;
  margin: 0 1rem;
  box-sizing: border-box;
`;

export const UpdateButton = styled.div`
  padding: 0 1rem;
  position: absolute;
  max-width: 18rem;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  min-width: 9rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1.5px solid ${$primary};
  background-color: ${$white};
  color: ${$primary};
  transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease, position 0.2s ease;
  -webkit-transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease,
    position 0.2s ease;

  &:hover {
    border-color: ${$primaryDark};
  }

  &:focus {
    outline: 0;
    border-color: ${$primaryDark};
  }
`;

export const ButtonInner = styled.div`
  height: 2.25rem;
  text-rendering: geometricPrecision;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    color: ${$primaryDark};
  }
`;
