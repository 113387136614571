import React, { useState, useEffect, useRef } from 'react';

import { Controller } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import { DateTime } from 'luxon';

import { Container, PlaceHolder, Warning, TextArea } from './styles';
import { errorMessages } from '../../util/validationHelpers';

import Calendar from 'react-calendar';

/*
  Needs to be used with react-hook-form in the parent component: 
  For more information see https://react-hook-form.com/
*/

interface Props {
  rules?: {
    required?: any;
    maxLength?: any;
    minLength?: any;
    max?: any;
    min?: any;
    pattern?: any;
    validate?: any;
  };
  name: string;
  label: string;
  watch: any;
  errors: any;
  register: any;
  defaultValue?: string;
  type?: string;
  control: any;
  setValue: any;
  triggerValidation: any;
  testDataTag?: string;
  disabled?: boolean;
}

/*
  it is possible to input a custom message on the register prop:
    const form = useForm()
    <GcvInputForm
      ...
      rules={ minLength: {value:3, message: custom message} }
      {...form} 
    />
*/

export const GcvInputTextArea = ({
  register,
  rules = {},
  label,
  name,
  defaultValue,
  watch,
  errors,
  triggerValidation,
  testDataTag,
  disabled,
}: Props) => {
  const [focus, setFocus] = useState(false);
  const [isTiny, setIsTiny] = useState(defaultValue !== undefined);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    triggerValidation(name);
    setFocus(false);
  };

  useEffect(() => {
    setIsTiny(focus || !!watch(name));
  }, [focus, watch(name)]);

  return (
    <Container>
      <PlaceHolder tiny={isTiny || defaultValue !== ''} focus={focus}>
        {label} {rules.required ? '*' : ''}
      </PlaceHolder>
      <TextArea
        name={name}
        defaultValue={defaultValue}
        ref={register(rules)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-cy={testDataTag}
        disabled={disabled}
      ></TextArea>
      <Warning data-testid={`warning`}>
        {errors[name] && `* ${label} ${errors[name].message || errorMessages[errors[name].type]}`}
      </Warning>
    </Container>
  );
};
