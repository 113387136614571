import React, { useState } from 'react';
import { DateTime } from 'luxon';
import parse from 'html-react-parser';

import { DispensaryAccountReviewWithData } from '@gcv/shared';
import { BubbleConfig } from './chart-options';
import { createStyles, makeStyles, Popover, Theme } from '@material-ui/core';
import { tooltip } from './Tooltip';
import { formatMoney } from 'libs/react-ui/src/util';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';

interface Props {
  review: DispensaryAccountReviewWithData;
  month: number;
}

const getCreditColorGradient = (maxCount: number, count: number) => {
  if (count < maxCount * 0.2) {
    return '#C1EEAA';
  } else if (count < maxCount * 0.4) {
    return '#96E688';
  } else if (count < maxCount * 0.6) {
    return '#44D359';
  } else if (count < maxCount * 0.8) {
    return '#00BC66';
  } else if (count <= maxCount * 1) {
    return '#00A77E';
  }
};

const getDebitColorGradient = (maxCount: number, count: number) => {
  if (count < maxCount * 0.2) {
    return '#CFE2FE';
  } else if (count < maxCount * 0.4) {
    return '#B0D0FF';
  } else if (count < maxCount * 0.6) {
    return '#8BB7F9';
  } else if (count < maxCount * 0.8) {
    return '#4791FF';
  } else if (count <= maxCount * 1) {
    return '#0066FF';
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(0.25),
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 10%)',
      border: '1px solid #E0E5F0',
      borderRadius: 0,
    },
  })
);

export const TransactionTimelineWireBubbleChart: React.FC<Props> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [transactionDate, setTransactionDate] = useState('');
  const [transactionTotal, setTransactionTotal] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    transactionTotal: number,
    transactionCount: number,
    transactionDate: string
  ) => {
    setTransactionDate(transactionDate);
    setTransactionTotal(transactionTotal);
    setTransactionCount(transactionCount);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const chartData = chartDataAdapter(props.review, props.month);

  let maxCreditAmount = chartData.dataset[0].data.reduce((prev, curr) => {
    return prev.amount > curr.amount ? { ...prev } : { ...curr };
  });

  if (0 === maxCreditAmount.amount) {
    maxCreditAmount.amount = 1;
  }

  const maxCreditCount = chartData.dataset[0].data.reduce((prev, curr) => {
    return prev.count > curr.count ? { ...prev } : { ...curr };
  });

  let maxDebitAmount = chartData.dataset[1].data.reduce((prev, curr) => {
    return prev.amount > curr.amount ? { ...prev } : { ...curr };
  });

  if (0 === maxDebitAmount.amount) {
    maxDebitAmount.amount = 1;
  }

  const maxDebitCount = chartData.dataset[1].data.reduce((prev, curr) => {
    return prev.count > curr.count ? { ...prev } : { ...curr };
  });

  const maxBubbleSize = 15;

  return (
    <>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {parse(
          tooltip(DateTime.fromFormat(transactionDate, 'yyyy-MM-dd').toLocaleString(), [
            {
              label: 'Total',
              value: formatMoney(transactionTotal),
            },
            {
              label: '# of Tx',
              value: transactionCount.toString(),
            },
          ])
        )}
      </Popover>

      <div style={{ display: 'flex' }}>
        {chartData.dataset[0].data.map((d, i) => {
          let size = (d.amount / maxCreditAmount.amount) * maxBubbleSize;
          const radius = size / 2;

          return (
            <div
              key={i}
              style={{ flex: 1, alignItems: 'center', display: 'flex' }}
              onMouseEnter={e => handlePopoverOpen(e, d.amount, d.count, d.date)}
              onMouseLeave={handlePopoverClose}
            >
              <svg
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx={radius}
                  cy={radius}
                  r={radius}
                  fill={getCreditColorGradient(maxCreditCount.count, d.count)}
                />
              </svg>
            </div>
          );
        })}
      </div>

      <div style={{ display: 'flex' }}>
        {chartData.dataset[1].data.map((d, i) => {
          let size = (d.amount / maxDebitAmount.amount) * maxBubbleSize;
          const radius = size / 2;

          return (
            <div
              key={i}
              style={{ flex: 1, alignItems: 'center', display: 'flex' }}
              onMouseEnter={e => handlePopoverOpen(e, d.amount, d.count, d.date)}
              onMouseLeave={handlePopoverClose}
            >
              <svg
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx={radius} cy={radius} r={radius} fill={getDebitColorGradient(maxDebitCount.count, d.count)} />
              </svg>
            </div>
          );
        })}
      </div>

      <br />
      <br />

      <div style={{ display: 'flex' }}>
        {chartData.categories.map((c, i) => {
          return (
            <div key={i} style={{ flex: 1 }}>
              <GcvContent type="l2" content={c.label} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, month: number) => {
  const getCategories = () => {
    const date = DateTime.fromISO(Object.keys(review.data.daily_bank_core_transaction_summaries)[0]);
    const year = date.get('year');
    const firstOfMonth = DateTime.fromFormat(`${month.toString().padStart(2, '0')}-01-${year}`, 'MM-dd-yyyy');
    const daysInMonth = firstOfMonth.daysInMonth;
    const labels: { label: string }[] = [{ label: `${month}/1/${year}` }];

    for (let x = 1; x <= daysInMonth; x++) {
      if (0 === x % 7) {
        labels.push({ label: `${month}/${x}/${year}` });
      }
    }

    return labels;
  };

  return transactionDataRadarAdapter({
    categories: getCategories(),
    dataset: [
      // Credits
      {
        color: '#00BC66',
        data: Object.values(review.data.daily_bank_core_transaction_summaries)
          .filter(f => DateTime.fromFormat(f.date, 'yyyy-MM-dd').get('month') === month)
          .sort(
            (a, b) =>
              DateTime.fromFormat(a.date, 'yyyy-MM-dd').get('day') -
              DateTime.fromFormat(b.date, 'yyyy-MM-dd').get('day')
          )
          .map(d => {
            return {
              amount: d.deposit_amount_wire,
              count: d.number_of_wire_deposits,
              date: d.date,
            };
          }),
      },
      // Debits
      {
        color: '#93C954',
        data: Object.values(review.data.daily_bank_core_transaction_summaries)
          .filter(f => DateTime.fromFormat(f.date, 'yyyy-MM-dd').get('month') === month)
          .sort(
            (a, b) =>
              DateTime.fromFormat(a.date, 'yyyy-MM-dd').get('day') -
              DateTime.fromFormat(b.date, 'yyyy-MM-dd').get('day')
          )
          .map(d => {
            return {
              amount: d.withdrawal_amount_wire,
              count: d.number_of_wire_withdrawals,
              date: d.date,
            };
          }),
      },
    ],
  });
};

type TransactionRadarDataType = {
  categories: {
    label: string;
  }[];

  dataset: {
    color: string;
    data: {
      amount: number;
      count: number;
      date: string;
    }[];
  }[];
};

export const transactionDataRadarAdapter = (data: TransactionRadarDataType): BubbleConfig => {
  return {
    categories: data.categories,
    dataset: data.dataset,
  };
};
