import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';
import { ViewMoreIcon } from '../../../icons/ViewMoreIcon';

import { DrawerText, DrawerImage, DrawerList } from '../styles';
import { VerticalCenter } from '../../SalesAndDeposits/Deposits/depositDetailDrawer/styles';

interface Props {
  form: any;
}

export const LeafLogixForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, you’ll need your Leaf Logix API key. If this location is licensed for both
        medical and recreational sales, you will have two seperate API keys.
      </DrawerText>
      <DrawerText>
        Send the following email to{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:support@leaflogix.com">
          support@leaflogix.com
        </a>
        :
      </DrawerText>
      <DrawerText>
        <b>
          Hello, <br />
          <br />I need some assistance. I would like to integrate our system with the Green Check system. Please help me
          locate my API key(s) so that I can provide this to the Green Check team to set up the integration.
        </b>
      </DrawerText>
      <DrawerText>
        Once Leaf Logix provides you with your API key(s), enter it below. Then, enable and connect the integration.
      </DrawerText>
      <GcvInputForm
        {...form}
        name="recApiKey"
        label="Recreational API Key"
        type="text"
        style={{ width: 'fit-content' }}
      />
      <GcvInputForm {...form} name="medApiKey" label="Medical API Key" type="text" style={{ width: 'fit-content' }} />
    </>
  );
};
