import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Bank } from '@gcv/shared';
import { GcvButton, GcvCard, GcvLoading, GcvModal } from '../../../lib';
import { Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import { useApi, getEndpoint } from 'libs/react-ui/src/hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { states } from 'libs/gcv-ui/src/lib';
import { AddTransportationServiceModal } from 'libs/react-ui/src/shared/Transportation/AddTransportationService';
import { EditIcon } from 'libs/react-ui/src/icons/EditIcon';
import { FlexBox, VerticalCenter } from 'libs/react-ui/src/styles/theme';
import { api } from 'libs/react-ui/src/api';

const ButtonIcon = styled.span`
  color: #e0e0e0;
  &:hover {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  padding: 0 2rem;
`;

interface Props {
  bank?: Bank;
  emitData: (type: string, data) => void;
}

const defaultVendor = { id: '', type: '', name: '', street: '', city: '', state: '', zipCode: '', phone: '', tin: '' };

export const Transportation = ({ bank, emitData }: Props) => {
  const apiClient = api();

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedVendorEdit, setSelectedVendorEdit] = useState(defaultVendor);
  const [requireModalOpen, setRequireModalOpen] = useState(false);
  const [selectedVendorRequire, setSelectedVendorRequire] = useState(defaultVendor);

  if (!bank) {
    return <GcvLoading></GcvLoading>;
  }

  const toggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const toggleRequireModal = (vendorId?: string) => {
    if (vendorId) {
      setSelectedVendorRequire({ ...defaultVendor, id: vendorId });
    }

    setRequireModalOpen(!requireModalOpen);
  };

  const editTransportationService = async payload => {
    await apiClient.banks
      .updateTransportVendor(
        bank.id,
        payload.id,
        {
          ...payload,
          country: 'unitedstates',
          state: payload.state.value,
        },
        () => {}
      )
      .then(bank => {
        emitData('updateBank', bank);
      });
  };

  const addTransportationService = async payload => {
    await apiClient.banks
      .addTransportVendor(
        bank.id,
        {
          city: payload.city,
          country: 'unitedstates',
          name: payload.name,
          phone: payload.phone,
          state: payload.state.value,
          street: payload.street,
          zipCode: payload.zipCode,
        },
        () => {}
      )
      .then(bank => {
        emitData('updateBank', bank);
      })
      .catch(e => console.log(e));
  };

  const assignVendorToAllDispensaries = async (vendorId: string) => {
    setRequireModalOpen(false);
    await apiClient.banks
      .addDefaultTransportVendor(bank.id, vendorId, {}, () => {})
      .then(bank => {
        emitData('updateBank', bank);
      })
      .catch(e => console.log(e));
  };

  const editMot = async mot => {
    await apiClient.dispensaries
      .getTransportVendorById(mot.id, () => {})
      .then(vendor => {
        setSelectedVendorEdit(vendor);
        setEditModalOpen(true);
      });
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
        <Grid item xs={4}>
          <GcvCard>
            <GcvCard.Header
              title={<strong>Methods of Transportation</strong>}
              subtitle={
                <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                  Click the star to universally apply a service to all accounts. You can apply these services to
                  individuals from the accounts tab.
                </p>
              }
            />
            <GcvCard.Body>
              <List>
                {bank.transportVendors.map((mot, i) => {
                  return (
                    <div key={mot.name}>
                      <>
                        <ListItem>
                          <ListItemText primary={mot.name} />
                          <ListItemSecondaryAction>
                            <FlexBox>
                              <VerticalCenter style={{ cursor: 'pointer', marginRight: '1.5rem' }}>
                                <EditIcon onClick={() => editMot(mot)}></EditIcon>
                              </VerticalCenter>
                              <VerticalCenter>
                                <ButtonIcon
                                  className="material-icons"
                                  style={{ color: mot.favorite ? '#00bc66' : '#e0e0e0' }}
                                  onClick={() => toggleRequireModal(mot.id)}
                                >
                                  star_outline
                                </ButtonIcon>
                              </VerticalCenter>
                            </FlexBox>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </>
                    </div>
                  );
                })}
              </List>
            </GcvCard.Body>
          </GcvCard>
        </Grid>
        <Grid item xs={8}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GcvButton onClick={toggleAddModal} primary={true}>
              Add Transportation Service
            </GcvButton>
          </div>
        </Grid>
      </Grid>

      <AddTransportationServiceModal
        modalOpen={addModalOpen}
        toggleModal={toggleAddModal}
        onSubmit={addTransportationService}
      ></AddTransportationServiceModal>

      <AddTransportationServiceModal
        modalOpen={editModalOpen}
        toggleModal={() => setEditModalOpen(!editModalOpen)}
        onSubmit={editTransportationService}
        defaultValues={selectedVendorEdit}
      ></AddTransportationServiceModal>

      <GcvModal
        title={'Require service for this account?'}
        modalOpen={requireModalOpen}
        toggleModal={toggleRequireModal}
        backButton={
          <GcvButton tertiary onClick={() => toggleRequireModal()}>
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={() => assignVendorToAllDispensaries(selectedVendorRequire.id)} primary={true}>
            Continue
          </GcvButton>
        }
      >
        <ModalContent>
          <div style={{ padding: '0 2rem' }}>
            <div style={{ textAlign: 'center' }}>
              <svg fill="none" height="76" width="76" xmlns="http://www.w3.org/2000/svg" className="ng-star-inserted">
                <path d="M38 75a37 37 0 1 0 0-74 37 37 0 0 0 0 74z" fill="#F9FAFD" stroke="#E0E5F0"></path>
                <path
                  clipRule="evenodd"
                  d="M15.1 51.7l21.5-37.2a1 1 0 0 1 1.7 0l21.5 37.2a1 1 0 0 1-.9 1.5H16a1 1 0 0 1-.9-1.5z"
                  fill="#fff"
                  fillRule="evenodd"
                ></path>
                <path
                  clipRule="evenodd"
                  d="M15.1 51.7l21.5-37.2a1 1 0 0 1 1.7 0l21.5 37.2a1 1 0 0 1-.9 1.5H16a1 1 0 0 1-.9-1.5z"
                  stroke="#F5A623"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
                <path
                  clipRule="evenodd"
                  d="M36 40.1V26.4a1.4 1.4 0 0 1 2.9 0v13.7a1.4 1.4 0 0 1-2.9 0zM36 45.3a2 2 0 1 1 3.9 0 2 2 0 0 1-3.9 0z"
                  fill="#F5A623"
                  fillRule="evenodd"
                ></path>
              </svg>
            </div>
            <br />
            <br />
            <p>
              By clicking continue, the business will be required to use this service provided by you to physically
              transfer deposits.
            </p>
          </div>
        </ModalContent>
      </GcvModal>
    </>
  );
};
