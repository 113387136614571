import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

import {
  Dispensary,
  Bank,
  DispensaryRiskRating,
  BankDispensaryMetadataData,
  OrganizationRoleResolver,
  User,
} from '@gcv/shared';
import { GcvCard, GcvButton, GcvInputForm, GcvLoading, GcvInputSelect, GcvInputFile, GcvContent } from '../../../lib';
import { EditIcon } from 'libs/react-ui/src/icons/EditIcon';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { api } from 'libs/react-ui/src/api';
import { VerticalCenter } from 'libs/react-ui/src/lib/GcvInputFile/styles';
import { HorizontalCenter } from 'libs/react-ui/src/styles/theme';
import { dispensary_rating_options, dispensary_frequency_options } from 'libs/react-ui/src/constants/Accounts';
import { s3Util } from 'libs/react-ui/src/util/s3.util';
import mime from 'mime-types';
import * as base64arraybuffer from 'base64-arraybuffer';
import { formatDateOrDash } from 'libs/react-ui/src/util';

const HeaderSubContent = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;

const Label = styled.div`
  font-size: 13px;
  margin-top: 0px;
  padding: 5px 0;
  color: #a5a8ba;
  font-family: Lato, Helvetica, Arial, san-serif;
  line-height: 1.25;
`;

interface Props {
  dispensary: Dispensary;
  bank: Bank;
  user: User;
}

export const AccountInternal = ({ dispensary, bank, user }: Props) => {
  const apiClient = api();
  const { handleSubmit, reset, ...form } = useForm({});

  const [editAccountInfo, setEditAccountInfo] = useState(false);
  const [editOversight, setEditOversight] = useState(false);
  const [editAccountInformation, setEditAccountInformation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAccountInfo, setLoadingAccountInfo] = useState(false);
  const [loadingOversight, setLoadingOversight] = useState(false);
  const [loadingAccountInformation, setLoadingAccountInformation] = useState(false);
  const [riskRating, setRiskRating] = useState<{ label: string; value: DispensaryRiskRating }>({
    label: 'No Rating',
    value: 'none',
  });
  const [accountOpenDate, setAccountOpenDate] = useState('');
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [files, setFiles] = useState<{ file: File; docId: string }[]>([]);

  useEffect(() => {
    // edit state must be true on first render in order for rules to work
    // on second render 'useEffect lifecycle' we set it to false
    setEditAccountInfo(false);
    setEditOversight(false);
    setEditAccountInformation(false);

    getDispensaryMetadata();
    getExistingFiles();
  }, []);

  const getDispensaryMetadata = async () => {
    try {
      const res = await apiClient.banks.getDispensaryBankMetadata(bank.id, dispensary.id, () => {});

      if (res) {
        const metadata: BankDispensaryMetadataData = res.value;
        const values = {
          internalId: metadata.internal_id,
          reviewFrequency: dispensary_frequency_options.find(v => v.value === metadata.review_frequency),
          accountOpenDate: metadata.account_open_date,
        };
        //@ts-ignore
        reset(values);
        const option = metadata.risk_rating
          ? dispensary_rating_options.find(v => v.value === metadata.risk_rating)
          : dispensary_rating_options.find(v => v.value === 'none');
        setRiskRating(option);
        setAccountOpenDate(metadata.account_open_date);
      } else {
        const option = dispensary_rating_options.find(v => v.value === 'none');
        setRiskRating(option);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const saveOversight = async payload => {
    await apiClient.banks.updateDispensaryBankMetadata(
      bank.id,
      dispensary.id,
      {
        review_frequency: payload.reviewFrequency.value,
      },
      setLoadingOversight
    );
    const values = {
      internalId: payload.internal_id,
      reviewFrequency: dispensary_frequency_options.find(v => v.value === payload.reviewFrequency.value),
    };
    //@ts-ignore
    reset(values);
    setEditAccountInfo(false);
    setEditOversight(false);
    setEditAccountInformation(false);
  };

  const saveAccountIdentification = async payload => {
    await apiClient.banks.updateDispensaryBankMetadata(
      bank.id,
      dispensary.id,
      {
        internal_id: payload.internalId,
      },
      setLoadingAccountInfo
    );
    setEditAccountInfo(false);
    setEditOversight(false);
    setEditAccountInformation(false);
  };

  const saveAccountInformation = async payload => {
    await apiClient.banks.updateDispensaryBankMetadata(
      bank.id,
      dispensary.id,
      {
        account_open_date: payload.accountOpenDate,
      },
      setLoadingAccountInformation
    );
    setAccountOpenDate(payload.accountOpenDate);
    setEditAccountInfo(false);
    setEditOversight(false);
    setEditAccountInformation(false);
  };

  const frequencyValue = dispensary_frequency_options.find(v => v.value === form.watch('reviewFrequency'));

  const updateFileState = async (fileState: { allFiles: File[]; newFiles: File[]; removedFiles: File[] }) => {
    if (fileState.newFiles.length > 0) {
      await uploadDocuments(fileState.newFiles);
    }
    if (fileState.removedFiles.length > 0) {
      const existingFile = files.find(f => f.file === fileState.removedFiles[0]);
      if (existingFile) {
        const newFiles = [...files];
        newFiles.splice(files.indexOf(existingFile), 1);
        apiClient.documents.deleteOrgDocument(bank.id, existingFile.docId, () => {});
        setFiles(newFiles);
      }
    }
  };

  const uploadDocuments = async (blobs, data?) => {
    setLoadingDocuments(true);
    const createdDocumentIds = [];
    const createdDocuments = [];
    for (const blobIndex in blobs) {
      const blob = blobs[blobIndex];
      const document = await s3Util(apiClient)
        .handleAddDocumentToS3({ blob: blob, userType: 'bank' }, bank.id, `${bank.id}/${dispensary.id}/${blob.name}`)
        .then(async file => {
          return apiClient.documents.createDocument(
            {
              orgId: bank.id,
              s3Key: file.s3_key,
              fileName: file.filename,
            },
            () => {}
          );
        })
        .catch(err => {
          setLoadingDocuments(false);
          alert('Error uploading document to S3, please contact support@greencheckverified.com' + err);
          return [];
        });
      createdDocuments.push({ file: blob, docId: document.id });
      createdDocumentIds.push(document.id);
    }

    setFiles([...files, ...createdDocuments]);
    setLoadingDocuments(false);
    return createdDocumentIds;
  };

  const getExistingFiles = async () => {
    setLoadingDocuments(true);
    try {
      const documents = await apiClient.banks.getInternalBankDispensaryDocuments(bank.id, dispensary.id, () => {});
      if (documents) {
        const files = await Promise.all(
          documents.map(async document => {
            const { s3LinkPath } = await s3Util(apiClient).getPresignedUrl(document.s3_key, 'get', 'bank');

            // get extension from filename
            var re = /(?:\.([^.]+))?$/;
            const ext = re.exec(document.file_name)[1].toLowerCase();
            const type = mime.lookup(ext);

            const result = await fetch(s3LinkPath);
            const base64 = await result.text();
            const base64ArrayBuffer = base64arraybuffer.decode(base64);

            return { file: new File([base64ArrayBuffer], document.file_name, { type }), docId: document.id };
          })
        );

        setFiles(files);
        setLoadingDocuments(false);
      } else {
        setLoadingDocuments(false);
      }
    } catch (e) {
      setLoadingDocuments(false);
    }
  };

  const canUserUpdateMetaData = new OrganizationRoleResolver().userCanDoAction(
    bank.groups,
    user,
    'account_info_update'
  );

  return loading ? (
    <HorizontalCenter>
      <VerticalCenter>
        <GcvLoading></GcvLoading>
      </VerticalCenter>
    </HorizontalCenter>
  ) : (
    <>
      <GcvContent
        type={'p2'}
        style={{ marginBottom: '.5rem', position: 'relative', top: '-.5rem' }}
        content="Manage internal information and documentation associated with this account. This information is not visible to the business."
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header
              title={<strong>Account Identification</strong>}
              actions={
                !editAccountInfo && canUserUpdateMetaData ? (
                  <EditIcon
                    onClick={() => {
                      setEditAccountInfo(true);
                      setEditOversight(false);
                    }}
                  />
                ) : null
              }
            ></GcvCard.Header>
            <GcvCard.Body>
              <GcvInputForm {...form} name="internalId" label="Internal ID" type="text" readonly={!editAccountInfo} />
              <br />
              {loadingAccountInfo ? (
                <HorizontalCenter>
                  <GcvLoading small={true} style={{ height: 'fit-content' }}></GcvLoading>
                </HorizontalCenter>
              ) : editAccountInfo && canUserUpdateMetaData ? (
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton tertiary onClick={() => setEditAccountInfo(false)}>
                      Cancel
                    </GcvButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton primary onClick={handleSubmit(saveAccountIdentification)}>
                      Save
                    </GcvButton>
                  </Grid>
                </Grid>
              ) : null}
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header
              title={<strong>Oversight</strong>}
              actions={
                !editOversight && canUserUpdateMetaData ? (
                  <EditIcon
                    onClick={() => {
                      setEditAccountInfo(false);
                      setEditOversight(true);
                    }}
                  />
                ) : null
              }
            />
            <GcvCard.Body>
              {!editOversight ? (
                <>
                  <Label>Risk Rating</Label>
                  <span>{riskRating.label}</span>

                  <div style={{ marginTop: '1rem' }}>
                    <Label style={{ marginTop: '.75rem' }}>Review Frequency</Label>
                    <span>{form.watch('reviewFrequency') ? form.watch('reviewFrequency').label : 'None'}</span>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ marginTop: '2rem' }}>
                    <label data-tip="Risk Rating can only be changed during a Review">
                      <GcvInputSelect
                        {...form}
                        name={'riskRating'}
                        label={'Risk Rating'}
                        options={dispensary_rating_options}
                        defaultValue={riskRating}
                        readonly={true}
                      />
                      <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
                    </label>
                  </div>
                  <GcvInputSelect
                    {...form}
                    name={'reviewFrequency'}
                    label={'Review Frequency'}
                    options={dispensary_frequency_options}
                    rules={{ required: { value: true, message: 'is required' } }}
                    defaultValue={frequencyValue ? frequencyValue.value : ''}
                  />
                </>
              )}

              {loadingOversight ? (
                <HorizontalCenter>
                  <GcvLoading small={true} style={{ height: 'fit-content' }}></GcvLoading>
                </HorizontalCenter>
              ) : editOversight && canUserUpdateMetaData ? (
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton tertiary onClick={() => setEditOversight(false)}>
                      Cancel
                    </GcvButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton primary onClick={handleSubmit(saveOversight)}>
                      Save
                    </GcvButton>
                  </Grid>
                </Grid>
              ) : null}
            </GcvCard.Body>
          </GcvCard>
        </Grid>
        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header
              title={<strong>Account Information</strong>}
              actions={
                !editAccountInformation && canUserUpdateMetaData ? (
                  <EditIcon
                    onClick={() => {
                      setEditAccountInfo(false);
                      setEditOversight(false);
                      setEditAccountInformation(true);
                    }}
                  />
                ) : null
              }
            />
            <GcvCard.Body>
              {!editAccountInformation ? (
                <>
                  <GcvInputForm
                    {...form}
                    name="accountApprovedOnDate"
                    label="Account Approved On Date"
                    type="text"
                    defaultValue={formatDateOrDash(dispensary.due_diligence.bank_reviewed_on)}
                    readonly={true}
                  />
                  <br />
                  <GcvInputForm
                    {...form}
                    name="accountOpenDate"
                    label="Account Open Date"
                    type="text"
                    defaultValue={formatDateOrDash(accountOpenDate)}
                    readonly={true}
                  />
                </>
              ) : (
                <>
                  <GcvInputForm
                    {...form}
                    name="accountApprovedOnDate"
                    label="Account Approved On Date"
                    type="text"
                    defaultValue={formatDateOrDash(dispensary.due_diligence.bank_reviewed_on)}
                    readonly={true}
                  />
                  <br />
                  <GcvInputForm
                    {...form}
                    name="accountOpenDate"
                    label="Account Open Date"
                    type="date"
                    defaultValue={accountOpenDate}
                  />
                  <br />
                </>
              )}

              {loadingAccountInformation ? (
                <HorizontalCenter>
                  <GcvLoading small={true} style={{ height: 'fit-content' }}></GcvLoading>
                </HorizontalCenter>
              ) : editAccountInformation && canUserUpdateMetaData ? (
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton tertiary onClick={() => setEditAccountInformation(false)}>
                      Cancel
                    </GcvButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton primary onClick={handleSubmit(saveAccountInformation)}>
                      Save
                    </GcvButton>
                  </Grid>
                </Grid>
              ) : null}
            </GcvCard.Body>
          </GcvCard>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header title={'Supporting Documentation'} />
            <GcvCard.Body>
              {loadingDocuments ? (
                <GcvLoading style={{ height: 'fit-content', marginTop: '5rem' }}></GcvLoading>
              ) : (
                <GcvInputFile
                  updateFileState={updateFileState}
                  acceptedTypes={['all']}
                  files={files.map(f => f.file)}
                  multiple={true}
                  maxHeight={'10rem'}
                  large={true}
                ></GcvInputFile>
              )}
            </GcvCard.Body>
          </GcvCard>
        </Grid>
      </Grid>
    </>
  );
};
