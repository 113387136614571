// fx
import React, { useEffect, useState } from 'react';

// app
import { DailyCoreTransactionsSummary, Dispensary, DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvInputSelectNew } from 'libs/react-ui/src/lib';
import { determineReviewPeriod, formatMoney } from 'libs/react-ui/src/util';

import { Card } from './Card';
import { Container } from './Container';

import { TransactionTimelineAchBubbleChart } from './charts/TransactionTimelineAchBubbleChart';
import { TransactionTimelineCashBubbleChart } from './charts/TransactionTimelineCashBubbleChart';
import { TransactionTimelineCheckBubbleChart } from './charts/TransactionTimelineCheckBubbleChart';
import { TransactionTimelineWireBubbleChart } from './charts/TransactionTimelineWireBubbleChart';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';

interface Props {
  dispensaries: { [id: string]: Dispensary };
  review: DispensaryAccountReviewWithData;
}

const ReviewTransactions: React.FC<Props> = props => {
  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });
  const [chartDataMonth, setChartDataMonth] = useState(null);

  const months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  useEffect(() => {
    setChartDataMonth(getDateRange(props.review.data.daily_bank_core_transaction_summaries)[0].value);
  }, [props.review]);

  const getDateRange = (data: { [date: string]: DailyCoreTransactionsSummary }) => {
    const dateKeys = Object.keys(data);

    if (dateKeys.length === 0) {
      return [{ value: '', label: '' }];
    }

    const startDate = DateTime.fromFormat(
      dateKeys.reduce((a, b) => {
        return DateTime.fromFormat(a, 'yyyy-MM-dd') < DateTime.fromFormat(b, 'yyyy-MM-dd') ? a : b;
      }),
      'yyyy-MM-dd'
    );

    const endDate = DateTime.fromFormat(
      dateKeys.reduce((a, b) => {
        return DateTime.fromFormat(a, 'yyyy-MM-dd') > DateTime.fromFormat(b, 'yyyy-MM-dd') ? a : b;
      }),
      'yyyy-MM-dd'
    );

    const dates = [];
    for (let x = startDate.get('month'); x <= endDate.get('month'); x++) {
      dates.push({ value: x, label: months[x] });
    }

    return dates;
  };

  const setRangedChartData = e => {
    const month = e.value;

    setChartDataMonth(month);
  };

  return (
    <Container>
      <Container.Item>
        <Card>
          <Card.Header
            title="Transaction Timeline"
            actions={
              determineReviewPeriod(props.review) !== 'month' && [
                <GcvInputSelectNew
                  label="Month"
                  options={getDateRange(props.review.data.daily_bank_core_transaction_summaries)}
                  onChange={setRangedChartData}
                  dropWidth={'150px'}
                  labelWidth={'100px'}
                  dropCharLength={20}
                />,
              ]
            }
          />
          <Card.Content>
            <Container gutterTop>
              <Container.Item vCenter size={[2]}>
                <GcvContent type="h3" content="Cash" />
              </Container.Item>
              <Container.Item size={[10]}>
                {chartDataMonth && <TransactionTimelineCashBubbleChart review={props.review} month={chartDataMonth} />}
              </Container.Item>

              <Container.Item>
                <hr />
              </Container.Item>

              <Container.Item vCenter size={[2]}>
                <GcvContent type="h3" content="Check" />
              </Container.Item>
              <Container.Item size={[10]}>
                {chartDataMonth && <TransactionTimelineCheckBubbleChart review={props.review} month={chartDataMonth} />}
              </Container.Item>

              <Container.Item>
                <hr />
              </Container.Item>

              <Container.Item vCenter size={[2]}>
                <GcvContent type="h3" content="ACH" />
              </Container.Item>
              <Container.Item size={[10]}>
                {chartDataMonth && <TransactionTimelineAchBubbleChart review={props.review} month={chartDataMonth} />}
              </Container.Item>

              <Container.Item>
                <hr />
              </Container.Item>

              <Container.Item size={[2]}>
                <GcvContent type="h3" content="Wire" />
              </Container.Item>
              <Container.Item size={[10]}>
                {chartDataMonth && <TransactionTimelineWireBubbleChart review={props.review} month={chartDataMonth} />}
              </Container.Item>
            </Container>

            <Container hCenter gutterTop>
              <Container.Item size={[6]}>
                <Container hCenter width={200}>
                  <Container.Item lAlign dense size={[6]}>
                    <GcvContent type="l2" content="Less Credits" />
                  </Container.Item>
                  <Container.Item rAlign dense size={[6]}>
                    <GcvContent type="l2" content="More Credits" />
                  </Container.Item>

                  <Container.Item hCenter>
                    <svg width="116" height="16" viewBox="0 0 116 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="94" width="21.5873" height="16" rx="2" fill="#00A77E" />
                      <rect x="70.7622" width="21.5873" height="16" rx="2" fill="#00BC66" />
                      <rect x="47.1748" width="21.5873" height="16" rx="2" fill="#44D359" />
                      <rect x="23.5874" width="21.5873" height="16" rx="2" fill="#96E688" />
                      <rect width="21.5873" height="16" rx="2" fill="#C1EEAA" />
                    </svg>
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item size={[6]}>
                <Container hCenter width={200}>
                  <Container.Item lAlign dense size={[6]}>
                    <GcvContent type="l2" content="Less Debits" />
                  </Container.Item>
                  <Container.Item rAlign dense size={[6]}>
                    <GcvContent type="l2" content="More Debits" />
                  </Container.Item>

                  <Container.Item hCenter>
                    <svg width="116" height="16" viewBox="0 0 116 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="94" width="21.5873" height="16" rx="2" fill="#0066FF" />
                      <rect x="70.7622" width="21.5873" height="16" rx="2" fill="#4791FF" />
                      <rect x="47.1748" width="21.5873" height="16" rx="2" fill="#8BB7F9" />
                      <rect x="23.5874" width="21.5873" height="16" rx="2" fill="#B0D0FF" />
                      <rect width="21.5873" height="16" rx="2" fill="#CFE2FE" />
                    </svg>
                  </Container.Item>
                </Container>
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>
      <Container.Item>
        <Card>
          <Card.Header title="Transaction Summary" />
          <Card.Content>
            <Container flex gutterBottom>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Total Credits" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_cash +
                      props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_check +
                      props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_ach +
                      props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_wire
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Cash In" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_cash
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Check In" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_check
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="ACH In" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_ach
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Wire In" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.deposit_amount_wire
                  )}
                />
              </Container.Item>
            </Container>

            <Container flex>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Total Debits" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_cash +
                      props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_check +
                      props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_ach +
                      props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_wire
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Cash Out" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_cash
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Check Out" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_check
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="ACH Out" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_ach
                  )}
                />
              </Container.Item>
              <Container.Item flex>
                <GcvContent type="l1" grey2 content="Wire Out" />
                <GcvContent
                  type="p1"
                  content={formatMoney(
                    props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount_wire
                  )}
                />
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>
    </Container>
  );
};

export default ReviewTransactions;
