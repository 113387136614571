import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import {
  SubText,
  CardRowContainer,
  Card,
  PosIcon,
  DrawerHeader,
  VerticalCenter,
  Title,
  DrawerContent,
  ButtonContainer,
  DrawerText,
  IconContainer,
} from './styles';
import { GcvStatusIndicator, GcvDrawer, GcvInputForm, GcvButton, GcvCheckbox } from '../../lib';
import {
  Portal42Form,
  MjFreewayForm,
  GreenBitsForm,
  TreezForm,
  FlowHub,
  LeafLogixForm,
  CovaForm,
  GrowFlowForm,
} from './PosTypes/';
import { BackButtonIcon } from '../../icons/BackButtonIcon';

import { Dispensary, PosType, EventTypes, User } from '@gcv/shared';
import { useForm } from 'react-hook-form';
import { BioTrackForm } from './PosTypes/BioTrack';
import { useApi, getEndpoint } from '../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { DateTime } from 'luxon';
import { $grey2, formatISOToDateAtTime, $grey1 } from '../../util';
import { QuickBooksForm } from './PosTypes/QuickBooks';
import { BlazeForm } from './PosTypes/Blaze';
import { api } from '../../api';

export interface Pos {
  name: string;
  alternateName?: string;
  displayName?: string;
  isPending?: boolean;
  isConnected?: boolean;
}

export const posTypes: Pos[] = [
  {
    name: PosType.Portal42,
    displayName: 'Portal42',
  },
  {
    displayName: 'Leaf Logix',
    alternateName: PosType.LeafLogixWholesale,
    name: PosType.LeafLogix,
  },
  {
    displayName: 'Cova',
    name: PosType.Cova,
  },
  {
    name: PosType.MjFreeway,
    alternateName: PosType.MjFreewayWholesale,
    displayName: 'MJ Freeway',
  },
  {
    displayName: 'Treez',
    name: PosType.TreezIO,
  },
  {
    displayName: 'Flowhub',
    name: PosType.Flowhub,
  },
  {
    displayName: 'BioTrack',
    alternateName: PosType.BioTrackWholesale,
    name: PosType.BioTrack,
  },
  {
    displayName: 'Greenbits',
    name: PosType.GreenBits,
  },
  {
    displayName: 'Blaze',
    name: PosType.Blaze,
  },
  {
    displayName: 'Quickbooks',
    name: PosType.Quickbooks,
  },
  {
    displayName: 'Growflow',
    name: PosType.GrowFlow,
  },
];

const getFetchStrategy = (posType: PosType) => {
  if ([PosType.Quickbooks, PosType.BioTrack].includes(posType)) {
    return 'integrated';
  } else {
    return 'automatic';
  }
};

interface Props {
  dispensary: Dispensary;
  userMap: { [id: string]: User };
  emitData: (type) => void;
}

export const PosConfigs = ({ dispensary, emitData, userMap }: Props) => {
  const apiClient = api();

  const form = useForm();
  const mockForm = useForm();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerPosType, setDrawerPosType] = useState<Pos>({
    name: '',
    displayName: '',
    isConnected: false,
    isPending: false,
  });
  const [checked, setChecked] = useState(false);
  const [eventData, setEventData] = useState(null);

  const hasConfiguredPos =
    dispensary &&
    dispensary.posConfig &&
    dispensary.posConfig.posName &&
    dispensary.posConfig.posName !== PosType.Unsupported &&
    dispensary.posConfig.posName !== PosType.GcvCsv_Quickbooks &&
    dispensary.posConfig.posName !== PosType.GcvFaker_GcvCsv;

  useEffect(() => {
    if (drawerPosType.isPending || drawerPosType.isConnected) {
      getPosInformation();
    }
  }, [drawerPosType]);

  useEffect(() => {
    if (window.location.href.includes('realmId')) {
      apiClient.quickbooks
        .getQuickbooksAccessToken(
          dispensary.id,
          {
            uri: window.location.href,
          },
          () => {}
        )
        .then(res => {
          if (res.accessToken.access_token) {
            const posConfig = {
              posName: PosType.Quickbooks,
              auth: {},
              fetch: { fetchStrategy: getFetchStrategy(PosType.Quickbooks) },
              status: 'Connected',
            };
            putPosConfig(posConfig, () => {
              emitData('reroute');
            });
          }
        })
        .catch(e => {
          alert(
            'Error integrating the POS System, please contact support@greencheckverified.com for further assistance'
          );
          setDrawerOpen(false);
          setChecked(false);
        });
    }
  }, []);

  const getPosInformation = async () => {
    await apiClient.dispensaries
      .getEventByType(dispensary.id, EventTypes.UpdatedPosConfig, () => {})
      .then(data => {
        const latestConnection = data.reduce((acc, event) => (acc.date_created > event.date_created ? acc : event));
        setEventData(latestConnection);
      })
      .catch(e => {});
  };

  const toggleChecked = () => {
    setChecked(!checked);
  };

  const selectPos = (posName?: Pos) => {
    setDrawerPosType(posName);
    toggleDrawer();
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setChecked(false);
  };

  const renderPosTypes = () =>
    posTypes.map(({ name, displayName, alternateName }) => {
      const isPending =
        dispensary &&
        dispensary.posConfig &&
        (dispensary.posConfig.posName === name || dispensary.posConfig.posName === alternateName);
      const isConnected = isPending && dispensary.posConfig.status === 'Connected';

      const isCardActive = () => {
        if (hasConfiguredPos) {
          if (isConnected || isPending) {
            return true;
          }
        } else {
          return true;
        }
      };
      return (
        <Card
          onClick={() => (isCardActive() ? selectPos({ name, displayName, isConnected, isPending }) : null)}
          key={name}
          style={{ cursor: isCardActive() ? 'pointer' : 'auto' }}
        >
          <PosIcon
            src={`../../assets/${name}Icon.png`}
            style={{ opacity: !hasConfiguredPos || isPending || isConnected ? 1 : 0.6 }}
          ></PosIcon>
          <GcvStatusIndicator
            text={isConnected ? 'Connected' : isPending ? 'Pending' : 'Not Connected'}
            status={isConnected ? 'good' : isPending ? 'warning' : 'none'}
            style={{
              width: 'fit-content',
              margin: '10px auto',
              opacity: !hasConfiguredPos || isPending || isConnected ? 1 : 0.3,
            }}
          />
        </Card>
      );
    });

  const onSubmit = data => {
    const posConfig = {
      posName: PosType[drawerPosType.name],
      auth: data,
      fetch: { fetchStrategy: getFetchStrategy(PosType[drawerPosType.name]) },
      status: 'Not Connected',
    };

    if (drawerPosType.name === PosType.TreezIO) {
      posConfig.auth.dispensaryId = dispensary.id;
    }

    putPosConfig(posConfig, () => emitData('getDispensary'));
  };

  const putPosConfig = async (data, callback) => {
    await apiClient.dispensaries
      .setPosConfig(dispensary.id, { posConfig: data }, () => {})
      .then(() => {
        setDrawerOpen(false);
        setChecked(false);
        callback();
      })
      .catch(e => {
        alert('Error integrating the POS System, please contact support@greencheckverified.com for further assistance');
        setDrawerOpen(false);
        setChecked(false);
      });
  };

  const integrateQuickbooks = async () => {
    await apiClient.quickbooks
      .getQuickbooksAuthUri(() => {})
      .then(res => {
        window.open(res.authUri, '_self');
        setDrawerOpen(false);
        setChecked(false);
      })
      .catch(e => {
        alert(
          'Error integrating with Quickbooks, please contact support@greencheckverified.com for further assistance'
        );
        setDrawerOpen(false);
        setChecked(false);
      });
  };

  const needDateGateCheck =
    drawerPosType.name === PosType.GrowFlow ||
    drawerPosType.name === PosType.Cova ||
    drawerPosType.name === PosType.MjFreeway ||
    drawerPosType.name === PosType.MjFreewayWholesale;

  const toolTip = 'Please enter required fields to enable';

  return (
    <>
      <SubText>
        Connecting your sales tracking system to Green Check allows you to automatically upload your sales activity.
      </SubText>
      <CardRowContainer>{renderPosTypes()}</CardRowContainer>
      <GcvDrawer onClose={() => toggleDrawer()} open={drawerOpen} style={{ width: '482px', overflow: 'hidden' }}>
        <DrawerHeader>
          <VerticalCenter>
            <BackButtonIcon onClick={() => toggleDrawer()}></BackButtonIcon>
          </VerticalCenter>
          <VerticalCenter>
            <Title>
              {drawerPosType.displayName} {!hasConfiguredPos ? 'Setup' : 'Connection'}
            </Title>
          </VerticalCenter>
        </DrawerHeader>
        <DrawerContent>
          {!hasConfiguredPos ? (
            <>
              {drawerPosType.name === PosType.Portal42 ? <Portal42Form form={form}></Portal42Form> : null}
              {drawerPosType.name === PosType.MjFreeway ? <MjFreewayForm form={form}></MjFreewayForm> : null}
              {drawerPosType.name === PosType.GreenBits ? <GreenBitsForm form={form}></GreenBitsForm> : null}
              {drawerPosType.name === PosType.TreezIO ? <TreezForm form={form}></TreezForm> : null}
              {drawerPosType.name === PosType.Flowhub ? <FlowHub form={form}></FlowHub> : null}
              {drawerPosType.name === PosType.LeafLogix ? <LeafLogixForm form={form}></LeafLogixForm> : null}
              {drawerPosType.name === PosType.BioTrack ? <BioTrackForm form={form}></BioTrackForm> : null}
              {drawerPosType.name === PosType.Cova ? <CovaForm form={form}></CovaForm> : null}
              {drawerPosType.name === PosType.Quickbooks ? <QuickBooksForm form={form}></QuickBooksForm> : null}
              {drawerPosType.name === PosType.Blaze ? <BlazeForm form={form}></BlazeForm> : null}
              {drawerPosType.name === PosType.GrowFlow ? <GrowFlowForm form={form}></GrowFlowForm> : null}
              {needDateGateCheck ? (
                <>
                  <DrawerText>Please enter the date you sent the email:</DrawerText>
                  <GcvInputForm name="date" label="Email Date *" {...mockForm} type="date"></GcvInputForm>
                </>
              ) : null}
              {needDateGateCheck && !mockForm.watch('date') ? (
                <div style={{ marginTop: '30px' }}>
                  <span style={{ width: 'fit-content', display: 'inline' }}>
                    <label data-tip={toolTip}>
                      <GcvCheckbox checked={checked} onChange={toggleChecked} disabled={true} />
                      <ReactTooltip place="right" type="dark" effect="solid" delayShow={250} />
                    </label>
                  </span>
                  <span>
                    <b style={{ marginLeft: '10px', color: $grey1 }}>Enable {drawerPosType.displayName} integration</b>
                  </span>
                </div>
              ) : (
                <div style={{ marginTop: '30px' }}>
                  <span>
                    <label>
                      <GcvCheckbox checked={checked} onChange={toggleChecked}></GcvCheckbox>
                    </label>
                  </span>
                  <span>
                    <b style={{ marginLeft: '10px' }}>Enable {drawerPosType.displayName} integration</b>
                  </span>
                </div>
              )}
              <ButtonContainer>
                <GcvButton
                  primary={true}
                  onClick={
                    drawerPosType.name === PosType.Quickbooks ? integrateQuickbooks : form.handleSubmit(onSubmit)
                  }
                  disabled={!checked}
                >
                  Connect
                </GcvButton>
              </ButtonContainer>
            </>
          ) : (
            <>
              <DrawerText style={{ color: $grey2, marginBottom: '0' }}>Connected By</DrawerText>
              <DrawerText>
                {userMap && eventData && userMap[eventData.user_id]
                  ? userMap[eventData.user_id].firstName + ' ' + userMap[eventData.user_id].lastName
                  : ''}
              </DrawerText>
              <DrawerText style={{ color: $grey2, marginBottom: '0' }}>Connected On</DrawerText>
              <DrawerText>{eventData ? formatISOToDateAtTime(eventData.date_created) : ''}</DrawerText>
              <DrawerText style={{ marginTop: '30px', textAlign: 'center' }}>
                To disconnect this integration, please email{' '}
                <a rel="noopener noreferrer" target="_blank" href="mailto:support@greencheckverified.com">
                  support@greencheckverified.com
                </a>
                .
              </DrawerText>
              {drawerPosType.name === PosType.Quickbooks ? (
                <ButtonContainer>
                  <GcvButton primary={true} onClick={integrateQuickbooks}>
                    Refresh Quickbooks Token
                  </GcvButton>
                </ButtonContainer>
              ) : null}
            </>
          )}
        </DrawerContent>
      </GcvDrawer>
      <div style={{ marginTop: '40px' }}>
        Don't see your sales tracking system? Contact us at{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:support@greencheckverified.com">
          support@greencheckverified.com
        </a>
      </div>
    </>
  );
};
