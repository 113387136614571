import { Dispensary, DueDiligenceDocument, DueDiligenceRequirement, LicenseManager, PosType, User } from '@gcv/shared';

export const getDispensaryType = (posName: PosType): 'retail' | 'non_retail' => {
  if (
    posName === 'Quickbooks' ||
    posName === 'GcvCsv_Quickbooks' ||
    posName === 'MjFreewayWholesale' ||
    posName === 'BioTrackWholesale' ||
    posName === 'LeafLogixWholesale'
  ) {
    return 'non_retail';
  } else {
    return 'retail';
  }
};

export const getDispensaryLicenseType = (dispensary: Dispensary) => {
  if (
    dispensary.due_diligence &&
    dispensary.due_diligence.requirements &&
    dispensary.due_diligence.requirements.business_licenses &&
    dispensary.due_diligence.requirements.business_licenses.documents
  ) {
    const docs = Object.values(dispensary.due_diligence.requirements.business_licenses.documents);
    if (docs.length > 1) {
      return 'Multiple';
    } else {
      return getDispensaryLicenseName(docs[0].license_name, dispensary.state);
    }
  } else {
    return '--';
  }
};

export const getDispensaryLicenseName = (licenseName: string, dispensaryState: string): string => {
  const licensesByState = LicenseManager.getLicenseData(dispensaryState);
  const licenseMap = licensesByState.business_license_types;
  const license = licenseMap.find(license => license.value === licenseName)?.viewValue ?? licenseName;
  return license;
};

export const getDispensaryMotUsers = (
  dispensary: Dispensary,
  dispensaryUsers: User[]
): {
  id: string;
  name: string;
  favorite: boolean;
  active: boolean;
}[] => {
  return dispensary.methodOfTransportation.user.reduce((filteredUsers, val) => {
    const motUser = dispensaryUsers.find(user => user.id === val.id);
    if (motUser && motUser.invitation_status !== 'archived' && val.active) {
      val.name = `${motUser.firstName} ${motUser.lastName}`;
      filteredUsers.push(val);
    }
    return filteredUsers;
  }, []);
};
