import { $primary, $white } from '../../util';
import { GreenCheckAwsEnvironmentName } from '@gcv/shared';
import { environment } from 'apps/user-interface/src/environments/environment';

const moneyRegex = `^[+-]?[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]?[0-9])?$`;

export const buttonStyles = {
  height: '2.25rem',
  maxWidth: '18rem',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  padding: '0 1rem',
  textAlign: 'center',
  borderWidth: '1.5px',
  border: `1px solid ${$primary}`,
  minWidth: '9rem',
  cursor: 'pointer',
  backgroundColor: $white,
  color: $primary,
  marginLeft: '1rem',
};

export const FFstyleOverrides = {
  borderRadius: '4px',
  primaryButtonColor: '#00bc66',
  primaryButtonFontSize: '1.6rem',
  primaryButtonFontColor: '#fff',
  secondaryButtonColor: '#00bc66',
  secondaryButtonBorderColor: '#dbe0eb',
  secondaryButtonFontSize: '1.6rem',
  secondaryButtonFontColor: '#fff',
  invertedButtonColor: '#00bc66',
  linkColor: '#c6d1dd',
  linkAltColor: '#4a90e2',
  primaryTextColor: '#3a3c45',
  secondaryTextColor: '#a5a8ba',
  errorColor: '#ff4e50',
  successColor: '#00bc66',
  warningColor: '#f5a623',
  borderColor: '#dbe0eb',
  fontFamily: 'Lato, Helvetica, Arial, san-serif',
};

export const dateTimeFields = {
  customer_id_expiry_date: true,
  customer_dob: true,
  Date: true,
  'Payment Date': true,
  receipt_date: true,
};

export const transactionDateTimeFields = {
  Date: true,
  'Payment Date': true,
  receipt_date: true,
};

export const retailFields = [
  {
    label: 'Transaction ID',
    key: 'transaction_id',
    description: 'The ID of the transaction',
    validators: [
      {
        validate: 'required',
        error: 'A transaction ID is required',
      },
    ],
  },
  {
    label: 'Receipt Date',
    key: 'receipt_date',
    description: 'The date of the transaction',
    validators: [
      {
        validate: 'required',
        error: 'A receipt date is required',
      },
    ],
  },
  {
    label: 'Customer ID',
    key: 'customer_id_number',
    description: `The ID number of the customer's identification card`,
    validators: [
      {
        validate: 'required',
        error: 'A customer ID is required',
      },
    ],
  },
  {
    label: 'Customer ID Type',
    key: 'customer_id_type',
    description: `e.g. MMJ, passport, driver's license, etc.`,
  },
  {
    label: 'Customer ID Exp Date',
    key: 'customer_id_expiry_date',
    description: `The expiration date of the customer's ID`,
  },
  {
    label: 'Customer Date of Birth',
    key: 'customer_dob',
    description: 'The date of birth of the customer',
  },
  {
    label: 'Employee Name',
    key: 'employee_name',
    description: 'The name of the employee making the sale',
  },
  {
    label: 'Employee ID Number',
    key: 'employee_id_number',
    description: `The ID of the employee making the sale`,
  },
  {
    label: 'Product ID',
    key: 'product_id',
    description: 'The ID of the product sold',
  },
  {
    label: 'Product Name',
    key: 'product_name',
    description: 'The name of the product sold',
  },
  {
    label: 'Product Grams',
    key: 'product_grams',
    description: 'The weight of the product sold in grams',
    validators: [
      {
        validate: 'regex_matches',
        regex: '^[0-9.]*$',
        error: 'Must only contain numbers',
      },
    ],
  },
  {
    label: 'Price Per Unit',
    key: 'product_price_per_unit',
    description: 'The price per unit of product sold',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
    ],
  },
  {
    label: 'Product Type',
    key: 'product_type',
    description: 'eg. flower, oil, edible',
  },
  {
    label: 'Transaction Subtotal',
    key: 'transaction_subtotal',
    description: 'Subtotal amount in dollars',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
    ],
  },
  {
    label: 'Transaction Tax',
    key: 'transaction_tax',
    description: 'Tax amount in dollars',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
    ],
  },
  {
    label: 'Transaction Total',
    key: 'transaction_total',
    description: 'Total transaction amount in dollars',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required',
        error: 'Transaction Total is required',
      },
    ],
  },
  {
    label: 'Transaction Cash Paid',
    key: 'transaction_cash_paid',
    description: 'Total amount of transaction paid in cash',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required_without_all',
        error: 'You must provide at least one value in cash paid, debit paid, credit paid, or check paid',
        fields: ['transaction_debit_paid', 'transaction_credit_paid', 'transaction_check_paid'],
      },
    ],
  },
  {
    label: 'Transaction Debit Paid',
    key: 'transaction_debit_paid',
    description: 'Total amount of transaction paid by debit card',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required_without_all',
        error: 'You must provide at least one value in cash paid, debit paid, credit paid, or check paid',
        fields: ['transaction_cash_paid', 'transaction_credit_paid', 'transaction_check_paid'],
      },
    ],
  },
  {
    label: 'Transaction Credit Paid',
    key: 'transaction_credit_paid',
    description: 'Total amount of transaction paid by credit card',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required_without_all',
        error: 'You must provide at least one value in cash paid, debit paid, credit paid, or check paid',
        fields: ['transaction_debit_paid', 'transaction_cash_paid', 'transaction_check_paid'],
      },
    ],
  },
  {
    label: 'Transaction Check Paid',
    key: 'transaction_check_paid',
    description: 'Total amount of transaction paid by check',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required_without_all',
        error: 'You must provide at least one value in cash paid, debit paid, credit paid, or check paid',
        fields: ['transaction_debit_paid', 'transaction_credit_paid', 'transaction_cash_paid'],
      },
    ],
  },
  {
    label: 'Medical or Adult',
    key: 'medical_or_adult_use',
    description: 'Type of sale, "Medical" or "Adult"',
    validators: [
      {
        validate: 'regex_matches',
        regex: `^(medical|adult)$`,
        regexFlags: { ignoreCase: true },
        error: 'Must be either Medical or Adult',
      },
    ],
  },
];

export const nonRetailFields = [
  {
    label: 'Date',
    key: 'Date',
    description: 'The date of the transaction',
    validators: [
      {
        validate: 'required_without_all',
        error: 'A valid Invoice Date or Payment must be provided',
        fields: ['Payment Date'],
      },
    ],
  },
  {
    label: 'Invoice Number',
    key: 'Invoice Number',
    description: 'The number/ID of the invoice',
    validators: [
      {
        validate: 'required',
        error: 'An invoice number is required',
      },
    ],
  },
  {
    label: 'Customer',
    key: 'Customer',
    description: 'The name of the customer',
    validators: [
      {
        validate: 'required',
        error: 'A customer is required',
      },
    ],
  },
  {
    label: 'Product(s)',
    key: 'Product(s)',
    description: 'The name of the product sold',
  },
  {
    label: 'Quantity',
    key: 'Quantity',
    description: 'The quantity of the product sold',
    validators: [
      {
        validate: 'regex_matches',
        regex: '^[0-9.]*$',
        error: 'Must only contain numbers',
      },
    ],
  },
  {
    label: 'Price',
    key: 'Price',
    description: 'The price per unit of product sold',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
    ],
  },
  {
    label: 'Memo/Description',
    key: 'Memo/Description',
    description: 'Optional notes about this invoice/payment',
  },
  {
    label: 'Invoice Total',
    key: 'Invoice Total',
    description: 'Total amount of the invoice',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required_without_all',
        error: 'Invoice Total is required when Payment Amount is not populated',
        fields: ['Payment Amount'],
      },
    ],
  },
  {
    label: 'Payment Date',
    key: 'Payment Date',
    description: 'The date the payment was received',
    validators: [
      {
        validate: 'required_without_all',
        error: 'A valid Payment Date or Invoice Date must be provided',
        fields: ['Date'],
      },
    ],
  },
  {
    label: 'Payment Type',
    key: 'Payment Type',
    description: 'The type the payment (cash, credit, debit, or check)',
    validators: [
      {
        validate: 'regex_matches',
        regex: '^(cash|credit|debit|check)$',
        regexFlags: { ignoreCase: true },
        error: 'Must be either "Cash", "Credit", "Debit", or "Check"',
      },
    ],
  },
  {
    label: 'Payment ID',
    key: 'Payment ID',
    description: 'The ID of the payment',
  },
  {
    label: 'Payment Amount',
    key: 'Payment Amount',
    description: 'Amount of payment',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required_without_all',
        error: 'Payment amount is required when Invoice Total and Open Balance are not populated',
        fields: ['Invoice Total', 'Open Balance'],
      },
    ],
  },
  {
    label: 'Open Balance',
    key: 'Open Balance',
    description: 'Remaining balance on invoice (if applicable)',
    validators: [
      {
        validate: 'regex_matches',
        regex: moneyRegex,
        error: 'Must only contain currency values',
      },
      {
        validate: 'required_without_all',
        error: 'Open Balance is required when Payment Amount is not populated',
        fields: ['Payment Amount'],
      },
    ],
  },
];

export const validMedicalStrings = [
  'medical',
  'medical_use',
  'medical-use',
  'medical use',
  'medicinal',
  'medicinal_use',
  'medicinal-use',
  'medicinal use',
  'med',
];
export const validAdultStrings = [
  'adult',
  'adult_use',
  'adult-use',
  'adult use',
  'recreational',
  'recreational_use',
  'recreational-use',
  'recreational use',
  'rec',
];

export const importerOptions = (flatFileFields, isRetail) => ({
  fields: flatFileFields,
  type: 'Sale Records',
  allowInvalidSubmit: true,
  managed: true,
  allowCustom: true,
  disableManualInput: false,
  devMode: environment.env !== GreenCheckAwsEnvironmentName.PROD,
  styleOverrides: FFstyleOverrides,
  title: isRetail ? 'Upload Sales Data' : 'Upload Invoice Data',
  i18nOverrides: {
    'en-US': {
      otherLocales: ['en', 'en-CA', 'en-GB'],
      overrides: {
        fileTypes: isRetail
          ? `If your point of sale allows you to export a spreadsheet with your sales data, you can upload it here. The next step will ask you to match your spreadsheet columns to our standard column names. \n
       Need help? Need a template? Visit the Support page or email support@greencheckverified.com.`
          : `If your invoice tracking system allows you to export a spreadsheet with your invoice data, you can upload it here. The next step will ask you to match your spreadsheet columns to our standard column names.
      \n
       Need help? Need a template? Visit the Support page or email support@greencheckverified.com.`,
        buttons: {
          ok: 'Dismiss',
        },
      },
    },
  },
});

export const validationHook = async (record, index) => {
  let out = {} as any;
  const keys = Object.keys(record);
  keys.forEach(key => {
    if (record[key]) {
      if (record[key].includes('\n') || record[key].includes('\r')) {
        out[key] = {
          value: record[key]
            .replace('\n', '')
            .replace('\r', '')
            .trim(),
          info: [],
        };
      }
      if (typeof record[key] === 'string' && (record[key].charAt(0) === ' ' || record[key].slice(-1) === ' ')) {
        out[key] = {
          value: record[key].trim(),
          info: [
            {
              message: 'Leading and trailing space have been automatically removed',
              level: 'warning',
            },
          ],
        };
      }
      if (dateTimeFields[key] && record[key]) {
        if (!Date.parse(record[key])) {
          out[key] = {
            value: record[key],
            info: [
              {
                message: 'Invalid date format.',
                level: 'error',
              },
            ],
          };
        } else if (transactionDateTimeFields[key] && new Date(record[key]).getFullYear() === 2001) {
          out[key] = {
            value: record[key],
            info: [
              {
                message: 'Invalid date format. Please ensure you have entered a valid date with a year specified.',
                level: 'error',
              },
            ],
          };
        }
      }
      if (key === 'medical_or_adult_use' && record[key]) {
        if (validMedicalStrings.includes(record[key].toLowerCase())) {
          out[key] = {
            value: 'Medical',
          };
        } else if (validAdultStrings.includes(record[key].toLowerCase())) {
          out[key] = {
            value: 'Adult',
          };
        }
      }
    }
  });
  return out;
};
