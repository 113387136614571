import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { ButtonInner, UpdateButton, UpdateButtonContainer } from './styles';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { $primary } from '../../util';

export interface ButtonProps {
  buttons: { title: string; onClick: () => void; dataCy?: string; disabled?: boolean; toolTip?: string }[];
  title: string;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  dataCy?: string;
  style?: any;
}

export const GcvDropButton = ({ buttons, title, primary, secondary, dataCy, style }: ButtonProps) => {
  const [viewMore, setViewMore] = useState<boolean>(false);

  const buttonContainerRef = useRef();
  const buttonInnerRef = useRef();

  const handleViewMoreBlur = e => {
    if (buttonContainerRef && buttonInnerRef) {
      // @ts-ignore
      if (!buttonContainerRef.current.contains(e.target) && !buttonInnerRef.current.contains(e.target)) {
        setViewMore(false);
      }
    }
  };

  useEffect(() => {
    if (viewMore) {
      document.addEventListener('mousedown', handleViewMoreBlur);
      return () => {
        document.removeEventListener('mousedown', handleViewMoreBlur);
      };
    }
  }, [viewMore]);

  const toggleViewMore = () => {
    setViewMore(!viewMore);
  };

  return (
    <UpdateButtonContainer ref={buttonContainerRef} style={style}>
      <UpdateButton primary={primary} secondary={secondary}>
        <ButtonInner
          style={viewMore ? { borderBottom: `1px solid ${$primary}` } : {}}
          onClick={toggleViewMore}
          data-cy={dataCy}
        >
          {title}
          <DownArrowIcon style={{ marginLeft: '15px' }} color={$primary}></DownArrowIcon>
        </ButtonInner>
        {viewMore ? (
          <>
            {buttons.map(({ onClick, title, disabled, dataCy, toolTip }) => (
              <label data-tip={toolTip} key={title}>
                <ButtonInner
                  ref={buttonInnerRef}
                  onClick={() => (!disabled ? onClick() : null)}
                  data-cy={dataCy}
                  disabled={disabled}
                >
                  {title}
                </ButtonInner>
                {disabled ? <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} /> : null}
              </label>
            ))}
          </>
        ) : null}
      </UpdateButton>
    </UpdateButtonContainer>
  );
};
