import React from 'react';
import { DocumentDrawer } from 'libs/react-ui/src/shared/Requirements/DocumentDrawer/DocumentDrawer';
import { getEndpoint, useApi } from 'libs/react-ui/src/hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { Dispensary, User } from '@gcv/shared';
import { DocumentMetadata } from 'libs/react-ui/src/shared/Requirements/types';
import { RequirementMetadata } from 'libs/react-ui/src/components/Requirements/types';
import { formatISOToDateAtTime } from 'libs/react-ui/src/util/dateTimeHelpers';
import { GcvReadMore, GcvInputTextArea, GcvButton, GcvUserInputContainer, GcvDrawer } from '../../../lib/';
import { formatUserFirstAndLastName } from 'libs/react-ui/src/util';
import { useForm } from 'react-hook-form';
import { HorizontalCenter, VerticalCenter } from 'libs/react-ui/src/styles/theme';

//#region Styles

import { $blue, $grey11, $grey9, $white } from '../../../util/styleVariables';
import styled from 'styled-components';

export const ViewFile = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  margin: 15px auto;
  cursor: pointer;
`;

export const HeaderContent = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export const Spacer = styled.div`
  margin: 20px 0px;
`;

export const Body = styled.div`
  padding: 0px 75px 75px 75px;
  overflow-y: auto;
`;

export const SubTitle = styled.div`
  margin-top: 18px;
  font-size: 13px;
  line-height: 16px;
  color: ${$grey9};
`;

export const SubContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 2px 0px;
`;

export const ReadMoreButton = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  &: hover {
    cursor: pointer;
  }
`;

export const UserInput = styled.div`
  border: none;
`;

export const Block = styled.div`
  padding: 10px 0px;
  margin: 30px 0px;
  border-top: 1px solid ${$grey11};
`;

export const Footer = styled.div`
  border-top: 1px solid ${$grey11};
  padding: 25px;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${$white};
  flex-shrink: 0;
`;

export const DrawerContent = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
`;

//#endregion

interface DocumentDrawerProps {
  document: any;
  requirementMetadata: RequirementMetadata;
  dispensary: Dispensary;
  req_id: string;
  onCloseDrawer: () => void;
  getRequirementData: (dispensary: Dispensary) => void;
  updateDocument: (docId: string, status: string, note: string) => any;
  userMap: {
    [id: string]: User;
  };
  permissions: {
    updateRequirement: boolean;
    updateDocument: boolean;
  };
  documentType: 'document' | 'license';
}

export const AccountDocumentDrawer: React.FC<DocumentDrawerProps> = props => {
  const { handleSubmit, ...form } = useForm({});
  const documentMetadata: DocumentMetadata = props.document
    ? props.requirementMetadata.documents[props.document.id]
    : null;

  const approveDocument = async ({ note }) => {
    props
      .updateDocument(props.document.id, 'approved', note)
      .then(updatedDispensary => {
        props.onCloseDrawer();
        props.getRequirementData(updatedDispensary);
      })
      .catch(err => {
        alert('error updating document status, please contact support@greencheckverified.com' + err);
      });
  };

  return (
    <DocumentDrawer
      userMap={props.userMap}
      onClose={props.onCloseDrawer}
      open={document && documentMetadata ? true : false}
      dispensary={props.dispensary}
      documentMetadata={documentMetadata}
      document={props.document}
      requirementMetadata={props.requirementMetadata}
      documentType={props.documentType}
      header={
        <div>
          {documentMetadata ? (
            <>
              <SubContent style={{ margin: '0px' }}>
                {documentMetadata.reviewed_by ? 'Active' : 'Needs Review'}
              </SubContent>

              <SubTitle style={{ marginTop: '0px' }}>
                <GcvUserInputContainer string={documentMetadata.id} length={10} />
              </SubTitle>
            </>
          ) : null}
        </div>
      }
    >
      <>
        {props.requirementMetadata && props.requirementMetadata.documents && documentMetadata ? (
          documentMetadata.status === 'approved' || documentMetadata.status === 'archived' ? (
            <Block>
              <SubTitle>Internal Comment</SubTitle>
              <GcvReadMore
                style={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px' }}
                string={
                  documentMetadata.bank_internal_note
                    ? documentMetadata.bank_internal_note
                    : 'No comments were made on this file'
                }
                length={100}
              />

              <SubTitle>Reviewed On</SubTitle>
              <SubContent>{formatISOToDateAtTime(documentMetadata.review_date)}</SubContent>

              <SubTitle>Reviewed By</SubTitle>
              <SubContent>
                <GcvUserInputContainer
                  string={formatUserFirstAndLastName(props.userMap[documentMetadata.reviewed_by])}
                  length={40}
                />
              </SubContent>
            </Block>
          ) : (
            <VerticalCenter style={{ marginTop: 10 }}>
              <GcvInputTextArea
                {...form}
                name={'note'}
                label={'Add any notes you have about the document (optional)'}
                testDataTag="document-notes-textbox"
              ></GcvInputTextArea>

              <HorizontalCenter style={{ margin: '0px' }}>
                <GcvButton
                  onClick={handleSubmit(approveDocument)}
                  primary={true}
                  data-cy="mark-as-reviewed-button"
                  disabled={!props.permissions.updateDocument}
                >
                  Mark as Reviewed
                </GcvButton>
              </HorizontalCenter>
            </VerticalCenter>
          )
        ) : null}
      </>
    </DocumentDrawer>
  );
};
