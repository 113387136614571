import React from 'react';
import { Grid } from '@material-ui/core';
import { GcvButton, GcvInputSelectNew, GcvTimePeriodDropdown, GcvSearchDropDown } from '../../../lib';

export interface Option {
  value: string;
  label: string;
}

interface TitleProps {
  align?: 'left' | 'right';
  size: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const Title: React.FC<TitleProps> = props => {
  return (
    <Grid item xs={props.size} style={{ textAlign: props.align ? props.align : 'left' }}>
      <h3>{props.children}</h3>
    </Grid>
  );
};

interface ExportButtonProps {
  align?: 'left' | 'right';
  export?: () => void;
  size: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const ExportButton: React.FC<ExportButtonProps> = props => {
  return (
    <Grid item xs={props.size} style={{ textAlign: props.align ? props.align : 'left' }}>
      <GcvButton primary onClick={props.export} data-cy="export">
        Export Report
      </GcvButton>
    </Grid>
  );
};

interface InputSelectProps {
  align?: 'left' | 'right';
  onChange: (e) => void;
  selectOptions: Option[];
  size: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  text: string;
}

const InputSelect: React.FC<InputSelectProps> = props => {
  return (
    <Grid item xs={props.size} style={{ textAlign: props.align ? props.align : 'left' }} data-cy="input-select">
      <GcvInputSelectNew
        label={props.text}
        options={props.selectOptions}
        onChange={props.onChange}
        dropWidth={'175px'}
        labelWidth={'125px'}
      />
    </Grid>
  );
};

interface SearchDropdownProps {
  align?: 'left' | 'right';
  onSelect: (e) => void;
  selectOptions: Option[];
  size: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const SearchDropdown: React.FC<SearchDropdownProps> = props => {
  return (
    <Grid item xs={props.size} style={{ textAlign: props.align ? props.align : 'left' }} data-cy="search-dropdown">
      <GcvSearchDropDown filterReport={props.onSelect} distinctAccounts={props.selectOptions} />
    </Grid>
  );
};

interface TimePeriodSelectProps {
  align?: 'left' | 'right';
  labelText?: string;
  onChange: (e) => void;
  onLabelChange: (e) => void;
  selectableLabel?: boolean;
  selectOptions: Option[];
  size: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const TimePeriodSelect: React.FC<TimePeriodSelectProps> = props => {
  return (
    <Grid item xs={props.size} style={{ textAlign: props.align ? props.align : 'left' }}>
      <GcvTimePeriodDropdown
        selectableLabel={props.selectableLabel}
        labelText={props.labelText}
        emitData={e => props.onChange(e.value)}
        newStyle={true}
        labelOptions={props.selectOptions}
        onLabelChange={e => props.onLabelChange(e.value)}
      />
    </Grid>
  );
};

interface ReportProps {}

export const Report: React.FC<ReportProps> & {
  ExportButton: React.FC<ExportButtonProps>;
  InputSelect: React.FC<InputSelectProps>;
  SearchDropdown: React.FC<SearchDropdownProps>;
  TimePeriodSelect: React.FC<TimePeriodSelectProps>;
  Title: React.FC<TitleProps>;
} = props => {
  return (
    <Grid container spacing={3}>
      {props.children}
    </Grid>
  );
};

Report.ExportButton = ExportButton;
Report.InputSelect = InputSelect;
Report.SearchDropdown = SearchDropdown;
Report.TimePeriodSelect = TimePeriodSelect;
Report.Title = Title;
