import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AggregatesState } from './aggregates.reducer';
import {
  AggregateTypes,
  BankAggregate,
  DispensaryAggregate,
  FincenDepositAggregate,
  FincenReportAggregate,
  SalesAggregate,
} from '../../typings/aggregates';
import {
  selectAggregateById,
  selectBankAggregateById,
  selectBankAggregates,
  selectDispensaryAggregateById,
  selectDispensaryAggregates,
  selectFincenDepositAggregateById,
  selectFincenDepositAggregates,
  selectFincenReportAggregates,
  selectSalesAggregates,
  selectSarReportPeriodAggregates,
} from './aggregates.selectors';
import { Injectable } from '@angular/core';
import {
  LoadBankAggregate,
  LoadDispensaryAggregate,
  LoadDispensaryAggregates,
  LoadFincenDepositAggregate,
  LoadSalesAggregate,
  LoadSalesAggregates,
  LoadSarReportPeriodAggregate,
  ResetAggregates,
} from './aggregates.actions';
import { SarPeriodAggregate } from '@gcv/shared';

@Injectable({
  providedIn: 'root',
})
export class AggregatesFacade {
  salesAggregate$: Observable<SalesAggregate | {}>;
  fincenReportAggregate$: Observable<FincenReportAggregate | {}>;
  fincenDepositAggregate$: Observable<FincenDepositAggregate | {}>;
  bankAggregate$: Observable<BankAggregate | {}>;
  dispensaryAggregate$: Observable<DispensaryAggregate | {}>;
  sarReportPeriodAggregate$: Observable<SarPeriodAggregate | {}>;

  constructor(private store: Store<AggregatesState>) {
    this.salesAggregate$ = store.pipe(select(selectSalesAggregates));
    this.fincenDepositAggregate$ = store.pipe(select(selectFincenDepositAggregates));
    this.fincenReportAggregate$ = store.pipe(select(selectFincenReportAggregates));
    this.bankAggregate$ = store.pipe(select(selectBankAggregates));
    this.dispensaryAggregate$ = store.pipe(select(selectDispensaryAggregates));
    this.sarReportPeriodAggregate$ = store.pipe(select(selectSarReportPeriodAggregates));
  }

  loadSalesAggregate(companyId: string) {
    this.store.dispatch(new LoadSalesAggregate({ companyId, type: AggregateTypes.sales }));
  }

  loadSalesAggregates(companyIds: string[]) {
    this.store.dispatch(new LoadSalesAggregates({ companyIds, type: AggregateTypes.sales }));
  }

  loadBankAggregate(companyId: string) {
    this.store.dispatch(new LoadBankAggregate({ companyId, type: AggregateTypes.bank }));
  }

  loadDispensaryAggregate(companyId: string) {
    this.store.dispatch(new LoadDispensaryAggregate({ companyId, type: AggregateTypes.dispensary }));
  }

  loadDispensaryAggregates(companyIds: string[]) {
    this.store.dispatch(new LoadDispensaryAggregates({ companyIds, type: AggregateTypes.dispensary }));
  }

  loadFincenDepositAggregate(companyId: string) {
    this.store.dispatch(new LoadFincenDepositAggregate({ companyId, type: AggregateTypes.fincenDeposits }));
  }

  loadFincenReportAggregate(companyId: string) {
    // FIXME
  }

  loadSarReportPeriodAggregate(companyId: string) {
    this.store.dispatch(new LoadSarReportPeriodAggregate({ companyId, type: AggregateTypes.sarPeriod }));
  }

  selectSarReportPeriodAggregate() {
    return this.store.pipe(select(selectSarReportPeriodAggregates));
  }

  selectSalesAggregateById(companyId: string) {
    return this.store.pipe(select(selectAggregateById, { companyId }));
  }

  selectDispensaryAggregatesById(companyId: string) {
    return this.store.pipe(select(selectDispensaryAggregateById, { companyId }));
  }

  selectBankAggregateById(bankId: string) {
    return this.store.pipe(select(selectBankAggregateById, { bankId }));
  }

  selectFincenDepositAggregateById(reportId: string) {
    return this.store.pipe(select(selectFincenDepositAggregateById, { reportId }));
  }

  selectFincenReportAggregates() {
    return this.store.pipe(select(selectFincenReportAggregates));
  }

  selectSarReportPeriodAggregates() {
    return this.store.pipe(select(selectSarReportPeriodAggregates));
  }

  resetAggregateStore() {
    this.store.dispatch(new ResetAggregates());
  }

  selectAllSalesAggregates() {
    return this.store.pipe(select(selectSalesAggregates));
  }

  selectAllDispensaryAggregates() {
    return this.store.pipe(select(selectDispensaryAggregates));
  }
}
