<div class="wrapper" data-cy="edit-group-add-user-dialog.component">
  <div class="closeWrapper">
    <svg (click)="handleCloseClicked()" height="32" version="1" width="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle cx="15" cy="15" r="15" stroke="#DBE0EB" transform="translate(1 1)" />
        <path
          d="M19 12l-3 3-3-3a1 1 0 0 0-1 1l3 3-3 3v2h1l3-3 3 3a1 1 0 0 0 2-2l-3-3 3-3v-1h-2z"
          fill="#A5A8BA"
          stroke="#FFF"
        />
      </g>
    </svg>
  </div>
  <div class="header">
    <div class="headerTextWrapper">
      <span class="groupName" data-cy="edit-group-add-user-dialog.component.groupName">{{ group.name }}</span>
      <span class="title">Add or Remove Users</span>
    </div>
  </div>
  <div class="content">
    <form class="filter-field">
      <mat-form-field [ngStyle]="{ width: '100%' }" appearance="standard">
        <mat-label>Search Users</mat-label>
        <input
          (input)="filterItems()"
          [(ngModel)]="filterText"
          data-cy="edit-group-add-user-dialog.component.filter"
          matInput
          maxlength="100"
          name="filter"
          type="text"
        />
        <svg height="21.069" matSuffix viewBox="0 0 20.894 21.069" width="20.894" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.66,20.652,13.9,15.893a8.785,8.785,0,1,1,1.9-1.849l4.734,4.734a1.325,1.325,0,1,1-1.873,1.873ZM6.349,2.954A6.313,6.313,0,1,0,8.8,2.459a6.313,6.313,0,0,0-2.449.494Z"
            fill="#a5a8ba"
            transform="translate(-0.027 0.029)"
          />
        </svg>
      </mat-form-field>
    </form>
    <div class="userWrapper">
      <div
        *ngFor="let user of filteredUsers; let i = index"
        class="user"
        data-cy="edit-group-add-user-dialog.component.user"
      >
        <gcv-initial-logo [firstName]="user.firstName" [lastName]="user.lastName"></gcv-initial-logo>
        <span
          [class.userActive]="selectedMap[user.id]"
          class="userName"
          data-cy="edit-group-add-user-dialog.component.userName"
          >{{ user.firstName }} {{ user.lastName }}</span
        >
        <span class="userRole" data-cy="edit-group-add-user-dialog.component.userRole">{{
          formatRole(user.role)
        }}</span>
        <mat-checkbox
          (change)="addUser(user, i)"
          [checked]="selectedMap[user.id]"
          color="primary"
          data-cy="edit-group-add-user-dialog.component.checkbox"
        ></mat-checkbox>
      </div>
    </div>
  </div>
  <span class="userCount">{{ selectedCount() }} users selected</span>
  <div class="btnWrapper">
    <gcv-button
      (clickEvent)="handleCloseClicked()"
      buttonText="Cancel"
      data-cy="edit-group-add-user-dialog.component.cancel"
      type="tertiary"
    ></gcv-button>
    <gcv-button
      (clickEvent)="handleSaveClicked()"
      [disabled]="(group.type === 'administration' || group.type === 'account_owner') && selectedCount() === 0"
      buttonText="Save"
      data-cy="edit-group-add-user-dialog.component.save"
      type="primary"
    ></gcv-button>
  </div>
</div>
