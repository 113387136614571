import React, { useEffect, useState } from 'react';
import { GcvTimePeriodDropdown, GcvSearchDropDown, GcvZeroState, GcvCard, GcvLoading } from 'libs/react-ui/src/lib';
import { DateTime } from 'luxon';
import calculateTimeRange from '../../../util/calculateDateRange';
import { useApi } from 'libs/react-ui/src/hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { Dispensary, DailySummary, DueDiligenceStatus, Deposit, Bank } from '@gcv/shared';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, Text, Label } from 'recharts';
import { $primary, $grey2 } from 'libs/react-ui/src/util/styleVariables';
import {
  ChartContainer,
  GreyTitle,
  BlackTitle,
  GreyText,
  SummaryLineContainer,
  LoadingContainer,
  Percentage,
  ToolTipContainer,
} from './styles';
import { cropInput, formatMoney, formatPercentage } from 'libs/react-ui/src/util/formatHelpers';
import { api } from 'libs/react-ui/src/api';
interface Props {
  bank: Bank;
  dispensaries: { [id: string]: Dispensary };
}

interface Option {
  value: string;
  label: string;
}

export const Overview = (props: Props) => {
  const [summaries, setSummaries] = useState<DailySummary[]>(null);
  const [deposits, setDeposits] = useState<Deposit[]>(null);
  const [timePeriod, setTimePeriod] = useState(calculateTimeRange('last30Days', props.bank.iana_timezone));
  const [loading, setLoading] = useState(true);
  const [distinctAccounts, setDistinctAccounts] = useState<Option[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<string[]>([]);
  const apiClient = api();

  const fetchDashboardData = async () => {
    try {
      const startDay = DateTime.fromISO(timePeriod.start).toFormat('yyyy-MM-dd');
      const endDay = DateTime.fromISO(timePeriod.end).toFormat('yyyy-MM-dd');
      const summaries = await apiClient.banks.getDailySummary(props.bank.id, startDay, endDay, () => {});

      setSummaries(summaries);
      const deposits = await apiClient.banks.getDeposits(props.bank.id, () => {}, timePeriod.start, timePeriod.end);
      setDeposits(deposits);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.bank.id) {
      fetchDashboardData();
    }
  }, [props.bank.id, timePeriod]);

  useEffect(() => {
    if (props.dispensaries) {
      const dispensaryOptions = Object.values(props.dispensaries)
        .filter(disp => disp.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED)
        .map((d: any) => {
          return { value: d.id, label: d.name };
        });
      setDistinctAccounts(dispensaryOptions);
    }
  }, [props.dispensaries]);

  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, props.bank.iana_timezone);
    setLoading(true);
    setTimePeriod(timeRange);
  };

  const handleFilterAccounts = results => {
    setFilteredAccounts(results);
  };

  const { chartData, depositData, salesData } = filterAndFormatData(
    filteredAccounts,
    props.dispensaries,
    summaries,
    deposits,
    timePeriod
  );

  const CustomizedAxisTick = props => {
    const { x, y, payload } = props;
    return (
      <Text x={x} y={y} textAnchor="end" verticalAnchor="end" angle={-45} width={200} style={{ fontSize: 12 }}>
        {chartData.length >= 30 ? null : cropInput(payload.value, 25)}
      </Text>
    );
  };

  const CustomTooltip = props => {
    const { active, payload, label } = props;
    if (active) {
      const { total, uncheckable_total, unverified_total, verified_total } = payload[0].payload as ChartData;
      return (
        <ToolTipContainer>
          <GreyTitle>{label}</GreyTitle>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
            <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
              {formatMoney(verified_total)}
              <Percentage>({formatPercentage(verified_total, total)})</Percentage>
            </BlackTitle>
            <GreyText>Verified</GreyText>
          </SummaryLineContainer>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
            <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
              {formatMoney(unverified_total)}
              <Percentage>({formatPercentage(unverified_total, total)})</Percentage>
            </BlackTitle>
            <GreyText>Unverified</GreyText>
          </SummaryLineContainer>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
            <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
              {formatMoney(uncheckable_total)}
              <Percentage>({formatPercentage(uncheckable_total, total)})</Percentage>
            </BlackTitle>
            <GreyText>Unchecked</GreyText>
          </SummaryLineContainer>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
            <BlackTitle style={{ fontSize: '16px' }}>{formatMoney(total)}</BlackTitle>
            <GreyText style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</GreyText>
          </SummaryLineContainer>
        </ToolTipContainer>
      );
    }

    return null;
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '1rem' }}>
          <GcvSearchDropDown distinctAccounts={distinctAccounts} filterReport={handleFilterAccounts} />
        </div>

        <GcvTimePeriodDropdown
          emitData={handleTimeChange}
          labelText={'Time Period'}
          newStyle={true}
          defaultValueDrop={'last30Days'}
        ></GcvTimePeriodDropdown>
      </div>
      {loading ? (
        <LoadingContainer>
          <GcvLoading></GcvLoading>
        </LoadingContainer>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <GcvCard style={{ width: '70%', margin: '1rem 1rem 1rem 0' }}>
            <GcvCard.Header
              title={
                <div style={{ marginBottom: '1rem', fontSize: '18px' }}>
                  <div>Total sales</div>
                  <div style={{ fontWeight: 'normal', color: $grey2, fontSize: '14px', marginTop: '.5rem' }}>
                    {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED)}
                    {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED) !==
                    DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                      ? ' - ' + DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                      : null}
                  </div>
                </div>
              }
              style={{ borderBottom: 'none' }}
            ></GcvCard.Header>
            <GcvCard.Body>
              {chartData.length ? (
                <ChartContainer>
                  <ResponsiveContainer width="100%" height={450}>
                    <BarChart data={chartData} margin={{ top: 15, right: 30, left: 20, bottom: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        tick={<CustomizedAxisTick />}
                        height={120}
                        interval={0}
                        minTickGap={1}
                      ></XAxis>
                      <YAxis width={100} tickFormatter={tick => formatMoney(tick)} />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="total" fill={$primary} />
                    </BarChart>
                  </ResponsiveContainer>
                </ChartContainer>
              ) : (
                <GcvZeroState basic={true}></GcvZeroState>
              )}
            </GcvCard.Body>
          </GcvCard>
          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', margin: '1rem 1rem 1rem 0' }}>
            <GcvCard>
              <GcvCard.Header
                title={
                  <div style={{ marginBottom: '1rem', fontSize: '18px' }}>
                    <div>Summary</div>
                    <div style={{ fontWeight: 'normal', color: $grey2, fontSize: '14px', marginTop: '.5rem' }}>
                      {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED)}
                      {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED) !==
                      DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                        ? ' - ' + DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                        : null}
                    </div>
                  </div>
                }
                style={{ borderBottom: 'none' }}
              ></GcvCard.Header>
              <GcvCard.Body style={{ padding: '8px' }}>
                <GreyTitle>Sales</GreyTitle>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(salesData.verified)}
                    <Percentage>({formatPercentage(salesData.verified, salesData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Verified</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(salesData.unverified)}
                    <Percentage>({formatPercentage(salesData.unverified, salesData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Unverified</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(salesData.uncheckable)}
                    <Percentage>({formatPercentage(salesData.uncheckable, salesData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Unchecked</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
                  <BlackTitle style={{ fontSize: '16px' }}>
                    {formatMoney(salesData.uncheckable + salesData.unverified + salesData.verified)}
                  </BlackTitle>
                  <GreyText style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</GreyText>
                </SummaryLineContainer>
                <GreyTitle style={{ marginTop: '2rem' }}>Deposits</GreyTitle>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(depositData.reconciled)}
                    <Percentage>({formatPercentage(depositData.reconciled, depositData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Reconciled</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(depositData.pending)}
                    <Percentage>({formatPercentage(depositData.pending, depositData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Pending</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(depositData.under_review)}
                    <Percentage>({formatPercentage(depositData.under_review, depositData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Under Review</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
                  <BlackTitle style={{ fontSize: '16px' }}>{formatMoney(depositData.total)}</BlackTitle>
                  <GreyText style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</GreyText>
                </SummaryLineContainer>
              </GcvCard.Body>
            </GcvCard>
          </div>
        </div>
      )}
    </>
  );
};

interface ChartData {
  total: number;
  name: string;
  unverified_total: number;
  verified_total: number;
  uncheckable_total: number;
}

const filterAndFormatData = (
  accountsToInclude: string[],
  dispensaries: { [id: string]: Dispensary },
  summaries: DailySummary[],
  deposits,
  timePeriod
) => {
  const filteredDispensaries: {
    [id: string]: ChartData;
  } = dispensaries
    ? Object.values(dispensaries)
        .filter(disp => disp.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED)
        .filter(disp => accountsToInclude.includes(disp.id))
        .reduce((acc, disp) => {
          acc[disp.id] = { total: 0, name: disp.name, unverified_total: 0, verified_total: 0, uncheckable_total: 0 };
          return acc;
        }, {})
    : {};

  const dispensariesWithSalesTotals: {
    [id: string]: ChartData;
  } = summaries
    ? summaries.reduce((acc, summary) => {
        if (accountsToInclude.includes(summary.dispensary_id)) {
          acc[summary.dispensary_id].total +=
            summary.unverified_total + summary.verified_total + summary.uncheckable_total;
          acc[summary.dispensary_id].unverified_total += summary.unverified_total;
          acc[summary.dispensary_id].verified_total += summary.verified_total;
          acc[summary.dispensary_id].uncheckable_total += summary.uncheckable_total;
        }
        return acc;
      }, filteredDispensaries)
    : {};

  const depositData: { reconciled?: number; pending?: number; total?: number; under_review?: number } = deposits
    ? deposits.reduce(
        (acc, dep) => {
          if (accountsToInclude.includes(dep.dispensary_id)) {
            if (
              DateTime.fromISO(dep.date_created)
                .toUTC()
                .toISO() >= timePeriod.start &&
              DateTime.fromISO(dep.date_created)
                .toUTC()
                .toISO() <= timePeriod.end
            ) {
              if (typeof dep.final_deposit === 'number') {
                if (dep.status === 'accepted') {
                  acc.reconciled += dep.final_deposit;
                } else if (dep.status === 'pending') {
                  acc.pending += dep.final_deposit;
                } else if (dep.status === 'under_review') {
                  acc.under_review += dep.final_deposit;
                }
                acc.total += dep.final_deposit;
              }
            }
          }
          return acc;
        },
        { reconciled: 0, pending: 0, total: 0, under_review: 0 }
      )
    : {};

  const salesData: { verified?: number; unverified?: number; uncheckable?: number; total?: number } = summaries
    ? summaries.reduce(
        (acc, summary) => {
          if (accountsToInclude.includes(summary.dispensary_id)) {
            acc.uncheckable += summary.uncheckable_total;
            acc.verified += summary.verified_total;
            acc.unverified += summary.unverified_total;
            acc.total += summary.uncheckable_total + summary.verified_total + summary.unverified_total;
          }
          return acc;
        },
        { verified: 0, unverified: 0, uncheckable: 0, total: 0 }
      )
    : {};

  const chartData = Object.values(dispensariesWithSalesTotals).sort((a, b) => (a.name > b.name ? 1 : -1));

  return { salesData, depositData, chartData };
};
