import React from 'react';

import { DispensaryAccountReviewWithData, DispensaryPeriodSummaryCoreTransactions } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { RadarConfig } from './chart-options';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TransactionAnalysisPreviousPeriodRadarChart: React.FC<Props> = props => {
  return <GcvChart type="radar" config={chartDataAdapter(props.review)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData) => {
  return transactionDataRadarAdapter(review.data.aggregated_core_transaction_data);
};

export const transactionDataRadarAdapter = (data: {
  current_period: DispensaryPeriodSummaryCoreTransactions;
  previous_period: DispensaryPeriodSummaryCoreTransactions;
  previous_year: DispensaryPeriodSummaryCoreTransactions;
  peer_groups: {};
}): RadarConfig => {
  return {
    categories: [
      {
        category: [
          {
            label: 'Cash In',
          },
          {
            label: 'Check In',
          },
          {
            label: 'Check Out',
          },
          {
            label: 'Cash Out',
          },
          {
            label: 'ACH/Wire Out',
          },
          {
            label: 'ACH/Wire In',
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: 'This Period',
        color: '#00BC66',
        anchorBgColor: '#00BC66',
        data: [
          {
            // cash in
            value: data.current_period.deposit_amount_cash / 100,
          },
          {
            // check in
            value: data.current_period.deposit_amount_check / 100,
          },
          {
            // check out
            value: data.current_period.withdrawal_amount_check / 100,
          },
          {
            // cash out
            value: data.current_period.withdrawal_amount_cash / 100,
          },
          {
            // ach/wire out
            value: (data.current_period.withdrawal_amount_ach + data.current_period.withdrawal_amount_wire) / 100,
          },
          {
            // ach/wire in
            value: (data.current_period.deposit_amount_ach + data.current_period.deposit_amount_wire) / 100,
          },
        ],
      },
      {
        seriesname: 'Previous Period',
        color: '#6896FB',
        anchorBgColor: '#6896FB',
        data: [
          {
            // cash in
            value: data.previous_period.deposit_amount_cash / 100,
          },
          {
            // check in
            value: data.previous_period.deposit_amount_check / 100,
          },
          {
            // check out
            value: data.previous_period.withdrawal_amount_check / 100,
          },
          {
            // cash out
            value: data.previous_period.withdrawal_amount_cash / 100,
          },
          {
            // ach/wire out
            value: (data.previous_period.withdrawal_amount_ach + data.previous_period.withdrawal_amount_wire) / 100,
          },
          {
            // ach/wire in
            value: (data.previous_period.deposit_amount_ach + data.previous_period.deposit_amount_wire) / 100,
          },
        ],
      },
    ],
  };
};
