import React from 'react';

import { DailySalesDistribution, DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { HeatmapConfig } from './chart-options';
import { formatMoney } from 'libs/react-ui/src/util';
import { DateTime } from 'luxon';
import { tooltip } from './Tooltip';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const DailySalesHeatmapChart: React.FC<Props> = props => {
  return <GcvChart type="heatmap" config={chartDataAdapter(props.review)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData) => {
  return dataAdapter(review.data.hourly_sales_distribution);
};

export const dataAdapter = (data: { [date: string]: DailySalesDistribution }): HeatmapConfig => {
  return {
    dataset: [
      {
        data: getHeatmapData(data),
      },
    ],
    colorrange: {
      gradient: '0',
      minvalue: '0',
      code: 'E24B1A',
      startlabel: 'Less Sales',
      endlabel: 'More Sales',
      color: [
        {
          code: 'C1EEAA',
          minvalue: getHeatmapGradient(data, 1, 'min'),
          maxvalue: getHeatmapGradient(data, 1, 'max'),
          label: 'Less',
        },
        {
          code: '96E688',
          minvalue: getHeatmapGradient(data, 2, 'min'),
          maxvalue: getHeatmapGradient(data, 2, 'max'),
          label: 'Less',
        },
        {
          code: '44D359',
          minvalue: getHeatmapGradient(data, 3, 'min'),
          maxvalue: getHeatmapGradient(data, 3, 'max'),
          label: 'Average',
        },
        {
          code: '00BC66',
          minvalue: getHeatmapGradient(data, 4, 'min'),
          maxvalue: getHeatmapGradient(data, 4, 'max'),
          label: 'More',
        },
        {
          code: '00A77E',
          minvalue: getHeatmapGradient(data, 5, 'min'),
          maxvalue: getHeatmapGradient(data, 5, 'max'),
          label: 'More',
        },
      ],
    },
  };
};

const formatAmPm = (hour: string) => {
  const hourNumber = parseInt(hour);

  if (0 === hourNumber || 24 === hourNumber) {
    return '12 AM';
  } else if (12 > hourNumber) {
    return `${hour} AM`;
  } else if (12 === hourNumber) {
    return '12 PM';
  } else {
    return `${hourNumber - 12} PM`;
  }
};

const getHeatmapData = (data: { [date: string]: DailySalesDistribution }) => {
  const sortedDays = Object.keys(data).sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf());

  let sortedHours = [];

  sortedDays.forEach(day => {
    Object.keys(data[day]).forEach(hour => {
      if (!sortedHours.includes(hour)) {
        sortedHours.push(hour);
      }
    });
  });

  sortedHours.sort((a, b) => parseInt(a) - parseInt(b));

  const heatmapData: {
    rowid: string;
    columnid: string;
    value: number;
    trlabel: string;
  }[] = [];

  sortedDays.forEach(day => {
    sortedHours.forEach(hour => {
      if (!data[day][hour]) {
        const chartData = {
          rowid: formatAmPm(hour),
          columnid: DateTime.fromFormat(day, 'yyyy-MM-dd')
            .get('day')
            .toString(),
          value: 0,
          trlabel: '',
        };

        heatmapData.push(chartData);
      } else {
        const chartData = {
          rowid: formatAmPm(hour),
          columnid: DateTime.fromFormat(day, 'yyyy-MM-dd')
            .get('day')
            .toString(),
          value: data[day][hour].quantity,
          trlabel: tooltip(DateTime.fromFormat(day, 'yyyy-MM-dd').toLocaleString(), [
            {
              label: 'Total',
              value: formatMoney(data[day][hour].cents),
            },
            {
              label: 'Time',
              value: formatAmPm(hour) + ' - ' + formatAmPm((+hour + 1).toString()),
            },
            {
              label: 'Transactions',
              value: data[day][hour].quantity.toString(),
            },
          ]),
        };

        heatmapData.push(chartData);
      }
    });
  });

  // have to sort final result again for some reason
  heatmapData.sort((a, b) => +a.columnid - +b.columnid);

  return heatmapData;
};

const getHeatmapGradient = (
  data: {
    [date: string]: DailySalesDistribution;
  },
  position: number,
  type: 'min' | 'max'
) => {
  let maxValue = 0;

  Object.values(data).map(day => {
    return Object.values(day).map(hour => {
      if (maxValue < hour.quantity) {
        maxValue = hour.quantity;
      }
    });
  });

  switch (position) {
    case 1:
      if (type === 'min') {
        return 0.001;
      } else {
        return maxValue * 0.2;
      }
    case 2:
      if (type === 'min') {
        return maxValue * 0.2 + 1;
      } else {
        return maxValue * 0.4;
      }
    case 3:
      if (type === 'min') {
        return maxValue * 0.4 + 1;
      } else {
        return maxValue * 0.6;
      }
    case 4:
      if (type === 'min') {
        return maxValue * 0.6 + 1;
      } else {
        return maxValue * 0.8;
      }
    case 5:
      if (type === 'min') {
        return maxValue * 0.8 + 1;
      } else {
        return maxValue;
      }
  }
};
