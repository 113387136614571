import React, { useState, useEffect } from 'react';
import { GcvDataTable, GcvLoading, GcvZeroState } from '../../../lib';

import { requirement, lastUpdated, status, type } from './columns';
import { Dispensary, DueDiligenceTemplate } from '@gcv/shared';
import { mergeGcvAndBankRequirements } from 'libs/react-ui/src/shared/Requirements/due-diligence-util';

interface RequirementsTableProps {
  dispensary: Dispensary;
  template: DueDiligenceTemplate;
  emitData: (type, reqData, dispensaryId?) => void;
}

export const RequirementsTable = ({ emitData, dispensary, template }: RequirementsTableProps) => {
  const isBank = window.location.href.includes('bank');
  const requirementsMetadataMap = dispensary.due_diligence.requirements;
  const dueDiligenceTemplate = mergeGcvAndBankRequirements(template, dispensary);

  if (!requirementsMetadataMap || !dueDiligenceTemplate) {
    return <GcvLoading></GcvLoading>;
  }

  const headerText = 'No Requirements Found';

  const formatText = (text: string) => {
    if (!text) {
      return '';
    }

    // remove underscores
    text = text.replace('_', ' ');

    // capitalize letters
    text = text.replace(/(?:^|\s)\S/g, function(a) {
      return a.toUpperCase();
    });

    return text;
  };

  const tableRows = Object.keys(dueDiligenceTemplate).map(reqId => {
    return {
      reqId,
      lastUpdated: requirementsMetadataMap[reqId] ? requirementsMetadataMap[reqId].last_updated : '',
      requirementName: formatText(dueDiligenceTemplate[reqId].name),
      status: requirementsMetadataMap[reqId] ? requirementsMetadataMap[reqId].status : '',
      type: formatText(dueDiligenceTemplate[reqId].type),
    };
  });

  const handleRowClick = row => {
    emitData('rowClick', row.reqId, dispensary.id);
  };

  const isPending =
    dispensary.due_diligence.due_diligence_status !== 'bank_awaiting_review' &&
    dispensary.due_diligence.due_diligence_status !== 'bank_approved';

  const columnsFI = [
    requirement('Requirement', handleRowClick),
    lastUpdated('Last Updated'),
    status('Status', handleRowClick),
  ];

  const columnsPendingFI = [requirement('Requirement', handleRowClick), lastUpdated('Last Updated')];

  const columnsCRB = [requirement('Requirement', handleRowClick), type('Type'), lastUpdated('Last Updated')];

  return (
    <GcvDataTable
      data={tableRows}
      columns={isBank ? (isPending ? columnsPendingFI : columnsFI) : columnsCRB}
      onRowClicked={handleRowClick}
      defaultSortField={'Requirement'}
      defaultSortAsc={false}
      keyField="Requirement"
      noDataComponent={<GcvZeroState basic={true} headerText={headerText} />}
      subHeaderAlign="left"
      paginationPerPage={8}
    />
  );
};
