import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { Deposit, convertStringNumberToWholeCents, convertNumberToWholeCents } from '@gcv/shared';

import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import { formatMoney } from '../../../util/formatHelpers';
import { formatISOToDateAtTime } from '../../../util/dateTimeHelpers';

import {
  GcvDrawer,
  dateFormatterFromISO,
  dateFormatterToISO,
  GcvLoading,
  GcvReadMore,
  GcvStatusIndicator,
  GcvUserInputContainer,
  GcvInputSelectNew,
} from '../../../lib';

import { Header, SubTitle, HeaderContent, Block, Body, SubContent, DrawerContent } from './styles';
import { DepositDetailDrawerFooter } from './DepositDetailDrawerFooter';
import { DepositDetailDrawerDepositForm } from './DepositDetailDrawerDepositForm';
import { DepositDetailDrawerReconciledForm } from './DepositDetailDrawerReconciledForm';
import { GcvCollapsibleSection } from 'libs/react-ui/src/lib/GcvCollapsableSection/GcvCollapsibleSection';
import { api } from 'libs/react-ui/src/api';
import { Button, Snackbar } from '@material-ui/core';

interface Props {
  deposit: Deposit;
  bankId: string;
  type: string;
  userMap: any;
  dispensaryMap: any;
  emitData: (deposit, type: 'acceptDeposit' | 'updateDeposit') => any;
  onClose: () => void;
  open: boolean;
}

export const DepositDetailDrawer: React.FC<Props> = props => {
  if (!props.open) {
    return <GcvDrawer open={false} onClose={props.onClose} />;
  }
  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const [datePostedIsDateReceived, setDatePostedIsDateReceived] = useState(true);
  const [dispUsersMap, setDispUsersMap] = useState({});
  const [formComplete, setFormComplete] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [depositStatus, setDepositStatus] = useState<string>(props.deposit.status);
  const [snackBarMessage, setsnackBarMessage] = useState<string>();
  const apiClient = api();

  useEffect(() => {
    getDispensaryUsers();
  }, []);

  const getDispensaryUsers = async () => {
    await apiClient.organizations
      .getUsersForOrg(props.deposit.dispensary_id, () => {})
      .then(res => {
        setDispUsersMap(
          res.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
          }, {})
        );
      });
  };

  const getFormattedMethodsOfTransportation = methods => {
    const formattedMethods = [];
    Object.keys(methods).forEach(key => {
      methods[key].forEach(val => {
        if (val.active) {
          const reactSelectOption = { label: val.name, value: val };
          formattedMethods.push(reactSelectOption);
        }
      });
    });
    return formattedMethods;
  };

  let initialdelivery_method: any;
  if (props.type === 'bank') {
    const methodsOfTransportation = props.dispensaryMap[props.deposit.dispensary_id].methodOfTransportation;
    const methodsOfTransportationFormatted = getFormattedMethodsOfTransportation(methodsOfTransportation);
    initialdelivery_method = methodsOfTransportationFormatted.find(val => val.value.id === props.deposit.delMethod.id);
  }

  const { register, handleSubmit, ...form } = useForm({
    defaultValues: {
      deliveryMethod: initialdelivery_method,
      dateReceived: dateFormatterFromISO(props.deposit.expected_arrival_date),
      datePosted: props.deposit.posted_date
        ? DateTime.fromISO(props.deposit.posted_date).toLocaleString(DateTime.DATE_MED)
        : '',
      additionalNotes: '',
    },
  });

  useEffect(() => {
    if (props.deposit.status === 'accepted') {
      setLoadingDeposit(false);
    }
    setDepositStatus(props.deposit.status);
  }, [props.deposit.status]);

  const { watch } = form;

  useEffect(() => {
    setFormComplete(
      watch('deliveryMethod') && watch('dateReceived') && ((datePostedIsDateReceived || watch('datePosted')) as boolean)
    );
  }, [watch('deliveryMethod'), watch('dateReceived'), watch('datePosted'), datePostedIsDateReceived]);

  const toggleDatePostedIsDateReceived = () => {
    setDatePostedIsDateReceived(!datePostedIsDateReceived);
  };

  const onReconcileSubmit = (formData: any) => {
    props.deposit.accepted_date = DateTime.local().toISO();
    props.deposit.final_deposit =
      typeof formData.depositAmountReceived === 'number'
        ? convertNumberToWholeCents(formData.depositAmountReceived)
        : convertStringNumberToWholeCents(formData.depositAmountReceived);
    props.deposit.delMethod = formData.deliveryMethod.value;
    props.deposit.bank_comments = formData.additionalNotes;
    props.deposit.arrived_date = dateFormatterToISO(formData.dateReceived);
    props.deposit.posted_date = dateFormatterToISO(
      datePostedIsDateReceived ? formData.dateReceived : formData.datePosted
    );

    setLoadingDeposit(true);
    props.emitData(props.deposit, 'acceptDeposit');
  };

  if (
    loadingDeposit ||
    (props.userMap && !Object.keys(props.userMap).length) ||
    (props.userMap && !Object.keys(dispUsersMap).length) ||
    (props.dispensaryMap && !Object.keys(props.dispensaryMap).length)
  ) {
    return (
      <GcvDrawer open={true} onClose={props.onClose} style={{ width: '482px' }}>
        <GcvLoading />
      </GcvDrawer>
    );
  }

  const onChangeStatus = async status => {
    if (status.value === props.deposit.status) {
      return;
    }
    let endpoint;
    let body = {};
    if (status.value === 'under_review') {
      endpoint = 'under-review';
      body = { reviewStartDate: DateTime.utc().toISO() };
    } else if (status.value === 'pending') {
      endpoint = 'pending';
    }

    setLoadingDeposit(true);
    await apiClient.banks
      .updateDepositStatus(
        props.bankId,
        props.deposit.dispensary_id,
        props.deposit.deposit_id,
        endpoint,
        body,
        () => {}
      )
      .then(response => {
        props.emitData(response, 'updateDeposit');
        setDepositStatus(status.value);
        setsnackBarMessage(`Deposit status set to ${status.value === 'pending' ? 'Pending' : 'Under Review'}`);
        setSnackBarOpen(true);
        setLoadingDeposit(false);
      })
      .catch(e => {
        setsnackBarMessage(`Could not update status, please try again.`);
        setSnackBarOpen(true);
      })
      .finally(() => {
        setLoadingDeposit(false);
      });
  };

  return (
    <GcvDrawer open={props.open} onClose={props.onClose} style={{ width: '482px' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBarOpen}
        autoHideDuration={4200}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarMessage}
      />
      <DrawerContent data-cy="deposit-detail-drawer">
        <Header>
          <div>
            <BackButtonIcon onClick={props.onClose} />
            <HeaderContent>
              Deposit Record
              <SubTitle style={{ marginTop: '0px' }}>
                <GcvUserInputContainer
                  string={props.dispensaryMap[props.deposit.dispensary_id].name}
                  length={36}
                  data-cy="deposit-dispensary-name"
                />
              </SubTitle>
            </HeaderContent>
          </div>
          <div>
            <SubContent style={{ margin: '0px', overflow: 'visible' }} data-cy="status-indicator">
              {props.deposit.status === 'accepted' ? (
                <div style={{ fontSize: '14px', fontWeight: 'bold' }} data-cy="status-text">
                  Reconciled
                </div>
              ) : (
                <GcvInputSelectNew
                  options={[
                    { value: 'under_review', label: 'Under Review' },
                    { value: 'pending', label: 'Pending' },
                  ]}
                  onChange={onChangeStatus}
                  defaultValueDrop={depositStatus}
                  dropWidth={'150px'}
                  dropCharLength={12}
                  buttonStyleOverride={{ backgroundColor: '#f2f4fa' }}
                ></GcvInputSelectNew>
              )}
            </SubContent>
          </div>
        </Header>
        <Body>
          <Block style={{ paddingLeft: '26px' }}>
            <SubTitle>
              {props.deposit.status === 'accepted' ? `Deposit Amount Received` : `Anticipated Deposit Amount`}
            </SubTitle>
            <SubContent
              style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '27px',
              }}
            >
              <GcvUserInputContainer
                string={formatMoney(props.deposit.final_deposit)}
                length={36}
                data-cy="deposit-amount"
              />
            </SubContent>
          </Block>
          <GcvCollapsibleSection label={'Customer Provided Details'}>
            <div style={{ marginLeft: '26px' }}>
              {props.deposit.status === 'accepted' ? (
                <>
                  <SubTitle>Anticipated Deposit Amount</SubTitle>
                  <SubContent>
                    {formatMoney(
                      props.deposit.anticipated_deposit_amount
                        ? props.deposit.anticipated_deposit_amount
                        : props.deposit.final_deposit
                    )}
                  </SubContent>
                </>
              ) : null}

              <SubTitle data-cy="deposit-date-title">
                {props.type === 'bank' ? 'Anticipated Receipt Date' : 'Planned Arrival Date'}
              </SubTitle>
              <SubContent data-cy="deposit-date">
                {DateTime.fromISO(props.deposit.expected_arrival_date).toLocaleString(DateTime.DATE_MED)}
              </SubContent>

              <SubTitle data-cy="deposit-mot-title">
                {props.type === 'bank' ? 'Anticipated Method of Transportation' : 'Planned Method of Transportation'}
              </SubTitle>
              <SubContent>
                <GcvUserInputContainer string={props.deposit.delMethod.name} length={36} data-cy="deposit-mot-name" />
              </SubContent>

              <SubTitle data-cy="deposit-comment-title">Customer Comment</SubTitle>
              <GcvReadMore
                string={props.deposit.comments ? props.deposit.comments : 'No comments were included with this deposit'}
                length={125}
                data-cy="comments"
              ></GcvReadMore>
            </div>
          </GcvCollapsibleSection>
          <GcvCollapsibleSection label={'Record Details'}>
            <div style={{ marginLeft: '26px' }}>
              <SubTitle>Max Deposit</SubTitle>
              <SubContent>
                <GcvUserInputContainer
                  string={formatMoney(props.deposit.max_deposit)}
                  length={36}
                  data-cy="deposit-max-deposit"
                ></GcvUserInputContainer>
              </SubContent>
              <SubTitle>Recorded By</SubTitle>
              <SubContent>
                <GcvUserInputContainer
                  string={
                    dispUsersMap[props.deposit.created_by]
                      ? `${dispUsersMap[props.deposit.created_by]['firstName']} ${
                          dispUsersMap[props.deposit.created_by]['lastName']
                        }`
                      : ''
                  }
                  length={36}
                  data-cy="reconciled-by"
                ></GcvUserInputContainer>
              </SubContent>
              <SubTitle>Recorded On</SubTitle>
              <SubContent data-cy="deposit-header-date">{formatISOToDateAtTime(props.deposit.deposit_date)}</SubContent>
              <SubTitle>Deposit ID</SubTitle>
              <SubContent>
                <GcvUserInputContainer string={props.deposit.deposit_id} length={50} data-cy="deposit-id" />
              </SubContent>
            </div>
          </GcvCollapsibleSection>

          {props.deposit.status !== 'accepted' ? (
            <div style={{ marginLeft: '26px' }}>
              <DepositDetailDrawerDepositForm
                dispensaryMap={props.dispensaryMap}
                datePostedIsDateReceived={datePostedIsDateReceived}
                deposit={props.deposit}
                form={form}
                getFormattedMethodsOfTransportation={getFormattedMethodsOfTransportation}
                register={register}
                toggleDatePostedIsDateReceived={toggleDatePostedIsDateReceived}
                type={props.type}
              />
            </div>
          ) : (
            <GcvCollapsibleSection label={'Reconciliation Details'} defaultOpen={true}>
              <div style={{ marginLeft: '26px' }}>
                <DepositDetailDrawerReconciledForm deposit={props.deposit} type={props.type} userMap={props.userMap} />
              </div>
            </GcvCollapsibleSection>
          )}
        </Body>

        {props.deposit.status !== 'accepted' ? (
          <DepositDetailDrawerFooter
            type={props.type}
            formComplete={formComplete}
            handleSubmit={handleSubmit(onReconcileSubmit)}
          />
        ) : null}
      </DrawerContent>
    </GcvDrawer>
  );
};
