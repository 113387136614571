import { Action } from '@ngrx/store';
import { Notification } from '@gcv/shared';

export enum NotificationActionTypes {
  LoadNotifications = '[Notification] Load Notifications',
  NotificationsLoaded = '[Notification] Notifications Loaded',

  UpdateNotification = '[Notification] Update Notification',
  NotificationUpdated = '[Notification] Notification Updated',

  CompleteNotification = '[Notification] Delete Notification',
  NotificationCompleted = '[Notification] Notification Deleted',

  ResetNotifications = '[Notification] Reset',
}

export class CompleteNotification implements Action {
  readonly type = NotificationActionTypes.CompleteNotification;

  constructor(public payload: Notification) {}
}

export class NotificationCompleted implements Action {
  readonly type = NotificationActionTypes.NotificationCompleted;

  constructor(public payload: Notification) {}
}

export class LoadNotifications implements Action {
  readonly type = NotificationActionTypes.LoadNotifications;

  constructor(public payload: { userId: string; orgId?: string; timeStamp?: string }) {}
}

export class NotificationsLoaded implements Action {
  readonly type = NotificationActionTypes.NotificationsLoaded;

  constructor(public payload: Notification[]) {}
}

export class UpdateNotification implements Action {
  readonly type = NotificationActionTypes.UpdateNotification;

  constructor(public payload: Notification) {}
}

export class NotificationUpdated implements Action {
  readonly type = NotificationActionTypes.NotificationUpdated;

  constructor(public payload: Notification) {}
}

export class ResetNotifications implements Action {
  readonly type = NotificationActionTypes.ResetNotifications;
}

export type NotificationActions =
  | LoadNotifications
  | NotificationsLoaded
  | UpdateNotification
  | NotificationUpdated
  | ResetNotifications
  | CompleteNotification
  | NotificationCompleted;
