import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Grid } from '@material-ui/core';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { Dropdown, VerticalCenter } from './styles';
import { GcvUserInputContainer } from '../GcvUserInputContainer/GcvUserInputContainer';
import { GcvSearchInput } from '../GcvSearchInput/GcvSearchInput';
import { GcvCheckbox } from '../GcvCheckbox/GcvCheckbox';
import { GcvButton } from '../GcvButton/GcvButton';

interface Props {
  distinctAccounts: Option[];
  filterReport?: (results: any[]) => void;
}
interface Option {
  value: string;
  label: string;
}

export const GcvSearchDropDown = (props: Props) => {
  const [query, setQuery] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState(props.distinctAccounts);
  const [selectedAccounts, setSelectedAccounts] = useState([] as Option[]);
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    setFilteredAccountsFromQuery();
  }, [props.distinctAccounts]);

  useEffect(() => {
    if (showSearchDropDown) {
      document.addEventListener('mousedown', handleDropdownBlur);
      setSelectedAccounts(selectedAccounts);

      return () => {
        document.removeEventListener('mousedown', handleDropdownBlur);
      };
    }
  }, [showSearchDropDown]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      props.filterReport(props.distinctAccounts.map(a => a.value));
    } else {
      props.filterReport(selectedAccounts.map(a => a.value));
    }
  }, [selectedAccounts]);

  useEffect(() => {
    setFilteredAccountsFromQuery();
  }, [query]);

  const toggleAccount = (account: Option) => {
    if (selectedAccounts.find(o => o.value === account.value)) {
      setSelectedAccounts(selectedAccounts.filter(a => a.value !== account.value));
    } else {
      setSelectedAccounts([...selectedAccounts, account]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedAccounts.length === filteredAccounts.length) {
      setSelectedAccounts([]);
    } else {
      setSelectedAccounts(filteredAccounts);
    }
  };

  const handleDropdownBlur = e => {
    if (dropdownRef) {
      // @ts-ignore
      if (!dropdownRef.current.contains(e.target)) {
        setShowSearchDropDown(false);
      }
    }
  };

  const applyFilter = () => {
    setShowSearchDropDown(false);

    if (!props.filterReport) {
      return;
    }

    if (selectedAccounts.length === 0) {
      props.filterReport(props.distinctAccounts.map(a => a.value));
    } else {
      props.filterReport(selectedAccounts.map(a => a.value));
    }
  };

  const onSearchAccountsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const setFilteredAccountsFromQuery = () => {
    updateSelectedAccounts();
    if (query) {
      setFilteredAccounts(props.distinctAccounts.filter(a => a.label.toLowerCase().includes(query.toLowerCase())));
    } else {
      setFilteredAccounts(props.distinctAccounts);
    }
  };

  const updateSelectedAccounts = () => {
    let existingSelectedAccounts = [];
    if (selectedAccounts.length > 0) {
      selectedAccounts.forEach(selectedAccount => {
        if (props.distinctAccounts.some(distinctAccount => distinctAccount.value === selectedAccount.value)) {
          existingSelectedAccounts.push(selectedAccount);
        }
      });
    }
    setSelectedAccounts(existingSelectedAccounts);
  };

  const toggleDropDown = e => {
    setShowSearchDropDown(true);
  };

  const getDisplayValue = () => {
    const selectedAccountNames = selectedAccounts.map(a => a.label);

    let string;
    if (selectedAccountNames.length === props.distinctAccounts.length || selectedAccountNames.length === 0) {
      string = 'All Accounts';
    } else if (selectedAccountNames.length > 1) {
      string = `${selectedAccountNames.length} Accounts`;
    } else if (selectedAccountNames.length === 1) {
      string = selectedAccountNames[0];
    }

    return <GcvUserInputContainer string={string} length={16}></GcvUserInputContainer>;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '34px', boxSizing: 'border-box' }}>
      <div
        style={{
          backgroundColor: '#F2F4FA',
          border: '1px solid #EBEEF7',
          borderRight: 'none',
          borderRadius: '4px 0 0 4px',
          padding: '6px 8px',
          width: '100px',
          textAlign: 'center',
          height: '34px',
          boxSizing: 'border-box',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '14px',
          color: '#828599',
          verticalAlign: 'middle',
        }}
      >
        <VerticalCenter style={{ height: '100%' }}>
          <div>Account(s)</div>
        </VerticalCenter>
      </div>
      <Dropdown>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '175px',
            cursor: 'pointer',
          }}
          onClick={toggleDropDown}
        >
          <VerticalCenter
            style={{
              padding: '6px 8px',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '14px',
              color: 'black',
            }}
          >
            {getDisplayValue()}
          </VerticalCenter>

          <VerticalCenter style={{ padding: '0 .5rem' }}>
            <DownArrowIcon></DownArrowIcon>
          </VerticalCenter>
        </div>

        {showSearchDropDown ? (
          <div
            style={{
              zIndex: 10000,
              position: 'absolute',
              backgroundColor: 'rgb(255, 255, 255)',
              border: '1px solid #EBEEF7',
              borderRadius: '0 0 4px 4px',
              borderTop: 0,
              padding: '15px',
              width: '275px',
              maxHeight: '500px',
              marginTop: '34px',
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '14px',
              boxSizing: 'border-box',
              boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
            }}
            ref={dropdownRef}
          >
            <div style={{ borderBottom: '1px solid #EBEEF7' }}>
              <GcvSearchInput
                label="Search accounts"
                placeholder="Search accounts"
                id="searchAccounts"
                value={query}
                style={{ height: 'auto', marginTop: '0px' }}
                onChange={onSearchAccountsChange}
              />
              <ul
                style={{
                  listStyleType: 'none',
                  maxHeight: '250px',
                  overflowY: 'auto',
                  padding: 0,
                }}
              >
                {filteredAccounts
                  .sort((accountA, accountB) => (accountA.label > accountB.label ? 1 : -1))
                  .map(account => {
                    const isSelected = selectedAccounts.some(sa => sa.value === account.value);

                    return (
                      <li key={account.value} style={{ paddingRight: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            margin: '8px 0',
                          }}
                        >
                          <ReactTooltip place="top" type="dark" effect="solid" delayShow={100} />
                          <p
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              margin: '0px 0.5rem 0 0',
                            }}
                            data-tip={account.label}
                          >
                            {account.label}
                          </p>
                          <label style={{ marginLeft: 'auto' }}>
                            <GcvCheckbox checked={isSelected} onChange={() => toggleAccount(account)} />
                          </label>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div style={{ marginTop: `15px` }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <span style={{ color: '#9B9EB1', fontSize: '16px' }}>{selectedAccounts.length} Selected</span>
                </Grid>
                <Grid item xs={4}>
                  <span style={{ fontSize: '14px', color: '#6896FB', cursor: 'pointer' }} onClick={toggleSelectAll}>
                    {filteredAccounts.length === selectedAccounts.length ? 'Deselect All' : 'Select All'}
                  </span>
                </Grid>
              </Grid>
              <div style={{ textAlign: 'center', width: '100%', marginTop: '15px' }}>
                <GcvButton primary onClick={applyFilter}>
                  Apply
                </GcvButton>
              </div>
            </div>
          </div>
        ) : null}
      </Dropdown>
    </div>
  );
};
