import React, { useEffect, useState } from 'react';
import { Route, useHistory, Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Dispensary } from '@gcv/shared';

import {
  Title,
  ToggleCardTableView,
  SearchInputContainer,
  Cards,
  Table,
  Header,
  VerticalCenter,
  InviteAccountContainer,
  CardIcon,
  CardIconDivider,
  TableIconLine,
} from './styles';
import { $grey13, $grey4 } from '../../util';
import { GcvSearchInput } from '../../lib/GcvSearchInput/GcvSearchInput';
import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';

import { GcvButton } from '../../lib';
import { RouteObject } from '../../util/types';
import { Pending } from './components/AccountsPending';
import { AwaitingReview } from './components/AccountsAwaitingReview';
import { Active } from './components/AccountsActive';
import { Button, Snackbar } from '@material-ui/core';

const Tabs: RouteObject[] = [
  {
    path: '/secure/bank/accounts/pending',
    render: props => <Pending {...props} />,
  },
  {
    path: '/secure/bank/accounts/awaiting-review',
    render: props => <AwaitingReview {...props}></AwaitingReview>,
  },
  {
    path: '/secure/bank/accounts/active',
    render: props => <Active {...props}></Active>,
  },
];

type Props = {
  dispensaries: Dispensary[];
  dispensaryAggregates: any[];
};

export const Accounts = ({ dispensaries, dispensaryAggregates }: Props) => {
  const history = useHistory();
  const [tableView, setTableView] = useState(dispensaries.length > 6);
  const [query, setQuery] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState(dispensaries);
  const [snackBarOpen, setSnackBarOpen] = useState(
    history.location.state && history.location.state['snackBarMessage'] !== undefined
  );

  useEffect(() => {
    const result = dispensaries.filter(account => account.name.toLowerCase().includes(query.toLowerCase()));
    setFilteredAccounts(result);
  }, [query, dispensaries]);

  const onSearchInput = e => {
    setQuery(e.target.value);
  };

  const emitData = payload => {
    if (payload.type === 'awaiting-review') {
      history.push(`/secure/bank/account/${payload.dispensary.id}/review/overview`);
    } else if (payload.type === 'pending') {
      history.push(`/secure/bank/account/${payload.dispensary.id}/review/overview`);
    } else if (payload.type === 'active') {
      history.push(`/secure/bank/account/${payload.dispensary.id}/view/account-owners`);
    }
  };

  const tabRoutes = [
    { path: '/secure/bank/accounts/active', label: 'Active' },
    { path: '/secure/bank/accounts/awaiting-review', label: 'Awaiting review' },
    { path: '/secure/bank/accounts/pending', label: 'Pending' },
  ];

  const action = (
    <Button color="inherit" size="small" onClick={() => setSnackBarOpen(false)}>
      Dismiss
    </Button>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        action={history.location.state && history.location.state['isError'] ? action : null}
        open={snackBarOpen}
        autoHideDuration={history.location.state && history.location.state['isError'] ? null : 4000}
        onClose={() => setSnackBarOpen(false)}
        message={history.location.state ? history.location.state['snackBarMessage'] : ''}
      />
      <Header>
        <Title style={{ margin: 0, marginBottom: '1rem' }}>Accounts</Title>

        <ToggleCardTableView>
          <InviteAccountContainer>
            <Link to={'/secure/bank/account/invite'}>
              <GcvButton primary>Invite Account</GcvButton>
            </Link>
          </InviteAccountContainer>

          <VerticalCenter>View:</VerticalCenter>
          <Cards
            onClick={() => {
              setTableView(false);
            }}
            style={{ backgroundColor: !tableView ? $grey13 : $grey4 }}
            data-cy="accounts-cards-toggle"
          >
            <CardIconDivider>
              <CardIcon></CardIcon>
              <CardIcon></CardIcon>
              <CardIcon></CardIcon>
            </CardIconDivider>
            <CardIconDivider>
              <CardIcon></CardIcon>
              <CardIcon></CardIcon>
              <CardIcon></CardIcon>
            </CardIconDivider>
            <CardIconDivider>
              <CardIcon></CardIcon>
              <CardIcon></CardIcon>
              <CardIcon></CardIcon>
            </CardIconDivider>
          </Cards>
          <Table
            onClick={() => {
              setTableView(true);
            }}
            style={{ backgroundColor: tableView ? $grey13 : $grey4 }}
            data-cy="accounts-table-toggle"
          >
            <TableIconLine></TableIconLine>
            <TableIconLine></TableIconLine>
            <TableIconLine></TableIconLine>
          </Table>
        </ToggleCardTableView>
      </Header>

      <Header style={{ marginTop: '37px' }}>
        <GcvTabs routes={tabRoutes}></GcvTabs>
        <SearchInputContainer>
          <GcvSearchInput
            style={{ width: 250, marginTop: 0, position: 'relative', right: 0 }}
            onChange={onSearchInput}
            value={query}
            placeholder="Search Accounts"
            label="Search Accounts"
            id="search"
            data-cy="search-accounts"
          />
        </SearchInputContainer>
      </Header>

      {Tabs.map(({ path, render }) => {
        return (
          <Route path={path} key={path}>
            {({ match }) =>
              match
                ? render({
                    dispensaries: filteredAccounts,
                    dispensaryAggregates: dispensaryAggregates,
                    tableView: tableView,
                    emitData: emitData,
                    isFiltered: query != '',
                  })
                : null
            }
          </Route>
        );
      })}
    </>
  );
};
