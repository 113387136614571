import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NotificationActionTypes } from './notifications.actions';
import { Notification } from '@gcv/shared';

export interface NotificationState extends EntityState<Notification> {}

export const adapterNotification: EntityAdapter<Notification> = createEntityAdapter<Notification>();

export const initialNotificationState = adapterNotification.getInitialState();

export function notificationReducer(state = initialNotificationState, action): NotificationState {
  switch (action.type) {
    case NotificationActionTypes.NotificationsLoaded:
      return adapterNotification.upsertMany(action.payload, state);
    case NotificationActionTypes.NotificationUpdated:
      return adapterNotification.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case NotificationActionTypes.ResetNotifications:
      return initialNotificationState;
    case NotificationActionTypes.NotificationCompleted:
      return adapterNotification.updateOne({ id: action.payload.id, changes: action.payload }, state);
    default:
      return state;
  }
}

export const {
  selectIds: selectNotificationIds,
  selectEntities: selectNotificationEntities,
  selectAll: selectAllNotifications,
} = adapterNotification.getSelectors();
