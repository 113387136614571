import { DataPersistence } from '../../utils/data.persistance';
import { DepositState } from './deposits.reducer';
import { Effect } from '@ngrx/effects';
import {
  AcceptDeposit,
  CreateDeposit,
  DepositAccepted,
  DepositActionTypes,
  DepositCreated,
  LoadDepositsById,
  DepositsByIdLoaded,
  LoadDepositsByBankId,
  DepositsByBankIdLoaded,
  LoadNewDeposit,
  NewDepositLoaded,
} from './deposits.actions';
import { map } from 'rxjs/operators';
import { Deposit } from '@gcv/shared';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';
import { LoadDispensaryAggregate } from '../aggregates/aggregates.actions';
import { AggregateTypes } from '../../typings/aggregates';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DepositsEffect {
  @Effect() loadDepositsById$ = this.dataPersistance.fetch(DepositActionTypes.LoadDepositsById, {
    run: (action: LoadDepositsById, state: DepositState) => {
      return this.sdk
        .dispensariesIdDepositsGet(action.payload.dispensaryId)
        .pipe(map((res: Deposit[]) => new DepositsByIdLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.LoadDepositsById, message: err });
    },
  });

  @Effect() loadDepositsByBankId$ = this.dataPersistance.fetch(DepositActionTypes.LoadDepositsByBankId, {
    run: (action: LoadDepositsByBankId, state: DepositState) => {
      let url = `/banks/${action.payload.bankId}/deposits`;
      if (action.payload.startDate) {
        url = url + `?startDate=${action.payload.startDate}`;
        if (action.payload.endDate) {
          url = url + `&endDate=${action.payload.endDate}`;
        }
      }
      return from(API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, url, {})).pipe(
        map((res: Deposit[][]) => new DepositsByBankIdLoaded(res))
      );
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.LoadDepositsByBankId, message: err });
    },
  });

  @Effect() createDeposit$ = this.dataPersistance.fetch(DepositActionTypes.CreateDeposit, {
    run: (action: CreateDeposit, state: DepositState) => {
      return this.sdk
        .dispensariesIdDepositsPost(action.payload.dispensaryId, action.payload.deposit)
        .pipe(map((res: Deposit) => new DepositCreated(res)));
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.CreateDeposit, message: err });
    },
  });

  @Effect() depositCreated$ = this.dataPersistance.fetch(DepositActionTypes.DepositCreated, {
    run: (action: DepositCreated, state: DepositState) => {
      return new LoadDispensaryAggregate({ companyId: action.payload.dispensary_id, type: AggregateTypes.dispensary });
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.DepositCreated, message: err });
    },
  });

  @Effect() acceptDeposit$ = this.dataPersistance.fetch(DepositActionTypes.AcceptDeposit, {
    run: (action: AcceptDeposit, state: DepositState) => {
      return this.sdk
        .banksIdDispensariesDispensaryIdDepositsDepositIdAcceptPost(
          action.payload.deposit.deposit_id,
          action.payload.deposit.dispensary_id,
          action.payload.bankId,
          action.payload.deposit
        )
        .pipe(map((res: Deposit) => new DepositAccepted(res)));
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.AcceptDeposit, message: err });
    },
  });

  @Effect() loadNewDeposit$ = this.dataPersistance.fetch(DepositActionTypes.LoadNewDeposit, {
    run: (action: LoadNewDeposit, state: DepositState) => {
      if (!action.payload.maxDepositFeatureFlag) {
        return this.sdk
          .dispensariesIdDepositsNewGet(action.payload.dispensaryId)
          .pipe(map((res: Deposit) => new NewDepositLoaded(res)));
      } else {
        return this.sdk
          .dispensariesIdMaxDepositGet(action.payload.dispensaryId)
          .pipe(map((res: Deposit) => new NewDepositLoaded(res)));
      }
    },
    onError: err => {
      return new AddError({ action: DepositActionTypes.LoadNewDeposit, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<DepositState>, private sdk: DefaultService) {}
}
