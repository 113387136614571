import React, { useContext } from 'react';

import {
  DispensaryAccountReviewWithData,
  DispensaryPeriodSummaryCoreTransactions,
  PeerGroupPeriodSummaryCoreTransactions,
} from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { RadarConfig } from './chart-options';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TransactionAnalysisPeerGroupRadarChart: React.FC<Props> = props => {
  const context = useContext(AccountMonitoringContext);

  return <GcvChart type="radar" config={chartDataAdapter(props.review, context.peerGroupId)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, peerGroupId: string) => {
  return transactionDataRadarAdapter(review.data.aggregated_core_transaction_data, peerGroupId);
};

export const transactionDataRadarAdapter = (
  data: {
    current_period: DispensaryPeriodSummaryCoreTransactions;
    previous_period: DispensaryPeriodSummaryCoreTransactions;
    previous_year: DispensaryPeriodSummaryCoreTransactions;
    peer_groups: { [id: string]: PeerGroupPeriodSummaryCoreTransactions };
  },
  peerGroupId: string
): RadarConfig => {
  const peerGroup = data.peer_groups[peerGroupId];

  return {
    categories: [
      {
        category: [
          {
            label: 'Cash In',
          },
          {
            label: 'Check In',
          },
          {
            label: 'Check Out',
          },
          {
            label: 'Cash Out',
          },
          {
            label: 'ACH/Wire Out',
          },
          {
            label: 'ACH/Wire In',
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: 'This Period',
        color: '#00BC66',
        anchorBgColor: '#00BC66',
        data: [
          {
            // cash in
            value: data.current_period.deposit_amount_cash / 100,
          },
          {
            // check in
            value: data.current_period.deposit_amount_check / 100,
          },
          {
            // check out
            value: data.current_period.withdrawal_amount_check / 100,
          },
          {
            // cash out
            value: data.current_period.withdrawal_amount_cash / 100,
          },
          {
            // ach/wire out
            value: (data.current_period.withdrawal_amount_ach + data.current_period.withdrawal_amount_wire) / 100,
          },
          {
            // ach/wire in
            value: (data.current_period.deposit_amount_ach + data.current_period.deposit_amount_wire) / 100,
          },
        ],
      },
      {
        seriesname: 'Peer Group',
        color: '#F5A623',
        anchorBgColor: '#F5A623',
        data: [
          {
            // cash in
            value: peerGroup?.avg_deposit_amount_cash / 100,
          },
          {
            // check in
            value: peerGroup?.avg_deposit_amount_check / 100,
          },
          {
            // check out
            value: peerGroup?.avg_withdrawal_amount_check / 100,
          },
          {
            // cash out
            value: peerGroup?.avg_withdrawal_amount_cash / 100,
          },
          {
            // ach/wire out
            value: (peerGroup?.avg_withdrawal_amount_ach + peerGroup?.avg_withdrawal_amount_wire) / 100,
          },
          {
            // ach/wire in
            value: (peerGroup?.avg_deposit_amount_ach + peerGroup?.avg_deposit_amount_wire) / 100,
          },
        ],
      },
    ],
  };
};
