import { DispensaryAccountReviewWithData, Dispensary, Bank, BankDispensaryMetadata } from '@gcv/shared';
import { formatDateOrDash, formatMoney } from 'libs/react-ui/src/util/formatHelpers';

export const getBasicTemplate = (
  review: DispensaryAccountReviewWithData,
  dispensary: Dispensary,
  bank: Bank,
  dispensaryMetadata
) => {
  const { current_period } = review.data.aggregated_core_transaction_data;
  return `
    <p>We are confirming the existing risk rating of ${formatMissingPlaceholder(
      'risk rating',
      dispensaryMetadata?.risk_rating
    )} on ${formatMissingPlaceholder('account name', dispensary.name)}, Account # ${formatMissingPlaceholder(
    'account number',
    dispensaryMetadata?.internal_id
  )}. The customer operates a business that is monitored under ${formatMissingPlaceholder(
    `institution's name`,
    bank.orgName
  )} cannabis banking program. This is a ${formatMissingPlaceholder(
    'business type',
    dispensary.business_type
  )} business located at ${formatMissingPlaceholder('business address', dispensary.streetAddress)}. </p>

    <p>Based on our ${formatMissingPlaceholder(
      'review frequency',
      dispensaryMetadata?.review_frequency
    )} review of account and sales activities, we’ve concluded that this account’s behavior is in line with the expectations based on historic and peer group performance.</p>

    <p>Account activity for ${formatMissingPlaceholder(
      'activity start date',
      formatDateOrDash(review.review_period_start_date)
    )} through ${formatMissingPlaceholder(
    'activity end date',
    formatDateOrDash(review.review_period_end_date)
  )} is as follows:</p>

    <ul>
    <li>Cash Deposits: ${formatMissingPlaceholder(
      'cash deposits total for period',
      formatMoney(current_period.deposit_amount_cash)
    )}</li>

    <li>Cash Withdrawals: ${formatMissingPlaceholder(
      'cash withdrawals total for period',
      formatMoney(current_period.withdrawal_amount_cash)
    )}</li>

    <li>Check Deposits: ${formatMissingPlaceholder(
      'check deposits total for period',
      formatMoney(current_period.deposit_amount_check)
    )}</li>

    <li>Check Withdrawals: ${formatMissingPlaceholder(
      'check withdrawals total for period',
      formatMoney(current_period.withdrawal_amount_check)
    )}</li>

    <li>ACH Credits: ${formatMissingPlaceholder(
      'ACH credits total for period',
      formatMoney(current_period.deposit_amount_ach)
    )}</li>

    <li>ACH Debits: ${formatMissingPlaceholder(
      'ACH debits total for period',
      formatMoney(current_period.withdrawal_amount_ach)
    )}</li>

    <li>Wire Credits: ${formatMissingPlaceholder(
      'wire credits total for period',
      formatMoney(current_period.deposit_amount_wire)
    )}</li>

    <li>Wire Debits: ${formatMissingPlaceholder(
      'wire debits total for period',
      formatMoney(current_period.withdrawal_amount_wire)
    )}</li>
    </ul>

    <p>
No suspicious activity has been identified and no marijuana-specific red flags have been detected. The institution will continue to monitor this account for further activity and update the risk profile as needed.</p>`;
};

const formatMissingPlaceholder = (label, value) => {
  return value ? value : `<b>[${label}]</b>`;
};
