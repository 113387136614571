import { DataPersistence } from '../../utils/data.persistance';
import { DispensaryState } from './dispensary.reducer';
import { Effect } from '@ngrx/effects';
import {
  AllDispensaryLoaded,
  ApproveGcvDueDiligence,
  BankDueDiligenceStatusUpdated,
  CreateDispensary,
  DispensariesByBankIdLoaded,
  DispensaryActionTypes,
  DispensaryCreated,
  DispensaryGroupsUpdated,
  DispensaryLoaded,
  DispensaryReviewed,
  DispensaryUpdated,
  DispensaryUpdateGroups,
  GcvDueDiligenceApproved,
  GcvDueDiligenceStatusUpdated,
  GcvDueDiligenceTemplateFetched,
  GetGcvDueDiligenceTemplate,
  LoadAllDispensary,
  LoadDispensariesByBankId,
  LoadDispensary,
  ReviewDispensary,
  UpdateBankDueDiligenceStatus,
  UpdateDispensary,
  UpdateGcvDueDiligenceStatus,
  ApproveBankReviewSections,
  BankReviewSectionsApproved,
  UpdateDispensaryBusinessDetails,
  UpdateDispensaryOperationalDetails,
  UpdateDispensaryAccountOwnersSectionComplete,
  UpdateDispensaryRequirement,
  InviteDispensaryUser,
} from './dispensary.actions';
import { Dispensary } from '@gcv/shared';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';
import { UserActionTypes } from '../user/user.actions';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { from } from 'rxjs';
import { OrganizationStaffCreated } from '../staff/staff.actions';

@Injectable({
  providedIn: 'root',
})
export class DispensaryEffect {
  @Effect() updateGcvDueDiligenceStatus$ = this.dataPersistance.fetch(
    DispensaryActionTypes.UpdateGcvDueDiligenceStatus,
    {
      run: (action: UpdateGcvDueDiligenceStatus, state: DispensaryState) => {
        return this.sdk
          .dispensariesIdGcvDueDiligenceStatusPut(action.payload.dispensaryId)
          .pipe(map((res: Dispensary) => new GcvDueDiligenceStatusUpdated(res)));
      },
      onError: (action, err) => {
        return new AddError({ action: DispensaryActionTypes.UpdateGcvDueDiligenceStatus, message: err });
      },
    }
  );

  @Effect() updateBankDueDiligenceStatus$ = this.dataPersistance.fetch(
    DispensaryActionTypes.UpdateBankDueDiligenceStatus,
    {
      run: (action: UpdateBankDueDiligenceStatus, state: DispensaryState) => {
        return this.sdk
          .dispensariesIdBankDueDiligenceStatusPut(action.payload.dispensaryId)
          .pipe(map((res: Dispensary) => new BankDueDiligenceStatusUpdated(res)));
      },
      onError: (action, err) => {
        return new AddError({ action: DispensaryActionTypes.UpdateBankDueDiligenceStatus, message: err });
      },
    }
  );

  @Effect() loadDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.LoadDispensary, {
    run: (action: LoadDispensary, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdGet(action.payload.dispensaryId)
        .pipe(map((res: Dispensary) => new DispensaryLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.LoadDispensary, message: err });
    },
  });

  @Effect() loadDispensariesbyBankId$ = this.dataPersistance.fetch(DispensaryActionTypes.LoadDispensariesByBankId, {
    run: (action: LoadDispensariesByBankId, state: DispensaryState) => {
      return this.sdk
        .banksIdDispensariesGet(action.payload.bankId)
        .pipe(map((res: Dispensary[]) => new DispensariesByBankIdLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.LoadDispensariesByBankId, message: err });
    },
  });

  @Effect() updateDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.UpdateDispensary, {
    run: (action: UpdateDispensary, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdPut(action.payload.id, action.payload)
        .pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.UpdateDispensary, message: err });
    },
  });

  @Effect() createDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.CreateDispensary, {
    run: (action: CreateDispensary, state: DispensaryState) => {
      return this.sdk.dispensariesPost(action.payload).pipe(map((res: Dispensary) => new DispensaryCreated(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.CreateDispensary, message: err });
    },
  });

  @Effect() loadAllDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.LoadAllDispensary, {
    run: (action: LoadAllDispensary, state: DispensaryState) => {
      return this.sdk.dispensariesGet().pipe(map((res: Dispensary[]) => new AllDispensaryLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.LoadAllDispensary, message: err });
    },
  });

  @Effect() dispensaryUpdateGroups$ = this.dataPersistance.fetch(DispensaryActionTypes.DispensaryUpdateGroups, {
    run: (action: DispensaryUpdateGroups, state: DispensaryState) => {
      return this.sdk
        .groupsOrgIdPut(action.payload.dispensaryId, action.payload.groups)
        .pipe(map((res: Dispensary) => new DispensaryGroupsUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.DispensaryUpdateGroups, message: err });
    },
  });

  @Effect() approveGcvDueDiligence$ = this.dataPersistance.fetch(DispensaryActionTypes.approveGcvDueDiligence, {
    run: (action: ApproveGcvDueDiligence, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdDiligenceGcvPost(action.payload.dispensaryId, action.payload.data)
        .pipe(map((res: Dispensary) => new GcvDueDiligenceApproved(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.approveGcvDueDiligence, message: err });
    },
  });

  @Effect() ApproveBankReviewSections$ = this.dataPersistance.fetch(DispensaryActionTypes.ApproveBankReviewSections, {
    run: (action: ApproveBankReviewSections, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdReviewSectionsPut(action.payload.dispensaryId, action.payload.data)
        .pipe(map((res: Dispensary) => new BankReviewSectionsApproved(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.ApproveBankReviewSections, message: err });
    },
  });

  @Effect() getGcvDueDiligenceTemplate$ = this.dataPersistance.fetch(DispensaryActionTypes.getGcvDueDiligenceTemplate, {
    run: (action: GetGcvDueDiligenceTemplate, state: DispensaryState) => {
      return this.sdk
        .dispensariesIdDiligenceTemplateGet(action.payload.id)
        .pipe(map(data => new GcvDueDiligenceTemplateFetched(data)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.approveGcvDueDiligence, message: err });
    },
  });

  @Effect() reviewDispensary$ = this.dataPersistance.fetch(DispensaryActionTypes.ReviewDispensary, {
    run: (action: ReviewDispensary, state: DispensaryState) => {
      const { bankId, dispensaryId } = action.payload;
      return this.sdk
        .diligenceBankBankIdDispensaryDispensaryIdReviewedOnPost(bankId, dispensaryId)
        .pipe(map((res: Dispensary) => new DispensaryReviewed(res)));
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.ReviewDispensary, message: err });
    },
  });

  @Effect() updateDispensaryAfterIdVerification$ = this.dataPersistance.fetch(UserActionTypes.UserKbaAnswersVerified, {
    run: (action, state: any) => {
      return this.sdk
        .dispensariesIdGet(state.dispensary.currentDispensary)
        .pipe(map((res: Dispensary) => new DispensaryLoaded(res)));
    },
    onError: (err, res) => {
      return new AddError({ action: UserActionTypes.UserKbaAnswersVerified, message: JSON.stringify(res) });
    },
  });

  @Effect() updateDispensaryBusinessDetails$ = this.dataPersistance.fetch(
    DispensaryActionTypes.UpdateDispensaryBusinessDetails,
    {
      run: (action: UpdateDispensaryBusinessDetails) => {
        return from(
          API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/dispensaries/${action.payload.dispensaryId}/business-details`, {
            body: action.payload.businessDetails,
          })
        ).pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
      },
      onError: err => {
        return new AddError({ action: DispensaryActionTypes.UpdateDispensary, message: err });
      },
    }
  );

  @Effect() updateDispensaryOperationalDetails$ = this.dataPersistance.fetch(
    DispensaryActionTypes.UpdateDispensaryOperationalDetails,
    {
      run: (action: UpdateDispensaryOperationalDetails) => {
        return from(
          API.put(
            GREEN_CHECK_SERVICES_AMPLIFY_NAME,
            `/dispensaries/${action.payload.dispensaryId}/operational-details`,
            {
              body: action.payload.operationalDetails,
            }
          )
        ).pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
      },
      onError: err => {
        return new AddError({ action: DispensaryActionTypes.UpdateDispensary, message: err });
      },
    }
  );

  @Effect() updateDispensaryAccountOwnersSectionComplete$ = this.dataPersistance.fetch(
    DispensaryActionTypes.UpdateDispensaryAccountOwnersSectionComplete,
    {
      run: (action: UpdateDispensaryAccountOwnersSectionComplete) => {
        return from(
          API.put(
            GREEN_CHECK_SERVICES_AMPLIFY_NAME,
            `/dispensaries/${action.payload.dispensaryId}/account-owners-section-complete`,
            {
              body: action.payload.accountOwnersSectionComplete,
            }
          )
        ).pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
      },
      onError: err => {
        return new AddError({ action: DispensaryActionTypes.UpdateDispensary, message: err });
      },
    }
  );

  @Effect() updateDispensaryRequirement$ = this.dataPersistance.fetch(
    DispensaryActionTypes.UpdateDispensaryRequirement,
    {
      run: (action: UpdateDispensaryRequirement) => {
        return from(
          API.put(
            GREEN_CHECK_SERVICES_AMPLIFY_NAME,
            `/dispensaries/${action.payload.dispensaryId}/requirements/${action.payload.requirement.id}`,
            {
              body: action.payload.requirement,
            }
          )
        ).pipe(map((res: Dispensary) => new DispensaryUpdated(res)));
      },
      onError: err => {
        return new AddError({ action: DispensaryActionTypes.UpdateDispensary, message: err });
      },
    }
  );

  @Effect() inviteDispensaryUser$ = this.dataPersistance.fetch(DispensaryActionTypes.InviteDispensaryUser, {
    run: (action: InviteDispensaryUser) => {
      return from(
        API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/dispensaries/${action.payload.dispensaryId}/users`, {
          body: action.payload.user,
        })
      ).pipe(
        switchMap((res: { user: any; org: any }) => {
          return [new DispensaryUpdated(res.org), new OrganizationStaffCreated(res.user)];
        })
      );
    },
    onError: err => {
      return new AddError({ action: DispensaryActionTypes.UpdateDispensary, message: err });
    },
  });
  constructor(private dataPersistance: DataPersistence<DispensaryState>, private sdk: DefaultService) {}
}
