import { NgModule } from '@angular/core';
import { GcvInlineSVGModule } from './inline-svg.module';
import { CognitoComponent } from '../app/auth/cognito/cognito.component';
import { SignInComponent } from './sign-in-component/sign-in.component.core';
import { ForgotPasswordComponent } from './forgot-password-component/forgot-password.component.core';
import { RequireNewPasswordComponent } from './require-new-password-component/require-new-password.component.core';
import { GcvMaterialCommonModule } from './material.module';
import { RoleDirective } from '../directives/role.directive';
import { NewPasswordComponent } from '../app/auth/cognito/new-password/new.password.component';
import { ViewAccountMotComponent } from './view-account-mot/view-account-mot.component';
import { ViewDocumentButtonComponent } from './view-document-button/view-document-button.component';
import { ReviewAccountDocumentComponent } from '../app/bank/accounts/review-account-document/review-account-document.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgIdleModule } from '@ng-idle/core';
import { GcvUiModule, PipesModule } from '@user-interface/gcv-ui';
import { NgReactWrappersModule } from '@green-check/ng-react-wrappers';
import { ReviewMultipleDocumentsModalComponent } from './review-multiple-documents-modal/review-multiple-documents-modal.component';
import { CreateNewLicenseModalComponent } from './create-new-license-modal/create-new-license-modal.component';
import { IdentificationDocumentCardComponent } from './identification-document-card/identification-document-card.component';
import { DueDiligenceFileUploadModalComponent } from './due-diligence-file-upload-modal/due-diligence-file-upload-modal.component';
import { ViewDepositHistoryComponent } from './view-deposit-history/view-deposit-history.component';
import { NavigationShellComponent } from './navigation-shell/navigation-shell.component';
import { RequirementDetailViewComponent } from './react-requirements/RequirementDetailView.wrapper.component';
import { RequirementsTableComponent } from './react-requirements/RequirementsTable.wrapper.component';
import { ReportsTableComponent } from './react-fincen-reports/ReportsTable.wrapper.component';
import { GcvEnterBankCodesComponent } from './react-gcv-bank-codes/GCVEnterBankCodes.wrapper.component';
import { FincenExportsComponent } from './react-fincen-exports/FincenExports.wrapper.component';
import { SalesDrawerComponent } from './react-sales-drawer/SalesDrawer.wrapper.component';
import { TimeFilterDropdown } from './react-time-filter/TimeFilterDropdown.wrapper.component';
import { PosConfigsComponent } from './react-pos-configs/PosConfig.wrapper.component';
import { AccountsComponent } from './react-account-bank/Accounts.wrapper.component';
import { DashboardComponent as BankDashboardComponent } from './react-dashboard-bank/Dashboard.wrapper.component';
import { DashboardComponent as DispensaryDashboardComponent } from './react-dashboard-crb/Dashboard.wrapper.component';
import { BankDueDiligenceComponent } from './react-bank-due-diligence/BankDueDiligence.wrapper.component';
import { BankDepositsComponent } from './react-deposits-bank/DepositsBank.wrapper.component';
import { BankSettingsComponent } from './react-settings-bank/SettingsBank.wrapper.component';
import { ReportsComponent } from './reports/Reports.wrapper.component';
import { BankFincenComponent } from './react-fincen-bank/FincenBank.wrapper.component';
import { ConfirmDeleteUserModalComponent } from './confirm-delete-user-modal/confirm-delete-user-modal.component';
import { AccountMonitoringComponent } from './react-account-monitoring/AccountMonitoringComponent.wrapper.component';

/**
 * Create a New Internal Module which imports ComponentLib and Other Non Lib Components
 */
@NgModule({
  declarations: [
    NavigationShellComponent,
    DueDiligenceFileUploadModalComponent,
    IdentificationDocumentCardComponent,
    CreateNewLicenseModalComponent,
    RequireNewPasswordComponent,
    ReviewAccountDocumentComponent,
    ForgotPasswordComponent,
    CognitoComponent,
    SignInComponent,
    ViewAccountMotComponent,
    NewPasswordComponent,
    ViewDocumentButtonComponent,
    ReviewMultipleDocumentsModalComponent,
    RoleDirective,
    ViewDepositHistoryComponent,
    RequirementDetailViewComponent,
    RequirementsTableComponent,
    FincenExportsComponent,
    ReportsTableComponent,
    GcvEnterBankCodesComponent,
    SalesDrawerComponent,
    TimeFilterDropdown,
    PosConfigsComponent,
    AccountsComponent,
    DispensaryDashboardComponent,
    BankDashboardComponent,
    BankDueDiligenceComponent,
    BankDepositsComponent,
    BankSettingsComponent,
    ReportsComponent,
    BankFincenComponent,
    ConfirmDeleteUserModalComponent,
    AccountMonitoringComponent,
  ],
  imports: [
    NgReactWrappersModule,
    GcvUiModule,
    PipesModule,
    GcvInlineSVGModule,
    NgxPaginationModule,
    NgIdleModule.forRoot(),
    GcvMaterialCommonModule,
  ],
  exports: [
    NavigationShellComponent,
    DueDiligenceFileUploadModalComponent,
    IdentificationDocumentCardComponent,
    CreateNewLicenseModalComponent,
    ReviewAccountDocumentComponent,
    ReviewMultipleDocumentsModalComponent,
    CognitoComponent,
    RequireNewPasswordComponent,
    SignInComponent,
    NgxPaginationModule,
    ForgotPasswordComponent,
    NgReactWrappersModule,
    GcvUiModule,
    PipesModule,
    GcvInlineSVGModule,
    ViewAccountMotComponent,
    GcvMaterialCommonModule,
    ViewDocumentButtonComponent,
    RoleDirective,
    NewPasswordComponent,
    ViewDepositHistoryComponent,
    RequirementDetailViewComponent,
    RequirementsTableComponent,
    FincenExportsComponent,
    ReportsTableComponent,
    GcvEnterBankCodesComponent,
    SalesDrawerComponent,
    TimeFilterDropdown,
    PosConfigsComponent,
    AccountsComponent,
    DispensaryDashboardComponent,
    BankDashboardComponent,
    BankDueDiligenceComponent,
    BankDepositsComponent,
    BankSettingsComponent,
    ReportsComponent,
    BankFincenComponent,
    AccountMonitoringComponent,
  ],

  entryComponents: [
    DueDiligenceFileUploadModalComponent,
    IdentificationDocumentCardComponent,
    CreateNewLicenseModalComponent,
    ReviewMultipleDocumentsModalComponent,
    ViewAccountMotComponent,
    ReviewAccountDocumentComponent,
    ViewDepositHistoryComponent,
    RequirementDetailViewComponent,
    RequirementsTableComponent,
    PosConfigsComponent,
    AccountsComponent,
    DispensaryDashboardComponent,
    BankDashboardComponent,
    BankDueDiligenceComponent,
    BankDepositsComponent,
    BankSettingsComponent,
    ReportsComponent,
    BankFincenComponent,
    AccountMonitoringComponent,
  ],
})
export class GcvCommonModule {}
