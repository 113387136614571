import React from 'react';
import { DateTime } from 'luxon';
import Dinero from 'dinero.js';
import { User } from '@gcv/shared';
import { GcvStatusIndicator } from '../lib/GcvStatusIndicator/GcvStatusIndicator';
import { AsYouType } from 'libphonenumber-js/min';

export const cropInput = (string: string, length: number, type?: string) => {
  const ellipsis = '...';
  if (string.length > length) {
    const [name, extension] = string.split('.');
    if (type === 'document' && extension) {
      const remainingLength = length - extension.length - ellipsis.length;
      const truncatedNameBeginning = name.slice(0, remainingLength - extension.length - 1);
      const truncatedNameEnding = name.slice(name.length - extension.length, name.length);
      return truncatedNameBeginning + ellipsis + truncatedNameEnding + '.' + extension;
    } else {
      return string.slice(0, length - 3) + ellipsis;
    }
  } else {
    return string;
  }
};

export const formatPositiveNegativeColor = (value: string | number) => {
  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;

  if (valueNumber < 0) {
    return <span style={{ color: 'red' }}>{value}</span>;
  } else {
    return <span style={{ color: '#00bc66' }}>{value}</span>;
  }
};

export const formatOrdinalIndicator = (i: number) => {
  if (!i) return '';

  var j = i % 10,
    k = i % 100;

  if (j == 1 && k != 11) {
    return i.toFixed(0) + 'st';
  }

  if (j == 2 && k != 12) {
    return i.toFixed(0) + 'nd';
  }

  if (j == 3 && k != 13) {
    return i.toFixed(0) + 'rd';
  }

  return i.toFixed(0) + 'th';
};

export const formatMoney = (value: number) => {
  if (typeof value !== 'number') {
    return '';
  }
  const format = value <= 0 ? '$0' : '$0,0.00';
  return Dinero({ amount: value }).toFormat(format);
};

export const formatMoneyWithNegatives = (value: number) => {
  if (typeof value !== 'number') {
    return '';
  }
  const format = '$0,0.00';
  if (value < 0) {
    return '(' + Dinero({ amount: value * -1 }).toFormat(format) + ')';
  } else {
    return Dinero({ amount: value }).toFormat(format);
  }
};

export const formatStatusIndicator = (row, clickHandler) => {
  const handleClick = () => clickHandler(row);
  const status: 'good' | 'warning' = row.status === 'accepted' || row.status === 'reconciled' ? 'good' : 'warning';
  return (
    <GcvStatusIndicator
      status={status}
      handleClick={handleClick}
      style={{ textTransform: 'capitalize' }}
      text={row.status === 'accepted' ? 'reconciled' : row.status === 'under_review' ? 'Under Review' : 'Pending'}
    />
  );
};

export const getFriendlyDate = (date: string) => {
  if (!date) {
    return '';
  }

  return DateTime.fromISO(date).toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export const formatDateOrDash = data => (data ? DateTime.fromISO(data).toLocaleString() : '');

export const formatUserFirstAndLastName = (user: User) => {
  return user ? user.firstName + ' ' + user.lastName : '';
};

export const formatNotAvailable = item => (item ? item : 'Not Available');

export const formatMissingDash = item => (item ? item : '--');

export const formatPercentage = (numerator: number, denominator: number) => {
  if (!denominator || typeof denominator !== 'number' || typeof numerator !== 'number') {
    return '0.0%';
  } else if ((numerator / denominator) * 100 < 0.1 && (numerator / denominator) * 100 > 0) {
    return '< 0.1%';
  } else {
    return ((numerator / denominator) * 100).toFixed(1) + '%';
  }
};

export const formatChangePercentage = (initialValue: number, currentValue: number) => {
  if (!initialValue || typeof initialValue !== 'number' || typeof currentValue !== 'number') {
    return '0.0%';
  } else if (
    ((currentValue - initialValue) / initialValue) * 100 < 0.1 &&
    ((currentValue - initialValue) / initialValue) * 100 > 0
  ) {
    return '0.1%';
  } else if (
    ((currentValue - initialValue) / initialValue) * 100 > -0.1 &&
    ((currentValue - initialValue) / initialValue) * 100 < 0
  ) {
    return '-0.1%';
  } else {
    return (((currentValue - initialValue) / initialValue) * 100).toFixed(1) + '%';
  }
};

export const parsePhoneNumber = (phoneValue: string): string => {
  if (phoneValue !== '') {
    const phoneNumber = phoneValue.replace(/[- )(]/g, '');
    return phoneNumber;
  } else {
    return '';
  }
};

export const transformPhoneNumber = (phoneValue: string): string => {
  try {
    if (phoneValue) {
      const phoneNumber = new AsYouType('US').input(phoneValue);
      return phoneNumber;
    }
  } catch (error) {
    return phoneValue;
  }
};
