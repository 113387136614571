import React, { useEffect, useState, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Button, Grid, Snackbar, Toolbar } from '@material-ui/core';
import { FlatfileImporter } from '@flatfile/react';
import ReactTooltip from 'react-tooltip';
import { Storage } from 'aws-amplify';

import {
  Bank,
  Dispensary,
  User,
  GreenCheckAwsEnvironmentName,
  BankInternalTransactionCodes,
  DispensaryTransactionFromBankCore,
  GreenCheckWebSocketMessage,
  DueDiligenceStatus,
} from '@gcv/shared';
import { RouteObject } from '../../util/types';
import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';
import { GcvLoading, GcvButton, GcvModal } from '../../lib';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { GcvPage } from '../../lib/GcvPage/GcvPage';

import { DataTab } from './components/DataTab';
import { OpenReviewsTab } from './components/OpenReviewsTab';
import { CompletedReviewsTab } from './components/CompletedReviewsTab';
import { environment } from 'apps/user-interface/src/environments/environment';
import { IField } from '@flatfile/adapter/build/main/obj.settings';
import { moneyRegex } from '../../util/validationHelpers';
import { api } from '../../api';
import { CreateReviewModal } from './components/CreateReviewModal';
import { FlexBox } from '../../styles/theme';
import { DateTime } from 'luxon';
import { s3Util } from '../../util/s3.util';

const FLATFILE_LICENSE_KEY = '58aa3874-be33-436e-bf36-5b77f10b49ef';

interface Props {
  bank: Bank;
  dispensaries: { [id: string]: Dispensary };
  user: User;
}

interface Option {
  value: string;
  label: string;
}

const Tabs: RouteObject[] = [
  {
    path: '/secure/bank/account-monitoring/data',
    render: props => <DataTab {...props}></DataTab>,
  },
  {
    path: '/secure/bank/account-monitoring/open-reviews',
    render: props => <OpenReviewsTab {...props}></OpenReviewsTab>,
  },
  {
    path: '/secure/bank/account-monitoring/completed-reviews',
    render: props => <CompletedReviewsTab {...props}></CompletedReviewsTab>,
  },
];

const AccountMonitoring = (props: Props) => {
  const apiClient = api();
  const [displayNotification, setDisplayNotification] = useState(false);
  const [nextImportDueDate, setNextImportDueDate] = useState('12/01/20');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [distinctAccounts, setDistinctAccounts] = useState<Option[]>([]);
  const [isFlatFileLoading, setIsFlatFileLoading] = useState(false);
  const [transactionCodes, setTransactionCodes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    newTransactions: 0,
    updatedTransactions: 0,
    errorCount: 0,
  });
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [updatedReviewId, setUpdatedReviewId] = useState('');
  const [createdReviewId, setCreatedReviewId] = useState('');

  useEffect(() => {
    const dispensaryOptions = Object.values(props.dispensaries)
      .filter(disp => disp.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED)
      .map(d => {
        return { value: d.id, label: d.name };
      });

    setDistinctAccounts(dispensaryOptions);
  }, [props.dispensaries]);

  useEffect(() => {
    apiClient.banks
      .getBankInternalCodes(props.bank.id, () => {})
      .then((codes: BankInternalTransactionCodes) => {
        setTransactionCodes(Object.keys(codes.value));
      })
      .catch(() => {});
    initWebsocketConnection();
  }, [props.bank.id]);

  const addCreatedReviewToList = (reviewId: string) => {
    setCreatedReviewId(reviewId);
  };

  const setSnackBarMessageAndOpen = (message: string) => {
    setSnackBarMessage(message);
    setSnackBarOpen(true);
  };

  const initWebsocketConnection = () => {
    const socket = new WebSocket(`${environment.gcvConfig.webSocketUrl}?token=${props.user.id}`);
    socket.onmessage = (message): void => {
      const socketMessage = JSON.parse(message.data) as GreenCheckWebSocketMessage;
      if (socketMessage.action === 'bank_review_generation_complete') {
        setUpdatedReviewId(socketMessage.data.reviewId);
        setSnackBarMessageAndOpen(
          `Your ${socketMessage.data['dispensaryName']} review is now available on the Open Reviews tab.`
        );
      } else if (socketMessage.action === 'uploadCoreTransactions') {
        handleTransactionUploadResults(socketMessage.data);
      }
    };
  };

  const toggleCreateModal = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const tabs = [
    { path: `/secure/bank/account-monitoring/data`, label: `Data` },
    { path: `/secure/bank/account-monitoring/open-reviews`, label: `Open Reviews` },
    { path: `/secure/bank/account-monitoring/completed-reviews`, label: `Completed Reviews` },
  ];

  const flatFileFields: IField[] = [
    {
      label: 'Account ID',
      key: 'bank_internal_dispensary_id',
      validators: [
        {
          validate: 'required',
          error: 'An internal account ID is required',
        },
      ],
    },
    {
      label: 'Transaction ID',
      key: 'bank_transaction_id',
      validators: [
        {
          validate: 'required',
          error: 'A transaction ID is required',
        },
      ],
    },
    {
      label: 'Transaction Code',
      key: 'transaction_code',
      validators: [
        {
          validate: 'required',
          error: 'A transaction code is required',
        },
      ],
    },
    {
      label: 'Transaction Date',
      key: 'transaction_date',
      validators: [
        {
          validate: 'required',
          error: 'A transaction date is required',
        },
      ],
    },
    {
      label: 'Amount',
      key: 'amount',
      validators: [
        {
          validate: 'required',
          error: 'An amount is required',
        },
        {
          validate: 'regex_matches',
          regex: moneyRegex,
          error: 'Must only contain currency values',
        },
      ],
    },
    { label: 'Post Transaction Balance', key: 'post_transaction_balance' },
  ];

  const flatFileCustomer = {
    companyId: props.bank.id,
    userId: props.user.id,
    name: props.user.firstName + ' ' + props.user.lastName,
    companyName: props.bank.orgName,
  };

  const initializeFlatFile = async () => {
    if (!isFlatFileLoading) {
      try {
        setIsFlatFileLoading(true);
        const importer = new FlatfileImporter(FLATFILE_LICENSE_KEY, importerOptions(flatFileFields));
        importer.setCustomer(flatFileCustomer);
        importer.registerFieldHook('transaction_code', values => {
          return values.map(([item, index]) => [
            transactionCodes.includes(item)
              ? { value: item }
              : {
                  value: item,
                  info: [
                    {
                      message: 'Transaction code not found. This transaction will not be imported',
                      level: 'error',
                    },
                  ],
                },
            index,
          ]);
        });
        importer.registerFieldHook('transaction_date', values => {
          return values.map(([item, index]) => [
            Date.parse(item.toString())
              ? { value: item }
              : {
                  value: item,
                  info: [
                    {
                      message: 'Invalid date format.',
                      level: 'error',
                    },
                  ],
                },
            index,
          ]);
        });
        importer.registerRecordHook(record => {
          return {
            transaction_code:
              record.transaction_code && transactionCodes.includes(record.transaction_code)
                ? { value: record.transaction_code }
                : {
                    value: record.transaction_code,
                    info: [
                      {
                        message: 'Transaction code not found. This transaction will not be imported',
                        level: 'error',
                      },
                    ],
                  },
            transaction_date: Date.parse(record.transaction_date)
              ? { value: record.transaction_date }
              : {
                  value: record.transaction_date,
                  info: [
                    {
                      message: 'Invalid date format.',
                      level: 'error',
                    },
                  ],
                },
          };
        });
        importer.registerRecordHook(record => {
          const trimmedRecord = {};
          Object.keys(record).forEach(key => {
            trimmedRecord[key] = { value: record[key].trim() };
          });
          return trimmedRecord;
        });
        const response = await importer.requestDataFromUser();
        importer.displaySuccess('Your upload is being processed. You will be notified of the result shortly.');
        onFlatFileComplete(response);
      } catch (e) {
        setIsFlatFileLoading(false);
      }
    }
  };

  const onFlatFileComplete = async data => {
    try {
      const transactionData = data.$data.map(row => row.data);
      let fileName = data.$meta.fileName;
      const stringifiedData = JSON.stringify(transactionData, null, 2);
      const time = DateTime.utc().toISO();

      if (!fileName) fileName = 'hand_crafted_file';
      const s3Key = `${props.bank.id}/${time}-${fileName}`;

      await Storage.vault.put(s3Key, stringifiedData, {
        level: 'public',
        contentType: 'text/plain',
        bucket: `${environment.env}-core-transaction-data`,
        metadata: { userId: props.user.id },
      });
    } catch (e) {
      setIsFlatFileLoading(false);
      setSnackBarMessageAndOpen(`Something went wrong. Please try again shortly.`);
    }
  };

  const importerOptions = flatFileFields => ({
    allowInvalidSubmit: true,
    managed: true,
    allowCustom: true,
    disableManualInput: false,
    devMode: environment.env !== GreenCheckAwsEnvironmentName.PROD,
    type: 'Transaction Records',
    fields: flatFileFields,
    title: 'Import Core Transactions',
    styleOverrides: {
      borderRadius: '4px',
      primaryButtonColor: '#00bc66',
      primaryButtonFontSize: '1.6rem',
      primaryButtonFontColor: '#fff',
      secondaryButtonColor: '#00bc66',
      secondaryButtonBorderColor: '#dbe0eb',
      secondaryButtonFontSize: '1.6rem',
      secondaryButtonFontColor: '#fff',
      invertedButtonColor: '#00bc66',
      linkColor: '#c6d1dd',
      linkAltColor: '#4a90e2',
      primaryTextColor: '#3a3c45',
      secondaryTextColor: '#a5a8ba',
      errorColor: '#ff4e50',
      successColor: '#00bc66',
      warningColor: '#f5a623',
      borderColor: '#dbe0eb',
      fontFamily: 'Lato, Helvetica, Arial, san-serif',
    },
  });

  const handleTransactionUploadResults = (response: {
    newTransactionIds: number;
    failedTransactions: number;
    duplicateTransactionIds: number;
    missingInternalIds: number;
    missingTransactionCodes: number;
  }) => {
    setModalData({
      newTransactions: response.newTransactionIds,
      updatedTransactions: response.duplicateTransactionIds,
      errorCount: response.failedTransactions,
    });
    setModalOpen(true);
    setIsFlatFileLoading(false);
  };

  const isUploadDataDisabled = Object.keys(transactionCodes).length === 0;

  const snackBarAction = (
    <Button color="inherit" size="small" onClick={() => setSnackBarOpen(false)}>
      Dismiss
    </Button>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        action={snackBarAction}
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarMessage}
        autoHideDuration={5000}
      />
      {displayNotification && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Toolbar
              style={{
                backgroundColor: '#F5A623',
                color: '#ffffff',
                margin: '-2rem -2rem 0 -2rem',
                justifyContent: 'center',
                height: '30px',
                minHeight: '30px',
              }}
            >
              Your next import is due on {nextImportDueDate} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                style={{
                  borderRadius: '4px',
                  border: '1px solid #F5A623',
                  backgroundColor: '#ffffff',
                  padding: '3px 10px',
                  cursor: 'pointer',
                }}
              >
                Import transaction data
              </button>
            </Toolbar>
          </Grid>
        </Grid>
      )}

      <GcvPage
        loader={(!props.bank || !props.dispensaries) && <GcvLoading />}
        header={
          <GcvPageHeader
            title={<>Monitoring</>}
            tabs={<GcvTabs routes={tabs}></GcvTabs>}
            actions={
              <FlexBox style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
                <GcvButton
                  secondary
                  onClick={toggleCreateModal}
                  style={{ marginRight: '1rem' }}
                  data-cy="create-review-button"
                >
                  Create New Review
                </GcvButton>
                <label data-for="uploadDataTooltip" data-tip={'Transaction codes needed before data import'}>
                  <GcvButton
                    disabled={isUploadDataDisabled}
                    onClick={initializeFlatFile}
                    primary={true}
                    isLoading={isFlatFileLoading}
                    data-cy="upload-data-button"
                  >
                    Upload Data
                  </GcvButton>
                  {isUploadDataDisabled ? (
                    <ReactTooltip id="uploadDataTooltip" place="top" type="dark" effect="solid" delayShow={250} />
                  ) : null}
                </label>
              </FlexBox>
            }
          ></GcvPageHeader>
        }
        body={
          <>
            {Tabs.map(({ path, render }) => {
              return (
                <Route exact path={path} key={path}>
                  {({ match }) => (match != null ? render({ ...props, updatedReviewId, createdReviewId }) : <></>)}
                </Route>
              );
            })}
            <CreateReviewModal
              bank={props.bank}
              dispensaryOptions={distinctAccounts}
              modalOpen={createModalOpen}
              toggleModal={toggleCreateModal}
              snackBarCallback={setSnackBarMessageAndOpen}
              addReviewCallback={addCreatedReviewToList}
            />

            <GcvModal
              toggleModal={toggleModal}
              modalOpen={modalOpen}
              backButton={
                <GcvButton onClick={toggleModal} tertiary={true}>
                  Dismiss
                </GcvButton>
              }
              title={'Transaction Import Results'}
            >
              <div style={{ textAlign: 'center', fontSize: '18px' }}>
                <p>
                  <b>New Transactions:</b> {modalData.newTransactions}
                </p>
                <p>
                  <b>Updated Transactions:</b> {modalData.updatedTransactions}
                </p>
                <p>
                  <b>Errors:</b> {modalData.errorCount}
                </p>

                {modalData.errorCount > 0 ? (
                  <p style={{ marginTop: '2rem' }}>
                    A member of our Support team will reach out to you shortly to help you address the errors
                  </p>
                ) : null}
              </div>
            </GcvModal>
          </>
        }
      />
    </>
  );
};

export default AccountMonitoring;
