import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

import { Dispensary, PeerGroup, DueDiligenceStatus } from '@gcv/shared';

import { GcvButton, GcvSearchInput, GcvModal, GcvCheckbox } from '../../../lib';
import { api } from 'libs/react-ui/src/api';
import { FlexBox } from 'libs/react-ui/src/styles/theme';

const AccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 12rem;
  overflow: auto;
  margin-top: 50px;
`;

const Account = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  margin-top: 5px;
`;

const ModalContent = styled.div`
  padding: 0 2rem;
`;

const AccountsSelected = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: #a5a8ba;
`;
const SelectAll = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #6896fb;
  cursor: pointer;
`;

interface Props {
  dispensaries: Dispensary[];
  peerGroup: PeerGroup;
  toggleModal: () => void;
  openModal: boolean;
  bankId: string;
  refreshData: () => void;
  loading: boolean;
}

export const AddAccountsModal = (props: Props) => {
  const apiClient = api();

  const [loading, setLoading] = useState(false);
  const [searchedModalAccount, setSearchedModalAccount] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState({});
  const [modalFilteredAccounts, setModalFilteredAccounts] = useState<Dispensary[]>([]);
  const approvedDispensaries = props.dispensaries
    ? props.dispensaries.filter(disp => disp.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED)
    : [];

  useEffect(() => {
    if (approvedDispensaries) {
      filterAccountsModal();
    }
  }, [searchedModalAccount, props]);

  useEffect(() => {
    const initAccounts = {};
    if (props.peerGroup && props.peerGroup.dispensaries) {
      Object.keys(props.peerGroup.dispensaries).forEach(prop => (initAccounts[prop] = true));
    }
    setSelectedAccounts(initAccounts);
  }, [props.peerGroup]);

  const filterAccountsModal = () => {
    let filterAccounts;
    if (searchedModalAccount) {
      filterAccounts = Object.values(approvedDispensaries).filter(dispensary =>
        dispensary.name.toLowerCase().includes(searchedModalAccount.toLowerCase())
      );
    } else {
      filterAccounts = Object.values(approvedDispensaries);
    }
    filterAccounts.sort((a, b) => (a.name > b.name ? 1 : -1));
    setModalFilteredAccounts(filterAccounts);
  };

  const selectAllAccounts = () => {
    if (Object.keys(selectedAccounts).length === approvedDispensaries.length) {
      setSelectedAccounts({});
    } else {
      const allDisps = {};
      approvedDispensaries.forEach(disp => (allDisps[disp.id] = true));

      setSelectedAccounts(allDisps);
    }
  };

  const toggleAccount = disp => setSelectedAccounts({ ...selectedAccounts, [disp.id]: !selectedAccounts[disp.id] });

  const addAccounts = () => {
    apiClient.peerGroups
      .updatePeerGroupDispensaries(
        props.bankId,
        props.peerGroup.id,
        { dispensaryIds: Object.keys(selectedAccounts).filter(k => selectedAccounts[k]) },
        setLoading
      )
      .then(() => {
        props.toggleModal();
        props.refreshData();
      });
  };

  return (
    <GcvModal
      toggleModal={props.toggleModal}
      modalOpen={props.openModal}
      backButton={
        <GcvButton tertiary onClick={props.toggleModal} data-cy="close-button">
          Close
        </GcvButton>
      }
      continueButton={
        <GcvButton onClick={addAccounts} primary={true} data-cy="save-button">
          Save
        </GcvButton>
      }
      title={'Add or Remove Accounts'}
      loading={loading || props.loading}
    >
      <ModalContent>
        <div style={{ padding: '0 2rem' }}>
          <GcvSearchInput
            label="Search Accounts"
            onChange={e => setSearchedModalAccount(e.target.value)}
            value={searchedModalAccount}
            placeholder={''}
            id="search"
            data-cy="search-accounts"
          ></GcvSearchInput>

          <AccountsContainer>
            {modalFilteredAccounts.map(disp => (
              <Account key={disp.id} data-cy="account-list-option">
                <div>{disp.name}</div>
                <label>
                  <GcvCheckbox onChange={() => toggleAccount(disp)} checked={!!selectedAccounts[disp.id]}></GcvCheckbox>
                </label>
              </Account>
            ))}
          </AccountsContainer>
          <FlexBox style={{ justifyContent: 'space-between', padding: '5px' }}>
            <AccountsSelected>
              {Object.keys(selectedAccounts).length} account{Object.keys(selectedAccounts).length === 1 ? '' : 's'}{' '}
              selected
            </AccountsSelected>
            <SelectAll onClick={selectAllAccounts} data-cy="select-all">
              {Object.keys(selectedAccounts).length === approvedDispensaries.length ? 'Unselect All' : 'Select All'}
            </SelectAll>
          </FlexBox>
        </div>
      </ModalContent>
    </GcvModal>
  );
};
