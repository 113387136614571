import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const documentsClient = (API: APIClass) => {
  return {
    getOrgDocuments: async (orgId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/documents/${orgId}`, setIsLoading);
    },

    getOrgDocumentById: async (orgId: string, documentId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/documents/${orgId}/${documentId}`, setIsLoading);
    },

    createDocument: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/documents`, body, setIsLoading);
    },

    deleteOrgDocument: async (orgId: string, documentId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/documents/${orgId}/${documentId}`, setIsLoading);
    },
  };
};
