import { DispensaryRiskRating } from '@gcv/shared';
import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const accountMonitoringClient = (API: APIClass) => {
  return {
    getAccountMonitoring: async (
      bankId: string,
      startDate: string,
      endDate: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(
        `/banks/${bankId}/monitoring?startDate=${startDate}&endDate=${endDate}`,
        setIsLoading
      );
    },

    getReview: async (reviewId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/account-review/${reviewId}`, setIsLoading);
    },

    getReviews: async (bankId: string, status: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/banks/${bankId}/reviews?status=${status}`, setIsLoading);
    },

    getMinifiedReview: async (reviewId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/account-review/${reviewId}/minified`, setIsLoading);
    },

    getDispensaryTimeline: async (bankId: string, dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/banks/${bankId}/dispensaries/${dispId}/timeline`, setIsLoading);
    },

    getDispensaryAccountActivity: async (
      bankId: string,
      dispId: string,
      startDate: string,
      endDate: string,
      chunkSize: 'month' | 'quarter' | 'year',
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(
        `/banks/${bankId}/dispensaries/${dispId}/monitoring?startDate=${startDate}&endDate=${endDate}&chunkSize=${chunkSize}`,
        setIsLoading
      );
    },

    updateReviewStatus: async (reviewId: string, reviewStatus: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/account-review/${reviewId}/status`, { status: reviewStatus }, setIsLoading);
    },

    startReview: async (reviewId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/account-review/${reviewId}/start`, {}, setIsLoading);
    },

    createSectionComment: async (
      reviewId: string,
      sectionName: string,
      comment: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/account-review/${reviewId}/section/${sectionName}/comment`,
        { comment },
        setIsLoading
      );
    },

    updateSectionComment: async (
      reviewId: string,
      sectionName: string,
      commentId: string,
      comment: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(
        `/account-review/${reviewId}/section/${sectionName}/comment/${commentId}`,
        { comment },
        setIsLoading
      );
    },

    markSectionAsReviewed: async (
      reviewId: string,
      sectionName: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(
        `/account-review/${reviewId}/section/${sectionName}/status`,
        { status: 'completed' },
        setIsLoading
      );
    },

    updateReviewRiskRating: async (
      reviewId: string,
      final_risk_rating: DispensaryRiskRating,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/account-review/${reviewId}/risk-rating`, { final_risk_rating }, setIsLoading);
    },

    updateReviewNarrative: async (reviewId: string, narrative: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/account-review/${reviewId}/narrative`, { narrative }, setIsLoading);
    },

    completeReview: async (reviewId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/account-review/${reviewId}/complete`, {}, setIsLoading);
    },

    createReview: async (
      bankId: string,
      dispensaryId: string,
      review_period_start_date: string,
      review_period_end_date: string,
      review_period_timeframe: string,
      foreground: boolean,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/banks/${bankId}/dispensaries/${dispensaryId}/review?foreground=${foreground}`,
        { review_period_start_date, review_period_end_date, review_period_timeframe },
        setIsLoading
      );
    },

    getPeerGroups: async (bankId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/banks/${bankId}/peer-groups`, setIsLoading);
    },

    uploadReviewDocuments: async (
      reviewId: string,
      documents: string[],
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/account-review/${reviewId}/documents`, { documents }, setIsLoading);
    },
  };
};
