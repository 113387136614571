import { DispensaryAccountReview } from '@gcv/shared';
import { DateTime } from 'luxon';

export const determineReviewPeriod = (review: DispensaryAccountReview): 'month' | 'quarter' | 'year' => {
  const diff = DateTime.fromISO(review.review_period_end_date).diff(
    DateTime.fromISO(review.review_period_start_date),
    'months'
  );
  if (diff.months < 1) {
    return 'month';
  } else if (diff.months < 3) {
    return 'quarter';
  } else {
    return 'year';
  }
};

export const getReviewPeriodFriendlyName = (review: DispensaryAccountReview): string => {
  const reviewPeriod = determineReviewPeriod(review);
  return reviewPeriod.charAt(0).toUpperCase() + reviewPeriod.slice(1) + 'ly Review';
};
