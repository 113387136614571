import React, { useEffect, useState } from 'react';

import { Dispensary, OrganizationRoleResolver, DueDiligenceStatus, User } from '@gcv/shared';
import { AccountOwnersCard } from './AccountOwnersCard';
import { Grid, Typography } from '@material-ui/core';
import { GcvButton } from 'libs/react-ui/src/lib';
import { SectionTypes } from '../Account-Review-Overview.page';
import { Header, ReviewText, Title, VerticalCenter } from '../styles';
import { BackButtonIcon } from 'libs/react-ui/src/icons/BackButtonIcon';
import { api } from 'libs/react-ui/src/api';

interface Props {
  dispensary?: Dispensary;
  canUserApproveAccounts: boolean;
  onSave: (type: SectionTypes) => {};
  onBack: () => void;
  isActiveAccount?: boolean;
  dispensaryUsers: User[];
}

export const AccountOwners = ({
  dispensary,
  canUserApproveAccounts,
  onSave,
  onBack,
  isActiveAccount,
  dispensaryUsers,
}: Props) => {
  const accountOwnersIds = dispensary
    ? new OrganizationRoleResolver().getGroupsWithRole(dispensary.groups, 'dispensary_account_owner')[0].users
    : [];

  const isAwaitingReview = dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW;
  const isAlreadyReviewed = dispensary.due_diligence.account_owners_section_bank_review_complete;

  const [accountsOwners, setAccountOwners] = useState([]);
  const [allAccountOwnersApproved, setAllAccountOwnersApproved] = useState(false);
  const apiClient = api();

  useEffect(() => {
    loadAccountOwners();
  }, [JSON.stringify(accountOwnersIds), dispensaryUsers]);

  const loadAccountOwners = async () => {
    const accountOwners = dispensaryUsers.filter(
      user => accountOwnersIds.includes(user.id) && user.identityVerified && user.invitation_status !== 'archived'
    );
    setAccountOwners(accountOwners);
    setAccountOwnersApproved(accountOwners);
  };

  const approve = async (accountOwner: User) => {
    const approvedIdentification = accountOwner.identification;
    approvedIdentification.idFront.status = 'approved';
    approvedIdentification.idBack.status = 'approved';

    await apiClient.users
      .updateUserIdentification(accountOwner.id, { identification: approvedIdentification }, () => {})
      .then(updatedUser => {
        accountsOwners.find(a => a.id === accountOwner.id)[0] = updatedUser;
        setAccountOwnersApproved(accountsOwners);
      });
  };

  const setAccountOwnersApproved = accountOwners => {
    const accountOwnersApproved = accountOwners.every(
      (a: User) =>
        a.identification &&
        a.identification.idFront.status === 'approved' &&
        a.identification.idBack.status === 'approved'
    );
    setAllAccountOwnersApproved(accountOwnersApproved);
  };

  return (
    <>
      {!isActiveAccount && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Header style={{ justifyContent: 'start' }}>
              <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />
              <VerticalCenter style={{ marginLeft: '1rem' }}>
                <Title>Account Owners</Title>
              </VerticalCenter>
            </Header>
            <ReviewText>View information about the Account Owners added to this business.</ReviewText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato, Helvetica, Arial, san-serif' }}>
              You are reviewing: <strong>{dispensary.name}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        {accountsOwners.map(owner => {
          return (
            <Grid item xs={12} key={owner.id}>
              <AccountOwnersCard
                dispensary={dispensary}
                owner={owner}
                approve={() => approve(owner)}
              ></AccountOwnersCard>
            </Grid>
          );
        })}
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {isAwaitingReview && !isAlreadyReviewed ? (
            <GcvButton
              primary={true}
              disabled={!allAccountOwnersApproved || !canUserApproveAccounts}
              onClick={() => onSave('account-owners')}
              data-cy="button.component.save"
            >
              Save
            </GcvButton>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
