import React, { useEffect, useState } from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Bank,
  User,
  DispensaryAccountReviewWithData,
  Dispensary,
  AccountReviewStatus,
  AccountReviewSectionStatus,
} from '@gcv/shared';
import { RouteObject } from '../../util/types';
import { GcvButton, GcvInputSelect, GcvInputSelectNew, GcvInputTextArea, GcvLoading } from '../../lib';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { GcvPage } from '../../lib/GcvPage/GcvPage';

import { BackButtonIcon } from '../../icons/BackButtonIcon';
import { VerticalCenter } from './styles';
import ReviewOverview from './components/ReviewOverview';
import ReviewAccountInfo from './components/ReviewAccountInfo';
import ReviewTransactions from './components/ReviewTransactions';
import ReviewSales from './components/ReviewSales';
import ReviewAnalysis from './components/ReviewAnalysis';
import ReviewNarrative from './components/ReviewNarrative';
import { Grid, List, ListItem, ListItemIcon } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { api } from '../../api';
import { getReviewPeriodFriendlyName } from '../../util';
import { AccountMonitoringContext } from './account-monitoring-context';

interface Props {
  bank: Bank;
  dispensaries: { [id: string]: Dispensary };
  user: User;
}

const Tabs: RouteObject[] = [
  {
    path: '/secure/bank/account-monitoring/review/:id/overview',
    render: props => <ReviewOverview {...props}></ReviewOverview>,
  },
  {
    path: '/secure/bank/account-monitoring/review/:id/account-info',
    render: props => <ReviewAccountInfo {...props}></ReviewAccountInfo>,
  },
  {
    path: '/secure/bank/account-monitoring/review/:id/transactions',
    render: props => <ReviewTransactions {...props}></ReviewTransactions>,
  },
  {
    path: '/secure/bank/account-monitoring/review/:id/sales',
    render: props => <ReviewSales {...props}></ReviewSales>,
  },
  {
    path: '/secure/bank/account-monitoring/review/:id/analysis',
    render: props => <ReviewAnalysis {...props}></ReviewAnalysis>,
  },
  {
    path: '/secure/bank/account-monitoring/review/:id/narrative',
    render: props => <ReviewNarrative {...props}></ReviewNarrative>,
  },
];

const ListNav = (props: {
  activeLinkName: string;
  clickable: boolean;
  navLinks: {
    name: string;
    text: string;
    url: string;
    isComplete: boolean;
  }[];
  onNavigate: (activeLinkName: string) => void;
}) => {
  return (
    <List>
      {props.navLinks.map((n, i) => {
        return (
          <ListNavItem
            key={n.name}
            name={n.name}
            text={n.text}
            url={n.url}
            activeLinkName={props.activeLinkName}
            isComplete={n.isComplete}
            onClick={props.onNavigate}
            clickable={props.clickable}
          />
        );
      })}
    </List>
  );
};

const ListNavItem = (props: {
  name: string;
  text: string;
  url: string;
  activeLinkName: string;
  isComplete: boolean;
  clickable: boolean;
  onClick: (activeLinkName: string) => void;
}) => {
  const history = useHistory();

  const isActive = props.activeLinkName === props.name;

  return (
    <ListItem
      style={{
        color: props.isComplete || isActive ? '#00BC66' : '#A5A8BA',
        cursor: props.clickable ? 'pointer' : null,
        paddingBottom: '12px',
      }}
      onClick={
        props.clickable
          ? () => {
              props.onClick(props.name);
              history.push(props.url);
            }
          : null
      }
    >
      <ListItemIcon>
        <>
          {props.isComplete && (
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.86578 0.338094L3.57293 4.31048L2.16966 2.9073C1.67338 2.41101 0.868581 2.41093 0.372213 2.9073C-0.124071 3.40366 -0.124071 4.20838 0.372213 4.70475L2.63993 6.97255C2.65078 6.9834 2.66256 6.99272 2.67366 7.00305C2.68925 7.01754 2.70467 7.03212 2.72077 7.04568C2.73831 7.0605 2.75644 7.07423 2.77466 7.08787C2.79135 7.10041 2.80779 7.11304 2.82482 7.12465C2.84406 7.1377 2.86371 7.14947 2.88346 7.16142C2.9004 7.17159 2.9171 7.18218 2.93438 7.1915C2.95556 7.20294 2.97717 7.21294 2.99886 7.22311C3.01556 7.2309 3.03191 7.23929 3.04886 7.24632C3.07224 7.25607 3.09597 7.2642 3.11978 7.27242C3.13571 7.27801 3.15138 7.2842 3.16757 7.28911C3.19333 7.29691 3.21934 7.30293 3.24544 7.30911C3.26018 7.31259 3.27467 7.31691 3.2895 7.31987C3.31873 7.32572 3.34822 7.32953 3.37779 7.33326C3.38974 7.33479 3.4016 7.33724 3.41363 7.33843C3.45524 7.3425 3.49693 7.3447 3.5387 7.3447C3.53879 7.3447 3.53878 7.3447 3.53878 7.3447C3.53887 7.3447 3.53895 7.3447 3.53895 7.3447C3.57861 7.3447 3.61835 7.34275 3.65792 7.33902C3.66944 7.33792 3.68088 7.33572 3.69241 7.33428C3.72037 7.33089 3.74825 7.3275 3.77604 7.32225C3.79061 7.31945 3.80493 7.31547 3.81934 7.31225C3.84374 7.30674 3.86814 7.3014 3.89229 7.29445C3.90831 7.28979 3.92398 7.28395 3.93983 7.27869C3.96194 7.27132 3.98406 7.2642 4.00584 7.25556C4.02261 7.24895 4.03897 7.24116 4.05557 7.23378C4.07583 7.22472 4.09608 7.2159 4.11599 7.20574C4.13311 7.19701 4.14971 7.18718 4.16649 7.17769C4.18513 7.1671 4.20377 7.15659 4.22199 7.1449C4.23885 7.13414 4.25521 7.12236 4.27164 7.11075C4.2891 7.09838 4.30647 7.08601 4.32342 7.07271C4.33926 7.06025 4.3546 7.04695 4.36994 7.03364C4.38053 7.02449 4.39163 7.01627 4.40205 7.0067L9.59223 2.20392C10.1075 1.72713 10.1386 0.922924 9.66188 0.407744C9.18525 -0.107435 8.38096 -0.138617 7.86578 0.338094Z"
                fill={props.isComplete || isActive ? '#00BC66' : '#A5A8BA'}
              />
            </svg>
          )}

          {!props.isComplete && (
            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.83203 10C8.59346 10 10.832 7.76142 10.832 5C10.832 2.23858 8.59346 0 5.83203 0C3.07061 0 0.832031 2.23858 0.832031 5C0.832031 7.76142 3.07061 10 5.83203 10Z"
                fill={props.isComplete || isActive ? '#00BC66' : '#A5A8BA'}
              />
            </svg>
          )}
        </>
      </ListItemIcon>
      {props.text}
    </ListItem>
  );
};

const Review: React.FC<Props> = props => {
  const apiClient = api();
  const history = useHistory();
  const location = useLocation<{ peerGroup: string }>();
  const { id } = useParams<{ id: string }>();
  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = useState(true);
  const [activeLinkName, setActiveLinkName] = useState('');
  const [review, setReview] = useState<DispensaryAccountReviewWithData>(null);
  const [comment, setComment] = useState('');
  const [selectedPeerGroup, setSelectedPeerGroup] = useState('');

  useEffect(() => {
    loadReview();

    const path = window.location.pathname;
    if (path.indexOf('account-info') > -1) {
      setActiveLinkName('account_info');
    } else if (path.indexOf('transactions') > -1) {
      setActiveLinkName('transaction_data');
    } else if (path.indexOf('sales') > -1) {
      setActiveLinkName('sales_activity');
    } else if (path.indexOf('analysis') > -1) {
      setActiveLinkName('analysis');
    } else if (path.indexOf('narrative') > -1) {
      setActiveLinkName('narrative');
    } else {
      setActiveLinkName('');
    }
  }, []);

  useEffect(() => {
    loadComment();

    if (location.state && location.state.peerGroup) {
      setSelectedPeerGroup(location.state.peerGroup);
    } else if (!selectedPeerGroup && review) {
      setSelectedPeerGroup(Object.values(review.data.peer_groups)[0].id);
    }
  }, [activeLinkName, review, location]);

  const loadReview = () => {
    apiClient.accountMonitoring.getReview(id, setIsLoading).then(review => {
      setReview(review);
    });
  };

  const loadComment = () => {
    if (!review || '' === activeLinkName) {
      return;
    }

    const section = review.sections[activeLinkName];
    const comments = Object.values(section.comments);
    let comment = '';

    if (comments.length > 0) {
      comment = (comments[0] as any).comment;
    }

    form.control.setValue('comment', comment);
    setComment(comment);
  };

  const navLinks = [
    {
      name: 'account_info',
      text: 'Account Information',
      url: `/secure/bank/account-monitoring/review/${id}/account-info`,
      isComplete:
        review !== null &&
        review.sections !== undefined &&
        review.sections['account_info'].status === AccountReviewSectionStatus.Completed,
    },
    {
      name: 'transaction_data',
      text: 'Transaction Data',
      url: `/secure/bank/account-monitoring/review/${id}/transactions`,
      isComplete:
        review !== null &&
        review.sections !== undefined &&
        review.sections['transaction_data'].status === AccountReviewSectionStatus.Completed,
    },
    {
      name: 'sales_activity',
      text: 'Sales Activity',
      url: `/secure/bank/account-monitoring/review/${id}/sales`,
      isComplete:
        review !== null &&
        review.sections !== undefined &&
        review.sections['sales_activity'].status === AccountReviewSectionStatus.Completed,
    },
    {
      name: 'analysis',
      text: 'Analysis',
      url: `/secure/bank/account-monitoring/review/${id}/analysis`,
      isComplete:
        review !== null &&
        review.sections !== undefined &&
        review.sections['analysis'].status === AccountReviewSectionStatus.Completed,
    },
    {
      name: 'narrative',
      text: 'Narrative and Conclusion',
      url: `/secure/bank/account-monitoring/review/${id}/narrative`,
      isComplete:
        review !== null &&
        review.sections !== undefined &&
        review.sections['narrative'].status === AccountReviewSectionStatus.Completed,
    },
  ];

  const onBack = () => {
    if (activeLinkName === '') {
      history.push(`/secure/bank/account-monitoring/open-reviews`);
    } else {
      setActiveLinkName('');
      history.push(`/secure/bank/account-monitoring/review/${id}/overview`);
    }
  };

  const onNavigate = (activeLink: string) => {
    setActiveLinkName(activeLink);
  };

  const addOrUpdateComment = async () => {
    if (review.status !== AccountReviewStatus.Completed && form.watch('comment')) {
      if (0 === Object.keys(review.sections[activeLinkName].comments).length) {
        await apiClient.accountMonitoring
          .createSectionComment(review.id, activeLinkName, form.watch('comment'), setIsLoading)
          .then(r => {
            setReview(r);
            return r;
          });
      } else {
        await apiClient.accountMonitoring
          .updateSectionComment(
            review.id,
            activeLinkName,
            Object.keys(review.sections[activeLinkName].comments)[0],
            form.watch('comment'),
            setIsLoading
          )
          .then(r => {
            setReview(r);
            return r;
          });
      }
    }
    return review;
  };

  // wait for a review to be available
  if (!review || isLoading) {
    return <GcvLoading />;
  }

  return (
    <>
      <GcvPage
        loader={(!props.bank || isLoading) && <GcvLoading />}
        header={
          <GcvPageHeader
            title={
              <div style={{ display: 'flex', width: '100%' }}>
                <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />

                <VerticalCenter
                  style={{ cursor: 'pointer', marginLeft: '1rem', flex: 1, justifyContent: 'end' }}
                  onClick={onBack}
                >
                  <div style={{ fontSize: '16px', fontWeight: 700, color: '#9B9EB1', lineHeight: '2rem' }}>
                    Back to {activeLinkName === '' ? <>Reviews</> : <>Overview</>}
                  </div>
                </VerticalCenter>

                <div style={{ flex: 1, fontSize: '20px', fontWeight: 700, color: '#16181E', textAlign: 'center' }}>
                  {props.dispensaries[review.dispensary_id]?.name}
                  <div style={{ fontSize: '14px', fontWeight: 500, color: '#16181E', textAlign: 'center' }}>
                    {getReviewPeriodFriendlyName(review)}{' '}
                    {DateTime.fromISO(review.review_period_start_date).toLocaleString()}
                    {' - '}
                    {DateTime.fromISO(review.review_period_end_date).toLocaleString()}
                  </div>
                </div>

                <div style={{ flex: 1 }}>
                  <GcvInputSelectNew
                    label="Compare"
                    options={review.data.peer_groups.map(pg => {
                      return {
                        label: pg.name,
                        value: pg.id,
                      };
                    })}
                    onChange={e => setSelectedPeerGroup(e.value)}
                    dropWidth={'175px'}
                    labelWidth={'95px'}
                    dropCharLength={25}
                    align={'flex-end'}
                    defaultValueDrop={selectedPeerGroup}
                  />
                </div>
              </div>
            }
            subTitle={<hr />}
          />
        }
        body={
          <Grid container spacing={2}>
            <Grid item xs={8} md={8} xl={9}>
              {Tabs.map(({ path, render }) => {
                return (
                  <AccountMonitoringContext.Provider value={{ peerGroupId: selectedPeerGroup }}>
                    <Route exact path={path} key={path}>
                      {({ match }) => (match != null ? render({ ...props, review, id, setReview }) : <></>)}
                    </Route>
                  </AccountMonitoringContext.Provider>
                );
              })}
            </Grid>
            <Grid item xs={4} md={4} xl={3}>
              <span style={{ fontSize: '16px', fontWeight: 700 }}>Sections</span>
              <span>
                <hr style={{ display: 'inline-block', width: '65%', margin: '0 0 0.25rem 1rem' }} />
              </span>

              <ListNav
                activeLinkName={activeLinkName}
                navLinks={navLinks}
                onNavigate={onNavigate}
                clickable={review.status !== AccountReviewStatus.New}
              />

              <Grid container spacing={2}>
                {activeLinkName !== '' && (
                  <>
                    <Grid item xs={12}>
                      <GcvInputTextArea
                        {...form}
                        name={'comment'}
                        label={
                          review.status === AccountReviewStatus.Completed
                            ? 'No comment entered'
                            : 'Add a comment to this section (optional)'
                        }
                        disabled={review.status === AccountReviewStatus.Completed}
                        defaultValue={comment}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <GcvButton
                        fullWidth
                        tertiary
                        disabled={activeLinkName === 'account_info'}
                        onClick={() => {
                          addOrUpdateComment().then(() => {
                            const index = navLinks.findIndex(n => n.name === activeLinkName);
                            let next = index - 1;

                            if (next < 0) {
                              next = navLinks.length - 1;
                            }

                            setActiveLinkName(navLinks[next].name);
                            history.push(navLinks[next].url);
                          });
                        }}
                      >
                        Back
                      </GcvButton>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <GcvButton
                        fullWidth
                        secondary
                        disabled={activeLinkName === 'narrative'}
                        onClick={() => {
                          addOrUpdateComment().then(() => {
                            const index = navLinks.findIndex(n => n.name === activeLinkName);
                            let next = index + 1;

                            if (next >= navLinks.length) {
                              next = 0;
                            }

                            setActiveLinkName(navLinks[next].name);
                            history.push(navLinks[next].url);
                          });
                        }}
                      >
                        Next
                      </GcvButton>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} style={{ textAlign: 'center', paddingRight: '0' }}>
                  {activeLinkName === '' && review.status === 'new' && (
                    <GcvButton
                      fullWidth
                      primary
                      onClick={() => {
                        apiClient.accountMonitoring.startReview(review.id, setIsLoading).then(updatedReview => {
                          setReview(updatedReview);
                          setActiveLinkName('account_info');
                          history.push(`/secure/bank/account-monitoring/review/${review.id}/account-info`);
                        });
                      }}
                    >
                      Begin Review
                    </GcvButton>
                  )}

                  {activeLinkName === '' && review.status === 'in_progress' && (
                    <GcvButton
                      fullWidth
                      secondary
                      onClick={() => {
                        setActiveLinkName('account_info');
                        history.push(`/secure/bank/account-monitoring/review/${review.id}/account-info`);
                      }}
                    >
                      Resume Review
                    </GcvButton>
                  )}

                  {activeLinkName !== '' && activeLinkName !== 'narrative' && (
                    <GcvButton
                      fullWidth
                      primary
                      disabled={
                        review !== null &&
                        review.sections !== undefined &&
                        review.sections[activeLinkName].status === AccountReviewStatus.Completed
                      }
                      onClick={async () => {
                        if (review.status !== AccountReviewStatus.Completed) {
                          await addOrUpdateComment().then(() => {
                            apiClient.accountMonitoring
                              .markSectionAsReviewed(review.id, activeLinkName, setIsLoading)
                              .then(r => {
                                setReview(r);
                              });
                          });
                        }

                        const index = navLinks.findIndex(n => n.name === activeLinkName);
                        let next = index + 1;

                        if (next >= navLinks.length) {
                          next = 0;
                        }

                        setActiveLinkName(navLinks[next].name);
                        history.push({
                          pathname: navLinks[next].url,
                          state: { peerGroup: selectedPeerGroup },
                        });
                      }}
                    >
                      Mark as Reviewed
                    </GcvButton>
                  )}

                  {activeLinkName === 'narrative' && (
                    <GcvButton
                      fullWidth
                      primary
                      disabled={
                        review !== null &&
                        review.sections !== undefined &&
                        (review.sections['account_info'].status !== AccountReviewSectionStatus.Completed ||
                          review.sections['transaction_data'].status !== AccountReviewSectionStatus.Completed ||
                          review.sections['sales_activity'].status !== AccountReviewSectionStatus.Completed ||
                          review.sections['analysis'].status !== AccountReviewSectionStatus.Completed ||
                          review.status === AccountReviewStatus.Completed)
                      }
                      onClick={() => {
                        addOrUpdateComment().then(() => {
                          apiClient.accountMonitoring
                            .markSectionAsReviewed(review.id, activeLinkName, setIsLoading)
                            .then(r => {
                              setReview(r);
                            })
                            .then(() => {
                              apiClient.accountMonitoring.completeReview(review.id, setIsLoading).then(() => {
                                history.push(`/secure/bank/account-monitoring/completed-reviews`);
                              });
                            });
                        });
                      }}
                    >
                      Complete Review
                    </GcvButton>
                  )}
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'center', paddingRight: '0' }}>
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#9B9EB1',
                      lineHeight: '2rem',
                    }}
                  >
                    Most Recent Transaction{' '}
                    {DateTime.fromISO(
                      review.data.aggregated_core_transaction_data.current_period.last_transaction_date
                    ).toLocaleString()}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default Review;
