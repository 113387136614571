import styled, { createGlobalStyle } from 'styled-components';

interface Props {
  single?: boolean;
}

// add animation to react-modal and colors to floating label input
export const ModalStyleOverrides = createGlobalStyle`

    .ReactModal__Content {
        height: unset !important;
        /* overflow-y: hidden !important; */
    }
    .ReactModalPortal > div {
        opacity: 0;
     }
     .ReactModalPortal .ReactModal__Overlay {
         transition: opacity 100ms ease-in-out;
         background: rgba(0, 0, 0, 0.6);
     }
     .ReactModalPortal .ReactModal__Overlay--after-open {
        opacity: 1;
     };
     .ReactModalPortal .ReactModal__Overlay--before-close {
        opacity: 0;
     }
`;

export const customModalStyles = {
  overlay: {
    zIndex: 5,
    background: 'rgba(0, 0, 0, 0.32)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    width: '500px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '10px',
    padding: '38px 45px',
    overflow: 'visible',
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  },
};

export const Body = styled.div``;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin: 0px auto 60px auto;
  width: fit-content;
`;

export const Buttons = styled.div<Props>`
  width: 60%;
  margin: 50px auto 20px auto;
  display: flex;
  justify-content: ${({ single }) => (single ? 'center' : 'space-between')};
`;

export const FormContainer = styled.div`
  width: 60%;
  margin: 40px auto;
`;
