import React, { useEffect } from 'react';
import uuid from 'uuid/v4';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { LinearProgress } from '@material-ui/core';

import { useApi } from '../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { DateTime } from 'luxon';
import { api } from '../../api';

interface TableData {
  org_name: string;
  date_created: string;
  status: string;
  file_link: string;
  pos_config: string;
}

type Order = 'asc' | 'desc';

// sorting functions
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// table header
interface HeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'org_name', numeric: false, disablePadding: true, label: 'Org Name' },
  { id: 'date_created', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'file_link', numeric: false, disablePadding: false, label: 'File Link' },
  { id: 'pos_config', numeric: false, disablePadding: false, label: 'POS Config' },
];

interface TableHeaderProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void;
  order: Order;
  orderBy: string;
}

function TableHeader(props: TableHeaderProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// table
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  })
);

export function FailedIngestionReport() {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof TableData>('date_created');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableRows, setTableRows] = React.useState([]);

  const apiClient = api();

  const rows = [];

  useEffect(() => {
    apiClient.dispensaries
      .getAllDispensaries(() => {})
      .then(async dispensaries => {
        const getEvents = [];

        for (const dispensary of dispensaries) {
          getEvents.push(
            apiClient.events
              .getFailedIngestions(dispensary.id, () => {})
              .then(r => {
                r.forEach(o => {
                  rows.push({
                    org_name: o.data.org_name,
                    date_created: o.date_created,
                    status: o.data.status,
                    file_link: o.data.file_link,
                    pos_config: o.data.pos_config,
                  });
                });
              })
              .catch(e => {
                console.error('Failed to retrieve ingestions', e.message);
              })
          );
        }

        await Promise.all(getEvents);
      })
      .then(() => {
        setTableRows(rows);
        setLoading(false);
      });
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableRows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {loading && <LinearProgress />}

      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size={'medium'}>
            <TableHeader classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {stableSort(tableRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={uuid()}>
                      <TableCell>{row.org_name}</TableCell>
                      <TableCell>{DateTime.fromISO(row.date_created).toLocaleString()}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        <a href={row.file_link} download={row.org_name + '.csv'} target="_blank">
                          Download
                        </a>
                      </TableCell>
                      <TableCell>{JSON.stringify(row.pos_config)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
