import React, { useState, useEffect } from 'react';

import { GcvDataTable, GcvTimePeriodDropdown, GcvZeroState, GcvInputSelectNew } from '../../../lib';
import { Deposit, FilterRequest, FilterOptions, DateTimeFilter, useFilter } from './ReportFilters';
import { DepositDetailDrawer } from '../../../shared/Deposits/DepositDetailDrawer';
import { Dictionary } from '@ngrx/entity';
import { Dispensary, IANATimezones, User } from '@gcv/shared';
import useQueryString from '../../../hooks/useQueryString';
import { DateTime } from 'luxon';
import { formatMoney, formatDateOrDash } from '../../../util';

interface Props {
  deposits: Deposit[];
  bankId: string;
  dispensary: Dispensary;
  staff: Dictionary<User>;
  viewStatus: string;
  emitData: any;
  timezone: IANATimezones;
}

interface Option {
  value: string;
  label: string;
}

export const Deposits: React.FC<Props> = props => {
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [loadingDeposits, setLoadingDeposits] = useState([]);
  const [timeSelectType, setTimeSelectType] = useState<string>('created');
  const [timeSelect, setTimeSelect] = useState<string>('all');
  const [selectedDepositId, setSelectedDepositId] = useQueryString('id', undefined);
  const [filterRequest, setFilterRequest] = useState<FilterRequest<Deposit>>({
    data: props.deposits,
    searchTerms: {},
  });

  const filterReport = useFilter(filterRequest, setFilterRequest, props.deposits);

  const columns = [
    {
      name: 'Deposit Amount',
      selector: 'final_deposit',
      sortable: true,
      grow: 2,
      center: true,
      format: row => formatMoney(row.final_deposit),
    },
    {
      name: 'Date Created',
      selector: 'date_created',
      sortable: true,
      grow: 2,
      center: true,
      format: row => DateTime.fromISO(row.date_created).toLocaleString(DateTime.DATE_MED),
    },
    {
      name: 'Created By',
      selector: row =>
        props.staff && Object.keys(props.staff).length && props.staff[row.created_by]
          ? `${props.staff[row.created_by]['firstName']} ${props.staff[row.created_by]['lastName']}`
          : '',
      sortable: true,
      grow: 2,
      center: true,
    },
    {
      name: 'Planned Arrival Date',
      selector: 'expected_arrival_date',
      sortable: true,
      grow: 2,
      center: true,
      format: row => formatDateOrDash(row.expected_arrival_date),
    },
  ];

  // initial populate deposits when props are ready
  useEffect(() => {
    setDeposits(props.deposits);
    setFilteredDeposits(props.deposits);

    loadingDeposits.forEach(loadingDeposit => {
      const updatedDeposit = props.deposits.find(d => d.id === loadingDeposit);
      if (updatedDeposit && updatedDeposit.status === 'pending') {
        updatedDeposit.reconciling = true;
      } else if (updatedDeposit && updatedDeposit.status === 'reconciled') {
        setLoadingDeposits(loadingDeposits.filter(d => d != updatedDeposit.id));
      }
    });
  }, [props.deposits]);

  // watch for state changes in a report filter request
  useEffect(() => {
    handleSearchFilters();
  }, [filterRequest]);

  const clearSelectedDepositId = () => {
    setSelectedDepositId('');
  };

  const handleRowClick = rowData => {
    setSelectedDepositId(rowData.deposit_id);
  };

  const handleSearchFilters = () => {
    const dateTimeFilter = new DateTimeFilter(setFilteredDeposits, props.timezone);

    dateTimeFilter.Handle(filterRequest);
  };

  const handleTimeTypeChange = e => {
    setTimeSelectType(e.value);
    filterReport({ value: timeSelect, timeSelectType: e.value }, FilterOptions.DateTimeFilter);
  };

  const handleTimeChange = e => {
    setTimeSelect(e.value);
    filterReport({ value: e.value, timeSelectType }, FilterOptions.DateTimeFilter);
  };

  const selectedDeposit = deposits.find(d => d.deposit_id === selectedDepositId);

  const filterOptions: Option[] = [
    { value: 'date_created', label: 'Created' },
    { value: 'expected_arrival_date', label: 'Planned Arrival' },
    { value: 'arrived_date', label: 'Arrived' },
  ];

  return (
    <>
      <div style={{ marginBottom: '1rem' }}>
        <GcvTimePeriodDropdown
          selectableLabel={true}
          emitData={handleTimeChange}
          newStyle={true}
          labelOptions={filterOptions}
          onLabelChange={handleTimeTypeChange}
          includeAllOption={true}
          defaultValueDrop={'all'}
        />
      </div>
      <div data-cy="deposits-table">
        <GcvDataTable
          data={filteredDeposits}
          columns={columns}
          onRowClicked={handleRowClick}
          keyField="react_key"
          defaultSortField="date_created"
          defaultSortAsc={false}
          subHeaderAlign="left"
          noDataComponent={
            <GcvZeroState
              bankDashboard={true}
              headerText={'There are no deposits to show with the selected filters.'}
              subText={'Please try changing your filters to show more records.'}
            />
          }
        />
      </div>

      <DepositDetailDrawer
        deposit={selectedDeposit}
        open={selectedDepositId && selectedDeposit}
        onClose={clearSelectedDepositId}
        bankId={props.dispensary.bank_id}
        dispensary={props.dispensary}
        userMap={props.staff}
        type={'dispensary'}
      />
    </>
  );
};
