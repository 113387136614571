import React, { useEffect, useState } from 'react';
import { Grid, TextField, Tooltip } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { Bank } from '@gcv/shared';
import { useApi } from 'libs/react-ui/src/hooks';
import { EditIcon } from 'libs/react-ui/src/icons/EditIcon';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { GcvButton, GcvCard, GcvInputForm, GcvInputSelect } from 'libs/react-ui/src/lib';
import { Day, HoursContainer, HoursContainerLast, Label, Time } from '../styles';
import { primaryFederalRegulators } from 'libs/gcv-ui/src/lib';
import { apiClient } from 'libs/react-ui/src/api/api-client';
import { api } from 'libs/react-ui/src/api';

interface Props {
  bank: Bank;
}

export const General: React.FC<Props> = props => {
  const apiClient = api();

  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });
  const { watch, setValue } = form;

  const [businessHours, setBusinessHours] = useState(props.bank.businessHours);
  const [editOrganizationForm, setEditOrganizationForm] = useState(true);
  const [editContactInformationForm, setEditContactInformationForm] = useState(true);
  const [editMainOfficeHoursForm, setEditMainOfficeHoursForm] = useState(true);
  const [editMainOfficeForm, setEditMainOfficeForm] = useState(true);

  useEffect(() => {
    // edit state must be true on first render in order for rules to work
    // on second render 'useEffect lifecycle' we set it to false
    setEditOrganizationForm(false);
    setEditContactInformationForm(false);
    setEditMainOfficeHoursForm(false);
    setEditMainOfficeForm(false);
  }, []);

  useEffect(() => {
    // default value being set does not actually set form value so we set it here
    setValue('primaryReg', {
      label: primaryFederalRegulators.filter(r => r.value == props.bank.primaryReg)[0].viewValue,
      value: props.bank.primaryReg,
    });
  }, []);

  const saveOrganizationDetails = async payload => {
    props.bank.primaryReg = watch('primaryReg') ? watch('primaryReg').value : props.bank.primaryReg;

    apiClient.banks.updateBankOrganizationalDetails(
      props.bank.id,
      {
        orgName: watch('orgName'),
        certificateOrCharterNumber: watch('certificateOrCharterNumber'),
        primaryReg: watch('primaryReg') ? watch('primaryReg').value : props.bank.primaryReg,
        phoneNumber: watch('phoneNumber'),
        website: watch('website'),
        address: watch('address'),
        city: watch('city'),
        state: props.bank.state,
        postalCode: watch('postalCode'),
        businessHours: businessHours,
      },
      () => {}
    );

    // TODO: Remove old api
    // API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${props.bank.id}/organizational-details`, {
    //   body: {
    //     orgName: watch('orgName'),
    //     certificateOrCharterNumber: watch('certificateOrCharterNumber'),
    //     primaryReg: watch('primaryReg') ? watch('primaryReg').value : props.bank.primaryReg,
    //     phoneNumber: watch('phoneNumber'),
    //     website: watch('website'),
    //     address: watch('address'),
    //     city: watch('city'),
    //     state: props.bank.state,
    //     postalCode: watch('postalCode'),
    //     businessHours: businessHours,
    //   },
    // });
    setEditOrganizationForm(false);
    setEditContactInformationForm(false);
    setEditMainOfficeHoursForm(false);
    setEditMainOfficeForm(false);
  };

  const calculateBusinessHours = (day: string): string => {
    if (!businessHours) {
      return '--';
    }

    if (!businessHours[day] || !businessHours[day].isOpen) {
      return 'Closed';
    }

    const getHours = (type: 'openHour' | 'closeHour') => {
      const time = businessHours[day][type];
      const hour = parseInt(time.split(':')[0]);

      if (hour < 12) {
        return `${time} A.M.`;
      } else if (hour === 12) {
        return `${time} P.M.`;
      } else {
        return `${hour - 12}:${time.split(':')[1]} P.M.`;
      }
    };

    return [getHours('openHour'), getHours('closeHour')].join(' - ');
  };

  const toggleBusinessOpenDay = (day: string) => {
    setBusinessHours({
      ...businessHours,
      [day]: {
        ...businessHours[day],
        isOpen: !businessHours[day].isOpen,
      },
    });
  };

  const setTime = (day: string, time: string, type: 'openHour' | 'closeHour') => {
    setBusinessHours({
      ...businessHours,
      [day]: {
        ...businessHours[day],
        [type]: time,
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GcvCard>
              <GcvCard.Header
                title={'Organization'}
                actions={!editOrganizationForm ? <EditIcon onClick={() => setEditOrganizationForm(true)} /> : undefined}
              />
              <GcvCard.Body>
                <GcvInputForm
                  {...form}
                  name="orgName"
                  label="Organization Name"
                  type="text"
                  defaultValue={props.bank.orgName}
                  readonly={true}
                  rules={{
                    required: editOrganizationForm,
                  }}
                />
                <GcvInputForm
                  {...form}
                  name="certificateOrCharterNumber"
                  label="Certificate or Charter Number"
                  type="text"
                  defaultValue={props.bank.certificateOrCharterNumber}
                  readonly={!editOrganizationForm}
                  rules={{
                    required: editOrganizationForm,
                  }}
                />
                {!editOrganizationForm && (
                  <>
                    <Label>Primary Regulator</Label>
                    <span>{primaryFederalRegulators.filter(r => r.value == props.bank.primaryReg)[0].viewValue}</span>
                  </>
                )}
                {editOrganizationForm && (
                  <>
                    <br />
                    <GcvInputSelect
                      {...form}
                      name="primaryReg"
                      label="Primary Regulator"
                      options={primaryFederalRegulators.map(f => {
                        return {
                          label: f.viewValue,
                          value: f.value,
                        };
                      })}
                      defaultValue={{
                        label: primaryFederalRegulators.filter(r => r.value == props.bank.primaryReg)[0].viewValue,
                        value: props.bank.primaryReg,
                      }}
                      rules={{
                        required: editOrganizationForm,
                      }}
                    />
                  </>
                )}
              </GcvCard.Body>

              {editOrganizationForm && (
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton tertiary onClick={() => setEditOrganizationForm(false)}>
                      Cancel
                    </GcvButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton primary disabled={!formState.isValid} onClick={handleSubmit(saveOrganizationDetails)}>
                      Save
                    </GcvButton>
                  </Grid>
                </Grid>
              )}
            </GcvCard>
          </Grid>

          <Grid item xs={12}>
            <GcvCard>
              <GcvCard.Header
                title={'Contact Information'}
                actions={
                  !editContactInformationForm ? (
                    <EditIcon onClick={() => setEditContactInformationForm(true)} />
                  ) : (
                    undefined
                  )
                }
              />
              <GcvCard.Body>
                <GcvInputForm
                  {...form}
                  name="phoneNumber"
                  label="Business Phone"
                  type="phone"
                  defaultValue={props.bank.phoneNumber}
                  readonly={!editContactInformationForm}
                  rules={{
                    required: editContactInformationForm,
                    minLength: 10,
                  }}
                />
                <GcvInputForm
                  {...form}
                  name="website"
                  label="Business Website"
                  type="text"
                  defaultValue={props.bank.website}
                  readonly={!editContactInformationForm}
                />
              </GcvCard.Body>

              {editContactInformationForm && (
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton tertiary onClick={() => setEditContactInformationForm(false)}>
                      Cancel
                    </GcvButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton primary disabled={!formState.isValid} onClick={handleSubmit(saveOrganizationDetails)}>
                      Save
                    </GcvButton>
                  </Grid>
                </Grid>
              )}
            </GcvCard>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <GcvCard>
          <GcvCard.Header
            title={'Main Office Hours'}
            actions={
              !editMainOfficeHoursForm ? <EditIcon onClick={() => setEditMainOfficeHoursForm(true)} /> : undefined
            }
          />
          {!editMainOfficeHoursForm && (
            <GcvCard.Body>
              <HoursContainer>
                <Day>Monday</Day>
                <Time>{calculateBusinessHours('monday')}</Time>
              </HoursContainer>
              <HoursContainer>
                <Day>Tuesday</Day>
                <Time>{calculateBusinessHours('tuesday')}</Time>
              </HoursContainer>
              <HoursContainer>
                <Day>Wednesday</Day>
                <Time>{calculateBusinessHours('wednesday')}</Time>
              </HoursContainer>
              <HoursContainer>
                <Day>Thursday</Day>
                <Time>{calculateBusinessHours('thursday')}</Time>
              </HoursContainer>
              <HoursContainer>
                <Day>Friday</Day>
                <Time>{calculateBusinessHours('friday')}</Time>
              </HoursContainer>
              <HoursContainer>
                <Day>Saturday</Day>
                <Time>{calculateBusinessHours('saturday')}</Time>
              </HoursContainer>
              <HoursContainerLast>
                <Day>Sunday</Day>
                <Time>{calculateBusinessHours('sunday')}</Time>
              </HoursContainerLast>
            </GcvCard.Body>
          )}

          {editMainOfficeHoursForm && (
            <GcvCard.Body>
              <HoursContainer>
                <Day>
                  <GcvButton
                    pill
                    primary={businessHours.monday.isOpen}
                    tertiary={!businessHours.monday.isOpen}
                    onClick={() => toggleBusinessOpenDay('monday')}
                    style={{ width: '4.5rem' }}
                  >
                    Mon
                  </GcvButton>
                </Day>
                <Time>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="monday.open"
                        label="Open"
                        type="time"
                        defaultValue={businessHours.monday.openHour}
                        disabled={!businessHours.monday.isOpen}
                        onChange={e => setTime('monday', e.currentTarget.value, 'openHour')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="monday.close"
                        label="Close"
                        type="time"
                        defaultValue={businessHours.monday.closeHour}
                        disabled={!businessHours.monday.isOpen}
                        onChange={e => setTime('monday', e.currentTarget.value, 'closeHour')}
                      />
                    </Grid>
                  </Grid>
                </Time>
              </HoursContainer>
              <HoursContainer>
                <Day>
                  <GcvButton
                    pill
                    primary={businessHours.tuesday.isOpen}
                    tertiary={!businessHours.tuesday.isOpen}
                    onClick={() => toggleBusinessOpenDay('tuesday')}
                    style={{ width: '4.5rem' }}
                  >
                    Tue
                  </GcvButton>
                </Day>
                <Time>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="tuesday.open"
                        label="Open"
                        type="time"
                        defaultValue={businessHours.tuesday.openHour}
                        disabled={!businessHours.tuesday.isOpen}
                        onChange={e => setTime('tuesday', e.currentTarget.value, 'openHour')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="tuesday.close"
                        label="Close"
                        type="time"
                        defaultValue={businessHours.tuesday.closeHour}
                        disabled={!businessHours.tuesday.isOpen}
                        onChange={e => setTime('tuesday', e.currentTarget.value, 'closeHour')}
                      />
                    </Grid>
                  </Grid>
                </Time>
              </HoursContainer>
              <HoursContainer>
                <Day>
                  <GcvButton
                    pill
                    primary={businessHours.wednesday.isOpen}
                    tertiary={!businessHours.wednesday.isOpen}
                    onClick={() => toggleBusinessOpenDay('wednesday')}
                    style={{ width: '4.5rem' }}
                  >
                    Wed
                  </GcvButton>
                </Day>
                <Time>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="wednesday.open"
                        label="Open"
                        type="time"
                        defaultValue={businessHours.wednesday.openHour}
                        disabled={!businessHours.wednesday.isOpen}
                        onChange={e => setTime('wednesday', e.currentTarget.value, 'openHour')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="wednesday.close"
                        label="Close"
                        type="time"
                        defaultValue={businessHours.wednesday.closeHour}
                        disabled={!businessHours.wednesday.isOpen}
                        onChange={e => setTime('wednesday', e.currentTarget.value, 'closeHour')}
                      />
                    </Grid>
                  </Grid>
                </Time>
              </HoursContainer>
              <HoursContainer>
                <Day>
                  <GcvButton
                    pill
                    primary={businessHours.thursday.isOpen}
                    tertiary={!businessHours.thursday.isOpen}
                    onClick={() => toggleBusinessOpenDay('thursday')}
                    style={{ width: '4.5rem' }}
                  >
                    Thu
                  </GcvButton>
                </Day>
                <Time>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="thursday.open"
                        label="Open"
                        type="time"
                        defaultValue={businessHours.thursday.openHour}
                        disabled={!businessHours.thursday.isOpen}
                        onChange={e => setTime('thursday', e.currentTarget.value, 'openHour')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="thursday.close"
                        label="Close"
                        type="time"
                        defaultValue={businessHours.thursday.closeHour}
                        disabled={!businessHours.thursday.isOpen}
                        onChange={e => setTime('thursday', e.currentTarget.value, 'closeHour')}
                      />
                    </Grid>
                  </Grid>
                </Time>
              </HoursContainer>
              <HoursContainer>
                <Day>
                  <GcvButton
                    pill
                    primary={businessHours.friday.isOpen}
                    tertiary={!businessHours.friday.isOpen}
                    onClick={() => toggleBusinessOpenDay('friday')}
                    style={{ width: '4.5rem' }}
                  >
                    Fri
                  </GcvButton>
                </Day>
                <Time>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="friday.open"
                        label="Open"
                        type="time"
                        defaultValue={businessHours.friday.openHour}
                        disabled={!businessHours.friday.isOpen}
                        onChange={e => setTime('friday', e.currentTarget.value, 'openHour')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="friday.close"
                        label="Close"
                        type="time"
                        defaultValue={businessHours.friday.closeHour}
                        disabled={!businessHours.friday.isOpen}
                        onChange={e => setTime('friday', e.currentTarget.value, 'closeHour')}
                      />
                    </Grid>
                  </Grid>
                </Time>
              </HoursContainer>
              <HoursContainer>
                <Day>
                  <GcvButton
                    pill
                    primary={businessHours.saturday.isOpen}
                    tertiary={!businessHours.saturday.isOpen}
                    onClick={() => toggleBusinessOpenDay('saturday')}
                    style={{ width: '4.5rem' }}
                  >
                    Sat
                  </GcvButton>
                </Day>
                <Time>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="saturday.open"
                        label="Open"
                        type="time"
                        defaultValue={businessHours.saturday.openHour}
                        disabled={!businessHours.saturday.isOpen}
                        onChange={e => setTime('saturday', e.currentTarget.value, 'openHour')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="saturday.close"
                        label="Close"
                        type="time"
                        defaultValue={businessHours.saturday.closeHour}
                        disabled={!businessHours.saturday.isOpen}
                        onChange={e => setTime('saturday', e.currentTarget.value, 'closeHour')}
                      />
                    </Grid>
                  </Grid>
                </Time>
              </HoursContainer>
              <HoursContainerLast>
                <Day>
                  <GcvButton
                    pill
                    primary={businessHours.sunday.isOpen}
                    tertiary={!businessHours.sunday.isOpen}
                    onClick={() => toggleBusinessOpenDay('sunday')}
                    style={{ width: '4.5rem' }}
                  >
                    Sun
                  </GcvButton>
                </Day>
                <Time>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="sunday.open"
                        label="Open"
                        type="time"
                        defaultValue={businessHours.sunday.openHour}
                        disabled={!businessHours.sunday.isOpen}
                        onChange={e => setTime('sunday', e.currentTarget.value, 'openHour')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="sunday.close"
                        label="Close"
                        type="time"
                        defaultValue={businessHours.sunday.closeHour}
                        disabled={!businessHours.sunday.isOpen}
                        onChange={e => setTime('sunday', e.currentTarget.value, 'closeHour')}
                      />
                    </Grid>
                  </Grid>
                </Time>
              </HoursContainerLast>
            </GcvCard.Body>
          )}

          {editMainOfficeHoursForm && (
            <Grid container spacing={2}>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <GcvButton tertiary onClick={() => setEditMainOfficeHoursForm(false)}>
                  Cancel
                </GcvButton>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <GcvButton primary onClick={handleSubmit(saveOrganizationDetails)}>
                  Save
                </GcvButton>
              </Grid>
            </Grid>
          )}
        </GcvCard>
      </Grid>

      <Grid item xs={4}>
        <GcvCard>
          <GcvCard.Header
            title={'Main Office'}
            actions={!editMainOfficeForm ? <EditIcon onClick={() => setEditMainOfficeForm(true)} /> : undefined}
          />
          <GcvCard.Body>
            <GcvInputForm
              {...form}
              name="address"
              label="Address"
              type="text"
              defaultValue={props.bank.address}
              readonly={!editMainOfficeForm}
              rules={{
                required: editMainOfficeForm,
              }}
            />
            <GcvInputForm
              {...form}
              name="city"
              label="City"
              type="text"
              defaultValue={props.bank.city}
              readonly={!editMainOfficeForm}
              rules={{
                required: editMainOfficeForm,
              }}
            />
            {!editMainOfficeForm && (
              <GcvInputForm
                {...form}
                name="state"
                label="State"
                type="text"
                defaultValue={props.bank.state}
                readonly
                rules={{
                  required: editMainOfficeForm,
                }}
              />
            )}
            {editMainOfficeForm && (
              <Tooltip title="Contact support@greencheckverified.com to update your state">
                <div>
                  <GcvInputSelect
                    {...form}
                    name="state"
                    label="State"
                    options={[
                      {
                        label: props.bank.state,
                        value: props.bank.state,
                      },
                    ]}
                    defaultValue={{
                      label: props.bank.state,
                      value: props.bank.state,
                    }}
                    readonly
                    rules={{
                      required: editOrganizationForm,
                    }}
                  />
                </div>
              </Tooltip>
            )}
            <GcvInputForm
              {...form}
              name="postalCode"
              label="ZIP"
              type="text"
              defaultValue={props.bank.postalCode}
              readonly={!editMainOfficeForm}
              rules={{
                required: editMainOfficeForm,
              }}
            />
          </GcvCard.Body>

          {editMainOfficeForm && (
            <Grid container spacing={2}>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <GcvButton tertiary onClick={() => setEditMainOfficeForm(false)}>
                  Cancel
                </GcvButton>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <GcvButton primary disabled={!formState.isValid} onClick={handleSubmit(saveOrganizationDetails)}>
                  Save
                </GcvButton>
              </Grid>
            </Grid>
          )}
        </GcvCard>
      </Grid>
    </Grid>
  );
};
