export const isFileExtensionPdf = file => {
  return file && doesValueHaveFileExtensionPdf(file.name);
};

export const isFileExtensionJpegOrPng = file => {
  return file && (doesValueHaveFileExtensionJpeg(file.name) || doesValueHaveFileExtensionPng(file.name));
};

export const doesValueHaveFileExtensionPdf = value => {
  return /(.pdf)/i.test(value);
};

export const doesValueHaveFileExtensionPng = value => {
  return /(.png)/i.test(value);
};

export const doesValueHaveFileExtensionJpeg = value => {
  return /(.jpe?g)/i.test(value);
};
