import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { $blue, $danger, $grey2, $grey3, $grey4, $primary, $primaryDark, $white } from '../../util/styleVariables';

export const UpdateButtonContainer = styled.div`
  z-index: 1;
  width: 12rem;
  margin: 0 1rem;
  box-sizing: border-box;
`;

interface MainButtonProps {
  primary: boolean;
  secondary: boolean;
}
export const UpdateButton = styled.div<MainButtonProps>`
  padding: 0 1rem;
  position: absolute;
  width: 12rem;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: ${props => (props.primary ? null : `1.5px solid ${$primary}`)};
  background-color: ${props => (props.primary ? $primary : $white)};
  color: ${props => (props.primary ? $white : $primary)};
  transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease, position 0.2s ease;
  -webkit-transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease,
    position 0.2s ease;

  &:hover {
    border-color: ${$primaryDark};
  }

  &:focus {
    outline: 0;
    border-color: ${$primaryDark};
  }
`;

interface ButtonProps {
  disabled?: boolean;
}

export const ButtonInner = styled.div<ButtonProps>`
  height: 2.25rem;
  text-rendering: geometricPrecision;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${props => (!props.disabled ? $primary : $grey2)};
  cursor: pointer;
  &:hover {
    color: ${props => (!props.disabled ? $primaryDark : null)};
  }
`;
