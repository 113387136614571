import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';
import {
  BankInternalTransactionCodesRequest,
  BankInternalTransactionCodes,
  DocumentUpload,
  BankDispensaryMetadataData,
} from '@gcv/shared';

export const banksClient = (API: APIClass) => {
  return {
    getBanks: async (setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get('/banks', setIsLoading);
    },

    getBankById: async (bankId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/banks/${bankId}`, setIsLoading);
    },

    getBankDispensaries: async (bankId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/banks/${bankId}/dispensaries`, setIsLoading);
    },

    getDailySummary: async (
      bankId: string,
      startDate: string,
      endDate: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(`/banks/${bankId}/dailySummary/${startDate}/${endDate}`, setIsLoading);
    },

    getFiDepositsReport: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/banks/${bankId}/reporting/dispensaries/deposits`, body, setIsLoading);
    },

    getFiAccountSummaryReport: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/banks/${bankId}/reporting/account-summary`, body, setIsLoading);
    },

    getFiDailySummariesReport: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post('/banks/reporting/dailySummaries', body, setIsLoading);
    },

    createBank: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post('/banks', body, setIsLoading);
    },

    addDispensary: async (bankId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/banks/${bankId}/dispensaries`, body, setIsLoading);
    },

    createDispensaryTransactionsFromBankCore: async (
      bankId: string,
      body: {
        transactionData: {
          bank_internal_dispensary_id: string;
          post_transaction_balance: number;
          amount: number;
          transaction_type: 'deposit' | 'withdrawal';
          payment_type: 'ach' | 'wire' | 'cash' | 'check';
          bank_transaction_id: string;
          transaction_date: string;
        }[];
      },
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/banks/${bankId}/core-transactions/`, body, setIsLoading);
    },

    getDeposits: async (
      bankId: string,
      setIsLoading: (isLoading: boolean) => void,
      startDate?: string,
      endDate?: string
    ) => {
      let url = `/banks/${bankId}/deposits`;
      if (startDate) {
        url = url + `?startDate=${startDate}`;
        if (endDate) {
          url = url + `&endDate=${endDate}`;
        }
      }
      return await apiClient(API).get(url, setIsLoading);
    },

    acceptDeposit: async (
      bankId: string,
      dispensaryId: string,
      depositId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/banks/${bankId}/dispensaries/${dispensaryId}/deposits/${depositId}/accept`,
        body,
        setIsLoading
      );
    },

    updateDepositStatus: async (
      bankId: string,
      dispensaryId: string,
      depositId: string,
      endpoint: 'pending' | 'under-review',
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/banks/${bankId}/dispensaries/${dispensaryId}/deposits/${depositId}/${endpoint}`,
        body,
        setIsLoading
      );
    },

    addTransportVendor: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/banks/${bankId}/transportVendor`, body, setIsLoading);
    },

    getFiSalesReport: async (bankId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/banks/${bankId}/reporting/dispensaries/sales`, body, setIsLoading);
    },

    getPreComplianceReport: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/banks/${bankId}/reporting/dispensaries/precomplianceReview`,
        body,
        setIsLoading
      );
    },

    getInternalBankDispensaryDocuments: async (
      bankId: string,
      dispId: string,
      setIsLoading: (isLoading: boolean) => void
    ): Promise<DocumentUpload[]> => {
      return await apiClient(API).get(`/banks/${bankId}/dispensary/${dispId}/internal-documents`, setIsLoading);
    },

    upsertTemplateRequirement: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/banks/${bankId}/diligence/requirement`, body, setIsLoading);
    },

    deleteTemplateRequirement: async (
      bankId: string,
      requirementId: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).delete(`/banks/${bankId}/diligence/requirement/${requirementId}`, setIsLoading);
    },

    updateBank: async (bankId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/banks/${bankId}`, body, setIsLoading);
    },

    updateBankDueDiligenceStatus: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/gcv-due-diligence-status`, body, setIsLoading);
    },

    assignDispensaryToBank: async (
      bankId: string,
      dispensaryId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/dispensaries/${dispensaryId}`, body, setIsLoading);
    },

    updateTransportVendor: async (
      bankId: string,
      vendorId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/transportVendor/${vendorId}`, body, setIsLoading);
    },

    addDefaultTransportVendor: async (
      bankId: string,
      vendorId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/transportVendor/${vendorId}/dispensaries`, body, setIsLoading);
    },

    assignTransportVendor: async (
      bankId: string,
      vendorId: string,
      dispensaryId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(
        `/banks/${bankId}/transportVendor/${vendorId}/dispensaries/${dispensaryId}`,
        body,
        setIsLoading
      );
    },

    updateDueDiligenceTemplate: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/diligence/template`, body, setIsLoading);
    },

    updateTemplateRequirementStatus: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).patch(`/banks/${bankId}/diligence/requirement/status`, body, setIsLoading);
    },

    deleteBank: async (bankId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/banks/${bankId}`, setIsLoading);
    },

    removeDispensaryFromBank: async (
      bankId: string,
      dispensaryId: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).delete(`/banks/${bankId}/dispensaries/${dispensaryId}`, setIsLoading);
    },

    removeTransportVendor: async (bankId: string, vendorId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/banks/${bankId}/transportVendor/${vendorId}`, setIsLoading);
    },

    removeDefaultTransportVendor: async (
      bankId: string,
      vendorId: string,
      dispensaryId: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).delete(
        `/banks/${bankId}/transportVendor/${vendorId}/dispensaries/${dispensaryId}`,
        setIsLoading
      );
    },

    updateBankOrganizationalDetails: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/organizational-details`, body, setIsLoading);
    },

    updateBankCannabisProgram: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/cannabis-program`, body, setIsLoading);
    },

    updateBankRegulatoryReporting: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/regulatory-reporting`, body, setIsLoading);
    },

    updateBankTransportOptOut: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/transport-opt-out`, body, setIsLoading);
    },

    updateBankUsersOptOut: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/users-opt-out`, body, setIsLoading);
    },

    updateBankDueDiligenceSectionComplete: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/due-diligence-section-complete`, body, setIsLoading);
    },

    inviteBankUser: async (bankId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/banks/${bankId}/users`, body, setIsLoading);
    },

    updateDispensaryBankMetadata: async (
      bankId: string,
      dispId: string,
      body: BankDispensaryMetadataData,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/banks/${bankId}/dispensary/${dispId}/metadata`, body, setIsLoading);
    },

    getDispensaryBankMetadata: async (bankId: string, dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/banks/${bankId}/dispensary/${dispId}/metadata`, setIsLoading);
    },

    upsertBankInternalCodes: async (
      bankId: string,
      codes: BankInternalTransactionCodesRequest,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/banks/${bankId}/internal-transaction-codes`,
        { internal_codes: codes },
        setIsLoading
      );
    },

    getBankInternalCodes: async (
      bankId: string,
      setIsLoading: (isLoading: boolean) => void
    ): Promise<BankInternalTransactionCodes> => {
      return await apiClient(API).get(`/banks/${bankId}/internal-transaction-codes`, setIsLoading);
    },

    setDispensaryPeerGroup: async (
      bankId: string,
      peerGroupId: string,
      dispensaryId: string,
      setIsLoading: (isLoading: boolean) => void
    ): Promise<BankInternalTransactionCodes> => {
      return await apiClient(API).post(
        `/banks/${bankId}/peer-groups/${peerGroupId}/dispensary/${dispensaryId}`,
        {},
        setIsLoading
      );
    },
  };
};
