import React, { useState, useEffect, useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  GcvInputSelect,
  GcvInputTextArea,
  GcvInputFile,
  GcvLoading,
  GcvButton,
  GcvContent,
} from 'libs/react-ui/src/lib';
import { useForm } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import { dispensary_rating_options } from 'libs/react-ui/src/constants/Accounts';
import { FlexBox } from 'libs/react-ui/src/styles/theme';
import { getBasicTemplate } from './narrativeTemplates';
import {
  Dispensary,
  DispensaryAccountReviewWithData,
  BankDispensaryMetadata,
  Bank,
  AccountReviewStatus,
  DispensaryRiskRating,
} from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';
import { s3Util } from 'libs/react-ui/src/util/s3.util';
import { debounce } from 'lodash';
import mime from 'mime-types';
import * as base64arraybuffer from 'base64-arraybuffer';
import fileType from 'file-type';
import { Card } from './Card';

const useStyles = makeStyles(theme => {
  return {
    header: {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    label: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#A5A8BA',
      fontFamily: 'Lato',
    },
    bottomFiller: {
      height: '150px',
      width: '100%',
    },
  };
});

interface Props {
  dispensaries: { [id: string]: Dispensary };
  review: DispensaryAccountReviewWithData;
  bank: Bank;
  dispMetadata: BankDispensaryMetadata;
  setReview: (review: DispensaryAccountReviewWithData) => void;
}

const ReviewNarrative = (props: Props) => {
  const classes = useStyles();
  const apiClient = api();

  const { handleSubmit, formState, watch, ...form } = useForm({ mode: 'onChange' });
  const [narrative, setNarrative] = useState(null);
  const [dispMetadata, setDispMetadata] = useState<BankDispensaryMetadata>(null);
  const [files, setFiles] = useState<{ file: File; docId: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const dispensary = props.dispensaries ? props.dispensaries[props.review.dispensary_id] : ({} as Dispensary);
  const defaultRiskRating =
    props.review && props.review.final_risk_rating
      ? dispensary_rating_options.find(op => op.value === props.review.final_risk_rating)
      : dispensary_rating_options.find(op => op.value === props.review.starting_risk_rating);

  useEffect(() => {
    if (props.bank.id && dispensary.id) {
      fetchMetadata();
    }
  }, [dispensary.id]);

  useEffect(() => {
    if (props.review && props.review.id && typeof narrative !== 'string') {
      setNarrative(props.review.narrative);
      getExistingFiles();
    }
  }, [props.review]);

  useEffect(() => {
    if (props.review.status !== AccountReviewStatus.Completed && typeof narrative === 'string') {
      props.setReview({ ...props.review, narrative: narrative });
      debouncedSaveNarrative(narrative);
    }
  }, [narrative]);

  const fetchMetadata = async () => {
    const bankMetaData = await apiClient.banks.getDispensaryBankMetadata(props.bank.id, dispensary.id, () => {});
    if (bankMetaData) {
      setDispMetadata(bankMetaData.value);
    }
    setLoading(false);
  };

  const handleNarrativeChange = (content: string) => {
    setNarrative(content);
  };

  const insertTemplate = () => {
    const template = getBasicTemplate(props.review, dispensary, props.bank, dispMetadata);
    handleNarrativeChange(template);
  };

  const debouncedSaveNarrative = useCallback(
    debounce(async (narrative: string) => {
      if (props.review.id) {
        await apiClient.accountMonitoring.updateReviewNarrative(props.review.id, narrative, () => {});
      }
    }, 2000),
    []
  );

  const updateRiskRating = async (rating: { label: string; value: DispensaryRiskRating }) => {
    await apiClient.accountMonitoring.updateReviewRiskRating(props.review.id, rating.value, () => {});
  };

  const uploadDocuments = async (blobs, data?) => {
    setLoadingDocuments(true);
    const createdDocumentIds = [];
    const createdDocuments = [];
    for (const blobIndex in blobs) {
      const blob = blobs[blobIndex];
      const document = await s3Util(apiClient)
        .handleAddDocumentToS3({ blob: blob, userType: 'bank' }, props.bank.id)
        .then(async file => {
          return apiClient.documents.createDocument(
            {
              orgId: props.review.bank_id,
              s3Key: file.s3_key,
              fileName: file.filename,
            },
            () => {}
          );
        })
        .catch(err => {
          setLoadingDocuments(false);
          alert('Error uploading document to S3, please contact support@greencheckverified.com' + err);
          return [];
        });
      createdDocuments.push({ file: blob, docId: document.id });
      createdDocumentIds.push(document.id);
    }

    setFiles([...files, ...createdDocuments]);
    setLoadingDocuments(false);
    return createdDocumentIds;
  };

  const updateFileState = async (fileState: { allFiles: File[]; newFiles: File[]; removedFiles: File[] }) => {
    const reviewDocumentList = files.map(f => f.docId);
    if (fileState.newFiles.length > 0) {
      const createdDocuments = await uploadDocuments(fileState.newFiles);
      reviewDocumentList.push(...createdDocuments);
    }
    if (fileState.removedFiles.length > 0) {
      const existingFile = files.find(f => f.file === fileState.removedFiles[0]);
      if (existingFile) {
        const newFiles = [...files];
        newFiles.splice(files.indexOf(existingFile), 1);
        reviewDocumentList.splice(reviewDocumentList.indexOf(existingFile.docId), 1);
        setFiles(newFiles);
      }
    }
    await apiClient.accountMonitoring.uploadReviewDocuments(props.review.id, reviewDocumentList, () => {});
  };

  const getDocumentsByOrgIdAndId = async (orgId, documentId) => {
    return await apiClient.documents.getOrgDocumentById(orgId, documentId, () => {});
  };

  const getDocumentById = async (orgId, documentId) => {
    try {
      const result = await getDocumentsByOrgIdAndId(orgId, documentId);
      if (Array.isArray(result)) {
        return result[0];
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const getExistingFiles = async () => {
    setLoadingDocuments(true);
    try {
      if (props.review.documents) {
        const files = await Promise.all(
          props.review.documents.map(async (docId: string) => {
            const document = await getDocumentById(props.bank.id, docId);
            const { s3LinkPath } = await s3Util(apiClient).getPresignedUrl(document.s3_key, 'get', 'bank');

            // get extension from filename
            var re = /(?:\.([^.]+))?$/;
            const ext = re.exec(document.file_name)[1].toLowerCase();
            const type = mime.lookup(ext);

            const result = await fetch(s3LinkPath);
            const base64 = await result.text();
            const base64ArrayBuffer = base64arraybuffer.decode(base64);

            return { file: new File([base64ArrayBuffer], document.file_name, { type }), docId };
          })
        );

        setFiles(files);
        setLoadingDocuments(false);
      } else {
        setLoadingDocuments(false);
      }
    } catch (e) {
      setLoadingDocuments(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <Card.Header title={'Narrative'} />
          <Card.Content>
            <Editor
              apiKey="4vc9c1fgzdo53m2f9qsh298oo9qd6js42hzehx4adrvb7mkq"
              init={{
                branding: false,
                height: 500,
                menubar: false,
                placeholder: 'Summarize the results of your review here...',
                plugins: [
                  'advlist autolink lists',
                  'searchreplace visualblocks code',
                  'table paste codesample textcolor',
                ],
                toolbar:
                  'formatselect | blockquote codesample | bold italic strikethrough backcolor | bullist numlist | undo redo',
              }}
              value={narrative}
              onEditorChange={handleNarrativeChange}
              disabled={props.review.status === AccountReviewStatus.Completed}
            />
            <br />
            {props.review.status !== AccountReviewStatus.Completed ? (
              <div
                style={{ color: '#02B45B', cursor: 'pointer', display: 'flex', alignItems: 'center', fontWeight: 600 }}
                onClick={insertTemplate}
              >
                Insert Template
              </div>
            ) : null}
          </Card.Content>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card height={305}>
          <Card.Header title={'Risk Rating'} />
          <Card.Content>
            {loading ? (
              <GcvLoading style={{ height: 'fit-content', marginTop: '5rem' }}></GcvLoading>
            ) : (
              <>
                {props.review.status !== AccountReviewStatus.Completed ? (
                  <>
                    <p>Based on your review, please confirm or adjust the risk rating for this account.</p>
                    <br />
                    <br />
                    <GcvInputSelect
                      {...form}
                      name="currentRiskRating"
                      label="Current Risk Rating"
                      options={dispensary_rating_options}
                      defaultValue={defaultRiskRating}
                      onChangeCallback={updateRiskRating}
                    />
                  </>
                ) : (
                  <>
                    <p>This review resulted in the new risk rating below.</p>
                    <br />
                    <GcvContent type="l1" grey2={true} content="Previous Risk Rating"></GcvContent>
                    <GcvContent
                      type="p1"
                      content={
                        dispensary_rating_options.find(rating => rating.value === props.review.starting_risk_rating)
                          ?.label
                      }
                    ></GcvContent>
                    <br />
                    <GcvContent type="l1" grey2={true} content="New Risk Rating"></GcvContent>
                    <GcvContent
                      type="p1"
                      content={
                        dispensary_rating_options.find(rating => rating.value === props.review.final_risk_rating)?.label
                      }
                    ></GcvContent>
                  </>
                )}
              </>
            )}
          </Card.Content>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card height={305}>
          <Card.Header title={'Supporting Documentation'} />
          <Card.Content>
            {loadingDocuments ? (
              <GcvLoading style={{ height: 'fit-content', marginTop: '5rem' }}></GcvLoading>
            ) : (
              <GcvInputFile
                updateFileState={updateFileState}
                acceptedTypes={['all']}
                files={files.map(f => f.file)}
                multiple={true}
                maxHeight={'10rem'}
                large={true}
                viewFilesOnly={props.review.status === AccountReviewStatus.Completed}
              ></GcvInputFile>
            )}
          </Card.Content>
        </Card>
      </Grid>
      <div className={classes.bottomFiller}></div>
    </Grid>
  );
};

export default ReviewNarrative;
