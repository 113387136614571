import React, { useState, useEffect } from 'react';
import {
  Dispensary,
  Bank,
  User,
  DueDiligenceDocumentStatus,
  DueDiligenceRequirementStatus,
  DueDiligenceTemplate,
  DueDiligenceStatus,
} from '@gcv/shared';

import ReactTooltip from 'react-tooltip';

import { GcvDataTable, GcvLoading, GcvZeroState, GcvCheckbox, GcvButton, GcvUserInputContainer } from '../../../lib';

import { DocumentDrawer } from '../DocumentDrawer/DocumentDrawer';
import { useApi, getEndpoint, getData, getV2Endpoint } from '../../../hooks/';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { ActivityFeed } from './ActivityFeed';
import { ActivityButtons } from './ActivityButtons';
import { BackButtonIcon } from '../../../icons/BackButtonIcon';

import {
  Header,
  HeaderContainer,
  SubHeader,
  Description,
  Archive,
  ArchiveText,
  ContentContainerLeft,
  ContentContainerRight,
  TableContainer,
  ColumnContainer,
  ArchiveContainer,
  ButtonContainer,
  ContentContainerBottom,
} from './styles';

import { fileName, reviewedBy, uploadedOn } from './columns';
import { formatUserFirstAndLastName, checkPermissions } from 'libs/react-ui/src/util';
import {
  getEmptyRequirement,
  mergeGcvAndBankRequirements,
} from 'libs/react-ui/src/shared/Requirements/due-diligence-util';

interface RequirementDetailViewProps {
  dispensary: Dispensary;
  emitData: (type, data?, callback?) => any;
  companyType: string;
  bank: Bank;
  requirementId: string;
  user: User;
  template: DueDiligenceTemplate;
}

export const RequirementDetailView = ({
  requirementId,
  dispensary,
  emitData,
  companyType,
  bank,
  user,
  template,
}: RequirementDetailViewProps) => {
  const { API } = useApi();
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [clearRows, setClearRows] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [requirementMetadata, setRequirementMetadata] = useState(null);
  const [documentsMap, setDocumentsMap] = useState(null);
  const [userMap, setUserMap] = useState(null);
  const [viewArchived, setViewArchived] = useState(false);
  const [selectedArchivedRows, setSelectedArchivedRows] = useState([]);
  const [selectedActiveRows, setSelectedActiveRows] = useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  const [dueDiligenceTemplateRequirement, setDueDiligenceTemplateRequirement] = useState(null);

  const formatRequirementMetadata = dispensary => {
    const requirements = dispensary.due_diligence.requirements;
    const singleReq = requirements[requirementId]
      ? requirements[requirementId]
      : getEmptyRequirement(dueDiligenceTemplateRequirement);

    setRequirementMetadata(singleReq);
    setDataLoading(false);
    setSelectedActiveRows([]);
    setSelectedArchivedRows([]);
    setClearRows(!clearRows);
  };

  const formatDocumentdata = documentsArray => {
    const documentMap = {};
    Object.values(documentsArray).forEach(document => {
      const d = document as any;
      documentMap[d.id] = document;
    });
    setDocumentsMap(documentMap);
  };

  const getRequirementData = (updatedDispensary?) => {
    if (updatedDispensary) {
      emitData('updateDispensary', updatedDispensary);
      setReloadData(!reloadData);
    } else {
      getData.getDocuments(API, formatDocumentdata, dispensary.id);
    }
  };

  const updateActivity = () => {
    setReloadData(!reloadData);
  };

  useEffect(() => {
    if (dispensary && dispensary.id) {
      getRequirementData();
      formatRequirementMetadata(dispensary);
      setDueDiligenceTemplateRequirement(mergeGcvAndBankRequirements(template, dispensary)[requirementId]);
    }
    if (userMap === null && dispensary && dispensary.id) {
      getData.getUsers(API, setUserMap, dispensary.id, bank ? bank.id : null);
    }
  }, [dispensary, bank, template]);

  if (!dispensary || !documentsMap || !requirementMetadata || !userMap || !dueDiligenceTemplateRequirement) {
    return <GcvLoading />;
  }

  if (requirementMetadata === 'Requirement Not Found') {
    return (
      <div style={{ margin: '0 auto' }}>
        <SubHeader>Sorry Requirement Not Found.</SubHeader>
        <GcvButton primary={true} onClick={getRequirementData}>
          Try again
        </GcvButton>
      </div>
    );
  }

  const permissions = {
    updateRequirement: checkPermissions(['requirement_approve'], user, companyType === 'bank' ? bank : dispensary),
    updateDocument: checkPermissions(['document_approve'], user, companyType === 'bank' ? bank : dispensary),
  };

  const archivedFiles = [];
  const activeFiles = [];

  const zeroStateText = viewArchived ? 'You currently have no Archived Files' : 'You currently have no Active Files';

  Object.keys(requirementMetadata.documents).map(documentKey => {
    if (documentsMap[documentKey]) {
      const document = {
        uploadDate: documentsMap[documentKey].upload_date,
        documentName: documentsMap[documentKey].file_name,
        reviewedBy: requirementMetadata.documents[documentKey].reviewed_by
          ? formatUserFirstAndLastName(userMap[requirementMetadata.documents[documentKey].reviewed_by])
          : '',
        docId: documentKey,
        status: requirementMetadata.documents[documentKey].status,
      };
      requirementMetadata.documents[documentKey].status === DueDiligenceDocumentStatus.ARCHIVED
        ? archivedFiles.push(document)
        : activeFiles.push(document);
    }
  });

  const handleRowClick = row => {
    setSelectedDocumentId(row.docId);
  };

  const onCloseDrawer = () => {
    setSelectedDocumentId('');
  };

  const handleBackClick = () => {
    emitData('back');
  };

  const viewFile = (docId, document) => {
    emitData('viewDocument', { docId, document });
  };

  const updateDocument = (docId, status, note?) => {
    const body = {
      status: status,
      note: note,
    };

    return API.patch(
      GREEN_CHECK_SERVICES_AMPLIFY_NAME,
      getEndpoint.documentsPatch(dispensary.id, requirementId, docId),
      {
        body: body,
      }
    );
  };

  const openFile = documentId => {
    setSelectedDocumentId(documentId);
  };

  const updateArchiveStatus = async (status: DueDiligenceDocumentStatus, selectedRows) => {
    const docIds = selectedRows.map(row => row.docId);
    let updatedDispensary;
    for (const docId of docIds) {
      try {
        updatedDispensary = await updateDocument(docId, status);
      } catch (err) {
        alert('error updating document status, please contact support@greencheckverified.com' + err);
      }
    }

    getRequirementData(updatedDispensary);
  };

  const updateFiles = () => {
    viewArchived
      ? updateArchiveStatus(DueDiligenceDocumentStatus.APPROVED, selectedArchivedRows)
      : updateArchiveStatus(DueDiligenceDocumentStatus.ARCHIVED, selectedActiveRows);
  };

  const handleViewArchiveClick = () => {
    setViewArchived(!viewArchived);
  };

  const onSelectedRowsChange = props => {
    viewArchived ? setSelectedArchivedRows(props.selectedRows) : setSelectedActiveRows(props.selectedRows);
  };

  const columnsFI = [
    fileName('File Name', handleRowClick),
    uploadedOn('Uploaded On'),
    reviewedBy('Reviewed By', handleRowClick),
  ];

  const columnsCRB = [fileName('File Name', handleRowClick), uploadedOn('Uploaded On')];

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'Row must be reviewed before archiving';
    return props.disabled ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  const isRowDisabled = row => {
    return row.status !== DueDiligenceDocumentStatus.APPROVED && row.status !== DueDiligenceDocumentStatus.ARCHIVED;
  };

  const isApprovalDisabled = () => {
    const areActiveFilesReviewed = activeFiles.reduce(
      (acc, file) => acc && file.status === DueDiligenceDocumentStatus.APPROVED,
      true
    );

    const isRequirementAlreadyReviewed = requirementMetadata.status === DueDiligenceRequirementStatus.REVIEWED;

    const isPendingAccountWithNoDocuments =
      dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_AWAITING_REVIEW &&
      dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_APPROVED &&
      activeFiles.length === 0;

    return (
      !areActiveFilesReviewed ||
      !permissions.updateRequirement ||
      isRequirementAlreadyReviewed ||
      isPendingAccountWithNoDocuments
    );
  };

  const documentType =
    dueDiligenceTemplateRequirement.type === 'business_licenses' ||
    dueDiligenceTemplateRequirement.type === 'employee_licenses'
      ? 'license'
      : 'document';

  return (
    <div>
      <SubHeader>
        <GcvUserInputContainer
          string={companyType === 'bank' ? dispensary.name : 'Company Profile'}
          length={100}
          data-cy="requirement-title"
        />
      </SubHeader>
      <ColumnContainer>
        <ContentContainerLeft style={{ paddingRight: '40px' }}>
          <HeaderContainer>
            <BackButtonIcon onClick={handleBackClick} />
            <Header>
              <GcvUserInputContainer
                string={dueDiligenceTemplateRequirement.name}
                length={500}
                clickHandler={handleBackClick}
                data-cy="requirement-name"
              />
            </Header>
          </HeaderContainer>
          <Description>
            <GcvUserInputContainer
              string={dueDiligenceTemplateRequirement.description}
              length={500}
              data-cy="requirement-subtitle"
            />
          </Description>
          {companyType === 'bank' ? (
            <ArchiveContainer>
              <div>
                {viewArchived && selectedArchivedRows.length > 0 ? (
                  <GcvButton primary={true} onClick={updateFiles}>
                    Restore Files to Active
                  </GcvButton>
                ) : null}
                {!viewArchived && selectedActiveRows.length > 0 ? (
                  <GcvButton primary={true} onClick={updateFiles}>
                    Archive Files
                  </GcvButton>
                ) : null}
              </div>
              <Archive onClick={handleViewArchiveClick} data-cy="active-archived-switch">
                <span>
                  Switch To:
                  <ArchiveText>
                    {viewArchived ? `Active Files (${activeFiles.length})` : `Archived Files (${archivedFiles.length})`}
                  </ArchiveText>
                </span>
              </Archive>
            </ArchiveContainer>
          ) : null}
        </ContentContainerLeft>
        <ContentContainerRight>
          <ActivityButtons
            companyType={companyType}
            dispensaryState={dispensary.state}
            updateActivities={updateActivity}
            updateApplication={getRequirementData}
            requirementMetadata={requirementMetadata}
            reqId={requirementId}
            dispensaryId={dispensary.id}
            userMap={userMap}
            documentType={documentType}
            requirementUpdateDisabled={isApprovalDisabled()}
            documentUpdateDisabled={!permissions.updateDocument}
          />
        </ContentContainerRight>
      </ColumnContainer>
      <ContentContainerBottom>
        <TableContainer data-cy="requirement-documents-table">
          <GcvDataTable
            data={
              companyType === 'dispensary'
                ? activeFiles.concat(archivedFiles)
                : viewArchived
                ? archivedFiles
                : activeFiles
            }
            columns={companyType === 'dispensary' ? columnsCRB : columnsFI}
            onRowClicked={handleRowClick}
            defaultSortField={'Requirement'}
            defaultSortAsc={false}
            keyField="docId"
            noDataComponent={<GcvZeroState basic={true} headerText={zeroStateText} />}
            subHeaderAlign="left"
            selectableRows={companyType !== 'dispensary'}
            clearSelectedRows={clearRows}
            onSelectedRowsChange={onSelectedRowsChange}
            selectableRowsComponent={renderSelectComponent}
            selectableRowDisabled={isRowDisabled}
            progressPending={dataLoading}
            paginationPerPage={8}
          />
        </TableContainer>

        <ActivityFeed openFile={openFile} dispensaryId={dispensary.id} reqId={requirementId} reloadData={reloadData} />
      </ContentContainerBottom>
      <DocumentDrawer
        viewFile={viewFile}
        companyType={companyType}
        userMap={userMap}
        onClose={onCloseDrawer}
        open={selectedDocumentId ? true : false}
        dispensary={dispensary}
        documentMetadata={requirementMetadata.documents[selectedDocumentId]}
        document={documentsMap[selectedDocumentId]}
        updateDocument={updateDocument}
        getRequirementData={getRequirementData}
        requirementMetadata={requirementMetadata}
        documentType={documentType}
        ableToUpdate={permissions.updateDocument}
      />
    </div>
  );
};
