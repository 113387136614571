import React, { useState } from 'react';
import { Dispensary } from '@gcv/shared';

import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { ForwardArrowIcon } from '../../icons/ForwardArrowIcon';
import { GcvInitialsLogo } from '../../lib/GcvInitialsLogo/GcvInitialsLogo';
import { GcvUserInputContainer } from '../../lib/GcvUserInputContainer/GcvUserInputContainer';

import { Container, Option, DropDown, Main, Logout, VerticalCenter } from './styles';

interface Props {
  currentCompany: Dispensary;
  companies: {};
  firstName: string;
  lastName: string;
  email: string;
  emitData: (payload: { id?: string; settings?: boolean; logout?: boolean }) => any;
}

export const ProfileDropdown = ({ currentCompany, companies, firstName, lastName, email, ...props }: Props) => {
  const cropLength = 36;

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const openDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  const handleOrgClick = id => {
    emitData({ id });
  };
  const handleSettingsClicked = () => {
    emitData({ settings: true });
  };
  const handleLogoutClicked = () => {
    emitData({ logout: true });
  };
  const emitData = payload => {
    props.emitData(payload);
    closeDrawer();
  };

  const renderDispensaries = () => {
    return Object.keys(companies).map(id => (
      <Option onClick={() => handleOrgClick(id)} key={id}>
        <VerticalCenter>
          <GcvUserInputContainer string={companies[id].name} length={cropLength} />
        </VerticalCenter>
        <ForwardArrowIcon />
      </Option>
    ));
  };

  return (
    <Container onClick={toggleDrawer} onMouseLeave={closeDrawer}>
      <Main onClick={toggleDrawer}>
        <VerticalCenter style={{ height: '4rem' }}>
          <GcvUserInputContainer string={currentCompany.name} length={cropLength} />
        </VerticalCenter>
        <VerticalCenter style={{ height: '4rem' }}>
          <DownArrowIcon />
        </VerticalCenter>
      </Main>

      {open ? (
        <DropDown style={{ width: '340px', marginLeft: '-65px' }}>
          <Option style={{ justifyContent: 'flex-start', height: '100%' }} onClick={handleSettingsClicked}>
            <VerticalCenter>
              <GcvInitialsLogo firstName={firstName} lastName={lastName} />{' '}
            </VerticalCenter>
            <VerticalCenter style={{ marginLeft: '15px' }}>
              <div className="name">
                <GcvUserInputContainer string={firstName + ' ' + lastName} length={cropLength} />
              </div>
              <div className="email">
                <GcvUserInputContainer string={email} length={cropLength} />
              </div>
              <div className="settings">Profile Settings</div>
            </VerticalCenter>
          </Option>

          <div className="dispensaries">{renderDispensaries()}</div>

          <Logout onClick={handleLogoutClicked}>
            <VerticalCenter>Log Out</VerticalCenter>
          </Logout>
        </DropDown>
      ) : null}
    </Container>
  );
};
