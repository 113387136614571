import { IANATimezones } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '../../../../../common-ui/src/lib/common-ui';
import { useApi } from '../../../hooks';
import calculateTimeRange from '../../../util/calculateDateRange';
import { Report } from '../Components/Report';

interface Props {
  bankId: string;
  userId: string;
  accountList: {
    value: string;
    label: string;
    id: string;
  }[];
  timezone: IANATimezones;
  setExportActivitySuccess: (active: boolean) => void;
  setExportActivityFailure: (active: boolean) => void;
  setResponseModalActive: (active: boolean) => void;
}

export const DailySummariesReport: React.FC<Props> = props => {
  const apiClient = api();

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [timeSelectType, setTimeSelectType] = useState('created');
  const [timeSelect, setTimeSelect] = useState('today');

  useEffect(() => {
    setSelectedAccounts(props.accountList.map(a => a.value));
  }, [props.accountList]);

  const handleExportDailySummaries = async () => {
    const timeRange = calculateTimeRange(timeSelect, props.timezone);
    const startDay = DateTime.fromISO(timeRange.start).toFormat('yyyy-MM-dd');
    const endDay = DateTime.fromISO(timeRange.end).toFormat('yyyy-MM-dd');

    await apiClient.banks
      .getFiDailySummariesReport(
        {
          orgId: props.bankId,
          userId: props.userId,
          reportData: {
            dispensaryIds: selectedAccounts,
            startDate: startDay,
            endDate: endDay,
          },
        },
        () => {}
      )
      .then(() => {
        props.setExportActivitySuccess(true);
      })
      .catch(() => {
        props.setExportActivityFailure(true);
      })
      .finally(() => {
        props.setResponseModalActive(true);
      });
  };

  return (
    <Report>
      <Report.Title size={6} align="left">
        Filters
      </Report.Title>

      <Report.ExportButton size={6} align="right" export={handleExportDailySummaries} />

      <Report.TimePeriodSelect
        size={6}
        onLabelChange={setTimeSelectType}
        onChange={setTimeSelect}
        selectOptions={[{ value: 'created', label: 'Created' }]}
        labelText="Time Period"
      />

      <Report.SearchDropdown size={6} onSelect={setSelectedAccounts} selectOptions={props.accountList} />
    </Report>
  );
};
