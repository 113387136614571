import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Bank, Dispensary, DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvDataTable, GcvInputSelectNew, GcvLoading } from 'libs/react-ui/src/lib';
import {
  determineReviewPeriod,
  formatChangePercentage,
  formatMoney,
  formatMoneyWithNegatives,
  formatPercentage,
  formatOrdinalIndicator,
} from 'libs/react-ui/src/util';

import { Container } from './Container';
import { Card } from './Card';
import { Legend } from './Legend';

import { TransactionAnalysisPreviousPeriodRadarChart } from './charts/TransactionAnalysisPreviousPeriodRadarChart';
import { TransactionAnalysisPeerGroupRadarChart } from './charts/TransactionAnalysisPeerGroupRadarChart';
import { TransactionSummaryWithdrawalsDollarsBulletChart } from './charts/TransactionSummaryWithdrawalsDollarsBulletChart';
import { TransactionSummaryWithdrawalsDollarAvgBulletChart } from './charts/TransactionSummaryWithdrawalsDollarAvgBulletChart';
import { TransactionSummaryDepositDollarsBulletChart } from './charts/TransactionSummaryDepositDollarsBulletChart';
import { TransactionSummarySalesDollarsBulletChart } from './charts/TransactionSummarySalesDollarsBulletChart';
import { TransactionSummaryDepositsDollarAvgBulletChart } from './charts/TransactionSummaryDepositsDollarAvgBulletChart';
import { TotalSalesAndDepositsAreaChart } from './charts/TotalSalesAndDepositsAreaChart';
import { api } from 'libs/react-ui/src/api';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';
import { AccountMonitoringContext } from '../account-monitoring-context';

interface Props {
  bank: Bank;
  dispensaries: { [id: string]: Dispensary };
  review: DispensaryAccountReviewWithData;
}

const formatPositiveNegativeColor = (value: string) => {
  const valueNumber = parseFloat(value);

  if (valueNumber < 0) {
    return <span style={{ color: 'red' }}>{value}</span>;
  } else {
    return <span style={{ color: '#00bc66' }}>{value}</span>;
  }
};

const ReviewAnalysis: React.FC<Props> = props => {
  const apiClient = api();
  const localReview = cloneDeep(props.review);
  const [filteredReview, setFilteredReview] = useState(props.review);
  const [reviewOptions, setReviewOptions] = useState([]);

  const [peerGroups, setPeerGroups] = useState<{
    peer_groups: { [id: string]: { name: string; description: string } };
  }>();
  const [depositsPercent, setDepositsPercent] = useState('');
  const [salesPercent, setSalesPercent] = useState('');
  const [withdrawalsPercent, setWithdrawalsPercent] = useState('');
  const [accountActivityData, setAccountActivityData] = useState(undefined);

  const context = useContext(AccountMonitoringContext);

  useEffect(() => {
    apiClient.accountMonitoring
      .getPeerGroups(props.bank.id, () => {})
      .then(r => {
        setPeerGroups(r);
      });

    setChangeOverPeriod({ value: 'lastPeriod' });
  }, []);

  useEffect(() => {
    const options = getMonthsInTimeRange(props.review.review_period_start_date, props.review.review_period_end_date);
    onMonthChange(options[0]);
    setReviewOptions(options);
    getAccountActivityData();
  }, [props.review]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onMonthChange = month => {
    const newReviewDailyDistribution = {};

    Object.keys(props.review.data.daily_distribution)
      .filter(date => {
        return DateTime.fromFormat(date, 'yyyy-MM-dd').month === month.value;
      })
      .forEach(key => {
        newReviewDailyDistribution[key] = props.review.data.daily_distribution[key];
      });

    localReview.data.daily_distribution = newReviewDailyDistribution;

    setFilteredReview(localReview);
  };

  const getMonthsInTimeRange = (start: string, end: string): { label: string; value: string }[] => {
    const startDate = DateTime.fromISO(start)
      .setZone(props.dispensaries[props.review.dispensary_id].iana_timezone)
      .startOf('day');
    const endDate = DateTime.fromISO(end)
      .setZone(props.dispensaries[props.review.dispensary_id].iana_timezone)
      .startOf('day');

    const months = endDate.diff(startDate, 'months').months;
    const options = [];

    let rollingDate = startDate;

    for (let i = 0; i <= months; i++) {
      options.push({
        label: rollingDate.monthLong,
        value: rollingDate.month,
      });
      rollingDate = rollingDate.plus({ months: 1 });
    }

    return options;
  };

  const getAccountActivityData = () => {
    const startDate = DateTime.fromISO(props.review.review_period_start_date)
      .minus({ years: 1 })
      .toISO();
    apiClient.accountMonitoring
      .getDispensaryAccountActivity(
        props.bank.id,
        props.review.dispensary_id,
        startDate,
        props.review.review_period_end_date,
        determineReviewPeriod(props.review),
        () => {}
      )
      .then(accountActivityData => {
        setAccountActivityData(accountActivityData.reverse());
      });
  };

  const coreData = props.review.data.aggregated_core_transaction_data;
  const salesData = props.review.data.aggregated_sales_data;

  const transactionAnalysisData = [
    {
      label: 'Cash In',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.deposit_amount_cash),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount_cash - coreData.previous_period.deposit_amount_cash
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.deposit_amount_cash,
              coreData.current_period.deposit_amount_cash
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount_cash - coreData.previous_year.deposit_amount_cash
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_year.deposit_amount_cash,
              coreData.current_period.deposit_amount_cash
            )
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(coreData.peer_groups[context.peerGroupId]?.percentiles.cash_in)} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'ACH / Wire In',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.deposit_amount_ach + coreData.current_period.deposit_amount_wire),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount_ach +
              coreData.current_period.deposit_amount_wire -
              (coreData.previous_period.deposit_amount_ach + coreData.previous_period.deposit_amount_wire)
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.deposit_amount_ach + coreData.previous_period.deposit_amount_wire,
              coreData.current_period.deposit_amount_ach + coreData.current_period.deposit_amount_wire
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount_ach +
              coreData.current_period.deposit_amount_wire -
              (coreData.previous_year.deposit_amount_ach + coreData.previous_year.deposit_amount_wire)
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_year.deposit_amount_ach + coreData.previous_year.deposit_amount_wire,
              coreData.current_period.deposit_amount_ach + coreData.current_period.deposit_amount_wire
            )
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.ach_wire_in
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Check In',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.deposit_amount_check),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount_check - coreData.previous_period.deposit_amount_check
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.deposit_amount_check,
              coreData.current_period.deposit_amount_check
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount_check - coreData.previous_year.deposit_amount_check
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_year.deposit_amount_check,
              coreData.current_period.deposit_amount_check
            )
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.check_in
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Deposits',
      isSummary: true,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.deposit_amount),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount - coreData.previous_period.deposit_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(coreData.previous_period.deposit_amount, coreData.current_period.deposit_amount)
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount - coreData.previous_year.deposit_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(coreData.previous_year.deposit_amount, coreData.current_period.deposit_amount)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.avg_deposit_amount
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Cash Out',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.withdrawal_amount_cash),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount_cash - coreData.previous_period.withdrawal_amount_cash
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.withdrawal_amount_cash,
              coreData.current_period.withdrawal_amount_cash
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount_cash - coreData.previous_year.withdrawal_amount_cash
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_year.withdrawal_amount_cash,
              coreData.current_period.withdrawal_amount_cash
            )
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.cash_out
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'ACH / Wire Out',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(
            coreData.current_period.withdrawal_amount_ach + coreData.current_period.withdrawal_amount_wire
          ),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount_ach +
              coreData.current_period.withdrawal_amount_wire -
              (coreData.previous_period.withdrawal_amount_ach + coreData.previous_period.withdrawal_amount_wire)
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.withdrawal_amount_ach + coreData.previous_period.withdrawal_amount_wire,
              coreData.current_period.withdrawal_amount_ach + coreData.current_period.withdrawal_amount_wire
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount_ach +
              coreData.current_period.withdrawal_amount_wire -
              (coreData.previous_year.withdrawal_amount_ach + coreData.previous_year.withdrawal_amount_wire)
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_year.withdrawal_amount_ach + coreData.previous_year.withdrawal_amount_wire,
              coreData.current_period.withdrawal_amount_ach + coreData.current_period.withdrawal_amount_wire
            )
          ),
        },
        {
          name: 'Peer Group', //TODO get this from backend
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.ach_wire_out
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Check Out',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.withdrawal_amount_check),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount_check - coreData.previous_period.withdrawal_amount_check
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.withdrawal_amount_check,
              coreData.current_period.withdrawal_amount_check
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount_check - coreData.previous_year.withdrawal_amount_check
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_year.withdrawal_amount_check,
              coreData.current_period.withdrawal_amount_check
            )
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.check_out
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Withdrawals',
      isSummary: true,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.withdrawal_amount),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount - coreData.previous_period.withdrawal_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.withdrawal_amount,
              coreData.current_period.withdrawal_amount
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount - coreData.previous_year.withdrawal_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(coreData.previous_year.withdrawal_amount, coreData.current_period.withdrawal_amount)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.avg_withdrawal_amount
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Sales',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(salesData.current_period.total_sales),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(salesData.current_period.total_sales - salesData.previous_period.total_sales),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_period.total_sales, salesData.current_period.total_sales)
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(salesData.current_period.total_sales - salesData.previous_year.total_sales),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_year.total_sales, salesData.current_period.total_sales)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            salesData.peer_groups[context.peerGroupId]?.percentiles.avg_total_sales
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Variance',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(salesData.current_period.variance),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(salesData.previous_period.variance),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_period.variance, salesData.current_period.variance)
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(salesData.previous_year.variance),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_year.variance, salesData.current_period.variance)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            salesData.peer_groups[context.peerGroupId]?.percentiles.avg_variance
          )} percentile`,
          delta: null,
        },
      ],
    },
  ];

  const cell = field => <span title={field}>{field}</span>;

  const accountActivityColumns = [
    {
      name: 'Period',
      selector: 'time_label',
      sortable: true,
      left: true,
    },
    {
      name: 'Deposits',
      selector: 'deposits',
      sortable: true,
      left: true,
      cell: r => cell(formatMoney(r['deposits'])),
    },
    {
      name: 'Sales',
      selector: 'sales',
      sortable: true,
      left: true,
      cell: r => cell(formatMoney(r['sales'])),
    },
    {
      name: 'Sales - Deposits',
      selector: 'salesMinusDeposits',
      sortable: true,
      left: true,
      cell: r => cell(formatMoneyWithNegatives(r['sales'] - r['deposits'])),
    },
    {
      name: 'Variance',
      selector: 'variance',
      sortable: true,
      left: true,
      format: r => formatPercentage(r['variance'], 100),
    },
    {
      name: 'Withdrawals',
      selector: 'withdrawals',
      sortable: true,
      left: true,
      cell: r => cell(formatMoney(r['withdrawals'])),
    },
  ];

  const setChangeOverPeriod = e => {
    const timePeriod = e.value;

    switch (timePeriod) {
      case 'lastPeriod':
        setDepositsPercent(
          formatChangePercentage(coreData.previous_period.deposit_amount, coreData.current_period.deposit_amount)
        );

        setSalesPercent(
          formatChangePercentage(salesData.previous_period.total_sales, salesData.current_period.total_sales)
        );

        setWithdrawalsPercent(
          formatChangePercentage(coreData.previous_period.withdrawal_amount, coreData.current_period.withdrawal_amount)
        );
        break;
      case 'lastYear':
        setDepositsPercent(
          formatChangePercentage(coreData.previous_year.deposit_amount, coreData.current_period.deposit_amount)
        );

        setSalesPercent(
          formatChangePercentage(salesData.previous_year.total_sales, salesData.current_period.total_sales)
        );

        setWithdrawalsPercent(
          formatChangePercentage(coreData.previous_year.withdrawal_amount, coreData.current_period.withdrawal_amount)
        );
        break;
      case 'peerGroup':
        setDepositsPercent(
          formatChangePercentage(
            coreData.peer_groups[context.peerGroupId]?.avg_deposit_amount,
            coreData.current_period.deposit_amount
          )
        );

        setSalesPercent(
          formatChangePercentage(
            salesData.peer_groups[context.peerGroupId]?.avg_total_sales,
            salesData.current_period.total_sales
          )
        );

        setWithdrawalsPercent(
          formatChangePercentage(
            coreData.peer_groups[context.peerGroupId]?.avg_withdrawal_amount,
            coreData.current_period.withdrawal_amount
          )
        );
        break;
    }
  };

  return (
    <Container>
      <Container.Item>
        <Card>
          <Card.Header
            title={'Transaction Analysis'}
            actions={[
              <Legend
                inline
                items={[
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                      </svg>
                    ),
                    label: 'This Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#1270FC" />
                      </svg>
                    ),
                    label: 'Previous Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#F5A623" />
                      </svg>
                    ),
                    label: 'Peer Group',
                  },
                ]}
              />,
            ]}
          />
          <Card.Content>
            <Container>
              <Container.Item hCenter size={[6]}>
                <GcvContent type="l1" content="CURRENT vs PREVIOUS PERIOD" />

                <TransactionAnalysisPreviousPeriodRadarChart review={props.review} />
              </Container.Item>
              <Container.Item hCenter size={[6]}>
                <GcvContent type="l1" content="CURRENT vs PEER GROUP*" />

                <TransactionAnalysisPeerGroupRadarChart review={props.review} />
              </Container.Item>

              <Container.Item hCenter>
                <div style={{ fontSize: '12px', color: '#3A3C45', textAlign: 'center' }}>
                  <strong>*Current Peer Group:</strong>{' '}
                  {peerGroups && peerGroups.peer_groups && peerGroups.peer_groups[context.peerGroupId]?.name}
                  &nbsp;&nbsp;
                  <label
                    data-for="currentPeerGroupTooltip"
                    data-tip={
                      peerGroups && peerGroups.peer_groups && peerGroups.peer_groups[context.peerGroupId]?.description
                    }
                  >
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.95 2.75H6.05V3.85H4.95V2.75ZM4.95 4.95H6.05V8.25H4.95V4.95ZM5.5 0C2.464 0 0 2.464 0 5.5C0 8.536 2.464 11 5.5 11C8.536 11 11 8.536 11 5.5C11 2.464 8.536 0 5.5 0ZM5.5 9.9C3.0745 9.9 1.1 7.9255 1.1 5.5C1.1 3.0745 3.0745 1.1 5.5 1.1C7.9255 1.1 9.9 3.0745 9.9 5.5C9.9 7.9255 7.9255 9.9 5.5 9.9Z"
                        fill="#9B9EB1"
                      />
                    </svg>
                    <ReactTooltip id="currentPeerGroupTooltip" place="top" type="dark" effect="solid" delayShow={250} />
                  </label>
                </div>
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header
            title={'Transaction Summary'}
            actions={[
              <Legend
                inline
                items={[
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                      </svg>
                    ),
                    label: 'This Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#6896FB" />
                      </svg>
                    ),
                    label: 'Previous Period',
                  },
                  {
                    icon: (
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ borderRadius: '6px' }}
                      >
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#ffffff" stroke="#000000" />
                      </svg>
                    ),
                    label: 'Previous Year',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#3A3C45" />
                      </svg>
                    ),
                    label: 'Peer Group Avg (current period)',
                  },
                ]}
              />,
            ]}
          />
          <Card.Content>
            <Container>
              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Sales" />
                    <GcvContent type="l1" grey2 content="$000s" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummarySalesDollarsBulletChart review={props.review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Deposits" />
                    <GcvContent type="l1" grey2 content="$000s" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryDepositDollarsBulletChart review={props.review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Deposits Avg" />
                    <GcvContent type="l1" grey2 content="Dollar average" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryDepositsDollarAvgBulletChart review={props.review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Withdrawals" />
                    <GcvContent type="l1" grey2 content="$000s" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryWithdrawalsDollarsBulletChart review={props.review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Withdrawals Avg" />
                    <GcvContent type="l1" grey2 content="Dollar average" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryWithdrawalsDollarAvgBulletChart review={props.review} />
                  </Container.Item>
                </Container>
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header
            title={'Total Sales and Deposits'}
            actions={[
              reviewOptions.length && determineReviewPeriod(props.review) !== 'month' && reviewOptions.length ? (
                <div style={{ marginRight: '1rem' }}>
                  <GcvInputSelectNew
                    label="Month"
                    options={reviewOptions}
                    onChange={onMonthChange}
                    dropWidth={'150px'}
                    labelWidth={'100px'}
                    dropCharLength={20}
                  />
                </div>
              ) : (
                undefined
              ),
              <div style={{ textAlign: 'right', width: '100%', paddingTop: '10px' }}>
                <Legend
                  inline
                  items={[
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                        </svg>
                      ),
                      label: 'Sales',
                    },
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#6896FB" />
                        </svg>
                      ),
                      label: 'Deposits',
                    },
                  ]}
                />
              </div>,
            ]}
          />
          <Card.Content>
            <TotalSalesAndDepositsAreaChart review={filteredReview} />
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header title={'Transaction Analysis'} />
          <Card.Content>
            <Container gutterBottom>
              <Container.Item hCenter size={[4]}>
                <GcvContent type="l1" content="DEPOSITS" />
                <GcvContent type="p1" content={formatMoney(coreData.current_period.deposit_amount)} />
              </Container.Item>
              <Container.Item hCenter size={[4]}>
                <GcvContent type="l1" content="SALES" />
                <GcvContent type="p1" content={formatMoney(salesData.current_period.total_sales)} />
              </Container.Item>
              <Container.Item hCenter size={[4]}>
                <GcvContent type="l1" content="WITHDRAWALS" />
                <GcvContent type="p1" content={formatMoney(coreData.current_period.withdrawal_amount)} />
              </Container.Item>
            </Container>

            <Container>
              <Container.Item>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#f2f4fa',
                    border: '1px solid #ebeef7',
                    boxSizing: 'border-box',
                    borderRadius: '10px 10px 0px 0px',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: 'rgba(0,0,0,0.87)',
                    height: '40px',
                    margin: '-8px',
                  }}
                >
                  <div style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}></div>
                  {transactionAnalysisData[0].columns.map((c, i) => {
                    return (
                      <div
                        key={i}
                        style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}
                      >
                        {c.name}
                      </div>
                    );
                  })}
                </div>
              </Container.Item>

              <div
                style={{
                  borderRight: '1px solid #EBEEF7',
                  borderLeft: '1px solid #EBEEF7',
                  borderBottom: '1px solid #EBEEF7',
                  borderRadius: '0 0 10px 10px',
                  width: '100%',
                  padding: '0 0.5rem',
                }}
              >
                {transactionAnalysisData.map((d, i) => {
                  return (
                    <Container.Item
                      sparse
                      borderBottom={i < transactionAnalysisData.length - 1}
                      bold={d.isSummary}
                      key={i}
                    >
                      <div style={{ display: 'flex', fontSize: '13px' }}>
                        <div style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}>
                          {d.label}
                        </div>
                        {d.columns.map((c, i) => {
                          return (
                            <div
                              key={i}
                              style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}
                            >
                              {c.value}
                              {c.delta ? <>&nbsp;&nbsp;&nbsp;{c.delta}</> : ''}
                            </div>
                          );
                        })}
                      </div>
                    </Container.Item>
                  );
                })}
              </div>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header title={'Account Activity'} />
          <Card.Content>
            <GcvDataTable
              data={accountActivityData ? accountActivityData : []}
              columns={accountActivityColumns}
              defaultSortField={'name'}
              defaultSortAsc={true}
              keyField="id"
              subHeaderAlign="left"
              paginationPerPage={10}
              noDataComponent={<GcvLoading></GcvLoading>}
              pointerOnHover={false}
              highlightOnHover={false}
            />
          </Card.Content>
        </Card>
      </Container.Item>
    </Container>
  );
};

export default ReviewAnalysis;
