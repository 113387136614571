import { Pipe, PipeTransform } from '@angular/core';
import { Notification } from '@gcv/shared';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dynamicNotification' })
export class DynamicNotificationPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(notification: Notification, type: string): string {
    if (type === 'title') {
      switch (notification.type) {
        case 'bank_dispensary_review':
          return `${
            notification.email_data.dispensary_name
              ? notification.email_data.dispensary_name
              : notification.data.dispensary_name
          } is awaiting review`;
        case 'new_deposit':
          return `${notification.email_data.dispensary_name} has a new deposit`;
        case 'new_fincen':
          return `${notification.email_data.dispensary_name} has a new ${
            notification.email_data.report_type ? notification.email_data.report_type : notification.data.report_type
          }`;
        case 'completed_dispensary_review':
          return `You have been approved! You can now start banking!`;
        case 'complete_evs':
          return 'Please verify your identity';
        case 'assign_fincen_report':
          return 'You have been assigned a FinCEN report';
        case 'banking_partner_identified':
          return 'Banking Partner Identified';
        case 'activity_reporting':
          return 'Your report is ready for download';
        case 'new_document_uploaded':
          return 'New Document Uploaded';
        case 'expired_business_license':
          return 'Your business license has expired';
        case 'expired_employee_license':
          return 'Your employee license has expired';
        case 'upcoming_business_license_expiry':
          return 'Your business license is about to expire';
        case 'upcoming_employee_license_expiry':
          return 'Your employee license is about to expire';
        case 'request_document_upload':
          return `${notification.email_data.bank_name} requested a document`;
        case 'new_comment_added_to_requirement':
          return `New Comment Added`;
        case 'sales_ingestion_failure':
          return `There was an issue with your sales upload.  Click for details.`;
        case 'sales_ingestion_partial_success_non_retail':
          return `Your sales upload had some errors. Click for details.`;
        case 'sales_ingestion_partial_success_retail':
          return `Your sales upload had some errors. Click for details.`;
        default:
          return '';
      }
    } else {
      switch (notification.type) {
        case 'bank_dispensary_review':
          return `Submitted On: ${this.datePipe.transform(notification.created_date)}`;
        case 'new_deposit':
          return `Submitted On: ${this.datePipe.transform(
            notification.email_data.deposit_date ? notification.email_data.deposit_date : notification.data.deposit_date
          )}`;
        case 'new_fincen':
          return `Received On: ${this.datePipe.transform(
            notification.email_data.received_date
              ? notification.email_data.received_date
              : notification.data.received_date
          )}`;
        case 'completed_dispensary_review':
          return `Approved On: ${this.datePipe.transform(notification.created_date)}`;
        case 'complete_evs':
          return `Received On: ${this.datePipe.transform(notification.send_date)}`;
        case 'assign_fincen_report':
          return `Received On: ${this.datePipe.transform(notification.send_date)}`;
        case 'banking_partner_identified':
          return `${notification.email_data.bank_name ? notification.email_data.bank_name : notification.data.orgName}`;
        case 'activity_reporting':
          return `Created On: ${this.datePipe.transform(notification.created_date)}`;
        case 'new_document_uploaded':
          return notification.email_data.name ? notification.email_data.name : notification.data.name;
        case 'expired_business_license':
        case 'expired_employee_license':
        case 'upcoming_business_license_expiry':
        case 'upcoming_employee_license_expiry':
          return notification.email_data.license_type ? notification.email_data.license_type : '';
        case 'request_document_upload':
          return `${notification.email_data.requirement_name} on ${this.datePipe.transform(notification.created_date)}`;
        case 'new_comment_added_to_requirement':
          return `${notification.email_data.org_name}`;
        case 'sales_ingestion_partial_success_retail':
          return `Uploaded On: ${this.datePipe.transform(notification.email_data.date_time)}`;
        case 'sales_ingestion_partial_success_non_retail':
          return `Uploaded On: ${this.datePipe.transform(notification.email_data.date_time)}`;
        case 'sales_ingestion_failure':
          return `Uploaded On: ${this.datePipe.transform(notification.email_data.date_time)}`;
        default:
          return '';
      }
    }
  }
}
