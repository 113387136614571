import { IANATimezones } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';
import React, { useEffect, useState } from 'react';

import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '../../../../../common-ui/src/lib/common-ui';
import { useApi } from '../../../hooks';
import calculateTimeRange from '../../../util/calculateDateRange';

import { Report } from '../Components/Report';

interface Props {
  bankId: string;
  userId: string;
  accountList: {
    value: string;
    label: string;
    id: string;
  }[];
  timezone: IANATimezones;
  setExportActivitySuccess: (active: boolean) => void;
  setExportActivityFailure: (active: boolean) => void;
  setResponseModalActive: (active: boolean) => void;
}

export const DepositsReport: React.FC<Props> = props => {
  const apiClient = api();

  const [depositStatus, setDepositStatus] = useState({ value: 'all' });
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [timeSelectType, setTimeSelectType] = useState('created');
  const [timeSelect, setTimeSelect] = useState('today');

  useEffect(() => {
    setSelectedAccounts(props.accountList.map(a => a.value));
  }, [props.accountList]);

  const handleExportDeposits = async () => {
    const timeRange = calculateTimeRange(timeSelect, props.timezone);

    await apiClient.banks
      .getFiDepositsReport(
        props.bankId,
        {
          orgId: props.bankId,
          userId: props.userId,
          reportData: {
            dispensaryIds: selectedAccounts,
            startDate: timeRange.start,
            endDate: timeRange.end,
            status: (depositStatus as any).value,
          },
        },
        () => {}
      )
      .then(() => {
        props.setExportActivitySuccess(true);
      })
      .catch(() => {
        props.setExportActivityFailure(true);
      })
      .finally(() => {
        props.setResponseModalActive(true);
      });
  };

  return (
    <Report>
      <Report.Title size={6} align="left">
        Filters
      </Report.Title>

      <Report.ExportButton size={6} align="right" export={handleExportDeposits} />

      <Report.TimePeriodSelect
        size={6}
        onLabelChange={setTimeSelectType}
        onChange={setTimeSelect}
        selectOptions={[{ value: 'created', label: 'Created' }]}
        labelText="Created"
      />

      <Report.SearchDropdown size={6} onSelect={setSelectedAccounts} selectOptions={props.accountList} />

      <Report.InputSelect
        size={6}
        text="Status"
        onChange={setDepositStatus}
        selectOptions={[
          { value: 'all', label: 'All' },
          { value: 'pending', label: 'Pending' },
          { value: 'approved', label: 'Reconciled' },
          { value: 'under_review', label: 'Under Review' },
        ]}
      />
    </Report>
  );
};
