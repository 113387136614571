import React from 'react';
import DataTable from 'react-data-table-component';
import { Oval } from 'svg-loaders-react';
import { $primary } from '../../util/styleVariables';
import { DataTableStyleOverrides } from './styles';

export const GcvDataTable = props => {
  const highlight = props.highlight != null && props.highlight != undefined ? props.highlight : true;
  const pointer = props.pointer != null && props.pointer != undefined ? props.pointer : true;

  return (
    <>
      <DataTableStyleOverrides />
      <DataTable
        pagination
        responsive={false}
        paginationPerPage={10}
        keyField="id"
        subHeaderAlign="center"
        paginationComponentOptions={{ noRowsPerPage: true }}
        highlightOnHover={highlight}
        pointerOnHover={pointer}
        progressComponent={<Oval height={75} width={75} stroke={$primary} viewBox="0 0 38 38" />}
        progressShowTableHead
        {...props}
      />
    </>
  );
};
