import React from 'react';
import { Route } from 'react-router-dom';

import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';
import { Bank, FincenSarReport, FincenCtrReport, User, DueDiligenceStatus, Dispensary } from '@gcv/shared';
import { RouteObject } from '../../util/types';
import { FincenTabExports, FincenTabManagement, FincenTabReports, CreateReportButton } from './components';
import styled from 'styled-components';
import { $grey1 } from '../../util';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import useQueryString from '../../hooks/useQueryString';
import { GcvButton, GcvZeroState } from '../../lib';
import { Dictionary } from '@ngrx/entity';

//#region styles

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.25px;
  color: ${$grey1};
`;

//#endregion

interface Props {
  bank: Bank;
  emitData: (payload) => void;
  reports: (FincenCtrReport | FincenSarReport)[];
  reportsLoading: boolean;
  sarPeriods: any;
  staff: { [id: string]: User };
  viewStatus: string;
  dispensaries: Dictionary<Dispensary>;
}

export const Fincen: React.FC<Props> = props => {
  const [reportType, setReportType] = useQueryString('report_type', 'CTR');

  const openReports = {
    ctrs: [],
    sars: [],
  };
  const filedReports = {
    ctrs: [],
    sars: [],
  };

  props.reports.forEach(report => {
    if (report.report_type === 'SAR') {
      if (report.exported_on) {
        filedReports.sars.push(report);
      } else {
        openReports.sars.push(report);
      }
    } else if (report.report_type === 'CTR') {
      if (report.exported_on) {
        filedReports.ctrs.push(report);
      } else {
        openReports.ctrs.push(report);
      }
    }
  });

  const Tabs: RouteObject[] = [
    {
      path: `/secure/bank/fincen/management`,
      render: props => <FincenTabManagement {...props} />,
    },
    {
      path: `/secure/bank/fincen/open`,
      render: props => (
        <FincenTabReports
          reportsStatus="open"
          reports={openReports}
          reportsType={reportType}
          dispensaryMap={props.dispensaries}
          bank={props.bank}
          reportsLoading={props.reportsLoading}
          emitData={props.emitData}
          setReportsType={setReportType}
        />
      ),
    },
    {
      path: `/secure/bank/fincen/exports`,
      render: props => <FincenTabExports {...props} />,
    },
    {
      path: `/secure/bank/fincen/filed`,
      render: props => (
        <FincenTabReports
          reportsStatus="filed"
          reports={filedReports}
          reportsType={reportType}
          dispensaryMap={props.dispensaries}
          bank={props.bank}
          reportsLoading={props.reportsLoading}
          emitData={props.emitData}
          setReportsType={setReportType}
        />
      ),
    },
  ];

  const tabRoutes = () => [
    { path: `/secure/bank/fincen/management`, label: `Management`, query: window.location.search },
    { path: `/secure/bank/fincen/open`, label: `Open`, query: window.location.search },
    { path: `/secure/bank/fincen/exports`, label: `Exports`, query: window.location.search },
    { path: `/secure/bank/fincen/filed`, label: `Filed`, query: window.location.search },
  ];

  return (
    <GcvPage
      header={
        <GcvPageHeader
          title={'FinCEN'}
          actions={
            props.bank.due_diligence.due_diligence_status === DueDiligenceStatus.GCV_APPROVED ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CreateReportButton emitData={() => {}} bankId={props.bank.id} />
              </div>
            ) : null
          }
        />
      }
      body={
        props.viewStatus !== 'DefaultState' ? (
          <>
            <GcvZeroState
              headerText="You currently have no FinCEN reports to view"
              dashboardSubText="To begin accepting applications from cannabis businesses please finish setting up your company profile."
              basic={true}
              button={
                <GcvButton
                  secondary={props.viewStatus === 'DueDiligenceInProgressState'}
                  primary={props.viewStatus === 'DueDiligenceZeroState'}
                  onClick={() => props.emitData({ type: 'goToOnboarding' })}
                >
                  {props.viewStatus === 'DueDiligenceZeroState' ? "Let's Go" : 'Continue'}
                </GcvButton>
              }
            ></GcvZeroState>
          </>
        ) : (
          <>
            <div style={{ margin: '1rem 0' }}>
              <GcvTabs routes={tabRoutes()}></GcvTabs>
            </div>

            {Tabs.map(({ path, render }) => {
              return (
                <Route exact path={path} key={path}>
                  {({ match }) =>
                    match != null ? (
                      render({
                        bank: props.bank,
                        emitData: props.emitData,
                        sarPeriods: props.sarPeriods,
                        staff: props.staff,
                        reports: props.reports,
                        reportsType: reportType,
                        setReportsType: setReportType,
                        reportsLoading: props.reportsLoading,
                        dispensaries: props.dispensaries,
                      })
                    ) : (
                      <></>
                    )
                  }
                </Route>
              );
            })}
          </>
        )
      }
    />
  );
};
