import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { Bank, BankInternalTransactionCodesRequest } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';
import { GcvButton, GcvDataTable, GcvLoading, GcvSearchInput, GcvZeroState } from 'libs/react-ui/src/lib';
import { PeerGroupDrawer } from './PeerGroupDrawer';
import { FlexBox } from 'libs/react-ui/src/styles/theme';

interface Props {
  bank: Bank;
}

export const TransactionCodes = (props: Props) => {
  const apiClient = api();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [bankCodes, setBankCodes] = useState<BankInternalTransactionCodesRequest>(
    {} as BankInternalTransactionCodesRequest
  );

  useEffect(() => {
    loadTransactionCodes();
  }, []);

  const loadTransactionCodes = async () => {
    const codes = await apiClient.banks.getBankInternalCodes(props.bank.id, setLoading);
    if (codes && codes.value) {
      setBankCodes(codes.value);
    }
  };

  const tableData = Object.values(bankCodes);

  const columns = [
    {
      name: 'Transaction Name',
      selector: 'transaction_name',
      sortable: true,
      left: true,
    },
    {
      name: 'Transaction Code',
      selector: 'transaction_code',
      sortable: true,
      left: true,
    },
    {
      name: 'Transaction Type',
      selector: 'transaction_type',
      sortable: true,
      left: true,
    },
    {
      name: 'Payment Type',
      selector: 'payment_type',
      sortable: true,
      left: true,
    },
  ];

  return (
    <>
      <FlexBox style={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
        <div>Contact support@greencheckverified.com to add or update internal codes</div>
      </FlexBox>
      {loading ? (
        <FlexBox style={{ justifyContent: 'center', width: '100%' }}>
          <GcvLoading></GcvLoading>
        </FlexBox>
      ) : (
        <GcvDataTable
          data={tableData}
          columns={columns}
          defaultSortField={'transaction_code'}
          defaultSortAsc={true}
          keyField="id"
          highlight={false}
          pointer={false}
          noDataComponent={
            <GcvZeroState
              basic={true}
              headerText={'No codes exist'}
              subText={'Contact support@greencheckverified.com to add or update internal codes'}
            />
          }
          subHeaderAlign="left"
          paginationPerPage={10}
        />
      )}
    </>
  );
};
