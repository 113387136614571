import React from 'react';

import { DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { RadarConfig } from './chart-options';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TransactionDataRadarChart: React.FC<Props> = props => {
  return <GcvChart type="radar" config={chartDataAdapter(props.review)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData) => {
  const coreData = review.data.aggregated_core_transaction_data;

  return transactionDataRadarAdapter({
    current_period: {
      cash_in: coreData.current_period.deposit_amount_cash / 100,
      check_in: coreData.current_period.deposit_amount_check / 100,
      check_out: coreData.current_period.withdrawal_amount_check / 100,
      cash_out: coreData.current_period.withdrawal_amount_cash / 100,
      ach_wire_in: (coreData.current_period.deposit_amount_ach + coreData.current_period.deposit_amount_wire) / 100,
      ach_wire_out:
        (coreData.current_period.withdrawal_amount_ach + coreData.current_period.withdrawal_amount_wire) / 100,
    },
    previous_period: {
      cash_in: coreData.previous_period.deposit_amount_cash / 100,
      check_in: coreData.previous_period.deposit_amount_check / 100,
      check_out: coreData.previous_period.withdrawal_amount_check / 100,
      cash_out: coreData.previous_period.withdrawal_amount_cash / 100,
      ach_wire_in: (coreData.previous_period.deposit_amount_ach + coreData.previous_period.deposit_amount_wire) / 100,
      ach_wire_out:
        (coreData.previous_period.withdrawal_amount_ach + coreData.previous_period.withdrawal_amount_wire) / 100,
    },
  });
};

type TransactionRadarDataType = {
  current_period: {
    cash_in: number;
    check_in: number;
    check_out: number;
    cash_out: number;
    ach_wire_out: number;
    ach_wire_in: number;
  };

  previous_period: {
    cash_in: number;
    check_in: number;
    check_out: number;
    cash_out: number;
    ach_wire_out: number;
    ach_wire_in: number;
  };
};

export const transactionDataRadarAdapter = (data: TransactionRadarDataType): RadarConfig => {
  return {
    categories: [
      {
        category: [
          {
            label: 'Cash In',
          },
          {
            label: 'Check In',
          },
          {
            label: 'Check Out',
          },
          {
            label: 'Cash Out',
          },
          {
            label: 'ACH/Wire Out',
          },
          {
            label: 'ACH/Wire In',
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: 'This Period',
        color: '#00BC66',
        anchorBgColor: '#00BC66',
        data: [
          {
            // cash in
            value: data.current_period.cash_in,
          },
          {
            // check in
            value: data.current_period.check_in,
          },
          {
            // check out
            value: data.current_period.check_out,
          },
          {
            // cash out
            value: data.current_period.cash_out,
          },
          {
            // ach out
            value: data.current_period.ach_wire_out,
          },
          {
            // ach in
            value: data.current_period.ach_wire_in,
          },
        ],
      },
      {
        seriesname: 'Previous Period',
        color: '#6896FB',
        anchorBgColor: '#6896FB',
        data: [
          {
            // cash in
            value: data.previous_period.cash_in,
          },
          {
            // check in
            value: data.previous_period.check_in,
          },
          {
            // check out
            value: data.previous_period.check_out,
          },
          {
            // cash out
            value: data.previous_period.cash_out,
          },
          {
            // ach out
            value: data.previous_period.ach_wire_out,
          },
          {
            // ach in
            value: data.previous_period.ach_wire_in,
          },
        ],
      },
    ],
  };
};
