import * as React from 'react';
import { Button } from './styles';
import { Oval } from 'svg-loaders-react';
import { $white } from '../../util';

export interface ButtonProps {
  pill?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  dangerSecondary?: boolean;
  link?: boolean;
  cancel?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  large?: boolean;
  small?: boolean;
  children?: React.ReactNode;
  style?: { [k: string]: any };
  ref?: any;
  isLoading?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const GcvButton = ({ children, ...props }: ButtonProps) =>
  props.isLoading ? (
    <Button {...props}>
      <span className="buttonInner">
        <Oval style={{ marginRight: '10px' }} height={20} width={20} stroke={$white} viewBox="0 0 40 40" />
        {children}
      </span>
    </Button>
  ) : (
    <Button {...props}>
      <div className="buttonInner">{children}</div>
    </Button>
  );
