import { IANATimezones } from '@gcv/shared';
import { DateTime } from 'luxon';
import calculateTimeRange from '../../../util/calculateDateRange';

export interface Deposit {
  id: string;
  created: string;
  account: string;
  amount: string;
  methodOfTransport: string;
  plannedArrivalDate: string;
  dateArrived: string;
  status: string;
  dispensary_id: string;
  reconciling: Boolean;
}

export enum FilterOptions {
  DateTimeFilter = 'DateTimeFilter',
}

export interface FilterRequest<T> {
  data: T[];
  searchTerms: { [key in FilterOptions]?: { value: any; [key: string]: any } };
}

export const useFilter = (filterRequest, setFilterRequest, filterData) => (
  query: { value: string | string[]; [key: string]: any },
  filterType: FilterOptions
) => {
  const filterSearchTerms = {};
  filterSearchTerms[filterType] = query;

  setFilterRequest({
    data: filterData,
    searchTerms: {
      ...filterRequest.searchTerms,
      ...filterSearchTerms,
    },
  });
};

abstract class Filter<T> {
  protected next: Filter<T>;

  constructor(protected completeRequest: (requestData: {}) => void) {}

  public Next(next: Filter<T>): void {
    this.next = next;
  }

  public abstract Handle(request: FilterRequest<T>): void;
}

export class DateTimeFilter extends Filter<Deposit> {
  timezone;

  constructor(completeRequest: (requestData: {}) => void, timezone: IANATimezones) {
    super(completeRequest);
    timezone = timezone;
  }

  public Handle(request: FilterRequest<Deposit>): void {
    const filterValue = request.searchTerms[FilterOptions.DateTimeFilter];

    if (filterValue) {
      const timeRange = calculateTimeRange(filterValue.value, this.timezone);
      request.data = request.data.filter(
        d =>
          DateTime.fromISO(d[filterValue.timeSelectType])
            .toUTC()
            .toISO() >= timeRange.start &&
          DateTime.fromISO(d[filterValue.timeSelectType])
            .toUTC()
            .toISO() <= timeRange.end
      );
    }

    if (this.next) {
      this.next.Handle(request);
    } else {
      this.completeRequest(request.data);
    }
  }
}
