import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { GcvButton, GcvCard, GcvInputForm } from 'libs/react-ui/src/lib';
import { EditIcon } from 'libs/react-ui/src/icons/EditIcon';
import { Bank } from '@gcv/shared';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { useApi } from 'libs/react-ui/src/hooks';
import { api } from 'libs/react-ui/src/api';

interface Props {
  bank: Bank;
}

export const CannabisProgram: React.FC<Props> = props => {
  const apiClient = api();
  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });
  const { watch } = form;

  const [editDescriptionForm, setEditDescriptionForm] = useState(true);
  const [editPrimaryContactForm, setEditPrimaryContactForm] = useState(true);

  useEffect(() => {
    // edit state must be true on first render in order for rules to work
    // on second render 'useEffect lifecycle' we set it to false
    setEditDescriptionForm(false);
    setEditPrimaryContactForm(false);
  }, []);

  const saveCannabisProgramDetails = async payload => {
    await apiClient.banks.updateBankCannabisProgram(
      props.bank.id,
      {
        description: watch('description'),
        primary_point_of_contact: {
          firstName: watch('firstName'),
          lastName: watch('lastName'),
          title: watch('title'),
          phoneNumber: watch('phoneNumber'),
          emailAddress: watch('emailAddress'),
        },
      },
      () => {}
    );

    setEditDescriptionForm(false);
    setEditPrimaryContactForm(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <GcvCard>
          <GcvCard.Header
            title={'Description'}
            actions={!editDescriptionForm ? <EditIcon onClick={() => setEditDescriptionForm(true)} /> : undefined}
          />
          <GcvCard.Body>
            <GcvInputForm
              {...form}
              name="description"
              label="About Your Institution"
              type="text"
              defaultValue={props.bank.description}
              readonly={!editDescriptionForm}
              rules={{
                required: editDescriptionForm,
              }}
            />

            {editDescriptionForm && (
              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <GcvButton tertiary onClick={() => setEditDescriptionForm(false)}>
                    Cancel
                  </GcvButton>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <GcvButton primary disabled={!formState.isValid} onClick={handleSubmit(saveCannabisProgramDetails)}>
                    Save
                  </GcvButton>
                </Grid>
              </Grid>
            )}
          </GcvCard.Body>
        </GcvCard>
      </Grid>

      <Grid item xs={6}>
        <GcvCard>
          <GcvCard.Header
            title={'Primary Contact Details'}
            actions={!editPrimaryContactForm ? <EditIcon onClick={() => setEditPrimaryContactForm(true)} /> : undefined}
          />
          <GcvCard.Body>
            <GcvInputForm
              {...form}
              name="firstName"
              label="First Name"
              type="text"
              defaultValue={props.bank.primary_point_of_contact.firstName}
              readonly={!editPrimaryContactForm}
              rules={{
                required: editPrimaryContactForm,
              }}
            />

            <GcvInputForm
              {...form}
              name="lastName"
              label="Last Name"
              type="text"
              defaultValue={props.bank.primary_point_of_contact.lastName}
              readonly={!editPrimaryContactForm}
              rules={{
                required: editPrimaryContactForm,
              }}
            />

            <GcvInputForm
              {...form}
              name="title"
              label="Title"
              type="text"
              defaultValue={props.bank.primary_point_of_contact.title}
              readonly={!editPrimaryContactForm}
              rules={{
                required: editPrimaryContactForm,
              }}
            />

            <GcvInputForm
              {...form}
              name="emailAddress"
              label="Email"
              type="text"
              defaultValue={props.bank.primary_point_of_contact.emailAddress}
              readonly={!editPrimaryContactForm}
              rules={{
                required: editPrimaryContactForm,
              }}
            />

            <GcvInputForm
              {...form}
              name="phoneNumber"
              label="Business Phone"
              type="phone"
              defaultValue={props.bank.primary_point_of_contact.phoneNumber}
              readonly={!editPrimaryContactForm}
              rules={{
                required: editPrimaryContactForm,
              }}
            />

            {editPrimaryContactForm && (
              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <GcvButton tertiary onClick={() => setEditPrimaryContactForm(false)}>
                    Cancel
                  </GcvButton>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <GcvButton primary disabled={!formState.isValid} onClick={handleSubmit(saveCannabisProgramDetails)}>
                    Save
                  </GcvButton>
                </Grid>
              </Grid>
            )}
          </GcvCard.Body>
        </GcvCard>
      </Grid>
    </Grid>
  );
};
