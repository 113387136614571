import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { GcvButton } from '../../../lib/GcvButton/GcvButton';
import { GcvTimePeriodDropdown } from '../../../lib/GcvTimePeriodDropdown/GcvTimePeriodDropdown';
import calculateDateRange from '../../../util/calculateDateRange';

import { IANATimezones } from '@gcv/shared';

import styled, { createGlobalStyle } from 'styled-components';
import { $fontFamily } from '../../../util/styleVariables';

type ActivityType = 'deposits' | 'sales' | 'daily-summaries';

interface AccountItemProps {
  isSelected?: boolean;
}

// customize style of react-modal
const customModalStyles = {
  overlay: {
    zIndex: 5,
    background: 'rgba(177, 178, 181, 0.7)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    width: '500px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '3px',
    padding: '38px 45px',
    overflow: 'visible',
  },
};

// add animation to react-modal and colors to floating label input
const ModalStyleOverrides = createGlobalStyle`

    .ReactModal__Content {
        height: unset !important;
        /* overflow-y: hidden !important; */
    }
    .ReactModalPortal > div {
        opacity: 0;
     }
     .ReactModalPortal .ReactModal__Overlay {
         transition: opacity 100ms ease-in-out;
         background: rgba(0, 0, 0, 0.6);
     }
     .ReactModalPortal .ReactModal__Overlay--after-open {
        opacity: 1;
     };
     .ReactModalPortal .ReactModal__Overlay--before-close {
        opacity: 0;
     }
`;

const ExportActivityModalBody = styled.div`
  font-family: ${$fontFamily};
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #16181e;
  }

  h5 {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-top: 25px;
  }

  .button-container {
    text-align: center;
    margin-top: 25px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  margin-top: 2rem;
`;

interface Props {
  active: boolean;
  timezone: IANATimezones;
  toggleModal: () => void;
  onSubmit: (payload: Payload) => void;
}

interface Payload {
  startDate: string;
  endDate: string;
  activityType?: ActivityType;
}

export const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 40px auto;

  label {
    font-weight: bold;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: -0.21875px;
    color: #3a3c45;
    margin-left: 5px;
  }

  input,
  label {
    cursor: pointer;
  }
`;

const modalStyles = {
  ...customModalStyles,
  content: {
    ...customModalStyles.content,
    width: '450px',
    height: 'fit-content',
  },
};

export const ExportActivityModal = (props: Props) => {
  const [timePeriod, setTimePeriod] = useState(null);
  const [activityType, setActivityType] = useState<ActivityType>('sales');
  const [exportInProgress, setExportInProgress] = useState(false);

  useEffect(() => {
    Modal.setAppElement('#modal-root');
  }, []);

  useEffect(() => {
    if (!props.active) {
      // tslint:disable-next-line: no-use-before-declare
      resetFormState();
    }
  }, [props.active]);

  const resetFormState = () => {
    setActivityType('sales');
    setTimePeriod(null);
  };

  const onTimePeriodSelect = (value: any) => {
    setTimePeriod(value);
  };

  const onActivityTypeSelect: React.FormEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;

    if (value === 'sales' || value === 'deposits' || value === 'daily-summaries') {
      setActivityType(value);
    }
  };

  const createPayload = (): Payload => {
    const { start, end } = calculateDateRange(timePeriod.value, props.timezone);
    return {
      startDate: start,
      endDate: end,
      activityType,
    };
  };

  const handleSubmit = () => {
    const payload = createPayload();
    props.onSubmit(payload);
    setExportInProgress(true);
  };

  return (
    <>
      <ModalStyleOverrides />
      <Modal style={modalStyles} isOpen={props.active} onRequestClose={props.toggleModal}>
        <ExportActivityModalBody>
          <h2>Export Activity</h2>
          <h5>Please select the activity you'd like to export</h5>
          <div style={{ width: '250px' }}>
            <GcvTimePeriodDropdown emitData={onTimePeriodSelect} />
          </div>

          <RadioButtons>
            <RadioGroup>
              <input
                id="sales"
                type="radio"
                value="sales"
                onChange={onActivityTypeSelect}
                checked={activityType === 'sales'}
              />
              <label htmlFor="sales">Sales</label>
            </RadioGroup>
            <RadioGroup>
              <input
                id="deposits"
                type="radio"
                value="deposits"
                onChange={onActivityTypeSelect}
                checked={activityType === 'deposits'}
              />
              <label htmlFor="deposits">Deposits</label>
            </RadioGroup>
            <RadioGroup>
              <input
                id="daily-summaries"
                type="radio"
                value="daily-summaries"
                onChange={onActivityTypeSelect}
                checked={activityType === 'daily-summaries'}
              />
              <label htmlFor="daily-summaries">Daily Summaries</label>
            </RadioGroup>
          </RadioButtons>
          <ButtonContainer>
            <GcvButton tertiary onClick={props.toggleModal}>
              Cancel
            </GcvButton>
            <GcvButton primary disabled={!timePeriod || exportInProgress} onClick={handleSubmit}>
              Export
            </GcvButton>
          </ButtonContainer>
        </ExportActivityModalBody>
      </Modal>
    </>
  );
};
