import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

import * as uuidv4 from 'uuid/v4';
import * as _ from 'underscore';
import { DateTime, Interval } from 'luxon';
import { LoggerService } from '../logger/logger.service';

/*
    TODO: many of these functions in this service can probably be replaced by js libraries like underscore and lodash
*/

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private logger: LoggerService) {}

  gcvKey() {
    return uuidv4().toLowerCase(); // TODO return a base36 representation of the uuid
  }

  getTotalHackSalesDateRanges() {
    const startDateUTC = DateTime.fromISO('2017-12-31'); // TODO this is a hacky util just to get past August demos.
    const endDateUTC = DateTime.local().plus({ days: 1 });
    let indexDateUTC = startDateUTC;
    const weekRanges = [];
    while (indexDateUTC < endDateUTC) {
      weekRanges.push({
        startDate: indexDateUTC.toISODate(),
        endDate: indexDateUTC.plus({ days: 7 }).toISODate(),
      });
      indexDateUTC = indexDateUTC.plus({ days: 7 });
    }
    return weekRanges;
  }

  filterOutEmptyProperties<T>(obj: T): T {
    const filteredObj = _.pick(obj, (value: any) => {
      const keep = value !== null && value !== undefined && value !== '';
      return keep;
    });
    return filteredObj;
  }
  b64EncodeUnicode(str) {
    // from: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode(parseInt('0x' + p1, 16));
      })
    );
  }

  b64DecodeUnicode(str) {
    // from: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  // money is represented internally as integer cents
  toMoneyString(amount: number): string {
    const adjusted = (amount / 100).toFixed(2);
    return adjusted.toString();
  }

  getLocalFromUtc(utcIso) {
    const utcDateTime = DateTime.fromISO(utcIso);
    const localDateTime = utcDateTime.toLocal();
    const localDateTimeIso = localDateTime.toISO();
    return localDateTimeIso;
  }

  getDateTimeRange(dateTimeRangeSelection: string) {
    let timeRange = null;
    if (dateTimeRangeSelection === 'today') {
      timeRange = this.getUtcDateTimeRangeToday();
    } else if (dateTimeRangeSelection === 'yesterday') {
      timeRange = this.getUtcDateTimeRangeYesterday();
    } else if (dateTimeRangeSelection === 'thisWeek') {
      timeRange = this.getUtcDateTimeRangeThisWeek();
    } else if (dateTimeRangeSelection === 'thisMonth') {
      timeRange = this.getUtcDateTimeRangeThisMonth();
    } else if (dateTimeRangeSelection === 'thisQuarter') {
      timeRange = this.getUtcDateTimeRangeThisQuarter();
    } else if (dateTimeRangeSelection === 'thisYear') {
      timeRange = this.getUtcDateTimeRangeThisYear();
    } else if (dateTimeRangeSelection === 'lastWeek') {
      timeRange = this.getUtcDateTimeRangeLastWeek();
    } else if (dateTimeRangeSelection === 'lastMonth') {
      timeRange = this.getUtcDateTimeRangeLastMonth();
    } else if (dateTimeRangeSelection === 'lastQuarter') {
      timeRange = this.getUtcDateTimeRangeLastQuarter();
    } else if (dateTimeRangeSelection === 'lastYear') {
      timeRange = this.getUtcDateTimeRangeLastYear();
    } else if (dateTimeRangeSelection === 'last30Days') {
      timeRange = this.getUtcDateTimeRangeLastThirtyDays();
    } else if (this.isCustom(dateTimeRangeSelection)) {
      timeRange = this.getCustomTimeRange(dateTimeRangeSelection);
    } else {
      return;
    }
    return { start: timeRange.start, end: timeRange.end };
  }

  getChartGrouping(timePeriod) {
    const dateTimeRange = timePeriod;
    if (
      ['today', 'yesterday', 'thisWeek', 'thisMonth', 'lastWeek', 'lastMonth', 'last30Days'].includes(dateTimeRange)
    ) {
      return 'day';
    } else if (['thisQuarter', 'thisYear', 'lastQuarter', 'lastYear'].includes(dateTimeRange)) {
      return 'month';
    } else if (this.isCustom(timePeriod)) {
      const timeRange = this.getCustomTimeRange(timePeriod);
      const start = DateTime.fromISO(timeRange.start);
      const end = DateTime.fromISO(timeRange.end);
      const length = Interval.fromDateTimes(start, end).length('days');
      if (length > 60) {
        return 'month';
      } else {
        return 'day';
      }
    }
  }

  getLocalDateTimeRange(timePeriod) {
    let dateTimeRangeSelection = timePeriod;

    if (!dateTimeRangeSelection) {
      dateTimeRangeSelection = 'Last 30 days';
    }

    const dateTimeRange = this.getDateTimeRange(dateTimeRangeSelection);
    const start = this.getLocalFromUtc(dateTimeRange.start);
    const end = this.getLocalFromUtc(dateTimeRange.end);

    return { start, end };
  }

  getGroupMap(groupBy, startDate, endDate): object {
    if (groupBy === 'year') {
      return this.getGroupByYearKeys(startDate, endDate);
    } else if (groupBy === 'month') {
      return this.getGroupByMonthKeys(startDate, endDate);
      /*} else if (groupBy = 'week') {
        return this.getGroupByWeekMap(startDate, endDate);      TODO */
    } else if (groupBy === 'day') {
      return this.getGroupByDayKeys(startDate, endDate);
    } else {
      throw new Error(`unsupported groupBy: ${groupBy}`);
    }
  }

  getGroupByMonthKeys(startDate, endDate): object {
    const startKey = startDate.substring(0, 7);
    const endKey = endDate.substring(0, 7);
    const arrayYears = this.getArrayOfYears(startDate, endDate);
    const keys = [];
    arrayYears.forEach(year => {
      ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].forEach(month => {
        const key = `${year}-${month}`;
        if (startKey <= key && key <= endKey) {
          keys.push(key);
        }
      });
    });
    return keys;
  }

  getGroupByDayKeys(startDate, endDate) {
    const startKey = startDate.substring(0, 10);
    const endKey = endDate.substring(0, 10);
    const arrayYears = this.getArrayOfYears(startDate, endDate);
    const keys = [];
    arrayYears.forEach(year => {
      ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].forEach(month => {
        const numDaysInMonth = this.getNumDaysInMonth(parseInt(year, 10), parseInt(month, 10));
        for (let day = 1; day <= numDaysInMonth; day++) {
          const strDay = day < 10 ? `0${day}` : day.toString();
          const key = `${year}-${month}-${strDay}`;
          if (startKey <= key && key <= endKey) {
            keys.push(key);
          }
        }
      });
    });
    return keys;
  }

  getArrayOfYears(startDate, endDate) {
    const startYear = startDate.substring(0, 4);
    const endYear = endDate.substring(0, 4);
    if (startYear === endYear) {
      return [startYear];
    }

    const intStartYear = parseInt(startYear, 10);
    const intEndYear = parseInt(endYear, 10);
    const years = [];
    for (let year = intStartYear; year <= intEndYear; year++) {
      years.push(year.toString());
    }
    return years;
  }

  // pass in month number: Jan=1, Feb=2, etc
  getNumDaysInMonth(year, month): number {
    return [31, year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
  }

  getGroupByYearKeys(startDate, endDate): object {
    const keys = [];
    const arrayYears = this.getArrayOfYears(startDate, endDate);
    arrayYears.forEach(year => {
      keys.push(year);
    });
    return keys;
  }

  // returns the given local day in UTC ISO with hour/minute/second set to 00:00:00
  getStartOfDayUtcIso(localDayIso) {
    const localDateTime = DateTime.fromISO(localDayIso); // BUG is EST, should be EDT
    const localDateTimeStartOfDay = localDateTime.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const utcDateTime = localDateTimeStartOfDay.toUTC();
    const utcDateTimeIso = utcDateTime.toISO();
    return utcDateTimeIso;
  }

  // returns the given local day in UTC ISO with hour/minute/second set to 23:59:59
  getEndOfDayUtcIso(localDayIso) {
    const localDateTime = DateTime.fromISO(localDayIso);
    const localDateTimeEndOfDay = localDateTime.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    const utcDateTime = localDateTimeEndOfDay.toUTC();
    const utcDateTimeIso = utcDateTime.toISO();
    return utcDateTimeIso;
  }

  getUtcDateTimeRangeToday() {
    const localDateTime = DateTime.local();
    const localDateTimeIso = localDateTime.toISO();
    const start = this.getStartOfDayUtcIso(localDateTimeIso);
    const end = this.getEndOfDayUtcIso(localDateTimeIso);
    return { start, end };
  }

  getUtcDateTimeRangeYesterday() {
    const localDateTime = DateTime.local();
    const yesterdayDateTime = localDateTime.minus({ days: 1 });
    const yesterdayIso = yesterdayDateTime.toISO();
    const start = this.getStartOfDayUtcIso(yesterdayIso);
    const end = this.getEndOfDayUtcIso(yesterdayIso);
    return { start, end };
  }

  getUtcDateTimeRangeLastWeek() {
    const localDateTime = DateTime.local();
    const localDateTimeLastWeek = localDateTime.minus({ days: 7 });
    return this.getUtcDateTimeRangeForWeek(localDateTimeLastWeek);
  }

  getUtcDateTimeRangeThisWeek() {
    const localDateTime = DateTime.local();
    return this.getUtcDateTimeRangeForWeek(localDateTime);
  }

  getUtcDateTimeRangeForWeek(dateTime) {
    const daysSinceMonday = dateTime.weekday - 1;
    const localStartOfLastWeekDateTime = dateTime.minus({
      days: daysSinceMonday + 1,
    }); // target time span starts last sunday
    const localEndOfLastWeekDateTime = localStartOfLastWeekDateTime.plus({
      days: 6,
    });
    const start = this.getStartOfDayUtcIso(localStartOfLastWeekDateTime);
    const end = this.getEndOfDayUtcIso(localEndOfLastWeekDateTime);
    return { start, end };
  }

  getUtcDateTimeRangeLastMonth() {
    const localDateTime = DateTime.local();
    const localDateTimeLastMonth = localDateTime.minus({ months: 1 });
    return this.getUtcDateTimeRangeForMonth(localDateTimeLastMonth);
  }

  getUtcDateTimeRangeThisMonth() {
    const localDateTime = DateTime.local();
    return this.getUtcDateTimeRangeForMonth(localDateTime);
  }

  getUtcDateTimeRangeForMonth(dateTime) {
    const localFirstDayOfLastMonth = dateTime.set({ day: 1 });
    const localLastDayOfLastMonth = dateTime.set({ day: dateTime.daysInMonth });
    const start = this.getStartOfDayUtcIso(localFirstDayOfLastMonth);
    const end = this.getEndOfDayUtcIso(localLastDayOfLastMonth);
    return { start, end };
  }

  getUtcDateTimeRangeLastQuarter() {
    const localDateTime = DateTime.local();
    const localDateTime3MonthsAgo = localDateTime.minus({ months: 3 });
    return this.getUtcDateTimeRangeForQuarter(localDateTime3MonthsAgo);
  }

  getUtcDateTimeRangeThisQuarter() {
    const localDateTime = DateTime.local();
    return this.getUtcDateTimeRangeForQuarter(localDateTime);
  }

  getUtcDateTimeRangeForQuarter(dateTime) {
    const month = dateTime.get('month');
    if ([1, 2, 3].includes(month)) {
      const localFirstDayOfLastQuarter = dateTime.set({ month: 1, day: 1 });
      const localLastDayOfLastQuarter = dateTime.set({ month: 3, day: 31 });
      const start = this.getStartOfDayUtcIso(localFirstDayOfLastQuarter);
      const end = this.getEndOfDayUtcIso(localLastDayOfLastQuarter);
      return { start, end };
    } else if ([4, 5, 6].includes(month)) {
      const localFirstDayOfLastQuarter = dateTime.set({ month: 4, day: 1 });
      const localLastDayOfLastQuarter = dateTime.set({ month: 6, day: 30 });
      const start = this.getStartOfDayUtcIso(localFirstDayOfLastQuarter);
      const end = this.getEndOfDayUtcIso(localLastDayOfLastQuarter);
      return { start, end };
    } else if ([7, 8, 9].includes(month)) {
      const localFirstDayOfLastQuarter = dateTime.set({ month: 7, day: 1 });
      const localLastDayOfLastQuarter = dateTime.set({ month: 9, day: 30 });
      const start = this.getStartOfDayUtcIso(localFirstDayOfLastQuarter);
      const end = this.getEndOfDayUtcIso(localLastDayOfLastQuarter);
      return { start, end };
    } else if ([10, 11, 12].includes(month)) {
      const localFirstDayOfLastQuarter = dateTime.set({ month: 10, day: 1 });
      const localLastDayOfLastQuarter = dateTime.set({ month: 12, day: 31 });
      const start = this.getStartOfDayUtcIso(localFirstDayOfLastQuarter);
      const end = this.getEndOfDayUtcIso(localLastDayOfLastQuarter);
      return { start, end };
    }
  }

  getUtcDateTimeRangeLastYear() {
    const localDateTime = DateTime.local();
    const localLastYearDateTime = localDateTime.minus({ years: 1 });
    return this.getUtcDateTimeRangeForYear(localLastYearDateTime);
  }

  getUtcDateTimeRangeThisYear() {
    const localDateTime = DateTime.local();
    return this.getUtcDateTimeRangeForYear(localDateTime);
  }

  getUtcDateTimeRangeForYear(dateTime) {
    const localFirstOfLastYearIso = dateTime.set({ month: 1, day: 1 });
    const localEndOfLastYearIso = dateTime.set({ month: 12, day: 31 });
    const start = this.getStartOfDayUtcIso(localFirstOfLastYearIso);
    const end = this.getEndOfDayUtcIso(localEndOfLastYearIso);
    return { start, end };
  }

  getUtcDateTimeRangeYearToDate() {
    const localDateTime = DateTime.local();
    const localDateTimeIso = localDateTime.toISO();
    const firstOfYearDateTime = localDateTime.set({ month: 1, day: 1 });
    const start = this.getStartOfDayUtcIso(firstOfYearDateTime);
    const end = this.getEndOfDayUtcIso(localDateTimeIso);
    return { start, end };
  }

  getLocalDateTimeRangeYearToDate() {
    const localDateTime = DateTime.local();
    const localFirstOfYearDateTime = localDateTime.set({ month: 1, day: 1 });
    const start = localFirstOfYearDateTime.toISO();
    const end = localDateTime.toISO();
    return { start, end };
  }

  getCustomTimeRange(timePeriod) {
    const values = timePeriod.split(' - ');
    const start = new Date(values[0]).toISOString();
    const end = new Date(values[1]).toISOString();
    return { start, end };
  }

  getUtcDateTimeRangeLastThirtyDays() {
    const localDateTime = DateTime.local();
    const localMinusThirty = localDateTime.minus({ days: 30 });
    const start = this.getStartOfDayUtcIso(localMinusThirty.toISO());
    const end = this.getEndOfDayUtcIso(localDateTime.toISO());
    return { start, end };
  }

  getLocalFirstOfYearIso() {
    const todayDateTime = DateTime.local();
    const firstOfYearDateTime = todayDateTime.set({ month: 1, day: 1 }); // keep the local year
    const firstOfYearIso = firstOfYearDateTime.toISO();
    return firstOfYearIso;
  }

  getLocalTodayIso() {
    const todayDateTime = DateTime.local();
    const todayIso = todayDateTime.toISO();
    return todayIso;
  }

  logControlTree(parent: string, control: AbstractControl) {
    this.logger.debug('FormComponent.logControl: START');
    this.logControl(parent, control);
    this.logger.debug('FormComponent.logControl: END');
  }

  private logControl(parent: string, control: AbstractControl) {
    if (control instanceof FormControl) {
      this.logger.debug(`${parent} > FormControl`);
    } else if (control instanceof FormArray) {
      this.logger.debug(`${parent} > FormArray`);
    } else if (control instanceof FormGroup) {
      this.logger.debug(`${parent} > FormGroup`);
      const keys = _.keys((control as FormGroup).controls);
      keys.forEach(key => {
        this.logControl(`${parent} > FormGroup [${key}] `, (control as FormGroup).controls[key]);
      });
    }
  }

  launchIntoFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  replacer() {
    const objects = [];
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        const found = objects.some(existing => {
          return existing === value;
        });
        if (found) {
          return '[Circular: ' + key + ']';
        }
        objects.push(value);
      }
      return value;
    };
  }

  plugValuesIntoString(searchPrefix: string, templateStr: string, substitutionMap: object) {
    for (const key of _.keys(substitutionMap)) {
      const searchStr = searchPrefix ? `${searchPrefix}${key}` : key;
      templateStr = templateStr.replace(`${searchStr}`, substitutionMap[key]);
    }
    return templateStr;
  }

  // replace an object in an array using the matchField to find the object to replace
  // TODO should use underscore.js or lodash instead of this code
  replaceobjectInList(list: object[], o: object, matchField) {
    for (let i = 0; i < list.length; i++) {
      if (list[i][matchField] === o[matchField]) {
        list[i] = o;
      }
    }
  }

  // loads the script at the given url and then executes the callback when the script has finished loading
  loadScript(url, callback, self, args) {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (script['readyState']) {
      // old IE
      script['onreadystatechange'] = () => {
        if (script['readyState'] === 'loaded' || script['readyState'] === 'complete') {
          script['onreadystatechange'] = null;
          callback(self, args);
        }
      };
    } else {
      // other browsers
      script.onload = () => {
        callback(self, args);
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  public deleteCookie(name) {
    // delete the cookie by setting its expiration date to the past
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  public deleteAllCookies() {
    // TODO use the underscore.js library or scan/map/etc to get away from for loops
    const cookies: string[] = document.cookie.split(';');
    for (const cookie of cookies) {
      this.deleteCookie(cookie);
    }
  }

  public getFingerprint(): object {
    const navigator = this.getNavigatorInfo();
    const mimeTypes = this.getMimeTypes();
    const plugins = this.getPlugins();
    return { navigator, mimeTypes, plugins };
  }

  public propertyExists(obj: object, propertyChain: string[]) {
    if (!obj) {
      return false;
    }
    let targetProperty = obj;
    for (const propertyName of propertyChain) {
      if (targetProperty[propertyName] !== undefined && targetProperty[propertyName] !== null) {
        targetProperty = targetProperty[propertyName];
      } else {
        return false;
      }
    }
    return true;
  }

  // returns true if the given property exists and has the given value, false otherwse
  public propertyExistsWithValue(obj: object, propertyChain: string[], value) {
    if (!obj) {
      return false;
    }
    let targetProperty = obj;
    for (const propertyName of propertyChain) {
      if (targetProperty[propertyName] !== undefined && targetProperty[propertyName] !== null) {
        targetProperty = targetProperty[propertyName];
      } else {
        return false;
      }
    }
    return targetProperty === value;
  }

  public getNavigatorInfo(): object {
    const navigatorInfo: object = {
      doNotTrack: window.navigator['doNotTrack'],
      product: window.navigator.product,
      appCodeName: window.navigator.appCodeName,
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform,
      appVersion: window.navigator.appVersion,
      appName: window.navigator.appName,
      vendorSub: window.navigator.vendorSub,
      vendor: window.navigator.vendor,
      productSub: window.navigator.productSub,
      cookieEnabled: window.navigator.cookieEnabled,
      language: window.navigator.language,
    };
    return navigatorInfo;
  }

  public getMimeTypes(): string[] {
    const mimeTypes: string[] = [];
    for (const property in window.navigator.mimeTypes) {
      if (window.navigator.plugins.hasOwnProperty(property)) {
        const mimeType = window.navigator.mimeTypes[property];
        if (mimeType['type']) {
          mimeTypes.push(mimeType['type']);
        }
      }
    }
    return mimeTypes;
  }

  public getPlugins(): string[] {
    const plugins: string[] = [];
    for (const property in window.navigator.plugins) {
      if (window.navigator.plugins.hasOwnProperty(property)) {
        const plugin = window.navigator.plugins[property];
        if (plugin.filename) {
          plugins.push(plugin.name);
        }
      }
    }
    return plugins;
  }

  public getNewTimeFilter(timePeriod: string): string {
    if (this.isCustom(timePeriod)) {
      return timePeriod;
    } else {
      const newFilter = timePeriod
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
          return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, '');
      return newFilter;
    }
  }

  isCustom(timePeriod: string) {
    if (timePeriod && timePeriod.includes('-')) {
      return true;
    }
  }
}
