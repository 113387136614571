import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';
import { VerticalCenter } from '../../styles/theme';
import { Bank, DueDiligenceStatus } from '@gcv/shared';
import { RouteObject } from '../../util/types';
import { General } from './components/General';
import { CannabisProgram } from './components/CannabisProgram';
import { DueDiligence } from './components/DueDiligence';
import { RegulatoryReporting } from './components/RegulatoryReporting';
import { Transportation } from './components/Transportation';
import styled from 'styled-components';
import { $grey1 } from '../../util';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { GcvButton, GcvZeroState } from '../../lib';

//#region styles

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.25px;
  color: ${$grey1};
`;

//#endregion

interface Props {
  bank: Bank;
  emitData: (type: string, updateStatus?: boolean) => void;
  viewStatus: string;
}

export const CompanyProfile: React.FC<Props> = props => {
  const Tabs: RouteObject[] = [
    {
      path: '/secure/bank/company/cannabis-program',
      render: props => <CannabisProgram {...props} />,
    },
    {
      path: '/secure/bank/company/due-diligence',
      render: props => <DueDiligence {...props} />,
    },
    {
      path: '/secure/bank/company/general',
      render: props => <General {...props} />,
    },
    {
      path: '/secure/bank/company/regulatory-reporting',
      render: props => <RegulatoryReporting {...props} />,
    },
    {
      path: '/secure/bank/company/transportation',
      render: props => <Transportation {...props} />,
    },
  ];

  const tabRoutes = [
    { path: `/secure/bank/company/general`, label: `General` },
    { path: `/secure/bank/company/cannabis-program`, label: `Cannabis Program` },
    { path: `/secure/bank/company/regulatory-reporting`, label: `Regulatory Reporting` },
    { path: `/secure/bank/company/due-diligence`, label: `Due Diligence` },
    { path: `/secure/bank/company/transportation`, label: `Transportation` },
  ];

  return (
    <GcvPage
      header={
        <Header style={{ justifyContent: 'start' }}>
          <VerticalCenter>
            <Title>Company Profile</Title>
          </VerticalCenter>
        </Header>
      }
      body={
        props.viewStatus !== 'DefaultState' ? (
          <>
            <GcvZeroState
              headerText="Configure Your Program"
              dashboardSubText="To begin accepting applications from cannabis businesses please finish setting up your company profile."
              bankDD={true}
              button={
                <GcvButton
                  secondary={props.viewStatus === 'DueDiligenceInProgressState'}
                  primary={props.viewStatus === 'DueDiligenceZeroState'}
                  onClick={() => props.emitData('goToOnboarding')}
                >
                  {props.viewStatus === 'DueDiligenceZeroState' ? "Let's Go" : 'Continue'}
                </GcvButton>
              }
            ></GcvZeroState>
          </>
        ) : (
          <>
            <div style={{ margin: '1rem 0' }}>
              <GcvTabs routes={tabRoutes}></GcvTabs>
            </div>

            {Tabs.map(({ path, render }) => {
              return (
                <Route exact path={path} key={path}>
                  {({ match }) => (match != null ? render({ bank: props.bank, emitData: props.emitData }) : <></>)}
                </Route>
              );
            })}
          </>
        )
      }
    />
  );
};
