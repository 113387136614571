import { Environment } from './env-interface';
import { GreenCheckAwsEnvironmentName } from '@gcv/shared';

export const environment: Environment = {
  production: false,
  amplify: {
    aws_project_region: 'us-west-1',
    aws_cognito_identity_pool_id: 'us-west-1:2b7e33b2-fcac-4e8a-a19c-c450982803c6',
    aws_cognito_region: 'us-west-1',
    aws_user_pools_id: 'us-west-1_xtRWQ9PLN',
    aws_user_pools_web_client_id: '5absam8u9p8ffjb6sf1lvine6l',
  },
  gcvConfig: {
    launchDarklyClientSideId: '5ba1164b0a11eb3ed9f9514d',
    webSocketUrl: 'wss://4vymdgbb46.execute-api.us-east-1.amazonaws.com/Prod',
  },
  env: GreenCheckAwsEnvironmentName.DR,
  basePath: 'https://drapi.greencheckverified.com',
  storageConfig: {
    salesFile: 'dr-new-data-file-bucket',
    document: 'dr-gcv-company-document-store',
    regulatoryGuide: '',
  },
};
