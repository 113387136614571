import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { GcvDrawer, GcvUserInputContainer } from '../../../lib';
import {
  DrawerHeader,
  DrawerContainer,
  TitleContainer,
  GreyText,
  DrawerBody,
  FakeTableRow,
  RowItem,
  WarningsExceptionsContainer,
  WarningExceptionLabel,
  ExceptionsList,
  HeaderText,
} from './styles';
import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import { GcvSaleStatusIndicator } from '../../../lib/GcvSaleStatusIndicator/GcvSaleStatusIndicator';
import { GcvDataTable } from '../../../lib';
import { formatMoneyWithNegatives, formatNotAvailable, formatISOToDateAtTime, getDispensaryType } from '../../../util/';
import { PosType, Sale, posDisplayNames } from '@gcv/shared';
import { GcvCollapsibleSection } from 'libs/react-ui/src/lib/GcvCollapsableSection/GcvCollapsibleSection';

interface Props {
  open?: boolean;
  data?: Sale;
  onClose: () => void;
}

const medicalOrAdultMap = {
  medical: 'Medical',
  adult_use: 'Adult',
};

const posTypeMap = Object.keys(posDisplayNames).reduce((acc, posType) => {
  acc[posType] = posDisplayNames[posType] + ' Integration';
  return acc;
}, {});

posTypeMap['GcvFaker_GcvCsv'] = 'Manual Upload';
posTypeMap['GcvCsv_Quickbooks'] = 'Manual Upload';

const calculateCash = (total: number, debit: number, credit: number, check: number) => {
  return total - (debit + credit + check);
};

export const PurchaseRecordDrawer = (props: Props) => {
  const { open, onClose, data } = props;
  const isRetail = data && getDispensaryType(data.origin as PosType) === 'retail';

  const columns = useMemo(
    () => [
      {
        name: 'Product',
        selector: 'product_name',
        sortable: true,
        center: true,
        grow: 4,
        format: row => <GcvUserInputContainer string={row.product_name} length={40} />,
      },
      {
        name: 'Qty',
        selector: 'num_units',
        sortable: true,
        center: true,
      },
      {
        name: 'Price/Unit',
        selector: 'price_per_unit',
        sortable: true,
        center: true,
        format: row => formatMoneyWithNegatives(row.price_per_unit ? row.price_per_unit : 0),
      },
      {
        name: 'Total Price',
        selector: '',
        format: row => {
          if (row.price_per_unit && row.num_units) {
            return formatMoneyWithNegatives(Math.round(row.price_per_unit * row.num_units));
          } else {
            return '';
          }
        },
      },
    ],
    []
  );

  const renderWarnings = () => {
    return data.compliance_warnings.map(warning => (
      <li data-cy="warning" key={warning}>
        {warning}
      </li>
    ));
  };

  const renderExceptions = () => {
    return data.compliance_exceptions.map(exception => (
      <li data-cy="exception" key={exception}>
        {exception}
      </li>
    ));
  };

  const hasWarnings = data && data.compliance_warnings && data.compliance_warnings.length > 0;

  const hasExceptions = data && data.compliance_exceptions && data.compliance_exceptions.length > 0;

  const transactionDetailsRetail = [
    {
      title: 'Customer ID',
      text: data && data.customer_id ? data.customer_id.split('.')[1].slice(0, 6) : 'Not Provided',
    },
    { title: 'Employee ID', text: formatNotAvailable(data ? data.employee_id : 'Not Provided') },
    {
      title: 'Payment Type',
      text:
        data && data.payment_type
          ? data.payment_type.charAt(0).toUpperCase() + data.payment_type.substr(1).toLowerCase()
          : 'Not Provided',
    },
    {
      title: 'Sale Type',
      text:
        data && data.medical_or_adult && medicalOrAdultMap[data.medical_or_adult]
          ? medicalOrAdultMap[data.medical_or_adult]
          : 'Not Provided',
    },
    {
      title: 'Source',
      text: data && data.origin ? posTypeMap[data.origin] : 'Not Provided',
    },
  ];

  const paymentDetails = [];
  if (data && data.cash_paid) {
    paymentDetails.push({
      title: 'Cash Paid',
      text: formatNotAvailable(
        formatMoneyWithNegatives(calculateCash(data.total_paid, data.debit_paid, data.credit_paid, data.check_paid))
      ),
    });
  }
  if (data && data.debit_paid) {
    paymentDetails.push({
      title: 'Debit Paid',
      text: formatNotAvailable(formatMoneyWithNegatives(data.debit_paid)),
    });
  }
  if (data && data.credit_paid) {
    paymentDetails.push({
      title: 'Credit Paid',
      text: formatNotAvailable(formatMoneyWithNegatives(data.credit_paid)),
    });
  }
  if (data && data.check_paid) {
    paymentDetails.push({
      title: 'Check Paid',
      text: formatNotAvailable(formatMoneyWithNegatives(data.check_paid)),
    });
  }
  if (data && data.origin && !isRetail) {
    paymentDetails.push({
      title: 'Source',
      text: posTypeMap[data.origin] ? posTypeMap[data.origin] : 'Not Provided',
    });
  }

  const invoiceDetailsNonRetail = [
    {
      title: 'Invoice Number',
      text: data && data.invoice_id ? data.invoice_id : 'Not Provided',
    },
    {
      title: 'Invoice Total',
      text:
        data && data.invoice_total
          ? formatNotAvailable(formatMoneyWithNegatives(data.invoice_total))
          : data && data.total_paid
          ? formatNotAvailable(formatMoneyWithNegatives(data.total_paid))
          : 'Not Provided',
    },
    {
      title: 'Open Balance',
      text:
        data && data.invoice_open_balance
          ? formatNotAvailable(formatMoneyWithNegatives(data.invoice_open_balance))
          : data && data.invoice_total && data.total_paid
          ? formatNotAvailable(formatMoneyWithNegatives(data.invoice_total - data.total_paid))
          : 'Not Provided',
    },
    {
      title: 'Invoice Date',
      text: data && data.receipt_date ? DateTime.fromISO(data.receipt_date).toLocaleString() : 'Not Provided',
    },
    {
      title: 'Customer',
      text: data && data.company_name ? data.company_name : 'Not Provided',
    },
    {
      title: 'Memo/Description',
      text: data && data.invoice_memo ? data.invoice_memo : 'Not Provided',
    },
  ];

  return (
    <GcvDrawer open={open} onClose={onClose} style={{ width: '645px' }}>
      <DrawerContainer data-cy="purchase-record-drawer" style={{ overflowX: 'hidden' }}>
        {data ? (
          <>
            <DrawerHeader>
              <TitleContainer>
                <BackButtonIcon onClick={onClose} style={{ cursor: 'pointer' }} />
                <div
                  style={{
                    marginLeft: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <HeaderText>{isRetail ? 'Transaction Record' : 'Payment Record'}</HeaderText>
                    <GreyText data-cy="receipt-date" style={{ display: 'inline', marginLeft: '1rem' }}>
                      {isRetail
                        ? formatISOToDateAtTime(data.receipt_date)
                        : DateTime.fromISO(
                            data.invoice_payment_date ? data.invoice_payment_date : data.receipt_date
                          ).toLocaleString(DateTime.DATE_SHORT)}
                    </GreyText>
                  </div>
                  <GreyText>{data.sale_id}</GreyText>
                </div>
              </TitleContainer>
              <GcvSaleStatusIndicator status={data.status} />
            </DrawerHeader>

            <DrawerBody style={{ overflowX: 'hidden' }}>
              <RowItem
                style={{
                  paddingLeft: '44px',
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '27px',
                }}
              >
                <span>{isRetail ? 'Transaction Total' : 'Payment Amount'}</span>
                <div>{formatNotAvailable(formatMoneyWithNegatives(data.total_paid))}</div>
              </RowItem>

              {data && data.products_sold && data.products_sold.length ? (
                <>
                  <GcvDataTable
                    data={data.products_sold}
                    columns={columns}
                    subHeaderAlign="left"
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    pagination={false}
                    keyField="id"
                    responsive={false}
                    highlightOnHover={false}
                    pointerOnHover={false}
                  />

                  <div className="rdt_TableRow">
                    <FakeTableRow>
                      <span data-cy="tax-label">Sales Tax</span>
                      <span data-cy="tax">{formatNotAvailable(formatMoneyWithNegatives(data.tax))}</span>
                    </FakeTableRow>
                  </div>

                  <div className="rdt_TableRow" style={{ borderBottom: '1px solid #dbe0eb' }}>
                    <FakeTableRow>
                      <span style={{ fontWeight: 'bold' }} data-cy="total-label">
                        Total
                      </span>
                      <span style={{ fontSize: 16, fontWeight: 'bold' }} data-cy="total">
                        {formatNotAvailable(formatMoneyWithNegatives(data.total_paid))}
                      </span>
                    </FakeTableRow>
                  </div>
                </>
              ) : (
                <div style={{ padding: '20px', background: '#ebecef', textAlign: 'center' }}>
                  Product information was not provided for this {isRetail ? 'transaction' : 'payment'}
                </div>
              )}

              {isRetail ? (
                <>
                  <GcvCollapsibleSection label={'Transaction Details'}>
                    {transactionDetailsRetail.map(item => {
                      return (
                        <RowItem key={item.title}>
                          <span data-cy={`${item.title}`}>{item.title}</span>
                          <div data-cy={`${item.title}-value`}>{item.text}</div>
                        </RowItem>
                      );
                    })}
                  </GcvCollapsibleSection>
                </>
              ) : (
                <>
                  <GcvCollapsibleSection label={'Invoice Details'}>
                    {invoiceDetailsNonRetail.map(item => {
                      return (
                        <RowItem key={item.title}>
                          <span data-cy={`${item.title}`}>{item.title}</span>
                          <div data-cy={`${item.title}-value`}>{item.text}</div>
                        </RowItem>
                      );
                    })}
                  </GcvCollapsibleSection>
                </>
              )}
              <GcvCollapsibleSection label={'Payment Details'}>
                {paymentDetails.map(item => {
                  return (
                    <RowItem key={item.title}>
                      <span data-cy={`${item.title}`}>{item.title}</span>
                      <div data-cy={`${item.title}-value`}>{item.text}</div>
                    </RowItem>
                  );
                })}
              </GcvCollapsibleSection>

              {hasWarnings ? (
                <WarningsExceptionsContainer>
                  <WarningExceptionLabel type="warning" data-cy="warnings-label">
                    Warnings
                  </WarningExceptionLabel>
                  <ExceptionsList>{renderWarnings()}</ExceptionsList>
                </WarningsExceptionsContainer>
              ) : null}

              {hasExceptions ? (
                <WarningsExceptionsContainer>
                  <WarningExceptionLabel type="red_flag" data-cy="exceptions-label">
                    Exceptions
                  </WarningExceptionLabel>
                  <ExceptionsList>{renderExceptions()}</ExceptionsList>
                </WarningsExceptionsContainer>
              ) : null}
            </DrawerBody>
          </>
        ) : null}
      </DrawerContainer>
    </GcvDrawer>
  );
};
