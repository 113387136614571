import React, { useEffect, useState } from 'react';
import { GcvLoading } from 'libs/react-ui/src/lib';
import styled from 'styled-components';
import { formatMissingDash, formatDateOrDash } from 'libs/react-ui/src/util/formatHelpers';
import { DispensaryAccountReviewWithData, Dispensary, DueDiligenceDocumentStatus } from '@gcv/shared';
import { FlexBox, VerticalCenter, HorizontalCenter } from 'libs/react-ui/src/styles/theme';
import { ForwardArrowIcon } from 'libs/react-ui/src/icons/ForwardArrowIcon';
import { api } from 'libs/react-ui/src/api';
import { DateTime } from 'luxon';
import { Card } from './Card';
import { Container } from './Container';
import { getDispensaryLicenseName } from 'libs/react-ui/src/util';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';

const Pagination = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #a5a8ba;
`;

interface Props {
  review: DispensaryAccountReviewWithData;
  id: string;
  dispensaries: { [id: string]: Dispensary };
}

const ReviewAccountInfo = (props: Props) => {
  if (!props.review || !props.dispensaries || !props.dispensaries[props.review.dispensary_id]) {
    return <GcvLoading></GcvLoading>;
  }

  const apiClient = api();
  const [eventsData, setEventsData] = useState([]);
  const [licenseIndex, setLicenseIndex] = useState(0);
  const [eventsLoading, setEventsLoading] = useState(false);

  const dispensary = props.dispensaries[props.review.dispensary_id];
  const licenses = Object.values(dispensary.due_diligence.requirements.business_licenses.documents).filter(
    l => l.status !== DueDiligenceDocumentStatus.ARCHIVED
  );
  const currentLicense = licenses[licenseIndex];

  const updateIndex = (positive: boolean) => {
    if (positive) {
      if (licenseIndex < licenses.length - 1) {
        setLicenseIndex(licenseIndex + 1);
      }
    } else {
      if (licenseIndex > 0) {
        setLicenseIndex(licenseIndex - 1);
      }
    }
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    try {
      const timeline = await apiClient.accountMonitoring.getDispensaryTimeline(
        props.review.bank_id,
        props.review.dispensary_id,
        setEventsLoading
      );
      setEventsData(timeline);
    } catch {
      setEventsLoading(false);
    }
  };

  const upcomingEvents = eventsData.filter(event => event.date > DateTime.utc().toISO());
  const pastEvents = eventsData.filter(event => event.date <= DateTime.utc().toISO());

  const renderEvents = events =>
    events
      .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
      .map((event, index) => (
        <div
          style={{ padding: '.75rem 0', borderTop: !index ? '0' : `1px solid #E0E5F0`, minHeight: '2rem' }}
          key={event.date + index}
        >
          <FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
            <FlexBox style={{ flexDirection: 'column' }}>
              <GcvContent type="h3" content={event.eventTitle ? event.eventTitle : ''}></GcvContent>
              <GcvContent type="l1" grey2={true} content={event.eventSubtitle ? event.eventSubtitle : ''}></GcvContent>
            </FlexBox>
            <div style={{ paddingRight: '.25rem' }}>
              <GcvContent type="l1" grey2={true} content={formatDateOrDash(event.date)}></GcvContent>
            </div>
          </FlexBox>
        </div>
      ));

  const DashedLine = props => {
    return (
      <FlexBox style={{ justifyContent: 'center', ...props.style }}>
        <VerticalCenter style={{ width: '40%' }}>
          <div style={{ borderTop: '1px dashed #E0E5F0', margin: '0 8px' }} />
        </VerticalCenter>
        <GcvContent content={props.content} type="h3" />
        <VerticalCenter style={{ width: '40%' }}>
          <div style={{ borderTop: '1px dashed #E0E5F0', margin: '0 8px' }} />
        </VerticalCenter>
      </FlexBox>
    );
  };

  return (
    <Container>
      <Container.Item size={[12, 12, 6, 6, 6]}>
        <Card>
          <Card.Header title={'Account Information'} />
          <Card.Content>
            <GcvContent type="l1" grey2={true} content="Name"></GcvContent>
            <GcvContent type="p1" content={formatMissingDash(dispensary.name)}></GcvContent>
            <br />
            <GcvContent type="l1" grey2={true} content="EIN"></GcvContent>
            <GcvContent type="p1" content={formatMissingDash(dispensary.ein)}></GcvContent>
            <br />
            <GcvContent type="l1" grey2={true} content="Type"></GcvContent>
            <GcvContent
              type="p1"
              content={formatMissingDash(dispensary.business_type === 'wholesale' ? 'Wholesale' : 'Retail')}
            ></GcvContent>
            <br />
            <GcvContent type="l1" grey2={true} content="Status"></GcvContent>
            <GcvContent type="p1" content={'Status'}></GcvContent>
          </Card.Content>
        </Card>
        <div style={{ marginTop: '18px' }}>
          <Card>
            <Card.Header
              title={`License${licenses.length > 1 ? 's' : ''}`}
              actions={
                licenses.length > 1 && [
                  <FlexBox>
                    <ForwardArrowIcon
                      style={{ transform: 'rotate(180deg)' }}
                      onClick={() => updateIndex(false)}
                    ></ForwardArrowIcon>
                    <VerticalCenter style={{ width: 'auto' }}>
                      <Pagination>
                        {licenseIndex + 1} of {licenses.length}
                      </Pagination>
                    </VerticalCenter>
                    <ForwardArrowIcon onClick={() => updateIndex(true)}></ForwardArrowIcon>
                  </FlexBox>,
                ]
              }
            />
            <Card.Content>
              <GcvContent type="l1" grey2={true} content="License Type"></GcvContent>
              <GcvContent
                type="p1"
                content={formatMissingDash(getDispensaryLicenseName(currentLicense.license_name, dispensary.state))}
              ></GcvContent>
              <br />
              <GcvContent type="l1" grey2={true} content="License Number"></GcvContent>
              <GcvContent type="p1" content={formatMissingDash(currentLicense.license_number)}></GcvContent>
              <br />
              <GcvContent type="l1" grey2={true} content="License Expiration Date"></GcvContent>
              <GcvContent type="p1" content={formatDateOrDash(currentLicense.expiration_date)}></GcvContent>
            </Card.Content>
          </Card>
        </div>
      </Container.Item>
      <Container.Item size={[12, 12, 6, 6, 6]}>
        <Card height={558}>
          <Card.Header title={'Green Check Timeline'} />
          <Card.Content>
            {eventsLoading ? (
              <GcvLoading></GcvLoading>
            ) : (
              <div style={{ maxHeight: '480px', overflowY: 'auto', borderTop: '1px solid #E0E5F0' }}>
                <DashedLine content="Upcoming" style={{ marginTop: '1rem' }}></DashedLine>
                {upcomingEvents.length ? (
                  renderEvents(upcomingEvents)
                ) : (
                  <HorizontalCenter>
                    <GcvContent
                      type="l1"
                      grey2={true}
                      content="There are no upcoming events"
                      style={{ margin: '2rem auto' }}
                    />
                  </HorizontalCenter>
                )}
                <DashedLine content="Past"></DashedLine>
                {renderEvents(pastEvents)}
              </div>
            )}
          </Card.Content>
        </Card>
      </Container.Item>
    </Container>
  );
};

export default ReviewAccountInfo;
