import React from 'react';
import { GcvTimePeriodDropdown, GcvZeroState, GcvCard, GcvLoading, GcvContent } from 'libs/react-ui/src/lib';
import { DateTime } from 'luxon';
import calculateTimeRange from '../../../util/calculateDateRange';
import { Dispensary, DailySummary, Deposit, MaxDeposit } from '@gcv/shared';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, Text, Label } from 'recharts';
import { $primary, $grey1 } from 'libs/react-ui/src/util/styleVariables';
import { ChartContainer, SummaryLineContainer, ToolTipContainer } from './styles';
import { cropInput, formatMoney, formatPercentage } from 'libs/react-ui/src/util/formatHelpers';
import { TimePeriod } from '../Dashboard.page';
import { FlexBox } from 'libs/react-ui/src/styles/theme';

interface Props {
  dispensary: Dispensary;
  deposits: Deposit[];
  setTimePeriod: (timeRange: TimePeriod) => any;
  timePeriod: TimePeriod;
  summaries: DailySummary[];
  loadingData: boolean;
  maxDeposit: MaxDeposit;
}

export const Sales = (props: Props) => {
  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, props.dispensary.iana_timezone);
    props.setTimePeriod({ val: results.value, timeRange });
  };

  const { chartData, depositTotal, salesData, purchaseData, chartTimeScale } = filterAndFormatData(
    props.summaries,
    props.deposits,
    props.timePeriod
  );

  const CustomizedXTick = props => {
    const { x, y, payload } = props;
    return (
      <Text
        x={x}
        y={y}
        textAnchor="end"
        verticalAnchor="end"
        angle={-45}
        width={200}
        fontSize={14}
        fontWeight={600}
        color={$grey1}
        style={{ fontSize: 12, fontWeight: 500, fontFamily: 'lato', color: $grey1 }}
      >
        {chartData.length >= 34 ? null : cropInput(payload.value, 25)}
      </Text>
    );
  };

  const CustomizedYTick = props => {
    const { x, y, payload } = props;
    return (
      <Text
        textAnchor="end"
        verticalAnchor="end"
        x={x}
        y={y}
        style={{ fontSize: 12, fontWeight: 500, fontFamily: 'lato', color: $grey1 }}
      >
        {formatMoney(payload.value)}
      </Text>
    );
  };

  const getSalesBreakdown = (
    verified: number,
    unverified: number,
    unchecked: number,
    total: number,
    money?: boolean
  ) => (
    <>
      <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
        <FlexBox>
          <GcvContent type="p1" content={money ? formatMoney(verified) : verified} />
          <GcvContent
            type="p1"
            grey2={true}
            style={{ marginLeft: '.2rem' }}
            content={`(${formatPercentage(verified, total)})`}
          />
        </FlexBox>
        <GcvContent type="l1" grey2={true} content={`Verified`} />
      </SummaryLineContainer>
      <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
        <FlexBox>
          <GcvContent type="p1" content={money ? formatMoney(unverified) : unverified} />
          <GcvContent
            type="p1"
            grey2={true}
            style={{ marginLeft: '.2rem' }}
            content={`(${formatPercentage(unverified, total)})`}
          />
        </FlexBox>
        <GcvContent type="l1" grey2={true} content={`Unverified`} />
      </SummaryLineContainer>
      <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
        <FlexBox>
          <GcvContent type="p1" content={money ? formatMoney(unchecked) : unchecked} />
          <GcvContent
            type="p1"
            grey2={true}
            style={{ marginLeft: '.2rem' }}
            content={`(${formatPercentage(unchecked, total)})`}
          />
        </FlexBox>
        <GcvContent type="l1" grey2={true} content={`Unchecked`} />
      </SummaryLineContainer>
      <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
        <GcvContent type="h3" content={money ? formatMoney(total) : total} />
        <GcvContent type="l1" grey2={true} content={`Total`} />
      </SummaryLineContainer>
    </>
  );

  const CustomTooltip = props => {
    const { active, payload, label } = props;
    if (active && payload && payload[0]) {
      const { total, uncheckable_total, unverified_total, verified_total } = payload[0].payload as ChartData;
      return (
        <ToolTipContainer>
          <GcvContent
            type="h2"
            content={
              chartTimeScale === 'days'
                ? DateTime.fromFormat(label, 'MM/dd/yyyy').toLocaleString(DateTime.DATE_MED)
                : DateTime.fromFormat(label, 'MM/yyyy').toLocaleString(DateTime.DATE_MED)
            }
          />
          {getSalesBreakdown(verified_total, unverified_total, uncheckable_total, total, true)}
        </ToolTipContainer>
      );
    }

    return null;
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <GcvCard style={{ width: '75%', margin: '0 1rem 0 0', height: 'fit-content' }}>
          <GcvCard.Header
            title={
              <div style={{ marginBottom: '.5rem', fontSize: '18px' }}>
                <GcvContent type="h2" content={'Total sales'} />
                <GcvContent
                  type="l1"
                  grey2={true}
                  style={{ marginTop: '.5rem' }}
                  content={`${DateTime.fromISO(props.timePeriod.timeRange.start).toLocaleString(DateTime.DATE_MED)}${
                    DateTime.fromISO(props.timePeriod.timeRange.start).toLocaleString(DateTime.DATE_MED) !==
                    DateTime.fromISO(props.timePeriod.timeRange.end).toLocaleString(DateTime.DATE_MED)
                      ? ' - ' + DateTime.fromISO(props.timePeriod.timeRange.end).toLocaleString(DateTime.DATE_MED)
                      : ''
                  }`}
                />
              </div>
            }
            actions={
              <div style={{ padding: '1rem' }}>
                <GcvTimePeriodDropdown
                  emitData={handleTimeChange}
                  labelText={'Time Period'}
                  newStyle={true}
                  defaultValueDrop={props.timePeriod.val}
                ></GcvTimePeriodDropdown>
              </div>
            }
            style={{ borderBottom: 'none' }}
          ></GcvCard.Header>
          <GcvCard.Body>
            {props.loadingData ? (
              <GcvLoading></GcvLoading>
            ) : chartData && props.summaries.length ? (
              <ChartContainer>
                <ResponsiveContainer width="100%" height={500}>
                  <BarChart data={chartData} margin={{ top: 15, right: 30, left: 20, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tick={<CustomizedXTick />} height={70} interval={0} minTickGap={1}></XAxis>
                    <YAxis width={60} tick={<CustomizedYTick />} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="total" fill={$primary} />
                  </BarChart>
                </ResponsiveContainer>
              </ChartContainer>
            ) : (
              <GcvZeroState basic={true} headerText="No Sales available for Selected Time Period"></GcvZeroState>
            )}
          </GcvCard.Body>
        </GcvCard>
        <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
          <GcvCard>
            <GcvCard.Header
              title={
                <div style={{ marginBottom: '.5rem', fontSize: '18px' }}>
                  <GcvContent type="h2" content={'Summary'} />
                  <GcvContent
                    type="l1"
                    grey2={true}
                    style={{ marginTop: '.5rem' }}
                    content={`${DateTime.fromISO(props.timePeriod.timeRange.start).toLocaleString(DateTime.DATE_MED)}${
                      DateTime.fromISO(props.timePeriod.timeRange.start).toLocaleString(DateTime.DATE_MED) !==
                      DateTime.fromISO(props.timePeriod.timeRange.end).toLocaleString(DateTime.DATE_MED)
                        ? ' - ' + DateTime.fromISO(props.timePeriod.timeRange.end).toLocaleString(DateTime.DATE_MED)
                        : ''
                    }`}
                  />
                </div>
              }
              style={{ borderBottom: 'none', padding: '0' }}
            ></GcvCard.Header>
            <GcvCard.Body style={{ padding: '0' }}>
              {props.loadingData ? (
                <GcvLoading></GcvLoading>
              ) : (
                <>
                  <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                    <GcvContent type="h3" content={'Sales'} />
                  </SummaryLineContainer>
                  {getSalesBreakdown(
                    salesData.verified,
                    salesData.unverified,
                    salesData.uncheckable,
                    salesData.total,
                    true
                  )}

                  <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                    <GcvContent type="h3" style={{ marginTop: '.5rem' }} content={'Purchases'} />
                  </SummaryLineContainer>
                  {getSalesBreakdown(
                    purchaseData.bankable_records,
                    purchaseData.unbankable_records,
                    purchaseData.uncheckable_records,
                    purchaseData.total
                  )}

                  <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                    <GcvContent style={{ marginTop: '.5rem' }} type="h3" content={'Deposits'} />
                  </SummaryLineContainer>
                  <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
                    <GcvContent type="h3" content={formatMoney(depositTotal)} />
                    <GcvContent type="l1" grey2={true} content={`Total`} />
                  </SummaryLineContainer>
                </>
              )}
            </GcvCard.Body>
          </GcvCard>
          <div style={{ marginTop: '.5em' }}>
            <GcvCard>
              <GcvCard.Header
                title={<div style={{ fontSize: '18px' }}>Available for Deposit</div>}
                style={{ borderBottom: 'none', padding: '0', marginBottom: '.5rem' }}
              ></GcvCard.Header>
              <GcvCard.Body style={{ padding: '0' }}>
                {props.loadingData ? (
                  <GcvLoading></GcvLoading>
                ) : (
                  <GcvContent
                    type="h1"
                    green={true}
                    content={formatMoney(props.maxDeposit ? props.maxDeposit.max_deposit : 0)}
                  ></GcvContent>
                )}
              </GcvCard.Body>
            </GcvCard>
          </div>
        </div>
      </div>
    </>
  );
};

interface ChartData {
  total: number;
  name: string;
  unverified_total: number;
  verified_total: number;
  uncheckable_total: number;
}

const filterAndFormatData = (summaries: DailySummary[], deposits, timePeriod) => {
  const i1 = DateTime.fromISO(timePeriod.timeRange.start);
  const i2 = DateTime.fromISO(timePeriod.timeRange.end);
  const chartTimeScale: 'days' | 'months' = i2.diff(i1, 'days').toObject().days > 32 ? 'months' : 'days';
  const accumulator =
    chartTimeScale === 'days'
      ? getDaysInTimeRange(timePeriod.timeRange.start, timePeriod.timeRange.end)
      : getMonthsInTimeRange(timePeriod.timeRange.start, timePeriod.timeRange.end);

  const chartData = summaries
    ? Object.values(
        summaries.reduce(
          (acc, summary) => {
            const date = DateTime.fromISO(summary.date).toFormat(chartTimeScale === 'days' ? 'MM/dd/yyyy' : 'MM/yyyy');
            acc[date] = {
              date: date,
              total:
                (acc[date] ? acc[date].total : 0) +
                summary.unverified_total +
                summary.verified_total +
                summary.uncheckable_total,
              unverified_total: (acc[date] ? acc[date].unverified_total : 0) + summary.unverified_total,
              verified_total: (acc[date] ? acc[date].verified_total : 0) + summary.verified_total,
              uncheckable_total: (acc[date] ? acc[date].uncheckable_total : 0) + summary.uncheckable_total,
            };
            return acc;
          },
          { ...accumulator }
        )
      )
    : [];

  const depositTotal: number = deposits
    ? deposits.reduce((acc, dep) => {
        if (
          DateTime.fromISO(dep.date_created)
            .toUTC()
            .toISO() >= timePeriod.timeRange.start &&
          DateTime.fromISO(dep.date_created)
            .toUTC()
            .toISO() <= timePeriod.timeRange.end
        ) {
          if (typeof dep.final_deposit === 'number') {
            acc += dep.final_deposit;
          }
        }
        return acc;
      }, 0)
    : 0;

  const salesData: { verified?: number; unverified?: number; uncheckable?: number; total?: number } = summaries
    ? summaries.reduce(
        (acc, summary) => {
          acc.uncheckable += summary.uncheckable_total;
          acc.verified += summary.verified_total;
          acc.unverified += summary.unverified_total;
          acc.total += summary.uncheckable_total + summary.verified_total + summary.unverified_total;
          return acc;
        },
        { verified: 0, unverified: 0, uncheckable: 0, total: 0 }
      )
    : {};

  const purchaseData: {
    bankable_records?: number;
    unbankable_records?: number;
    uncheckable_records?: number;
    total?: number;
  } = summaries
    ? summaries.reduce(
        (acc, summary) => {
          acc.bankable_records += summary.bankable_records;
          acc.unbankable_records += summary.unbankable_records;
          acc.uncheckable_records += summary.uncheckable_records;
          acc.total += summary.uncheckable_records + summary.unbankable_records + summary.bankable_records;
          return acc;
        },
        { bankable_records: 0, unbankable_records: 0, uncheckable_records: 0, total: 0 }
      )
    : {};

  return { salesData, depositTotal, purchaseData, chartData, chartTimeScale };
};

const getDaysInTimeRange = (
  start: string,
  end: string
): {
  [day: string]: {
    date: string;
    total: number;
    unverified_total: number;
    verified_total: number;
    uncheckable_total: number;
  };
} => {
  const startDate = DateTime.fromISO(start).startOf('day');
  const endDate = DateTime.fromISO(end).startOf('day');
  let rollingDate = startDate;
  const days = endDate.diff(startDate, 'days').days;
  const keys = {};
  for (let i = 0; i <= days; i++) {
    const key = rollingDate.toFormat('MM/dd/yyyy');
    keys[key] = {
      date: key,
      total: 0,
      unverified_total: 0,
      verified_total: 0,
      uncheckable_total: 0,
    };
    rollingDate = rollingDate.plus({ days: 1 });
  }
  return keys;
};

const getMonthsInTimeRange = (
  start: string,
  end: string
): {
  [month: string]: {
    date: string;
    total: number;
    unverified_total: number;
    verified_total: number;
    uncheckable_total: number;
  };
} => {
  const startDate = DateTime.fromISO(start).startOf('day');
  const endDate = DateTime.fromISO(end).startOf('day');
  let rollingDate = startDate;
  const months = endDate.diff(startDate, 'months').months;
  const keys = {};
  for (let i = 0; i <= months; i++) {
    const key = rollingDate.toFormat('MM/yyyy');
    keys[key] = {
      date: key,
      total: 0,
      unverified_total: 0,
      verified_total: 0,
      uncheckable_total: 0,
    };
    rollingDate = rollingDate.plus({ months: 1 });
  }
  return keys;
};
