import { IANATimezones } from '@gcv/shared';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';

export const formatISOToDateAtTime = ISODate => {
  return (
    DateTime.fromISO(ISODate).toLocaleString(DateTime.DATE_MED) +
    ' at ' +
    DateTime.fromISO(ISODate).toLocaleString(DateTime.TIME_SIMPLE)
  );
};

// returns the given local day in UTC ISO with hour/minute/second set to 00:00:00
const getStartOfDayUtcIso = (localDayIso: string, timezone: IANATimezones) => {
  const localDateTimeStartOfDay = moment
    .tz(localDayIso, timezone)
    .startOf('D')
    .format();
  const utcDateTime = DateTime.fromISO(localDateTimeStartOfDay).toUTC();
  const utcDateTimeIso = utcDateTime.toISO();
  return utcDateTimeIso;
};

// returns the given local day in UTC ISO with hour/minute/second set to 23:59:59
const getEndOfDayUtcIso = (localDayIso: string, timezone: IANATimezones) => {
  const localDateTimeEndOfDay = moment
    .tz(localDayIso, timezone)
    .endOf('D')
    .format();
  const utcDateTime = DateTime.fromISO(localDateTimeEndOfDay).toUTC();
  const utcDateTimeIso = utcDateTime.toISO();
  return utcDateTimeIso;
};

export const getUtcDateTimeRangeForDay = (localDayIso: any, timezone: IANATimezones) => {
  return { start: getStartOfDayUtcIso(localDayIso, timezone), end: getEndOfDayUtcIso(localDayIso, timezone) };
};

const getUtcDateTimeRangeForWeek = (dateTime: DateTime, timezone: IANATimezones) => {
  const daysSinceMonday = dateTime.weekday - 1;
  const localStartOfLastWeekDateTime = dateTime.minus({
    days: daysSinceMonday + 1,
  }); // target time span starts last sunday
  const localEndOfLastWeekDateTime = localStartOfLastWeekDateTime.plus({
    days: 6,
  });
  const start = getStartOfDayUtcIso(localStartOfLastWeekDateTime.toISO(), timezone);
  const end = getEndOfDayUtcIso(localEndOfLastWeekDateTime.toISO(), timezone);
  return { start, end };
};

export const getUtcDateTimeRangeThisWeek = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  return getUtcDateTimeRangeForWeek(localDateTime, timezone);
};

export const getUtcDateTimeRangeToday = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const localDateTimeIso = localDateTime.toISO();
  const start = getStartOfDayUtcIso(localDateTimeIso, timezone);
  const end = getEndOfDayUtcIso(localDateTimeIso, timezone);
  return { start, end };
};

export const getUtcDateTimeRangeYesterday = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const yesterdayDateTime = localDateTime.minus({ days: 1 });
  const yesterdayIso = yesterdayDateTime.toISO();
  const start = getStartOfDayUtcIso(yesterdayIso, timezone);
  const end = getEndOfDayUtcIso(yesterdayIso, timezone);
  return { start, end };
};

const getUtcDateTimeRangeForMonth = (dateTime: DateTime, timezone: IANATimezones) => {
  const localFirstDayOfLastMonth = dateTime.set({ day: 1 });
  const localLastDayOfLastMonth = dateTime.set({ day: dateTime.daysInMonth });
  const start = getStartOfDayUtcIso(localFirstDayOfLastMonth.toISO(), timezone);
  const end = getEndOfDayUtcIso(localLastDayOfLastMonth.toISO(), timezone);
  return { start, end };
};

export const getUtcDateTimeRangeThisMonth = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  return getUtcDateTimeRangeForMonth(localDateTime, timezone);
};

const getUtcDateTimeRangeForQuarter = (dateTime: DateTime, timezone: IANATimezones) => {
  const month = dateTime.get('month');
  if ([1, 2, 3].includes(month)) {
    const localFirstDayOfLastQuarter = dateTime.set({ month: 1, day: 1 });
    const localLastDayOfLastQuarter = dateTime.set({ month: 3, day: 31 });
    const start = getStartOfDayUtcIso(localFirstDayOfLastQuarter.toISO(), timezone);
    const end = getEndOfDayUtcIso(localLastDayOfLastQuarter.toISO(), timezone);
    return { start, end };
  } else if ([4, 5, 6].includes(month)) {
    const localFirstDayOfLastQuarter = dateTime.set({ month: 4, day: 1 });
    const localLastDayOfLastQuarter = dateTime.set({ month: 6, day: 30 });
    const start = getStartOfDayUtcIso(localFirstDayOfLastQuarter.toISO(), timezone);
    const end = getEndOfDayUtcIso(localLastDayOfLastQuarter.toISO(), timezone);
    return { start, end };
  } else if ([7, 8, 9].includes(month)) {
    const localFirstDayOfLastQuarter = dateTime.set({ month: 7, day: 1 });
    const localLastDayOfLastQuarter = dateTime.set({ month: 9, day: 30 });
    const start = getStartOfDayUtcIso(localFirstDayOfLastQuarter.toISO(), timezone);
    const end = getEndOfDayUtcIso(localLastDayOfLastQuarter.toISO(), timezone);
    return { start, end };
  } else {
    const localFirstDayOfLastQuarter = dateTime.set({ month: 10, day: 1 });
    const localLastDayOfLastQuarter = dateTime.set({ month: 12, day: 31 });
    const start = getStartOfDayUtcIso(localFirstDayOfLastQuarter.toISO(), timezone);
    const end = getEndOfDayUtcIso(localLastDayOfLastQuarter.toISO(), timezone);
    return { start, end };
  }
};

export const getUtcDateTimeRangeThisQuarter = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  return getUtcDateTimeRangeForQuarter(localDateTime, timezone);
};

const getUtcDateTimeRangeForYear = (dateTime: DateTime, timezone: IANATimezones) => {
  const localFirstOfLastYearIso = dateTime.set({ month: 1, day: 1 });
  const localEndOfLastYearIso = dateTime.set({ month: 12, day: 31 });
  const start = getStartOfDayUtcIso(localFirstOfLastYearIso.toISO(), timezone);
  const end = getEndOfDayUtcIso(localEndOfLastYearIso.toISO(), timezone);
  return { start, end };
};

export const getUtcDateTimeRangeThisYear = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  return getUtcDateTimeRangeForYear(localDateTime, timezone);
};

export const getUtcDateTimeRangeLastWeek = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const localDateTimeLastWeek = localDateTime.minus({ days: 7 });
  return getUtcDateTimeRangeForWeek(localDateTimeLastWeek, timezone);
};

export const getUtcDateTimeRangeLastMonth = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const localDateTimeLastMonth = localDateTime.minus({ months: 1 });
  return getUtcDateTimeRangeForMonth(localDateTimeLastMonth, timezone);
};

export const getUtcDateTimeRangeLastQuarter = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const localDateTime3MonthsAgo = localDateTime.minus({ months: 3 });
  return getUtcDateTimeRangeForQuarter(localDateTime3MonthsAgo, timezone);
};

export const getUtcDateTimeRangeLastYear = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const localLastYearDateTime = localDateTime.minus({ years: 1 });
  return getUtcDateTimeRangeForYear(localLastYearDateTime, timezone);
};

export const getUtcDateTimeRangeLastThirtyDays = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const localMinusThirty = localDateTime.minus({ days: 30 });
  const start = getStartOfDayUtcIso(localMinusThirty.toISO(), timezone);
  const end = getEndOfDayUtcIso(localDateTime.toISO(), timezone);
  return { start, end };
};

export const getUtcDateTimeRangeLastSevenDays = (timezone: IANATimezones) => {
  const momentTime = moment.tz(timezone).format();
  const localDateTime = DateTime.fromISO(momentTime, { zone: timezone });
  const localMinusSeven = localDateTime.minus({ days: 7 });
  const start = getStartOfDayUtcIso(localMinusSeven.toISO(), timezone);
  const end = getEndOfDayUtcIso(localDateTime.toISO(), timezone);
  return { start, end };
};

export const getCustomTimeRange = timePeriod => {
  const values = timePeriod.split(' - ');
  const start = new Date(values[0]).toISOString();
  const end = new Date(values[1]).toISOString();
  return { start, end };
};

export const getUtcDateTimeRangeAll = () => {
  const start = DateTime.fromMillis(0)
    .toUTC()
    .toISO();
  const end = DateTime.utc()
    .endOf('day')
    .toISO();
  return { start, end };
};
