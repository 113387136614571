import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';

import { DrawerText, DrawerImage } from '../styles';

interface Props {
  form: any;
}

export const BioTrackForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, you will need to request the information below from BioTrack.
      </DrawerText>
      <DrawerText>
        Send the following email to{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:api@biotrackthc.com">
          api@biotrackthc.com
        </a>
        :
      </DrawerText>
      <DrawerText>
        <b>
          Hello,
          <br />
          <br />I need some assistance. I would like to integrate our system with the Green Check system. Please provide
          me with the necessary information about our database that the Green Check team needs for Read Only access.
        </b>
      </DrawerText>
      <DrawerText>
        Once BioTrack provides you with the necessary information, enter it below. Then, enable and connect the
        integration.
      </DrawerText>
      <GcvInputForm
        {...form}
        name="server_ip"
        label="Server IP"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="port"
        label="Port"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="database_name"
        label="Database Name"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="username"
        label="Username"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="password"
        label="Password"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
