import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';
import { ViewMoreIcon } from '../../../icons/ViewMoreIcon';

import { DrawerText, DrawerImage, DrawerList } from '../styles';
import { VerticalCenter } from '../../SalesAndDeposits/Deposits/depositDetailDrawer/styles';

interface Props {
  form: any;
}

export const TreezForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>To configure this integration, you’ll need your Treez API key and Org Name.</DrawerText>
      <DrawerText>
        Send the following email to{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:support@treez.io">
          support@treez.io
        </a>
        :
      </DrawerText>
      <DrawerText>
        <b>
          Hello, <br />
          <br />I need some assistance. I would like to integrate our system with the Green Check system. Please provide
          my API credentials so that I can provide them to the Green Check team to set up the integration.
        </b>
      </DrawerText>
      <DrawerText>
        Once Treez provides you with your API key, enter it below. Then, enable and connect the integration.
      </DrawerText>
      <GcvInputForm
        {...form}
        name="apiKey"
        label="API Key"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="dispensaryName"
        label="Org Name"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <DrawerText style={{ marginTop: '18px' }}>Your Org Name can be found in your Portal URL: </DrawerText>
      <DrawerImage style={{ width: '265px', height: '70px' }} src="../../../assets/TreezAPIExample.png"></DrawerImage>
    </>
  );
};
