import { Action } from '@ngrx/store';
import { Deposit } from '@gcv/shared';

export enum DepositActionTypes {
  LoadDepositsById = '[Deposits] Load Deposits By Id',
  DepositsByIdLoaded = '[Deposits] Deposits By Id Loaded',

  LoadDepositsByBankId = '[Deposits] Deposits By Bank Id',
  DepositsByBankIdLoaded = '[Deposits] Deposits By Bank Id Loaded',

  CreateDeposit = '[Deposits] Create Deposit',
  DepositCreated = '[Deposits] Deposit Created',

  AcceptDeposit = '[Deposits] Accept Deposit',
  DepositAccepted = '[Deposits] Deposit Accepted',

  LoadNewDeposit = '[Deposits] Load New Deposit',
  NewDepositLoaded = '[Deposits] New Deposit Loaded',

  ResetDeposits = '[Deposits] Reset Deposits',
}
export class LoadDepositsByBankId implements Action {
  readonly type = DepositActionTypes.LoadDepositsByBankId;

  constructor(public payload: { bankId: string; startDate?: string; endDate?: string }) {}
}
export class DepositsByBankIdLoaded implements Action {
  readonly type = DepositActionTypes.DepositsByBankIdLoaded;

  constructor(public payload: Deposit[][]) {}
}

export class LoadDepositsById implements Action {
  readonly type = DepositActionTypes.LoadDepositsById;

  constructor(public payload: { dispensaryId: string }) {}
}

export class DepositsByIdLoaded implements Action {
  readonly type = DepositActionTypes.DepositsByIdLoaded;

  constructor(public payload: Deposit[]) {}
}

export class CreateDeposit implements Action {
  readonly type = DepositActionTypes.CreateDeposit;

  constructor(public payload: { deposit: Partial<Deposit>; dispensaryId: string }) {}
}

export class DepositCreated implements Action {
  readonly type = DepositActionTypes.DepositCreated;

  constructor(public payload: Deposit) {}
}

export class AcceptDeposit implements Action {
  readonly type = DepositActionTypes.AcceptDeposit;

  constructor(public payload: { deposit: Deposit; bankId: string }) {}
}

export class DepositAccepted implements Action {
  readonly type = DepositActionTypes.DepositAccepted;

  constructor(public payload: Deposit) {}
}

export class LoadNewDeposit implements Action {
  readonly type = DepositActionTypes.LoadNewDeposit;

  constructor(public payload: { dispensaryId: string; maxDepositFeatureFlag?: boolean }) {}
}

export class NewDepositLoaded implements Action {
  readonly type = DepositActionTypes.NewDepositLoaded;

  constructor(public payload: Deposit) {}
}

export class ResetDeposits implements Action {
  readonly type = DepositActionTypes.ResetDeposits;
}

export type DepositActions =
  | LoadDepositsById
  | LoadDepositsByBankId
  | DepositsByIdLoaded
  | DepositsByBankIdLoaded
  | CreateDeposit
  | DepositCreated
  | AcceptDeposit
  | DepositAccepted
  | LoadNewDeposit
  | NewDepositLoaded;
