import styled from 'styled-components';
import { $black, $fontFamily, $grey2, $primaryBorder, $black2 } from '../../../util/styleVariables';
import { GcvCard } from '../../../styles/GcvCard';
import { mediaQuery } from '../../../styles/mediaQuery';

interface LineItemProps {
  indented?: boolean;
}

export const Container = styled.div`
  font-family: ${$fontFamily};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  ${mediaQuery.sm`
    flex-direction: column;
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: ${$black2};
    margin-left: 12px;
    margin-top: 0;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    margin-left: 12px;
    /* margin-top: 2.5px; */
    color: ${$grey2};
  }
`;

export const SalesSummaryCard = styled(GcvCard)`
  flex: 1;
  padding-top: 0;
  padding-bottom: 1.5rem;

  ${mediaQuery.sm`
        width: 80%;
        margin: 0 auto;
  `}
`;

export const DataTableContainer = styled.div`
  flex: 2.5;
  margin-left: 0.5rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: ${$black};
  }
`;

export const CardHeaderTotal = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  position: relative;

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: ${$grey2};
    position: absolute;
    bottom: -14px;
    right: 0;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const LineItem = styled.div<LineItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${$black};
  border-bottom: 1px solid ${$primaryBorder};

  &:nth-child(3n + 3) {
    border-bottom: none;
  }

  padding: 6px;
  padding-left: ${props => (props.indented ? '10px' : 0)};

  .amount {
    font-weight: ${props => (props.indented ? 'normal' : 'bold')};
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: ${$black2};
  }
`;
