import React, { useMemo, useEffect, useState } from 'react';
import { DailySummaries } from './components/Dashboard-DailySummaries';
import { Overview } from './components/Dashboard-Overview';
import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';
import { RouteObject } from '../../util/types';
import { Route } from 'react-router-dom';
import { Header } from './styles';
import { Dispensary, Bank, DueDiligenceStatus } from '@gcv/shared';
import { GcvButton, GcvLoading, GcvZeroState } from '../../lib';

interface Props {
  bank: Bank;
  daysStartDate: string;
  daysEndDate: string;
  dispensaries: { [id: string]: Dispensary };
  emitData: (type: string) => any;
  viewStatus: string;
}

const Tabs: RouteObject[] = [
  {
    path: '/secure/bank/dashboard/overview',
    render: props => <Overview {...props}></Overview>,
  },
  {
    path: '/secure/bank/dashboard/daily-summaries',
    render: props => <DailySummaries {...props} bankId={props.bank.id} />,
  },
];

export const Dashboard = (props: Props) => {
  const dashboardTabs = [
    { path: `/secure/bank/dashboard/overview`, label: `Overview` },
    { path: `/secure/bank/dashboard/daily-summaries`, label: `Daily Summaries` },
  ];

  return (
    <>
      <Header>Dashboard</Header>
      {!props.bank || !props.dispensaries ? (
        <GcvLoading></GcvLoading>
      ) : props.viewStatus !== 'DefaultState' ? (
        <>
          <GcvZeroState
            headerText="Configure Your Program"
            dashboardSubText="To begin accepting applications from cannabis businesses please finish setting up your company profile."
            bankDD={true}
            button={
              <GcvButton
                secondary={props.viewStatus === 'DueDiligenceInProgressState'}
                primary={props.viewStatus === 'DueDiligenceZeroState'}
                onClick={() => props.emitData('goToOnboarding')}
              >
                {props.viewStatus === 'DueDiligenceZeroState' ? "Let's Go" : 'Continue'}
              </GcvButton>
            }
          ></GcvZeroState>
        </>
      ) : (
        <>
          <div style={{ margin: '1rem 0', justifyContent: 'space-between', display: 'flex' }}>
            <GcvTabs routes={dashboardTabs}></GcvTabs>
          </div>
          {Tabs.map(({ path, render }) => {
            return (
              <Route exact path={path} key={path}>
                {({ match }) => (match != null ? render(props) : <></>)}
              </Route>
            );
          })}
        </>
      )}
    </>
  );
};
