import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { api } from 'libs/react-ui/src/api';
import { GcvButton, GcvInputSelect, GcvModal } from 'libs/react-ui/src/lib';
import { Bank } from '@gcv/shared';

interface Props {
  bank: Bank;
  dispensaryOptions: Option[];
  modalOpen: boolean;
  toggleModal: () => void;
  snackBarCallback: (message: string) => void;
  addReviewCallback: (review: any) => void;
}

interface Option {
  value: string;
  label: string;
}

export const CreateReviewModal: React.FC<Props> = props => {
  const apiClient = api();
  const history = useHistory();
  const { handleSubmit, formState, reset, ...form } = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = useState(false);
  const [timePeriodOptions, setTimePeriodOptions] = useState([]);

  const getTimePeriodDateRange = (period: string) => {
    const periodParts = period.split('-');
    let startDate = '';
    let endDate = '';

    switch (periodParts[0]) {
      case 'monthly':
        const lastDayOfMonth = new Date(+periodParts[2], +periodParts[1], 0).getDate();

        startDate = `${periodParts[1]}/01/${periodParts[2]}`;
        endDate = `${periodParts[1]}/${lastDayOfMonth}/${periodParts[2]}`;
        break;
      case 'quarterly':
        switch (periodParts[1]) {
          case '1':
            startDate = `01/01/${periodParts[2]}`;
            endDate = `03/31/${periodParts[2]}`;
            break;
          case '2':
            startDate = `04/01/${periodParts[2]}`;
            endDate = `06/30/${periodParts[2]}`;
            break;
          case '3':
            startDate = `07/01/${periodParts[2]}`;
            endDate = `09/30/${periodParts[2]}`;
            break;
          case '4':
            startDate = `10/01/${periodParts[2]}`;
            endDate = `12/31/${periodParts[2]}`;
            break;
        }

        break;
      case 'yearly':
        startDate = `01/01/${periodParts[1]}`;
        endDate = `12/31/${periodParts[1]}`;
        break;
    }

    return { startDate, endDate };
  };

  const getTimePeriodOptions = (e: { value: string; label: string }) => {
    const type = e.value;

    const date = new Date();
    // starts at 0 so add 1 to match actual month number
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const lastYear = date.getFullYear() - 1;

    const months = {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    const options: Option[] = [];

    switch (type) {
      case 'monthly':
        if (currentMonth > 6) {
          for (let x = 1; x <= 6; x++) {
            let month = currentMonth - x;

            options.push({
              value: `monthly-${month.toString().padStart(2, '0')}-${currentYear}`,
              label: `${months[month]} - ${currentYear}`,
            });
          }
        } else {
          for (let x = 1; x <= 6; x++) {
            let month = currentMonth - x;
            let year = currentYear;

            if (month < 1) {
              // add the negative month to the end of the year
              month = 12 + month;
              year = lastYear;
            }

            options.push({
              value: `monthly-${month.toString().padStart(2, '0')}-${year}`,
              label: `${months[month]} - ${year}`,
            });
          }
        }
        break;
      case 'quarterly':
        let currentQuarter = 0;
        let year = currentYear;

        if (date <= new Date(`3/31/${currentYear}`)) {
          currentQuarter = 1;
        } else if (date <= new Date(`6/20/${currentYear}`)) {
          currentQuarter = 2;
        } else if (date <= new Date(`8/30/${currentYear}`)) {
          currentQuarter = 3;
        } else {
          currentQuarter = 4;
        }

        for (let x = 1; x <= 4; x++) {
          let quarter = currentQuarter - x;

          if (quarter < 1) {
            // add negative quarter to end of year
            quarter = 4 + quarter;
            year = lastYear;
          }

          options.push({
            value: `quarterly-${quarter}-${year}`,
            label: `Q${quarter} - ${year}`,
          });
        }

        break;
      case 'yearly':
        options.push({
          value: `yearly-${lastYear}`,
          label: lastYear.toString(),
        });
        break;
    }

    setTimePeriodOptions(options);

    if (!options.map(o => o.value).includes(form.control.fields['timePeriod'].ref.value.value)) {
      //@ts-ignore
      reset({ timePeriod: '' });
    }
  };

  const createReview = () => {
    const dispensaryId = form.watch('selectedDispensary').value;

    const dateRange = getTimePeriodDateRange(form.watch('timePeriod').value);

    const startDate = DateTime.fromFormat(dateRange.startDate, 'MM/dd/yyyy')
      .toUTC()
      .toString();
    const endDate = DateTime.fromFormat(dateRange.endDate, 'MM/dd/yyyy')
      .toUTC()
      .toString();
    const foreGround = !form.watch('timePeriod').value.includes('yearly'); //can change when we decide to do foreground vs background

    apiClient.accountMonitoring
      .createReview(
        props.bank.id,
        dispensaryId,
        startDate,
        endDate,
        form.watch('timePeriod').label,
        foreGround,
        setIsLoading
      )
      .then(r => {
        props.toggleModal();
        if (!foreGround) {
          props.snackBarCallback(
            'Your review is being processed now. When it’s ready, it will be available on the Open Reviews tab.'
          );
          props.addReviewCallback(r['id']);
        } else {
          history.push(`/secure/bank/account-monitoring/review/${r.id}/overview`);
        }
      })
      .catch(e => {
        props.snackBarCallback('Something went wrong. Please try again shortly.');
      });
  };

  return (
    <GcvModal
      toggleModal={props.toggleModal}
      modalOpen={props.modalOpen}
      backButton={
        <GcvButton tertiary onClick={props.toggleModal}>
          Cancel
        </GcvButton>
      }
      continueButton={
        <GcvButton onClick={createReview} primary={true} disabled={!formState.isValid}>
          Create
        </GcvButton>
      }
      title={'Create Review'}
      loading={isLoading}
    >
      <GcvInputSelect
        {...form}
        name={'selectedDispensary'}
        label={'Account'}
        options={props.dispensaryOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
        rules={{ required: { value: true, message: 'is required' } }}
        defaultValue={''}
      />

      <GcvInputSelect
        {...form}
        name={'timePeriodType'}
        label={'Review Length'}
        options={[
          {
            value: 'monthly',
            label: 'Monthly',
          },
          {
            value: 'quarterly',
            label: 'Quarterly',
          },
          {
            value: 'yearly',
            label: 'Yearly',
          },
        ]}
        rules={{ required: { value: true, message: 'is required' } }}
        defaultValue={''}
        onChangeCallback={e => getTimePeriodOptions(e)}
      />

      <GcvInputSelect
        {...form}
        name={'timePeriod'}
        label={'Review Period'}
        options={timePeriodOptions}
        rules={{ required: { value: true, message: 'is required' } }}
        defaultValue={''}
      />
    </GcvModal>
  );
};
