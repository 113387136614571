import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { s3Util } from '../../util/s3.util';

import {
  Dispensary,
  Bank,
  User,
  DueDiligenceDocumentStatus,
  DueDiligenceRequirementStatus,
  DueDiligenceStatus,
  DueDiligenceDocument,
} from '@gcv/shared';

import ReactTooltip from 'react-tooltip';

import {
  GcvDataTable,
  GcvLoading,
  GcvZeroState,
  GcvCheckbox,
  GcvButton,
  GcvUserInputContainer,
  GcvReadMore,
  GcvInputTextArea,
} from '../../lib';

import { useApi, getEndpoint, getData, getV2Endpoint } from '../../hooks/';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';

import { fileName, reviewedBy, uploadedOn } from './columns';
import { formatUserFirstAndLastName, checkPermissions, formatISOToDateAtTime } from 'libs/react-ui/src/util';

import styled from 'styled-components';

//#region Styles

import { $blue, $grey11, $grey9, $primary, $primaryDark, $white } from '../../util/styleVariables';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { ActivityFeed } from '../../shared/Requirements/RequirementDetailView/ActivityFeed';
import { GcvDropButton } from '../../lib/GcvDropButton/GcvDropButton';
import { RequestFileUploadModal, AddCommentModal, UploadDocumentModal } from '../../shared/Requirements/Modals';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { AccountDocumentDrawer } from './components/AccountDocumentDrawer';
import { VerticalCenter, FlexBox } from '../../styles/theme';
import { getEmptyRequirement, mergeGcvAndBankRequirements } from '../../shared/Requirements/due-diligence-util';
import { api } from '../../api';

const SubHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0px;
  vertical-align: middle;
`;

const TableContainer = styled.div`
  margin-right: 13px;
  flex-grow: 1;
  box-sizing: border-box;
`;

const ArchiveContainer = styled.div`
  display: flex;
  height: 3rem;
  justify-content: space-between;
`;
const ArchiveSpacer = styled.div`
  width: 100%;
  height: 3rem;
`;

const Archive = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ArchiveText = styled.span`
  color: ${$blue};
  margin-left: 5px;
`;

const ContentContainerBottom = styled.div`
  display: flex;
`;

export const ViewFile = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  margin: 15px auto;
  cursor: pointer;
`;

export const HorizontalCenter = styled.div`
  margin: 60px 0;
  display: flex;
  justify-content: center;
`;

export const HeaderContent = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export const Spacer = styled.div`
  margin: 20px 0px;
`;

export const Body = styled.div`
  padding: 0px 75px 75px 75px;
  overflow-y: auto;
`;

export const SubTitle = styled.div`
  margin-top: 18px;
  font-size: 13px;
  line-height: 16px;
  color: ${$grey9};
`;

export const SubContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 2px 0px;
`;

export const ReadMoreButton = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  &: hover {
    cursor: pointer;
  }
`;

export const UserInput = styled.div`
  border: none;
`;

export const Block = styled.div`
  padding: 10px 0px;
  margin: 30px 0px;
  border-top: 1px solid ${$grey11};
`;

export const Footer = styled.div`
  border-top: 1px solid ${$grey11};
  padding: 25px;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${$white};
  flex-shrink: 0;
`;

export const DrawerContent = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
`;

//#endregion

interface Props {
  dispensaries: Dispensary[];
  bank: Bank;
  user: User;
  emitData: (type, data?) => any;
}

export const RequirementView = ({ dispensaries, bank, user, emitData }: Props) => {
  const { API } = useApi();
  const apiClient = api();

  const history = useHistory();
  const { dispensary_id, req_id } = useParams<{ dispensary_id: string; req_id: string }>();
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [clearRows, setClearRows] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [requirementMetadata, setRequirementMetadata] = useState(null);
  const [documentsMap, setDocumentsMap] = useState(null);
  const [userMap, setUserMap] = useState(null);
  const [viewArchived, setViewArchived] = useState(false);
  const [selectedArchivedRows, setSelectedArchivedRows] = useState([]);
  const [selectedActiveRows, setSelectedActiveRows] = useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  const [dueDiligenceTemplateRequirement, setDueDiligenceTemplateRequirement] = useState(null);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState<boolean>(false);
  const [addCommentModalOpen, setAddCommentModalOpen] = useState<boolean>(false);
  const buttonContainerRef = useRef();
  const buttonInnerRef = useRef();
  const [requestUploadModalOpen, setRequestUploadModalOpen] = useState(false);
  const dispensary = dispensaries ? dispensaries.find(disp => disp.id === dispensary_id) : null;

  const archivedFiles = [];
  const activeFiles = [];

  const getRequirementData = (updatedDispensary?) => {
    const formatDocumentdata = (documentsArray: DueDiligenceDocument[]) => {
      const documentMap = Object.values(documentsArray).reduce((acc, doc) => {
        acc[doc.id] = doc;
        return acc;
      }, {});
      setDocumentsMap(documentMap);
    };
    if (updatedDispensary) {
      emitData('updateDispensary', updatedDispensary);
      setReloadData(!reloadData);
    } else {
      getData.getDocuments(API, formatDocumentdata, dispensary_id);
    }
  };

  const addRequirementMetadataToState = dispensary => {
    const requirements = dispensary.due_diligence.requirements;
    const singleReq = requirements[req_id]
      ? requirements[req_id]
      : getEmptyRequirement(dueDiligenceTemplateRequirement);

    setRequirementMetadata(singleReq);
    setDataLoading(false);
    setSelectedActiveRows([]);
    setSelectedArchivedRows([]);
    setClearRows(!clearRows);
  };

  useEffect(() => {
    if (viewMore) {
      document.addEventListener('mousedown', handleViewMoreBlur);
      return () => {
        document.removeEventListener('mousedown', handleViewMoreBlur);
      };
    }
  }, [viewMore]);

  useEffect(() => {
    if (dispensary_id && dispensary) {
      getRequirementData();
      addRequirementMetadataToState(dispensary);

      const mergedReqs = mergeGcvAndBankRequirements(bank.due_diligence_template, dispensary);
      setDueDiligenceTemplateRequirement(mergedReqs[req_id]);
    }
    if (userMap === null) {
      getData.getUsers(API, setUserMap, dispensary_id, bank ? bank.id : null);
    }
  }, [dispensary, bank, bank.due_diligence_template]);

  if (!dispensary || !documentsMap || !requirementMetadata || !userMap || !dueDiligenceTemplateRequirement) {
    return <GcvLoading />;
  }

  if (requirementMetadata === 'Requirement Not Found') {
    return (
      <div style={{ margin: '0 auto' }}>
        <SubHeader>Sorry Requirement Not Found.</SubHeader>
        <GcvButton primary={true} onClick={getRequirementData}>
          Try again
        </GcvButton>
      </div>
    );
  }

  const handleViewMoreBlur = e => {
    if (buttonContainerRef && buttonInnerRef) {
      // @ts-ignore
      if (!buttonContainerRef.current.contains(e.target) && !buttonInnerRef.current.contains(e.target)) {
        setViewMore(false);
      }
    }
  };

  const handleRowClick = row => {
    setSelectedDocumentId(row.docId);
  };

  const onSelectedRowsChange = props => {
    viewArchived ? setSelectedArchivedRows(props.selectedRows) : setSelectedActiveRows(props.selectedRows);
  };

  const isRowDisabled = row => {
    return row.status !== DueDiligenceDocumentStatus.APPROVED && row.status !== DueDiligenceDocumentStatus.ARCHIVED;
  };

  const handleBackClick = () => {
    if (dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_APPROVED) {
      history.push(`/secure/bank/account/${dispensary.id}/review/documentation`);
    } else {
      history.push(`/secure/bank/account/${dispensary.id}/view/documents`);
    }
  };

  const approveRequirement = () => {
    const body = {
      status: DueDiligenceRequirementStatus.REVIEWED,
    };

    apiClient.documentManagement
      .updateRequirementStatus(dispensary.id, req_id, body, () => {})
      .then(updatedDispensary => {
        getRequirementData(updatedDispensary);
      })
      .catch(err => {
        alert('error approving requirement, please contact support@greencheckverified.com' + err);
      });
  };

  const isApprovalDisabled = () => {
    const areActiveFilesReviewed = activeFiles.reduce(
      (acc, file) => acc && file.status === DueDiligenceDocumentStatus.APPROVED,
      true
    );

    const isRequirementAlreadyReviewed = requirementMetadata.status === DueDiligenceRequirementStatus.REVIEWED;

    const isPendingAccountWithNoDocuments =
      dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_AWAITING_REVIEW &&
      dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_APPROVED &&
      activeFiles.length === 0;

    return (
      !areActiveFilesReviewed ||
      !permissions.updateRequirement ||
      isRequirementAlreadyReviewed ||
      isPendingAccountWithNoDocuments
    );
  };

  const updateDocument = (docId, status, note?) => {
    const body = {
      status: status,
      note: note,
    };

    return apiClient.documentManagement.updateDocumentStatus(dispensary.id, req_id, docId, body, () => {});
  };

  const updateArchiveStatus = async (status: DueDiligenceDocumentStatus, selectedRows) => {
    const docIds = selectedRows.map(row => row.docId);
    let updatedDispensary;
    for (const docId of docIds) {
      try {
        updatedDispensary = await updateDocument(docId, status);
      } catch (err) {
        alert('error updating document status, please contact support@greencheckverified.com' + err);
      }
    }

    getRequirementData(updatedDispensary);
  };

  const updateFiles = () => {
    viewArchived
      ? updateArchiveStatus(DueDiligenceDocumentStatus.APPROVED, selectedArchivedRows)
      : updateArchiveStatus(DueDiligenceDocumentStatus.ARCHIVED, selectedActiveRows);
  };

  const zeroStateText = viewArchived ? 'You currently have no Archived Files' : 'You currently have no Active Files';

  const columns = [
    fileName('File Name', handleRowClick),
    uploadedOn('Uploaded On'),
    reviewedBy('Reviewed By', handleRowClick),
  ];

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'Row must be reviewed before archiving';
    return props.disabled ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  const permissions = {
    updateRequirement: checkPermissions(['requirement_approve'], user, bank),
    updateDocument: checkPermissions(['document_approve'], user, bank),
  };

  const documentType =
    dueDiligenceTemplateRequirement.type === 'business_licenses' ||
    dueDiligenceTemplateRequirement.type === 'employee_licenses'
      ? 'license'
      : 'document';

  Object.keys(requirementMetadata.documents).map(documentKey => {
    if (documentsMap[documentKey]) {
      const document = {
        uploadDate: documentsMap[documentKey].upload_date,
        documentName: documentsMap[documentKey].file_name,
        reviewedBy: requirementMetadata.documents[documentKey].reviewed_by
          ? formatUserFirstAndLastName(userMap[requirementMetadata.documents[documentKey].reviewed_by])
          : '',
        docId: documentKey,
        status: requirementMetadata.documents[documentKey].status,
      };
      requirementMetadata.documents[documentKey].status === DueDiligenceDocumentStatus.ARCHIVED
        ? archivedFiles.push(document)
        : activeFiles.push(document);
    }
  });

  return (
    <GcvPage
      loader={!dispensary && <GcvLoading></GcvLoading>}
      header={
        <>
          <h1>{dispensary.name}</h1>
          <GcvPageHeader
            handleBackClick={handleBackClick}
            title={dueDiligenceTemplateRequirement.name}
            subTitle={dueDiligenceTemplateRequirement.description}
            actions={
              <VerticalCenter>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {permissions.updateDocument ? (
                    <GcvDropButton
                      title={'Update'}
                      secondary={true}
                      dataCy={'update-requirement-menu'}
                      buttons={[
                        {
                          title: 'Upload File',
                          onClick: () => setUploadFileModalOpen(!uploadFileModalOpen),
                          dataCy: 'upload-file-button',
                        },
                        {
                          title: 'Request Upload',
                          onClick: () => setRequestUploadModalOpen(!requestUploadModalOpen),
                          dataCy: 'request-upload-button',
                        },
                        {
                          title: 'Add Comment',
                          onClick: () => setAddCommentModalOpen(!addCommentModalOpen),
                          dataCy: 'add-comment-button',
                        },
                      ]}
                    ></GcvDropButton>
                  ) : null}
                  <GcvButton
                    primary={true}
                    onClick={approveRequirement}
                    disabled={isApprovalDisabled()}
                    data-cy="approve-requirement"
                  >
                    {requirementMetadata.status === 'reviewed' ? 'Approved' : 'Approve'}
                  </GcvButton>
                </div>
              </VerticalCenter>
            }
          ></GcvPageHeader>
        </>
      }
      body={
        <>
          <FlexBox>
            <TableContainer data-cy="requirement-documents-table">
              <ArchiveContainer>
                <div>
                  {viewArchived && selectedArchivedRows.length > 0 ? (
                    <GcvButton primary={true} onClick={updateFiles}>
                      Restore Files to Active
                    </GcvButton>
                  ) : null}
                  {!viewArchived && selectedActiveRows.length > 0 ? (
                    <GcvButton primary={true} onClick={updateFiles}>
                      Archive Files
                    </GcvButton>
                  ) : null}
                </div>
                <Archive onClick={() => setViewArchived(!viewArchived)} data-cy="active-archived-switch">
                  <span>
                    Switch To:
                    <ArchiveText>
                      {viewArchived
                        ? `Active Files (${activeFiles.length})`
                        : `Archived Files (${archivedFiles.length})`}
                    </ArchiveText>
                  </span>
                </Archive>
              </ArchiveContainer>
              <GcvDataTable
                data={viewArchived ? archivedFiles : activeFiles}
                columns={columns}
                onRowClicked={handleRowClick}
                defaultSortField={'Requirement'}
                defaultSortAsc={false}
                keyField="docId"
                noDataComponent={<GcvZeroState basic={true} headerText={zeroStateText} />}
                subHeaderAlign="left"
                selectableRows={true}
                clearSelectedRows={clearRows}
                onSelectedRowsChange={onSelectedRowsChange}
                selectableRowsComponent={renderSelectComponent}
                selectableRowDisabled={isRowDisabled}
                progressPending={dataLoading}
                paginationPerPage={8}
              />
            </TableContainer>

            <div style={{ flexGrow: 1 }}>
              <ArchiveSpacer />
              <ActivityFeed
                openFile={id => setSelectedDocumentId(id)}
                dispensaryId={dispensary_id}
                reqId={req_id}
                reloadData={reloadData}
              />
            </div>
          </FlexBox>

          <RequestFileUploadModal
            updateApplication={() => setReloadData(!reloadData)}
            dispensaryId={dispensary.id}
            requirementId={req_id}
            modalOpen={requestUploadModalOpen}
            toggleModal={() => setRequestUploadModalOpen(!requestUploadModalOpen)}
          />
          <AddCommentModal
            title={'Add an internal comment'}
            subTitle={'The business will not be able to view this comment'}
            updateApplication={() => setReloadData(!reloadData)}
            dispensaryId={dispensary_id}
            requirementId={req_id}
            userMap={userMap}
            modalOpen={addCommentModalOpen}
            toggleModal={() => setAddCommentModalOpen(!addCommentModalOpen)}
          />

          <UploadDocumentModal
            dispensaryState={dispensary.state}
            subTitle={'The business will see files uploaded here in their Company Profile'}
            userType={'bank'}
            updateApplication={getRequirementData}
            requirementName={requirementMetadata.name}
            dispensaryId={dispensary_id}
            requirementId={req_id}
            userMap={userMap}
            documentType={documentType}
            modalOpen={uploadFileModalOpen}
            toggleModal={() => setUploadFileModalOpen(!uploadFileModalOpen)}
          />

          <AccountDocumentDrawer
            document={documentsMap[selectedDocumentId]}
            requirementMetadata={requirementMetadata}
            userMap={userMap}
            dispensary={dispensary}
            req_id={req_id}
            permissions={permissions}
            onCloseDrawer={() => setSelectedDocumentId('')}
            getRequirementData={getRequirementData}
            updateDocument={updateDocument}
            documentType={documentType}
          />
        </>
      }
    />
  );
};
