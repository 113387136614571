import React from 'react';
import { AccountCard } from './AccountCard';
import { Container, GridContainer } from './styles';
import { GcvZeroState, GcvDataTable } from '../../../lib';
import { Dispensary, DueDiligenceStatus } from '@gcv/shared';
import { getDispensaryLicenseType } from 'libs/react-ui/src/util';

interface Props {
  accounts: any[];
  emitData?: (e) => any;
  tableView: boolean;
  tableColumns: any[];
  sortKey: string;
  isPending?: boolean;
  noAccountsZeroStateText: string;
  isFiltered: boolean;
}

export const AccountCardContainer = ({
  accounts,
  emitData,
  tableView,
  tableColumns,
  sortKey,
  isPending,
  noAccountsZeroStateText,
  isFiltered,
}: Props) => {
  const createProps = dispensary => {
    return {
      name: dispensary.name,
      complianceScore: dispensary.compliance_score,
      logo: '../../../../assets/icon_account_circle.svg',
      isApproved: dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED,
      isAwaitingReview: dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW,
    };
  };

  const handleButtonClicked = dispensary => {
    return () => {
      emitData(dispensary);
    };
  };

  const handleRowClicked = dispensary => {
    emitData(dispensary);
  };

  const renderCards = () => {
    return accounts.map(dispensary => {
      const accountCardProps = createProps(dispensary);
      return <AccountCard key={dispensary.id} {...accountCardProps} onButtonClick={handleButtonClicked(dispensary)} />;
    });
  };

  const data = accounts.map((dispensary: Dispensary) => {
    return {
      date_updated: dispensary.date_updated, // TODO: determine this some other way
      compliance_score: dispensary.compliance_score,
      submitted_date: dispensary.due_diligence.bank_submitted_date,
      reviewed_date: dispensary.due_diligence.bank_reviewed_on,
      due_diligence: dispensary.due_diligence,
      date_created: dispensary.date_created,
      license_type: getDispensaryLicenseType(dispensary),
      city: dispensary.city,
      state: dispensary.state,
      name: dispensary.name,
      id: dispensary.id,
    };
  });

  return (
    <Container>
      {accounts.length > 0 ? (
        tableView ? (
          <GcvDataTable
            data={data}
            columns={tableColumns}
            onRowClicked={handleRowClicked}
            defaultSortField={sortKey}
            defaultSortAsc={false}
            keyField="id"
            subHeaderAlign="left"
            paginationPerPage={8}
            pointerOnHover={true}
          />
        ) : (
          <GridContainer>{renderCards()}</GridContainer>
        )
      ) : isFiltered ? (
        <GcvZeroState
          basic={true}
          headerText={'There are no accounts that match your filter'}
          subText={'Use the search bar to change or remove your filter'}
        />
      ) : (
        <GcvZeroState
          basic={true}
          headerText={noAccountsZeroStateText}
          subText={"Don't worry, Green Check will notify you when there's a new account to review"}
        />
      )}
    </Container>
  );
};
