import React, { useState, useEffect } from 'react';
import { GcvDataTable, GcvLoading, GcvZeroState } from '../../../lib';
import {
  finalDeposit,
  dateCreated,
  createdBy,
  expectedArrivalDate,
  status,
  crbAccount,
  methodOfTransportation,
  arrivalDate,
} from './columns';
import { DepositsProps } from './types';
import { DepositDetailDrawer } from './depositDetailDrawer/DepositDetailDrawer';
import _ from 'lodash';
import useQueryString from '../../../hooks/useQueryString';

export const DepositsCRB = (props: DepositsProps) => {
  const headerText = 'There are no Green Check transactions available for this time period';

  const [selectedDepositId, setSelectedDepositId] = useQueryString('id', undefined);
  const [deposit, setDeposit] = useState(null);

  useEffect(() => {
    setDeposit(props.deposits.find(el => el.deposit_id === selectedDepositId));
  }, [selectedDepositId, props.deposits]);

  const handleRowClick = rowData => {
    if (props.depositDetailFlag) {
      setSelectedDepositId(rowData.deposit_id);
    } else {
      const deposit = props.deposits.find(el => el.deposit_id === rowData.deposit_id);
      props.emitData('viewDeposit', deposit, props.bankId);
    }
  };

  const clearSelectedDepositId = () => {
    setSelectedDepositId(null);
  };

  const columnsCRB = () => [
    finalDeposit('Deposit Amount'),
    dateCreated('Created On'),
    createdBy('Created By', props.staff),
    expectedArrivalDate('Planned Arrival Date'),
  ];

  const columnsFI = () => [
    crbAccount('Account', props.dispensaries),
    finalDeposit('Amount'),
    methodOfTransportation('Method of Transportation'),
    expectedArrivalDate('Planned Arrival Date'),
    arrivalDate('Date Arrived'),
    status('Status', handleRowClick),
  ];

  const columns = props.type === 'bank' ? columnsFI() : columnsCRB();

  const defaultSortField = props.type === 'bank' ? 'expected_arrival_date' : 'date_created';

  if (props.loading) {
    return <GcvLoading />;
  }

  const sort = (rows, field: any = '', direction, sortFn) => {
    // if MOT, Account, or Created By fields, sort case insensitive
    // do not try this at home
    const stringifiedField = field.toString();
    if (
      typeof field === 'function' &&
      (stringifiedField.includes('delMethod') ||
        stringifiedField.includes("[row.dispensary_id]['name']") ||
        stringifiedField.includes('created_by'))
    ) {
      return sortCaseInsensitive(rows, field, direction);
    }

    if (sortFn && typeof sortFn === 'function') {
      return sortFn(rows, field, direction);
    }

    if (stringifiedField.includes('expected_arrival_date')) {
      return _.orderBy(rows, ({ expected_arrival_date }) => expected_arrival_date || '', direction);
    }

    return _.orderBy(rows, field, direction);
  };

  const sortCaseInsensitive = (rows, selectorFn, direction) => {
    const handleField = row => {
      const value = selectorFn(row);

      if (value) {
        return value.toLowerCase();
      }
      return '';
    };
    return _.orderBy(rows, handleField, direction);
  };

  return (
    <div data-cy="bank-deposits.component.deposits">
      {props.deposits && props.deposits.length ? (
        <GcvDataTable
          data={props.deposits}
          columns={columns}
          onRowClicked={handleRowClick}
          defaultSortField={defaultSortField}
          defaultSortAsc={false}
          keyField="deposit_id"
          sortFunction={sort}
        />
      ) : (
        <GcvZeroState basic={true} headerText={headerText} />
      )}

      <DepositDetailDrawer
        open={selectedDepositId && deposit}
        onClose={clearSelectedDepositId}
        deposit={deposit}
        emitData={props.emitData}
        bankId={props.bankId}
        userMap={props.staff}
        type={props.type}
        dispensaryMap={props.dispensaries}
      />
    </div>
  );
};
