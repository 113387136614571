import { Dispensary, IANATimezones } from '@gcv/shared';
import { DateTime } from 'luxon';

export interface DispensaryMap {
  [id: string]: Dispensary;
}

export const getTableDataSar = (sarReports: any[], dispensaryMap: DispensaryMap, bankTimezone: IANATimezones) => {
  return sarReports.map(sar => {
    if (sar.part4FormGroup && sar.homeGroup) {
      const { suspiciousActivityFromDate, suspiciousActivityToDate, amountInvolvedInThisReport } = sar.part4FormGroup;
      const { continuingActivityReport, correctOrAmendPriorReport, initialReport } = sar.homeGroup;
      let sarType = '';

      if (continuingActivityReport && correctOrAmendPriorReport) {
        sarType = 'Continuing Activity Report + Correct/Amend Prior Report';
      } else if (continuingActivityReport) {
        sarType = 'Continuing Activity Report';
      } else if (correctOrAmendPriorReport) {
        sarType = 'Correct/Amend Prior Report';
      } else if (initialReport) {
        sarType = 'Initial Report';
      }

      //use user-entered account name if exists
      let entity;
      if (sar.part3FormArray) {
        entity = sar.part3FormArray.find(p => p.isSubjectInformationEntity)
          ? sar.part3FormArray.find(p => p.isSubjectInformationEntity)
          : sar.part3FormArray[0];
      }
      const accountName = entity
        ? entity.individualsLastNameOrEntitysLegalName
        : dispensaryMap[sar.dispensary_id]
        ? dispensaryMap[sar.dispensary_id].name
        : '';
      const dueDateFrom = suspiciousActivityToDate ? suspiciousActivityToDate : suspiciousActivityFromDate;
      const dueDate = DateTime.fromISO(dueDateFrom, { zone: bankTimezone })
        .toUTC()
        .plus({ days: 30 })
        .toISO();
      return {
        id: sar.id,
        account: accountName,
        dueDate: dueDate,
        amount: amountInvolvedInThisReport ? Number(amountInvolvedInThisReport) : 0,
        exportedOn: sar.exported_on,
        reportType: sarType,
        status: sar.status,
        bsaId: sar.bsaId,
        trackingId: sar.trackingId,
        report: sar,
      };
    }
  });
};

export const getTableDataCtr = (ctrReports: any[], dispensaryMap: DispensaryMap, bankTimezone: IANATimezones) => {
  return ctrReports.map(ctr => {
    if (ctr.part4FormGroup) {
      const { totalCashIn, totalCashOut, dateOfTransaction } = ctr.part4FormGroup;

      //use user-entered account name if exists
      let entity;
      if (ctr.part3FormArray) {
        entity =
          ctr.part3FormArray && ctr.part3FormArray.find(p => p.ifEntity)
            ? ctr.part3FormArray.find(p => p.ifEntity)
            : ctr.part3FormArray[0];
      }
      const accountName = entity
        ? entity.legalNameOrLastName
        : dispensaryMap[ctr.dispensary_id]
        ? dispensaryMap[ctr.dispensary_id].name
        : '';
      const transactionDateUtc = dateOfTransaction
        ? DateTime.fromISO(dateOfTransaction)
            .toUTC()
            .toISO()
        : '';
      const dueDate = transactionDateUtc
        ? DateTime.fromISO(transactionDateUtc, { zone: bankTimezone })
            .toUTC()
            .plus({ days: 15 })
            .toISO()
        : '';
      return {
        id: ctr.id,
        account: accountName,
        dueDate: dueDate,
        dateOfTransaction: transactionDateUtc,
        amount: Number(totalCashIn ? totalCashIn : '0') + Number(totalCashOut ? totalCashOut : 0),
        exportedOn: ctr.exported_on,
        status: ctr.status,
        bsaId: ctr.bsaId,
        trackingId: ctr.trackingId,
        report: ctr,
      };
    }
  });
};
